import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
// import { toast } from 'react-toastify';
import GeneratedImagesSection from './GenerateImages';
import { v4 as uuidv4 } from 'uuid';
import FooterSection from '../FooterLayout';
import AlertModal from 'components/AlertModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import SocialMediaHeader from '../SocialMediaHeader';
import cn from 'classnames';
import { handleTokenExpiryIssue } from 'utils/functions.util';
import { BASE_URL } from 'utils/baseURL';

const OutputList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = window.location.pathname;
  const [apiCallIndex, setApiCallIndex] = useState();
  const [generatedImagesData, setGeneratedImagesData] = useState([]);
  const [recentGenImgList, setRecentGenImgList] = useState();
  const [generateLoading, setGenerateLoading] = useState(false);
  const [imageByTextPid, setImageByTextPid] = useState();
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [renderImageList, setRenderImageList] = useState(false);
  const generateData = JSON.parse(localStorage.getItem('tempData'));
  const displaySocialMediaHeader = path.includes('InstagramPost') || path.includes('FacebookPost');
  const [isUpScaled, setIsUpScaled] = useState(false);
  const [upScaledImgGen, setUpScaledImgGen] = useState(true);
  const [resizeImgGen, setResizeImgGen] = useState(true);

  const upScaledImgGenData = location.state?.selectedImage;
  const selectedTemp = JSON.parse(localStorage.getItem('selectedTemp'));

  const handleRecentImageOrigin = () => {
    let tempOrigin = '';
    if (path.includes('PhotoStudio')) {
      tempOrigin = 'Photo Studio';
    } else if (path.includes('ImageGenerator')) {
      tempOrigin = 'Image Generator';
    } else if (path.includes('InstagramPost')) {
      tempOrigin = 'Instagram';
    } else if (path.includes('FacebookPost')) {
      tempOrigin = 'Facebook';
    }
    return tempOrigin.toString();
  };

  const handleRecentGeneratedImages = async () => {
    // setRenderImageList(true);
    const data = new FormData();
    data.append('origin', handleRecentImageOrigin());
    const url = BASE_URL + 'image-gen/recent_images';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response?.data) {
        setGeneratedImagesData(response?.data?.data?.recent_images);
        setRecentGenImgList(response?.data?.data?.recent_images);
        console.log(response?.data?.detail?.message);
      }
    } catch (err) {
      console.log('Something went wrong');
    }
    setRenderImageList(false);
  };

  const generateIndividualImage = async (i, listData) => {
    if (apiCallIndex === 0) {
      setGenerateLoading(true);
    }
    const data = new FormData();
    data.append('prompt', generateData?.prompt?.prompt);
    data.append('theme', generateData?.prompt?.theme);
    data.append('pid', generateData?.selectedImageToGen?.pid);
    data.append('coordinates_str', JSON.stringify(generateData?.imageListData));
    data.append('canvas_height', generateData?.canvasWidth);
    data.append('canvas_width', generateData?.canvasWidth);
    data.append('shadow_option', '');
    data.append('gen_height', 1024);
    data.append('gen_width', 1024);
    data.append('style', generateData?.selectedImageStyle?.theme);
    data.append('img_generator', false);
    data.append('origin', selectedTemp?.name);

    const url = BASE_URL + 'image-gen/image_gen_single';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response.data && response.data.data) {
        listData[i] = { ...response?.data?.data, thumbnail: response?.data?.data?.url };
        setGeneratedImagesData([...listData]);
        // if (apiCallIndex === 0) {
        //   setGenerateLoading(false);
        // }
        if (apiCallIndex === 3) {
          setGenerateLoading(false);
        }
        return true;
      } else if (
        response.data.status_code === 400 &&
        response.data.detail.message === 'Your token has expired, Please login again'
      ) {
        handleTokenExpiryIssue();
      } else if (response.data.status_code === 400) {
        console.log(response.data.detail.message);
      }
    } catch (err) {
      setGenerateLoading(false);
      if (err?.response?.status === 402) {
        return setAlertModalOpen(true);
      }
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    }
    setGenerateLoading(false);
  };

  const generateImagesByText = async (i, listData) => {
    if (apiCallIndex === 0) {
      setGenerateLoading(true);
    }
    const pid = imageByTextPid || uuidv4();
    const data = new FormData();
    data.append('prompt', generateData?.prompt?.prompt);
    data.append('style', generateData?.prompt?.theme === 'None' ? '' : generateData?.selectedImageStyle?.theme);
    data.append('img_generator', generateData?.isImgGenerator);
    data.append('pid', pid);
    data.append('origin', selectedTemp?.name);
    if (!imageByTextPid) setImageByTextPid(pid);

    const url = BASE_URL + 'image-gen/image_gen_single';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response.data && response.data.data) {
        listData[i] = { ...response?.data?.data, thumbnail: response?.data?.data?.url };
        setGeneratedImagesData([...listData]);
        if (apiCallIndex === 3) {
          setGenerateLoading(false);
        }
        return true;
      } else if (
        response.data.status_code === 400 &&
        response.data.detail.message === 'Your token has expired, Please login again'
      ) {
        handleTokenExpiryIssue();
      } else if (response.data.status_code === 400) {
        console.log(response.data.detail.message);
      }
    } catch (err) {
      setGenerateLoading(false);
      if (err?.response?.status === 402) {
        return setAlertModalOpen(true);
      }
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    }
    setGenerateLoading(false);
  };

  const handleGenerateImages = () => {
    setImageByTextPid();
    let tempListData = generatedImagesData;
    if (generatedImagesData.length >= 28) {
      tempListData = generatedImagesData.slice(0, (generatedImagesData.length - 4));
    }
    for (let i = 0; i < 4; i++) {
      tempListData.unshift(null);
      setGeneratedImagesData(tempListData);
    }
    setApiCallIndex(0);
  };

  // call the generate api one after another
  const makeSequentialApiCall = async () => {
    if (apiCallIndex <= 3) {
      const res = await generateIndividualImage(apiCallIndex, generatedImagesData);
      if (res) {
        setApiCallIndex(apiCallIndex + 1);
      }
    } else localStorage.removeItem('tempData');
  };

  const makeSequentialApiCallForImageGenerator = async () => {
    if (apiCallIndex <= 3) {
      const res = await generateImagesByText(apiCallIndex, generatedImagesData);
      if (res) {
        setApiCallIndex(apiCallIndex + 1);
      }
    } else localStorage.removeItem('tempData');
  };

  useEffect(() => {
    handleRecentGeneratedImages();
  }, []);

  useEffect(() => {
    if (recentGenImgList && generateData) {
      handleGenerateImages();
    }
  }, [recentGenImgList]);

  useEffect(() => {
    if (apiCallIndex >= 0 && generateData) {
      if (generateData?.isImgGenerator) {
        makeSequentialApiCallForImageGenerator();
      } else makeSequentialApiCall();
    }
  }, [apiCallIndex]);

  // api to Resize image
  const generateResizeImage = async () => {
    setGenerateLoading(true);
    // to slice last element and add a null element to the start of the array
    let tempListData = generatedImagesData;
    if (generatedImagesData.length >= 28) {
      tempListData = generatedImagesData.slice(0, (generatedImagesData.length - 1));
    }
    tempListData.unshift(null);
    setGeneratedImagesData(tempListData);

    const data = new FormData();
    data.append('gid', location.state?.selectedImage?.gid);
    data.append('image_url', location.state?.selectedImage?.url);
    data.append('coordinates_str', '');
    data.append('canvas_height', location.state?.changedCanvasHeight);
    data.append('canvas_width', location.state?.changedCanvasWidth);
    data.append('new_height', location.state?.selectedOptSize?.height);
    data.append('new_width', location.state?.selectedOptSize?.width);

    const url = BASE_URL + 'image-gen/resize-image';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response.data && response.data.data) {
        const updatedTempListData = [...tempListData];
        updatedTempListData[0] = {
          ...response?.data?.data,
          thumbnail: response?.data?.data?.resized_url,
          resized: true,
          width: location.state?.selectedOptSize?.width,
          height: location.state?.selectedOptSize?.height
        };
        setGeneratedImagesData([...updatedTempListData]);
        setResizeImgGen(false);
      } else if (
        response.data.status_code === 400 &&
        response.data.detail.message === 'Your token has expired, Please login again'
      ) {
        handleTokenExpiryIssue();
      } else if (response.data.status_code === 400) {
        console.log(response.data.detail.message);
      }
    } catch (err) {
      if (err?.response?.status === 402) {
        console.log('Something went wrong');
      }
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    }
    setGenerateLoading(false);

    navigate(location.pathname, {});
  };

  // API to get upscaled Image
  const generateImageUpscale = async () => {
    setGenerateLoading(true);
    // to slice last element and add a null element to the start of the array
    let tempListData = generatedImagesData;
    if (generatedImagesData.length >= 28) {
      tempListData = generatedImagesData.slice(0, (generatedImagesData.length - 1));
    }
    tempListData.unshift(null);
    setGeneratedImagesData(tempListData);
    setIsUpScaled(true);

    const data = new FormData();
    data.append('pid', location.state?.selectedImage?.pid);
    data.append('gid', location.state?.selectedImage?.gid);
    data.append('image_url', location.state?.selectedImage?.url);

    const url = BASE_URL + 'image-gen/upscale';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response.data && response.data.data) {
        setUpScaledImgGen(false);
        const updatedTempListData = [...tempListData];
        updatedTempListData[0] = {
          ...response?.data?.data,
          thumbnail: response?.data?.data?.upscaled_url, upscaled: true
        };
        setGeneratedImagesData([...updatedTempListData]);

        setIsUpScaled(false);
      } else if (
        response.data.status_code === 400 &&
        response.data.detail.message === 'Your token has expired, Please login again'
      ) {
        handleTokenExpiryIssue();
      } else if (response.data.status_code === 400) {
        console.log(response.data.detail.message);
      }
    } catch (err) {
      if (err?.response?.status === 402) {
        console.log('Something went wrong');
      }
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    }
    setGenerateLoading(false);
    navigate(location.pathname, {});
  };

  useEffect(() => {
    if (location.state?.type === 'upscale' && generatedImagesData[0] && upScaledImgGen) {
      generateImageUpscale();
      setUpScaledImgGen(false);
    }
  }, [generatedImagesData]);

  useEffect(() => {
    if (location.state?.type === 'resize' && generatedImagesData[0] && resizeImgGen) {
      generateResizeImage();
      setResizeImgGen(false);
    }
  }, [generatedImagesData]);

  const ModalUpgradeCtaButton = () => {
    return (
      <button
        type='button'
        className='mt-3 inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold
         text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-primary-50 sm:mt-0 sm:w-auto text-white'
        onClick={() => {
          setAlertModalOpen(false);
          navigate('/mobile/plans');
        }}
      >
        Upgrade
      </button>
    );
  };

  return (
    <>
      <AlertModal modalOpen={alertModalOpen} setModalOpen={setAlertModalOpen}
        header={'Your photo credit limit is exhausted!'}
        body='Get 1000 photos and more advanced features starting at $9.99'
        additionalBtn={ModalUpgradeCtaButton}
      />
      {displaySocialMediaHeader && (
        <SocialMediaHeader socialMediaTab='image' generateLoading={generateLoading} />
      )}
      {renderImageList ? (
        <div className='w-full h-full flex items-center justify-center'>
          <div className='spinner-border' role='status'>
            <ClipLoader color='#757D89' />
          </div>
        </div>
      ) : (
        <>
          <div className={cn(displaySocialMediaHeader && 'pt-[20px]', 'pb-[56px]')}>
            <GeneratedImagesSection
              generatedImagesData={generatedImagesData}
              generateLoading={generateLoading}
              isUpScaled={isUpScaled}
              upScaledImgGenData={upScaledImgGenData}
            />
          </div>
          <FooterSection
            isGenerateBtnDisabled={generateLoading}
            isOutputBtnDisabled={generateLoading}
          />
        </>
      )}
    </>
  );
};

export default OutputList;