/* eslint-disable */

import React, { useEffect } from 'react';
import { Image as KonvaImage, Group } from 'react-konva';

const IndividualImageForAdjustImage = (props) => {
  const {
    image,
    handleList,
    selectedImageUrl,
    generateImage,
    imageItemRef,
    setIsImageMoved,
    imageRef
  } = props;
  const groupImgRef = imageItemRef;
 
  useEffect(() => {
    image.current = imageRef?.current;
    handleList();
  }, [imageRef]);

  return (
    <Group
      draggable={true}
      onDragStart={() => { setIsImageMoved(true); }}
    >
      <KonvaImage
        ref={imageRef}
        alt='Image'
        className='rounded-5'
        style={{ zIndex: 1 }}
        x={image.x}
        y={image.y}
        scaleX={image.scaleX}
        scaleY={image?.scaleY}
        rotation={image?.angle}
        image={generateImage(selectedImageUrl)}
        onClick={(e) => {
          e.cancelBubble = true;
          image.current = e.currentTarget;
          groupImgRef?.current?.nodes([imageRef?.current]);
        }}
        onTap={(e) => {
          e.cancelBubble = true;
          image.current = e.currentTarget;
          groupImgRef?.current?.nodes([imageRef?.current]);
        }}
        draggable={true}
      />
    </Group>
  );
};

export default IndividualImageForAdjustImage;