/* eslint-disable max-len */
import React from 'react';
import cn from 'classnames';
import RenderSmoothImage from 'components/ReactSmoothImageRendring';

const RenderImage = (props) => {
  const {
    image,
    handleSelectedGenImg,
    index,
    generateLoading
  } = props;

  const clickedImage = localStorage.getItem('clickedImage');
  return (
    <>
      <div
        className={cn('flex items-center justify-center relative rounded shadow text-center',
          clickedImage && clickedImage === (image.url || image) && 'border-[2px] border-[#757D89]',
          generateLoading && 'pointer-events-none'
        )}
        role="presentation"
        onClick={() => { handleSelectedGenImg(image); } }
      >
        <div
          className={cn('rounded shadow text-center h-full w-full',
            clickedImage && clickedImage === (image.url || image) && 'border-[2px] border-white'
          )}
        >
          <RenderSmoothImage
            src={image?.thumbnail || image}
            alt="loading..."
            objectFit="fill"
            id={`generated_images_${index}`}
            className='bg-[#F1F1F1] w-auto m-auto h-full'
            loadGif={generateLoading}
            imageData={image}
          />
        </div>
      </div>
    </>
  );
};

export default RenderImage;