import React, { useEffect, useRef, useState } from 'react';
import CaretLeft from 'assets/img/CaretLeft.svg';
import CaretRight from 'assets/img/CaretRight.svg';
import cn from 'classnames';
import { ClipLoader } from 'react-spinners';

const RelatedImages = ({
  relatedImagesData,
  selectedImage,
  handleOnClickImage,
  imageHistoryLoading
  // setDeletedImgIndex
}) => {
  const [showCarousel, setShowCarousel] = useState(false);
  const scrollRef = useRef(null);

  const calculateScroll = () => {
    let scrollLeftOrRight = 0;
    const relatedImage = document.getElementById('relatedImage');
    const relatedImageWidth = relatedImage?.clientWidth;
    const scrollableDivWidth = scrollRef?.current?.clientWidth;
    let noOfImagesInView = scrollableDivWidth / relatedImageWidth;
    noOfImagesInView = noOfImagesInView % 1 >= 0.5 ? Math.ceil(noOfImagesInView) : Math.floor(noOfImagesInView);
    scrollLeftOrRight = noOfImagesInView * relatedImageWidth;
    return scrollLeftOrRight;
  };

  const scrollToLeft = () => {
    const scrollLeft = calculateScroll();
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: 0,
        left: -scrollLeft,
        behavior: 'smooth'
      });
    }
  };

  const scrollToRight = () => {
    const scrollLeft = calculateScroll();
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: 0,
        left: scrollLeft,
        behavior: 'smooth'
      });
    }
  };

  const getThemeScrollableDivSize = () => {
    const relatedImagesDiv = document.getElementById('relatedImagesDiv');
    const relatedImage = document.getElementById('relatedImage');
    const relatedImagesDataWidth =
      (relatedImagesData?.length * relatedImage?.clientWidth) + (relatedImagesData?.length * 8);
    if (relatedImagesDataWidth > relatedImagesDiv?.clientWidth) {
      setShowCarousel(true);
    } else {
      setShowCarousel(false);
    }
  };

  useEffect(() => {
    getThemeScrollableDivSize();
  }, [relatedImagesData]);

  useEffect(() => {
    window.onresize = () => {
      getThemeScrollableDivSize();
    };
  }, []);

  return (
    <div className={'w-full relative grid grid-cols-22 items-center'} id='relatedImagesDiv'>
      {imageHistoryLoading ? (
        <div className='flex flex-col items-center justify-center mt-6 spinner-border bg-white absolute'
          style={{ height: '', width: '100%' }}>
          <ClipLoader color='#757D89' />
          <div className='lg:text-[14px] xl:text-[16px] xl-mid:text-[18px] 4xl:text-[20px] font-semibold mb-2'>
            Loading...</div>
        </div>
      ) : (
        <>
          {relatedImagesData && showCarousel && (
            <div className='col-span-1 flex'>
              <button className='absolute left-0 flex self-center xl:pl-[15px]' onClick={() => scrollToLeft()}>
                <img src={CaretLeft} height='28px' width='28px' alt='' />
              </button>
            </div>
          )}
          <div className={`${!showCarousel ? 'col-span-22' : 'col-span-20'}`}>
            <div className='flex relative'>
              <div className='overflow-hidden flex gap-[20px]' ref={scrollRef}>
                {relatedImagesData?.map((image, index) => {
                  return (
                    <div
                      key={index}
                      className='w-[145px] flex-none'
                      onClick={() => { handleOnClickImage(image); }}
                      id='relatedImage'
                    >
                      <div className='relative'>
                        <div className='flex flex-col gap-2 items-center justify-center rounded-[5px]'>
                          <div className={cn(image?.data?.url === selectedImage?.data?.url
                            ? 'border-[2px] border-[#000000] p-[2px]'
                            : 'border-none',
                            'h-[145px] w-[145px] flex items-center justify-center bg-[#F1F1F1] rounded-[5px]')}>
                            <img
                              src={image?.type === 'Uploaded' ? image?.data?.url : image?.data?.thumbnail}
                              alt=''
                              className='max-w-full max-h-full m-auto rounded-[5px]'
                            />
                          </div>
                          <p className='font-jost font-[400] text-[16px] text-[#000]'>{image?.type}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {relatedImagesData && showCarousel && (
            <div className='col-span-1 flex'>
              <button className='absolute right-0 flex self-center xl:pr-[15px]' onClick={() => scrollToRight()}>
                <img src={CaretRight} height='28px' width='28px' alt='' />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RelatedImages;