import React from 'react';
import SocialMediaHeader from '../SocialMediaHeader';
import RenderSmoothImage from 'components/ReactSmoothImageRendring';
import cn from 'classnames';

const PostPreview = () => {
  const selectedImage = JSON.parse(localStorage.getItem('selectedImage'));
  const caption = JSON.parse(localStorage.getItem('selectedCaption'))?.caption;
  const path = window.location.pathname;
  const isInstagram = path.includes('InstagramPost');

  return (
    <>
      <SocialMediaHeader socialMediaTab='preview' />
      <div className='p-[20px] mb-[56px] h-auto pt-[30px]'>
        {!caption && (
          <div className='mt-[10px] text-center text-[16px] font-jost font-[400] text-[#7D7D7D]'>
            Please click on text tab above to create caption
          </div>
        )}
        {!isInstagram && (
          <div className='pb-[20px]'>
            <span className='text-[16px] font-jost font-[400] text-[#000]'>{caption}</span>
          </div>
        )}
        <div className={cn('max-w-[385px] max-h-[385px] m-auto flex justify-center relative', !caption && 'pt-[10px]')}>
          <RenderSmoothImage
            src={selectedImage?.url}
            alt="loading..."
            objectFit="fill"
            className='bg-[#F1F1F1] w-auto m-auto h-full'
          />
        </div>
        {isInstagram && (
          <div className='pt-[20px]'>
            <span className='text-[16px] font-jost font-[400] text-[#000]'>{caption}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default PostPreview;