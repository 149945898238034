import React from 'react';
import { AppButton } from 'components/shared/AppButton';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

const FooterSection = (props) => {
  const navigate = useNavigate();
  const {
    isGenerateBtnDisabled,
    generateBtnData,
    isGenerateBtnGray,
    isOutputBtnDisabled,
    generateCaption
  } = props;

  const path = window.location.pathname;
  const selectedSocialMediaTab = localStorage.getItem('selectedSocialMediaTab') || 'image';

  const handleGenerateBtn = () => {
    if (generateCaption) {
      generateBtnData();
    } else {
      localStorage.removeItem('tempData');
      if (selectedSocialMediaTab === 'image') {
        if (path.includes('/Output')) {
          if (path.includes('PhotoStudio')) {
            navigate('/mobile/PhotoStudio');
          } else if(path.includes('ImageGenerator')) {
            navigate('/mobile/ImageGenerator');
          } else if (path.includes('InstagramPost')) {
            navigate('/mobile/InstagramPost/Image');
          } else if (path.includes('FacebookPost')) {
            navigate('/mobile/FacebookPost/Image');
          }
        } else {
          const tempData = generateBtnData();
          localStorage.setItem('tempData', JSON.stringify(tempData));
          if (tempData?.selectedImageToGen) {
            localStorage.setItem('selectedImg', JSON.stringify(tempData?.selectedImageToGen));
          }
          if (path.includes('PhotoStudio')) {
            navigate('/mobile/PhotoStudio/Output');
          } else if(path.includes('ImageGenerator')) {
            navigate('/mobile/ImageGenerator/Output');
          } else if(path.includes('InstagramPost')) {
            navigate('/mobile/InstagramPost/Image/Output');
          } else if(path.includes('FacebookPost')) {
            navigate('/mobile/FacebookPost/Image/Output');
          }
        }
      } else if (selectedSocialMediaTab === 'text') {
        if (path.includes('InstagramPost')) {
          navigate('/mobile/InstagramPost/Text');
        } else if (path.includes('FacebookPost')) {
          navigate('/mobile/FacebookPost/Text');
        }
      }
    }
  };

  const handleOutputBtn = () => {
    localStorage.removeItem('tempData');
    if (selectedSocialMediaTab === 'image') {
      if (path.includes('PhotoStudio')) {
        navigate('/mobile/PhotoStudio/Output');
      } else if(path.includes('ImageGenerator')) {
        navigate('/mobile/ImageGenerator/Output');
      } else if(path.includes('InstagramPost')) {
        navigate('/mobile/InstagramPost/Image/Output');
      } else if(path.includes('FacebookPost')) {
        navigate('/mobile/FacebookPost/Image/Output');
      }
    } else if (selectedSocialMediaTab === 'text') {
      if (path.includes('InstagramPost')) {
        navigate('/mobile/InstagramPost/Text/Output');
      } else if (path.includes('FacebookPost')) {
        navigate('/mobile/FacebookPost/Text/Output');
      }
    }
  };

  return (
    <div className='w-full fixed bottom-0 bg-white p-2'>
      <div className='grid grid-cols-10 gap-2'>
        <div className='col-span-5 flex justify-center'>
          <AppButton
            onClick={() => !path.includes('/Output') && handleOutputBtn()}
            type="button"
            variant={path.includes('/Output') ? 'black' : 'tertiary'}
            extendClass={cn(path.includes('/Output') && 'bg-black',
            'rounded-[5px] text-[16px] font-[400] h-[40px] !py-[2px] !px-[20px] w-[170px] font-jost')}
            disabled={isOutputBtnDisabled}
          >
            Output
          </AppButton>
        </div>
        <div className='col-span-5 flex justify-center'>
          <AppButton
            onClick={() => handleGenerateBtn()}
            type="button"
            variant={path.includes('/Output') || isGenerateBtnGray ? 'tertiary' : 'black'}
            extendClass={cn(path.includes('/Output') || isGenerateBtnGray ? '' : 'bg-black',
            'rounded-[5px] text-[16px] font-[400] h-[40px] !py-[2px] !px-[20px] w-[170px] font-jost')}
            disabled={isGenerateBtnDisabled}
          >
            Generate
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;