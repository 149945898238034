import React from 'react';
import CopyIcon from 'assets/icons/copyGoogleIcon.svg';

const DescriptionList = ({ descriptionListData }) => {

  const handleTextCopy = (clipText) => {
    if (clipText) {
      navigator?.clipboard?.writeText(clipText);
    }
  };

  return (
    <div className='px-12 py-[40px] flex flex-col gap-[40px] 
      h-[calc(100vh-58px)] overflow-y-scroll'>
      {descriptionListData?.output?.map((item, index) => (
        <div key={index}
          role='presentation'
          className=''
        >
          <div className='flex justify-between border-md border-[#D9D9D9] border-[1px] rounded-md p-[20px]'>
            <div className='w-[calc(100%-30px)] p-[20px]
              lg:text-[16px] xl:text-[18px] 2xl:text-[20px] text-[16px] font-[400]'>
              <span className='whitespace-pre-line'>{item}</span>
            </div>
            <div className='w-[30px] flex justify-end items-end'>
              <img className='hover:cursor-pointer'
                src={CopyIcon}
                width={20}
                height={20}
                alt='CopyIcon'
                onClick={() => handleTextCopy(item)}
              />
            </div>
          </div>
        </div>
      ))}</div>
  );
};

export default DescriptionList;
