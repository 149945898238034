import React from 'react';
import AppButtonSpinner from './AppButtonSpinner';
import cn from 'classnames';

const PrimaryClasses = ' bg-primary hover:bg-primary-light text-white ';
const DangerClasses = ' bg-red-500 hover:bg-red-600 text-white ';
const DefaultClasses = ' bg-white hover:bg-gray-200 text-gray-500 border border-gray-200 ';
const SecondaryClasses = ' bg-gray-700 hover:text-gray-700 hover:bg-white text-white border border-gray-200 ';
const TertiaryClasses = ' bg-[#D9D9D9] text-black border border-gray-200 ';
const successClasses = ' bg-green-500 hover:bg-green-600 text-white ';
const blackClasses = 'bg-black-500 hover:text-white-700 hover:bg-black-600 text-white border border-gray-200 ';

const selectVariant = (variant) => {
  switch (variant) {
    case 'primary':
      return PrimaryClasses;
    case 'danger':
      return DangerClasses;
    case 'light':
      return DefaultClasses;
    case 'secondary':
      return SecondaryClasses;
    case 'success':
      return successClasses;
    case 'black':
      return blackClasses;
    case 'tertiary':
      return TertiaryClasses;
    default:
      return DefaultClasses;
  }
};

const selectSize = (size) => {
  switch (size) {
    case 'sm':
      return 'px-2 py-1 text-xs';
    case 'lg':
      return 'px-4 text-[20px]';
    default:
      return 'px-3 text-base';
  }
};
const AppButton = ({
  onClick,
  size,
  variant,
  type,
  children,
  extendClass,
  isLoading,
  isFull,
  disabled,
  ...otherProps
}) => {
  let classes = `btn transition-all ${disabled ? 'cursor-not-allowed opacity-50' : ''} 
  ${size ? selectSize(size) : 'px-5 py-2.5 '} ${selectVariant(variant)} 
  ${isLoading ? 'cursor-not-allowed opacity-75 ' : ''} ${extendClass || ''}`;
  classes += isFull ? ' w-full' : '';
  return (
    // eslint-disable-next-line react/button-has-type
    <button onClick={onClick} type={type} disabled={disabled} className={cn(classes, 'font-jost')} {...otherProps} >
      {isLoading ? <AppButtonSpinner height={22} /> : children}
    </button>
  );
};
AppButton.defaultProps = {
  size: 'lg',
  extendClass: '',
  isLoading: false,
  isFull: false,
  onClick: () => { }
};

export default AppButton;
