/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import TextField from 'components/commonFields/TextFieldFormik';
import TextArea from 'components/commonFields/TextAreaFormik';
import Accordion from 'components/commonFields/Accordion';
import ReactSelect from 'components/ReactSelectFormik';
import { useNavigate } from 'react-router-dom';
import { AppButton } from 'components/shared/AppButton';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
// import { toast } from 'react-toastify';
import _ from 'lodash';
import * as Yup from 'yup';
import { handleTokenExpiryIssue } from 'utils/functions.util';
import { BASE_URL } from 'utils/baseURL';

const DynamicForm = (props) => {
  const {
    templateValue,
    templateKey,
    selectedImage,
    updatedCaptionListData,
    selectedGenImg,
    generateCaptionLoading,
    setGenerateCaptionLoading,
    isListingTemplate,
    selectedTab
  } = props;
  const [templateData, setTemplateData] = useState([]);
  const [validateForm, setValidateForm] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  // const [generateCaptionLoading, setGenerateCaptionLoading] = useState(false)
  const [captionList, setCaptionList] = useState();
  const [submitData, setSubmitData] = useState();
  const [apiSendingData, setApiSendingData] = useState('');
  const [templateTab, setTemplateTab] = useState();

  const navigate = useNavigate();
  const path = window.location.pathname;

  const handleBackToTemplates = () => {
    navigate('/Templates');
  };

  const getFormDataByTemplate = async () => {
    const data = new FormData();
    data.append('template', templateKey);
    const url = BASE_URL + 'image-gen/templates';

    try {
      const template = await axios.post(url, data, {
        headers: {
          token: LocalStorage.getItem()
        }
      });
      setTemplateData(template?.data?.data?.fields);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isListingTemplate) {
      setApiSendingData();
    }
    setSubmitData();
    getFormDataByTemplate();
  }, [templateKey]);

  useEffect(() => {
    setTimeout(() => { setLoadingForm(false); }, 1000);
    setLoadingForm(true);
  }, [templateKey]);

  const generateCaption = async (dataToSend) => {
    setGenerateCaptionLoading(true);
    try {
      const url = BASE_URL + (isListingTemplate ? 'image-gen/generate_captions_wa' : 'image-gen/generate_captions');
      const dataValue = isListingTemplate ? {
        'social_platform': templateValue?.name,
        'pid': (selectedGenImg?.pid || selectedImage?.pid),
        'gid': (selectedGenImg?.gid || selectedImage?.gid),
        'input_type': isListingTemplate ? selectedTab : ''
      } : {
        'social_platform': templateValue?.name,
        'variations': 4,
        'pid': (selectedGenImg?.pid || selectedImage?.pid),
        'gid': (selectedGenImg?.gid || selectedImage?.gid)
      };
      const sendingData = { ...dataValue, ...dataToSend };
      const res = await axios.post(url, sendingData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: LocalStorage.getItem()
        }
      });
      const responseData = res?.data?.data;
      // if product name is not changed and we are regenerating the caption then we are appending the new caption
      // on top of the older one
      if (path.includes('GoogleSearchAd') && dataToSend?.description === apiSendingData?.description) {
        const updatedCaptionList = {
          input: apiSendingData?.description,
          output: [...responseData?.output, ...captionList?.output]
        };
        setCaptionList(updatedCaptionList);
      } else if (isListingTemplate &&
        templateTab === selectedTab &&
        dataToSend?.product_name === apiSendingData?.product_name &&
        dataToSend?.brand_name === apiSendingData?.brand_name) {
        const updatedCaptionList = {
          input_type: responseData?.input_type,
          output: [...responseData?.output, ...captionList?.output]
        };
        setCaptionList(updatedCaptionList);
      } else {
        setCaptionList(responseData);
      }
      setTemplateTab(selectedTab);
      setApiSendingData(dataToSend);
    } catch (err) {
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      } else {
        console.log('Something went wrong');
      }
    } finally {
      setGenerateCaptionLoading(false);
    }
  };

  // To segregate the required field from the backend response
  const handleValidationData = (listData) => {
    let tempData = [];
    listData?.map((item) => {
      if (item.type === 'accordion') {
        tempData.push(...tempData, item.fields);
      } else tempData.push(...tempData, item);
      return tempData;
    });
    const uniqueSet = new Set(tempData.flat());
    const uniqueArray = Array.from(uniqueSet).map(item => item);
    const validateData = uniqueArray.filter((item) => item);
    setValidateForm(validateData);
  };

  useEffect(() => {
    if (templateData) {
      handleValidationData(templateData);
    }
  }, [templateData]);

  // Func to render the each field validation based on required field value
  const handleEachFieldValidation = (item) => {
    const withRequiredField = ['dropDown', 'dropDownWithArrow'].includes(item.type) ?
      Yup.object().required('Required field').nullable() : (
        (item.type === 'textField' && item.fieldType === 'number') ?
          Yup.number().min(0).integer().required('Required field').nullable() :
          Yup.string().required('Required field').nullable()
      );
    const withoutRequiredField = ['dropDown', 'dropDownWithArrow'].includes(item.type) ?
      Yup.object().nullable() : ((item.type === 'textField' && item.fieldType === 'number') ?
        Yup.number().min(0).integer().nullable() : Yup.string().nullable()
      );
    return item?.required ? withRequiredField : withoutRequiredField;
  };

  // To construct the validationSchema for the required fields
  const handleValidateFormData = (list) => {
    const validateSchema = Yup.object().shape(
      Object.fromEntries(list.map((field) => [field.name, handleEachFieldValidation(field)]))
    );
    return validateSchema;
  };

  useEffect(() => {
    if (validateForm?.length > 0) {
      handleValidateFormData(validateForm);
    }
  }, [validateForm]);

  // To constuct the dropdown options from the backend values
  const handleOptions = (optList) => {
    const dropDownOptions = optList?.map((item) => (
      {
        label: _.upperFirst(item),
        value: item
      }
    ));
    return dropDownOptions;
  };

  // To construct the form data based on the backend response
  const handleSelectForm = (data) => {
    switch (data?.type) {
      case 'textField':
        return (
          <TextField
            label={data?.label}
            name={data?.name}
            type={data?.fieldType || 'text'}
            placeholder={data?.placeholder}
          />
        );
      case 'textArea':
        return (
          <TextArea
            label={data?.label}
            name={data?.name}
            placeholder={data?.placeholder}
          />
        );
      case 'accordion':
        return (
          <Accordion
            data={handleDynamicForm(data?.fields)}
            label={data.label}
          />
        );
      case 'dropDown':
        return (
          <ReactSelect
            options={handleOptions(data?.value) || []}
            placeholder={data?.placeholder}
            label={data?.label}
            name={data?.name}
            noDropDownIndicator
          />
        );
      case 'dropDownWithArrow':
        return (
          <ReactSelect
            options={handleOptions(data?.value) || []}
            placeholder={data?.placeholder}
            label={data?.label}
            name={data?.name}
          />
        );
      default: break;
    }
  };

  // To call the form construct function
  const handleDynamicForm = (listData) => {
    const tempFormData = listData?.map((item) => (
      handleSelectForm(item)
    ));
    return tempFormData.length > 0 && tempFormData;
  };

  // to transfer the form data on submitting
  const handleSubmit = (data) => {
    const dataToSend = Object.keys(data).reduce((a, v) => (
      { ...a, [v]: typeof (data[v]) === 'object' ? data[v].value : data[v] }
    ), {});
    setSubmitData(dataToSend);
  };

  // btnId for google analytics for each generate caption button
  const handleGenerateCaptionBtnId = () => {
    const currentUrl = window?.location?.pathname;
    let tempId = '';
    switch (currentUrl) {
      case '/InstagramPost':
        tempId = 'InstaPage_clickGen_txt';
        break;
      case '/FacebookPost':
        tempId = 'FBPage_clickGen_txt';
        break;
      case '/LinkedInPost':
        tempId = 'LinkedinPage_clickGen_txt';
        break;
      default: break;
    }
    return tempId;
  };

  useEffect(() => {
    if (submitData) {
      generateCaption(submitData);
    }
  }, [submitData]);

  useEffect(() => {
    if (captionList) {
      updatedCaptionListData(captionList);
    }
  }, [captionList]);

  // passing the initial values for the listingTemplate forms
  const handleListingTempInitialData = () => {
    let initialData = {};
    if (isListingTemplate && apiSendingData) {
      initialData = {
        'brand_name': apiSendingData?.brand_name || '',
        'product_name': apiSendingData?.product_name || '',
        'description': apiSendingData?.description || ''
      };
    }
    return initialData;
  };

  return (
    loadingForm ? (
      <div className='flex flex-col items-center justify-center mt-6 spinner-border bg-white'
        style={{ height: '400px', width: '100%' }}>
        <ClipLoader color='#757D89' />
        <div className='lg:text-[14px] xl:text-[16px] xl-mid:text-[18px] 4xl:text-[20px] font-semibold mb-2'>
          Loading...
        </div>
      </div>
    ) : (
      <div className='grid grid-cols gap-4 pt-[10px] h-full'>
        <Formik
          initialValues={handleListingTempInitialData()}
          validationSchema={validateForm && handleValidateFormData(validateForm)}
          onSubmit={(data) => handleSubmit(data)}
        >
          <Form>
            <div className='grid grid-cols gap-4 pb-[65px]'>
              {templateData && handleDynamicForm(templateData)}
            </div>
            <div className='absolute bottom-[-20px] bg-white z-10 px-[20px] py-[10px] pr-[10px] w-full'>
              <div className='flex justify-end items-center'>
                <AppButton
                  onClick={() => handleBackToTemplates()}
                  type='button'
                  variant=''
                  extendClass='!bg-[#00000008] !text-black hover:!bg-[#00000008] rounded !text-[16px] font-[400] 
                    h-[48px] w-[180px] !py-[2px] px-[0px] mr-[10px] border-[1px] border-solid !border-[#000]'
                >
                  Back to templates
                </AppButton>
                <AppButton
                  type='submit'
                  variant='black'
                  isLoading={generateCaptionLoading}
                  extendClass='bg-black hover:bg-black rounded text-[16px] font-[400]
                  h-[48px] w-[180px] !py-[2px] !px-[20px]'
                  id={handleGenerateCaptionBtnId()}
                >
                  Generate
                </AppButton>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    )
  );
};

export default DynamicForm;