import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Stage, Layer, Line, Image as ImageKonva } from 'react-konva';
import backgroundMesh from 'assets/img/meshBgImageLarge.png';
import { ClipLoader } from 'react-spinners';

const ImageEditCanvas = (props) => {
  const {
    backgroundImage,
    brushSize,
    selectedOpt,
    handleReset,
    reset,
    setImageEdited,
    width,
    height,
    stageRef,
    originalImage
  } = props;
  const [lines, setLines] = useState([]);
  const [imgLoader, setImgLoader] = useState(true);
  const isDrawing = useRef();

  useEffect(() => {
    if (reset) {
      setLines([]);
    }
    handleReset(false);
  }, [reset]);

  useEffect(() => {
    if (lines && lines?.length > 0 ) {
      const imagePoints = lines?.find((item) => item?.points?.length > 2);
      if (imagePoints) {
        setImageEdited(true);
      } else setImageEdited(false);
    } else setImageEdited(false);
  }, [lines]);

  const imageEle = useMemo(() => {
    const img = document.createElement('img');
    img.src = backgroundImage + '?v='+Math.random();
    img.onload = () => {
      const { naturalWidth, naturalHeight } = img;
      const imageWidth = naturalWidth || 500;
      const imageHeight = naturalHeight || 500;
      const widthFactor = imageWidth / width;
      const heightFactor = imageHeight / height;
      if (widthFactor > heightFactor) {
        img.height = (1 * imageHeight) / widthFactor;
        img.width = (1 * imageWidth) / widthFactor;
      } else {
        img.height = (1 * imageHeight) / heightFactor;
        img.width = (1 * imageWidth) / heightFactor;
      }
      setImgLoader(false);
    };
    img.crossOrigin = 'Anonymous';
    // setImgLoader(false);
    return img;
  }, [backgroundImage, width, height]);

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e?.target?.getStage()?.getPointerPosition();
    setLines([...lines, { points: [pos?.x, pos?.y], brushSize: brushSize, showCirclePointer: true }]);
  };

  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (!isDrawing?.current) {
      return;
    }
    const stage = e?.target?.getStage();
    const point = stage?.getPointerPosition();
    let lastLine = lines[lines?.length - 1];
    // add point
    if (lastLine?.points) {
      lastLine.points = lastLine?.points?.concat([point.x, point.y]);
    }
    // replace last
    lines?.splice(lines?.length - 1, 1, lastLine);
    setLines(lines?.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  return (
    <>
      {imgLoader ? (
        <div
          className={'bg-canvasMeshBg flex items-center justify-center'}
          style={{ width: width, height: height }}
        >
          <ClipLoader color='757D89' />
        </div>
      ) : (imageEle &&
        <Stage
          width={width}
          height={height}
          onMouseDown={selectedOpt && handleMouseDown}
          onMouseMove={selectedOpt && handleMouseMove}
          onMouseUp={selectedOpt && handleMouseUp}
          ref={stageRef}
          id='canvas'
          style={{
            border: (originalImage?.colour_image || originalImage?.primary_image) && '1px solid #757D89',
            margin: '2px',
            display: 'block',
            width: `${width}px`,
            height: `${height}px`,
            backgroundImage: `url(${backgroundMesh})`,
            backgroundSize: 'cover',
            boxSizing: 'content-box'
          }}
        >
          <Layer>
            <ImageKonva
              image={imageEle}
            />
            {lines?.map((line, i) => {
              return (
                <>
                  <Line
                    key={i}
                    points={line?.points}
                    stroke='#E5E5E6'
                    strokeWidth={Number(line?.brushSize)}
                    tension={0.5}
                    lineCap="round"
                    lineJoin="round"
                    // globalCompositeOperation={
                    //   line?.tool === 'eraser' ? 'destination-out' : 'source-over'
                    // }
                    globalCompositeOperation={'destination-out'}
                  />
                  {/* {(line?.showCirclePointer) && (
                    <Circle
                      x={line?.points[line?.points?.length - 2]}
                      y={line?.points[line?.points?.length - 1]}
                      stroke='black'
                      radius={Number(line?.brushSize) / 2} // Adjust the radius as needed
                      fill='#E5E5E675' // Circle color
                      draggable // Allow dragging if needed
                      // style={!isDrawing?.current && { display: 'none' }}
                    />
                  )} */}
                </>
              );
            })}
          </Layer>
        </Stage>
      )}
    </>
  );
};

export default ImageEditCanvas;
