/* eslint-disable max-len */
import React from 'react';
import { AppButton } from 'components/shared/AppButton';
// import { useNavigate } from 'react-router-dom';

const Popup = (props) => {
  const { msgContent } = props;
  // const navigate = useNavigate();
  // const handleOkBtn = () => {
  //   navigate('/login');
  //   setPopup(false);
  // };
  
  return (
    <div
      className="relative z-50 flex items-center justify-center font-jost"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="relative flex h-full items-end justify-center p-4 text-center">
          <div
            className="sticky bottom-2/4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all"
          >
            <div className="bg-white p-3">
              <div className="w-full">
                <div className="text-left lg:text-[14px] xl:text-[16px] xl-mid:text-[18px] 4xl:text-[18px] font-[500]">
                    {msgContent}
                </div>
              </div>
            </div>
            <div className='w-full flex justify-center items-center p-2'>
              <AppButton
                // onClick={() => { handleOkBtn(); }}
                type="button"
                variant='black'
                extendClass='bg-black hover:bg-black rounded lg:text-[12px] xl:text-[14px] xl-mid:text-[16px] 4xl:text-[18px] h-[48px] w-[180px] px-5 2xl:px-7'
              >
                <a href='https://breeze.ai/'>Ok</a>
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;