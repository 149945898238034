import React, { useState, useEffect } from 'react';
import moment from 'moment/moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import verticalOptionIcon from 'assets/icons/simple-line-icons.svg';
import ImageClickFunctions from '../ImageClickFunctions'; // on image click PopUp Component
import cn from 'classnames';
import { getDropdownPosition } from 'utils/commonFunctions';
import useClickOutsideHandler from 'components/customHooks/useClickOutsideHandler';
import _ from 'lodash';
import sortingArrow from 'assets/icons/sortingArrow.svg';

const ListView = (props) => {
  const {
    selectedTab,
    imageList,
    uploadedImages,
    isSearched,
    searchDataList,
    handleImageClick,
    loading,
    searchFilterDivHeight,
    seImageClickData,
    setMultiImageSelectedList,
    setIsImgClickPopUps,
    setShareLinkKeyData,
    setIsAllowDownload,
    setShareLinkLoading,
    isFilesUploading
  } = props;
  const [isValidSrc, setIsValidSrc] = useState(true);
  const [imgFunctionPopUp, setImgFunctionPopUp] = useState(false);
  const [imgIndex, setImgIndex] = useState('');
  const [selectedAllImages, setSelectedAllImages] = useState([]); // to store selected images
  const [filteredImageList, setFilteredImageList] = useState([]); // storing modified searchResult data
  const [libraryImageList, setLibraryImageList] = useState([]); // storing image list
  const [sortOrder, setSortOrder] = useState({
    'image_name': false,
    'size': false
  }); // to hold the sorting order ('asc' or 'desc')
  const [sortedField, setSortedField] = useState(); // to hold the sorting field
  const [showSortingArrowOn, setShowSortingArrowOn] = useState('image_name');
  const [selectedField, setSelectedFiled] = useState();

  // Custom hook to handle the deselecting the selected image if the user click outside
  const clickImageCardRef = useClickOutsideHandler(
    'clickImageList',
    () => {
      const imageListData = isSearched ? filteredImageList : libraryImageList;
      if (imageListData?.length > 0 && selectedAllImages?.length > 0) {
        const updatedImages = imageListData?.map((item) => ({
          ...item,
          selected: false
        }));
        if (isSearched) {
          setFilteredImageList(updatedImages);
        } else setLibraryImageList(updatedImages);
        setSelectedAllImages([]);
      }
    },
    [libraryImageList, selectedAllImages, isFilesUploading, isSearched, filteredImageList]
  );

  // Clearing the selectedImages while toggling the tabs
  useEffect(() => {
    setSelectedAllImages([]);
  }, [selectedTab]);

  // assigning the selected images to parent value
  useEffect(() => {
    setMultiImageSelectedList(selectedAllImages);
  }, [selectedAllImages, selectedTab]);

  // Adding index and selected values for all the data for multiSelecting feature
  const handleIndexData = (listData) => {
    const tempData = listData?.map((item, index) => {
      if (item) {
        item['index'] = index;
        item['selected'] = false;
      }
      return item;
    });
    return tempData;
  };

  // Altering the search list data for multiSelect feature
  useEffect(() => {
    if (searchDataList?.length > 0) {
      let returnDataList = [];
      if (selectedTab === 'allImages') {
        returnDataList = searchDataList?.filter(item => item?.image_origin !== 'Uploaded');
      } else {
        returnDataList = searchDataList?.filter(item => item?.image_origin === 'Uploaded');
      }
      setFilteredImageList(handleIndexData(returnDataList));
    }
  }, [searchDataList, selectedTab]);

  // Altering the images list data for multiSelect feature
  useEffect(() => {
    if (selectedTab === 'allImages') {
      setLibraryImageList(handleIndexData(imageList?.history));
    } else {
      setLibraryImageList(handleIndexData(uploadedImages?.history));
    }
  }, [selectedTab, imageList, uploadedImages]);

  const handleImageSize = (imgSize) => {
    if (imgSize > 1023) {
      return `${Math.ceil(Number(imgSize / 1024))} MB`;
    } else return `${Math.ceil(Number(imgSize).toFixed(2))} KB`;
  };

  const handleDropdownPosition = (imageIndex, item) => {
    const imageElement = document.querySelector(`#Image-${imageIndex}`);
    const containerElement = document.getElementById('imageContainer');
    const dropdownHeight = item?.selected ? '120' : '250';
    const dropdownPosition = getDropdownPosition(imageElement, containerElement, dropdownHeight);
    return dropdownPosition;
  };

  const handleMouseEnter = (index) => {
    setImgIndex(index);
  };

  const handleMouseLeave = () => {
    setImgIndex('');
    setImgFunctionPopUp(false);
  };

  // Function to handle multiSelection of images
  const handleMultiImageSelection = (imageData, event) => {
    const isSelected = selectedAllImages?.some((item) => {
      if (imageData?.gid) {
        return item?.gid === imageData?.gid;
      } else if (imageData?.pid) {
        return item?.pid === imageData?.pid;
      }
      return false;
    });

    const imageListData = isSearched ? filteredImageList : libraryImageList;
    let selectedImageList = [];
    const specialKeySelected = event.altKey || event.ctrlKey || event.shiftKey;
    const selectedImageIndex = imageListData?.findIndex((item) => item === imageData);

    const updateSelectedState = (images, index, selected) => {
      const updatedImages = [...images];
      updatedImages[index].selected = selected;
      return updatedImages;
    };

    if (isSelected && specialKeySelected) {
      // If the image is already selected and a special key is pressed,
      // remove the selected item when clicking it again
      setSelectedAllImages((prevSelectedImages) =>
        prevSelectedImages.filter(
          (item) => !(item.gid === imageData.gid || item.pid === imageData.pid)
        )
      );
      selectedImageList = (updateSelectedState(imageListData, selectedImageIndex, false));
    } else if (!isSelected && specialKeySelected) {
      // Select the image only if it's not already selected and a special key is pressed
      setSelectedAllImages((prevSelectedImages) => [
        ...prevSelectedImages,
        { ...imageData, selected: true }
      ]);
      selectedImageList = (updateSelectedState(imageListData, selectedImageIndex, true));
    } else if (!specialKeySelected) {
      // Deselect all other images and select the current image if no special key is pressed
      const updatedImages = imageListData?.map((item) => ({ ...item, selected: false }));
      selectedImageList = (updateSelectedState(updatedImages, selectedImageIndex, true));
      setSelectedAllImages([{ ...imageData, selected: true }]);
    }

    if (selectedImageList?.length > 0) {
      if (isSearched) {
        setFilteredImageList(selectedImageList);
      } else setLibraryImageList(selectedImageList);
    }
  };

  // Function to handle multiSelection of images count
  const handleMultiSelectCount = () => {
    const countValue = selectedAllImages?.length;
    return countValue;
  };

  // Function to handle selected images are only upload or not
  const handleSelectedImagesUploadOnly = () => {
    let isUploadedImages;
    if (selectedTab === 'allImages') {
      isUploadedImages = selectedAllImages?.every((item) => !item?.gid);
    } else isUploadedImages = selectedAllImages?.length > 0;
    return isUploadedImages;
  };

  // Function to handle selected images are only generated or not
  const handleSelectedImagesGeneratedOnly = () => {
    const isGeneratedImages = selectedAllImages?.every((item) => item?.gid);
    return isGeneratedImages;
  };

  const toggleSortingField = (field) => {
    setSortOrder((prevSortOrder) => ({
      ...prevSortOrder,
      [field]: !prevSortOrder?.[field]
    }));
  };

  // Function to handle the sorting the give field in ascending or descending order
  const handleGeneralSorting = (sortingField) => {
    const sortingOrder = sortOrder[sortingField] ? 'asc' : 'desc';
    const imageListData = isSearched ? filteredImageList : libraryImageList;
    const sortingFieldData = sortingField === 'image_name' ?
      imageListData => imageListData[sortingField].toLowerCase() : sortingField;
    const sortedData = _.orderBy(imageListData, [sortingFieldData], [sortingOrder]);
    if (isSearched) {
      setFilteredImageList(sortedData);
    } else setLibraryImageList(sortedData);
    toggleSortingField(sortingField);
    setShowSortingArrowOn(sortedField);
  };

  return (
    <div id='imageContainer' style={{ height: `calc(100vh - ${searchFilterDivHeight}px)`, overflow: 'scroll' }}>
      {(isSearched ? filteredImageList?.length > 0 : libraryImageList?.length > 0) && (
        <table
          className="font-jost table w-full rounded table-fixed "
          id='clickImageList'
          ref={clickImageCardRef}
        >
          <thead className='border-[1px] border-[#D9D9D9] text-[#666]'>
            <tr className='top-0 w-full sticky z-10 gap-[10px]
              h-[65px] bg-[white] outline outline-1 outline-offset-0 outline-[#D9D9D9] py-[16px] px-[10px]'>
              <th
                className='text-left w-2/6 pl-4'
              >
                <div
                  className={cn('flex flex-row gap-[1px] cursor-pointer')}
                  onMouseEnter={() => {
                    setSortedField('image_name');
                    setSelectedFiled('image_name');
                  }}
                  onMouseLeave={() => setSelectedFiled()}
                >
                  <div
                    className={cn('w-auto h-[33px] px-2.5 py-[5px] bg-zinc-100',
                      'rounded-tl-[5px] rounded-bl-[5px] justify-start items-center gap-2.5 inline-flex',
                      selectedField === 'image_name' && 'bg-[#F0F0F0]'
                    )}
                  >
                    <div className='text-[16px] font-[500]'>File Name</div>
                  </div>
                  {(selectedField || showSortingArrowOn) === 'image_name' && (
                    <div
                      className={cn('bg-zinc-100 rounded-tr-[5px] rounded-br-[5px] w-[29px] h-[33px] px-2.5 py-[5px]',
                        'justify-start items-center gap-2.5 inline-flex',
                        selectedField === 'image_name' && 'bg-[#F0F0F0]')}
                      onClick={() => {
                        handleGeneralSorting('image_name');
                      }}
                    >
                      <div
                        className={cn((selectedField || showSortingArrowOn) === 'image_name'
                          && sortOrder['image_name'] && '-rotate-180')}
                      >
                        <img
                          src={sortingArrow}
                          alt='icon'
                          width='9px'
                          height='16px'
                        />
                      </div>
                    </div>
                  )}
                </div>
              </th>
              <th className='text-left'>
                <span className='p-3 text-[16px] font-[500]'>File Format</span>
              </th>
              <th
                className='text-left'
              >
                <div
                  className={cn('flex flex-row gap-[1px] cursor-pointer')}
                  onMouseEnter={() => {
                    setSortedField('size');
                    setSelectedFiled('size');
                  }}
                  onMouseLeave={() => setSelectedFiled()}
                >
                  <div
                    className={cn('w-auto h-[33px] px-2.5 py-[5px] bg-zinc-100',
                      'rounded-tl-[5px] rounded-bl-[5px] justify-start items-center gap-2.5 inline-flex',
                      selectedField === 'size' && 'bg-[#F0F0F0]'
                    )}
                  >
                    <div className='text-[16px] font-[500]'>Size</div>
                  </div>
                  {(selectedField || showSortingArrowOn) === 'size' && (
                    <div
                      className={cn('bg-zinc-100 rounded-tr-[5px] rounded-br-[5px]',
                        'justify-start items-center gap-2.5 inline-flex w-[29px] h-[33px] px-2.5 py-[5px]',
                        selectedField === 'size' && 'bg-[#F0F0F0]')}
                      onClick={() => {
                        handleGeneralSorting('size');
                      }}
                    >
                      <div
                        className={cn((selectedField || showSortingArrowOn) === 'size'
                          && sortOrder['size'] && '-rotate-180')}
                      >
                        <img
                          src={sortingArrow}
                          alt='icon'
                          width='9px'
                          height='16px'
                        />
                      </div>
                    </div>
                  )}
                </div>
              </th>
              <th className='text-left'>
                <span className='p-3 text-[16px] font-[500]'>Date Uploaded</span>
              </th>
              <th className='text-left'>
                <span className='p-3 text-[16px] font-[500]'>Date Created</span>
              </th>
              <th className='text-left'>
                <span className='p-3 text-[16px] font-[500]'>Dimensions</span>
              </th>
            </tr>
          </thead>
          <tbody className='text-[#7D7D7D]'>
            {(isSearched ? filteredImageList : libraryImageList)?.map((item, index) => (
              <tr
                key={index} id={`Image-${index}`}
                className={cn('font-[400]', item?.selected && 'bg-[#0038FF] bg-opacity-10 text-black')}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave()}
                onClick={(event) => item && handleMultiImageSelection(item, event)}
                onMouseDown={(e) => e?.shiftKey && e?.preventDefault()}
              >
                <td className='text-left p-2 w-full flex justify-between items-center text-[#000]'>
                  <div className='flex flex-wrap items-center'>
                    <span className='h-[40px] w-[40px] bg-[#F1F1F1] flex justify-center items-center rounded'>
                      <LazyLoadImage
                        src={(isValidSrc && item?.thumbnail ? item?.thumbnail : (item?.input_image_url || item?.url))}
                        alt={index}
                        className='max-w-full max-h-full rounded-[2px]'
                        onError={() => setIsValidSrc(false)}
                      />
                    </span>
                    <span className='flex-1 items-center pl-[20px] truncate max-w-[320px]'>{item?.image_name}</span>
                  </div >
                  <div className={cn('flex items-center justify-center rounded-[5px] cursor-pointer relative',
                    'mr-[32px] w-[28px] h-[28px]',
                    item?.selected ? 'hover:!bg-[#0038FF] hover:!bg-opacity-20' : 'hover:bg-[#F0F0F0]')}
                    onClick={(e) => {
                      e.stopPropagation();
                      setImgFunctionPopUp(!imgFunctionPopUp);
                      seImageClickData(item);
                    }}
                  >
                    <img src={verticalOptionIcon} alt='' width={28} height={28} />
                    {/*on image click PopUp Component*/}
                    {imgFunctionPopUp && imgIndex === index && (
                      <ImageClickFunctions
                        setImgFunctionPopUp={setImgFunctionPopUp}
                        handleImageClick={() => handleImageClick(item)}
                        imageData={item}
                        dropdownPosition={handleDropdownPosition(index, item)}
                        viewType={'list'}
                        multiSelectCount={handleMultiSelectCount()}
                        isUploadImagesOnly={handleSelectedImagesUploadOnly()}
                        isGeneratedImagesOnly={handleSelectedImagesGeneratedOnly()}
                        multiSelectedItems={selectedAllImages}
                        setIsImgClickPopUps={setIsImgClickPopUps}
                        setShareLinkKeyData={setShareLinkKeyData}
                        setShareLinkLoading={setShareLinkLoading}
                        setIsAllowDownload={setIsAllowDownload}
                      />
                    )}
                  </div>
                </td >
                <td className='text-left p-2'>{item?.image_type}</td>
                <td className='text-left p-2'>{item?.size && handleImageSize(item?.size)}</td>
                {/* Show the date uploaded for uploading images only */}
                {
                  selectedTab === 'allImages' ? <td /> :
                    <td className='text-left p-2'>{item?.time && moment(item?.time).format('MMM DD, YYYY')}</td>
                }
                {/* Show the date created wrt type such as 
                  time for generated images and created_on for uploaded image */}
                <td className='text-left p-2'>
                  {(selectedTab === 'allImages' ? item?.time : item?.created_on) &&
                    moment(selectedTab === 'allImages' ? item?.time : item?.created_on).format('MMM DD, YYYY')
                  }
                </td>
                <td className='text-left p-2'>{item?.dimensions &&
                  `${item?.dimensions[0]} x ${item?.dimensions[1]}`}</td>
              </tr >
            ))}
          </tbody >
        </table >
      )}
      {
        (isSearched ? filteredImageList?.length === 0 : libraryImageList?.length === 0) && (
          !loading && <div className='w-full h-full font-jost font-[600] lg:text-[13px] 
        xl:text-[16px] xl-mid:text-[18px] 4xl:text-[20px] whitespace-nowrap'>
            No record found
          </div>
        )
      }
    </div >
  );
};

export default ListView;