import React from 'react';
import { HashLoader } from 'react-spinners';

export { };
const override = {
  position: 'absolute',
  display: 'block',
  margin: '0 auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)'
};

const AppLoader = props => {
  const { isLoading, size } = props;
  return <HashLoader color='#757D89' loading={isLoading} cssOverride={override} size={size} />;
};

AppLoader.defaultProps = {
  size: 50
};

export default AppLoader;
