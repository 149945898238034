import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import { BASE_URL } from 'utils/baseURL';

export const handleProjectModifiedDate = (modDate) => {
  let modifiedValue = '';
  const currentTime = new Date()?.toLocaleString('en-US', { timeZone: 'Europe/London' });
  const timeDifference = Math.abs(new Date(currentTime) - new Date(modDate));
  const modifiedDay = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const modifiedHours = Math.floor(timeDifference / (1000 * 60 * 60));
  const modifiedMinutes = Math.floor(timeDifference / (1000 * 60));
  if (modifiedDay === 1) {
    modifiedValue = `Edited ${modifiedDay} day ago`;
  } else if (modifiedDay > 1) {
    modifiedValue = `Edited ${modifiedDay} days ago`;
  } else if (modifiedHours === 1) {
    modifiedValue = `Edited ${modifiedHours} hour ago`;
  } else if (modifiedHours > 1) {
    modifiedValue = `Edited ${modifiedHours} hours ago`;
  } else if (modifiedMinutes === 1) {
    modifiedValue = `Edited ${modifiedMinutes} minute ago`;
  } else if (modifiedMinutes > 1) {
    modifiedValue = `Edited ${modifiedMinutes} minutes ago`;
  } else modifiedValue = 'Edited recently';
  return modifiedValue;
};

export const getLinkExpireTime = (expirationTime) => {
  let expireHours = '';
  const currentTime = new Date()?.toLocaleString('en-US', { timeZone: 'Europe/London' });
  const timeDifference = Math.abs(new Date(currentTime) - new Date(expirationTime));
  const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutesLeft = Math.floor(timeDifference / (1000 * 60));
  if (hoursLeft < 1) {
    expireHours = `${minutesLeft} minutes`;
  } else if (hoursLeft === 1) {
    expireHours = `${hoursLeft} hour`;
  } else {
    expireHours = `${hoursLeft} hours`;
  }
  return expireHours;
};
  
export const getDropdownPosition = (imageElement, containerElement, threshold) => {
  let dropdownPosition = 'bottom';
  if (containerElement && imageElement) {
    const containerRect = containerElement.getBoundingClientRect();
    const imageRect = imageElement.getBoundingClientRect();
    const imageRelativePosition = {
      top: imageRect.top - containerRect.top,
      bottom: containerRect.height - (imageRect.y - imageRect.height)
    };

    if (imageRelativePosition.top < threshold && imageRelativePosition.bottom < threshold) {
      dropdownPosition = 'bottom';
    } else if (imageRelativePosition.bottom < threshold) {
      dropdownPosition = 'top';
    } else {
      dropdownPosition = 'bottom';
    }
  }
  return dropdownPosition;
};

export const handleMultiImageDownload = async (items) => {
  try {
    const token = LocalStorage?.getItem(); // Replace with your actual token
    const data = new FormData();
    const gidArray = [];
    const pidArray = [];
    // Iterate through the data array
    items?.forEach(item => {
      if (item.gid) {
        gidArray.push(item?.gid);
      } else if (item.pid) {
        pidArray.push(item?.pid);
      }
    });
    if (gidArray?.length > 0) {
      data.append('gids', gidArray);
    }
    if (pidArray?.length > 0) {
      data.append('pids', pidArray);
    }
    const url = BASE_URL + 'image-gen/downloadMultiple';
    const response = await axios.post(
      url,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Token': token
        },
        responseType: 'blob'
      }
    );
    const blob = new Blob([response.data], { type: 'application/zip' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'breeze.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading zip file:', error);
  }
};