import React from 'react';
import { useField } from 'formik';
import cn from 'classnames';

const ToggleBtn = ({
  label, values, handleToggleChange, disabled, blueCheckedColor, ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <span className='flex text-[14px]'>
      <label className='pr-[10px]'>{label}</label>
      <label className={cn('relative inline-flex items-cente', disabled ? 'cursor-not-allowed' : 'cursor-pointer')}>
        <input
          type="checkbox"
          value=""
          className="sr-only peer"
          {...field}
          {...props}
          checked={values?.checked}
          onChange={handleToggleChange && handleToggleChange}
          disabled={disabled}
        />
        <div className={`${blueCheckedColor ? 'w-[28px] h-[16px]' : 'w-10 h-5'} 
        bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-gray-800 
        rounded-full peer dark:bg-black peer-checked:after:translate-x-full 
        peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
        after:bg-white after:border-gray-300 after:border after:rounded-full 
        ${blueCheckedColor ? 'after:h-[12px] after:w-[12px]' : 'after:h-4 after:w-[18px]'} 
        after:transition-all dark:border-black ${blueCheckedColor ? 'peer-checked:bg-[#0038FF]' :
        'peer-checked:bg-black'}`}></div>
      </label>
      {meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </span>
  );
};

export default ToggleBtn;
