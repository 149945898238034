import React, { createRef, useState, useRef, useEffect } from 'react';
import { Transformer, Layer, Stage } from 'react-konva';
import { IndividualImage } from './IndividualImage';
import cn from 'classnames';

const CanvasAdvanceScreen = ({
  imgSrc = '',
  width,
  height,
  stageRef,
  canvasTransformerRef,
  handleImagesPosition,
  resetSelectedImage,
  isMeshBg,
  isMobScreen,
  isListingTemplates
}) => {
  let imageUrl = imgSrc;
  const trRef = canvasTransformerRef;
  const containerRef = useRef(null);
  const [images, setImages] = useState([]);
  const [clickedOutSide, setClickedOutSide] = useState(true);

  const handleList = () => {
    handleImagesPosition(images);
  };

  useEffect(() => {
    var img1 = new Image();
    img1.src = imgSrc;
    let imageWidth, imageHeight;
    img1.onload = function() {
      imageWidth = img1.width;
      imageHeight = img1.height;
      const widthFactor = imageWidth / width;
      const heightFactor = imageHeight / height;
      if (widthFactor > heightFactor) {
        img1.height = (((isListingTemplates ? 0.8 : 0.4) * imageHeight) / widthFactor);
        img1.width = (((isListingTemplates ? 0.8 : 0.4) * imageWidth) / widthFactor);
      } else {
        img1.height = (((isListingTemplates ? 0.8 : 0.4) * imageHeight) / heightFactor);
        img1.width = (((isListingTemplates ? 0.8 : 0.4) * imageWidth) / heightFactor);
      }
      setImages([{
        x: img1?.width ? (width/2 - img1?.width/2) : width / 3,
        y: img1?.height ? (height/2 - img1?.height/2) : height / 3,
        current: createRef()
      }]);
      trRef?.current?.nodes([]);
    };
  }, [imageUrl, isListingTemplates]);

  const generateImage = (imageSrc) => {
    const img = document.createElement('img');
    img.src = imageSrc;

    function onload() {
      const { naturalWidth, naturalHeight } = img;
      const widthFactor = naturalWidth / width;
      const heightFactor = naturalHeight / height;
      if (widthFactor > heightFactor) {
        img.height = (((isListingTemplates ? 0.8 : 0.4) * naturalHeight) / widthFactor);
        img.width = (((isListingTemplates ? 0.8 : 0.4) * naturalWidth) / widthFactor);
      } else {
        img.height = (((isListingTemplates ? 0.8 : 0.4) * naturalHeight) / heightFactor);
        img.width = (((isListingTemplates ? 0.8 : 0.4) * naturalWidth) / heightFactor);
      }
    }
    onload();
    img.onload = onload;
    return img;
  };

  const addImageToStage = (index) => {
    setImages((currentImages) => [
      ...currentImages,
      {
        x: width / 3 + ((index * 20) || 20),
        y: height / 3 + (index * 20 || 20),
        current: createRef()
      }
    ]);
  };

  const handleDeleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    if (newImages.length < 1) {
      resetSelectedImage();
      imageUrl = null;
      trRef?.current?.nodes([]);
    }
    setImages(newImages);
  };

  return (
    <div
      ref={containerRef}
      className={cn('rounded-md bg-white mr-0', isMeshBg && !isListingTemplates && 'bg-canvasMeshBg',
      isListingTemplates && 'bg-canvasWithGuideline bg-cover')}
    >
      <Stage
        onClick={() => {
          trRef?.current?.nodes([]);
          setClickedOutSide(true);
        }}
        onTap={() => {
          trRef?.current?.nodes([]);
          setClickedOutSide(true);
        }}
        ref={stageRef}
        width={width}
        height={height}
      >
        <Layer>
          {!isListingTemplates && (
            <Transformer
              enabledAnchors={[
                'top-left',
                'top-right',
                'bottom-left',
                'bottom-right'
              ]}
              keepRatio={true}
              aspect
              ref={trRef}
              style={{
                zIndex: '0'
              }}
              anchorStroke='black'
              anchorSize={isMobScreen && 6}
              borderStroke='black'
              className='rounded-5'
            ></Transformer>
          )}
          {images?.map((image, index) => {
            return (
              <IndividualImage
                key={index}
                keyValue={index}
                image={image}
                onDelete={handleDeleteImage}
                images={images}
                setImages={setImages}
                selectedImageUrl={imageUrl}
                generateImage={generateImage}
                addImageToStage={addImageToStage}
                imageItemRef={trRef}
                clickedOutSide={clickedOutSide}
                setClickedOutSide={setClickedOutSide}
                isMobScreen={isMobScreen}
                handleList={handleList}
                isListingTemplates={isListingTemplates}
              />
            );
          })}
        </Layer>
      </Stage>
    </div>
  );
};

export default CanvasAdvanceScreen;
