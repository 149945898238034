import React, { useEffect } from 'react';
import LoginForm from 'components/LoginForm';
// import RegisterFormImage from '../assets/img/RegisterFormSideImg.png';
import LoginBg from '../assets/img/LoginBg.jpg';
import { Helmet } from 'react-helmet';

const LoginPage = () => {
  useEffect(() => {
    document.title = 'breeze.ai app login'; // Change the document title
    return () => {
      document.title = 'breeze.ai app'; // Reset the title when the component unmounts
    };
  }, []);
  return (
    <>
      <Helmet>
        <meta name='description' content='Breeze.ai helps you create beautiful photos and captions 
        for your marketing content with AI. Login and find out' />
      </Helmet>
      <main className='w-full h-screen'>
        <div className='fluid flex h-full rounded-lg'>
          {/* <div className='min-h-fit justify-center items-center w-[45%] max-w-[800px] hidden lg:flex'>
            <img src={RegisterFormImage} alt='side image' className='w-[80%]' />
          </div>
          <LoginForm /> */}
          <div className='relative w-full h-full flex justify-center items-center backdrop-filter 
          backdrop-brightness-[0.2]'>
            <img src={LoginBg} alt='' className='w-full h-full absolute opacity-[0.5] top-0 left-0 object-cover' />
            <LoginForm />
          </div>
        </div>
      </main>
    </>
  );
};

export default LoginPage;
