import React from 'react';
import { useRoutes } from 'react-router-dom';
import LoginPage from 'pages/LoginPage';
import RegisterPage from 'pages/RegisterPage';
import ForgetPasswordPage from 'pages/ForgetPasswordPage';
import DesktopRoutes from './DesktopRoutes';
import MobileRoutes from '../routes/MobileRoutes';
import CollectContent from 'components/DesktopComponents/CollectContent';
import CollectContentSuccessSubmit from 'components/DesktopComponents/CollectContent/SuccessfullySubmittedScreen';
import ImageLibrarySingleImageDetails from 'components/DesktopComponents/ImageLibrarySingleImageDetails';
import ProjectDocuments from 'components/DesktopComponents/Projects/ProjectDocuments';

function ProjectRoutes() {
  const allRoutes = useRoutes([
    { path: 'recover-password', element: <ForgetPasswordPage /> },
    { path: 'login', element: <LoginPage /> },
    { path: 'register', element: <RegisterPage /> },
    { path: 'd/:key', element: <CollectContent /> },
    { path: 'd/:key/success', element: <CollectContentSuccessSubmit /> },
    { path: 'imageInfo/:key', element: <ImageLibrarySingleImageDetails /> },
    { path: 'projectShare/:projectShareKey', element: <ProjectDocuments /> },
    {
      path: 'imageLibrary/:pid/:gid?',
      element: <ImageLibrarySingleImageDetails />
    },
    {
      path: '/*',
      children: [
        {
          path: '*',
          element: <DesktopRoutes />
        }
      ]
    },
    {
      path: '/mobile/*',
      children: [
        {
          path: '*',
          element: <MobileRoutes />
        }
      ]
    }
  ]);
  // Hiding the intercom app if the routes url in not /login or /register
  if(['/login', '/register'].includes(window?.location?.pathname)) {
    window?.Intercom && window?.Intercom('update', { hide_default_launcher: false
    });
  } else {
    window?.Intercom && window?.Intercom('update', { hide_default_launcher: true });
  }
  return allRoutes;
}

export default ProjectRoutes;
