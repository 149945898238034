/* eslint-disable max-len */
import React from 'react';
import cn from 'classnames';
import RenderSmoothImageComponent from './renderImageSmoothly';

const RenderImageList = (props) => {
  const {
    image,
    clickedImage,
    setClickedImage,
    index,
    generateLoading,
    handleReset
  } = props;

  return (
    <>
      <div
        className={cn('flex items-center justify-center relative rounded shadow text-center w-full h-full',
          clickedImage && clickedImage?.gid === (image?.gid || image) && 'border-[2px] border-[#757D89]'
        )}
        role="presentation"
        onClick={() => { setClickedImage(image); handleReset(true); }}
      >
        <div
          className={cn('rounded shadow text-center h-full w-full',
            clickedImage && clickedImage?.gid === (image?.gid || image) && 'border-[2px] border-white'
          )}
        >
          <RenderSmoothImageComponent
            src={image?.thumbnail || image?.url || image?.edited_url}
            alt="loading..."
            objectFit="fill"
            id={`generated_images_${index}`}
            className='bg-[#F1F1F1] w-auto m-auto h-full'
            loadGif={generateLoading}
          />
        </div>
      </div>
    </>
  );
};

export default RenderImageList;