import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import InfoIcon from 'assets/icons/Info.svg';
import KeywordsIcon from 'assets/icons/keywordsIcon.svg';
import ReactSelectCreatable from 'components/ReactSelectCreatable';
import plusIcon from 'assets/icons/plusIcon.svg';
import closeIcon from 'assets/img/closeIcon.svg';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import { ClipLoader } from 'react-spinners';
import { handleTokenExpiryIssue } from 'utils/functions.util';
import { BASE_URL } from 'utils/baseURL';
import TabButton from 'components/shared/TabButton';
import moment from 'moment';
import cn from 'classnames';

const ImageInfoAndKeywords = ({
  selectedImage,
  imageHistory,
  selectedTab,
  setSelectedTab,
  shareKey,
  projectShareData
}) => {
  console.log('data', shareKey, projectShareData);
  const [showSelectBox, setShowSelectBox] = useState(false);
  const [isFocussed, setIsFocussed] = useState();
  const [createdKeywords, setCreatedKeywords] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedImage) {
      setCreatedKeywords([]);
    }
  }, [selectedImage]);

  const handleImageSize = (imgSize) => {
    if (imgSize > 1023) {
      return `${Math.ceil(Number(imgSize / 1024))} MB`;
    } else return `${Math.ceil(Number(imgSize).toFixed(2))} KB`;
  };

  const getTableValues = (field) => {
    let value = '';
    switch (field) {
      case 'time':
        value = selectedImage?.data?.time ? moment(selectedImage?.data?.time).format('MMM DD, YYYY') : '';
        return value;
      case 'created_on':
        value = selectedImage?.data?.created_on ? moment(selectedImage?.data?.created_on).format('MMM DD, YYYY') : '';
        return value;
      case 'dimensions':
        value = selectedImage?.data?.dimensions ?
          `${selectedImage?.data?.dimensions[0]} * ${selectedImage?.data?.dimensions[1]}` : '';
        return value;
      case 'image_name':
        value = selectedImage?.data?.image_name?.split('.')[0];
        return value;
      case 'size':
        value = selectedImage?.data?.size && handleImageSize(selectedImage?.data?.size);
        return value;
      default: value = selectedImage?.data?.[field];
        return value;
    }
  };

  const handleClickAddKeywords = () => {
    setShowSelectBox(true);
    setIsFocussed(true);
  };

  //finding which keyword is removed and deleting it
  const handleDeleteKeyword = async (keywords) => {
    let difference = createdKeywords.filter(x => !keywords.includes(x));
    setLoading(true);
    try {
      const url = BASE_URL + 'image-gen/delete_keywords';
      const data = new FormData();
      if (selectedImage?.type === 'Uploaded') data.append('pid', selectedImage?.data?.pid);
      if (selectedImage?.type === 'Generated') data.append('gid', selectedImage?.data?.gid);
      data.append('keywords', difference[0]?.value);
      const res = await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: LocalStorage.getItem()
        }
      });
      console.log(res);
      setCreatedKeywords(keywords);
    } catch (err) {
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
    } finally {
      setLoading(false);
    }
  };

  const createOption = (option) => {
    return { label: option, value: option };
  };

  //saving newly created keywords
  const handleCreateKeyword = async (option) => {
    setLoading(true);
    try {
      const url = BASE_URL + 'image-gen/add_keywords';
      const data = new FormData();
      if (selectedImage?.type === 'Uploaded') data.append('pid', selectedImage?.data?.pid);
      if (selectedImage?.type === 'Generated') data.append('gid', selectedImage?.data?.gid);
      data.append('keywords', option);
      const res = await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: LocalStorage.getItem()
        }
      });
      console.log(res);
      setCreatedKeywords((prev) => [...prev, createOption(option)]);
      setShowSelectBox(true);
      setIsFocussed(true);
    } catch (err) {
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedImage) {
      if (selectedImage?.data?.keywords?.user?.length > 0) {
        // setSavedKeywords(selectedImage?.data?.keywords?.user);
        const savedKeywords = selectedImage?.data?.keywords?.user?.map((keyword) => (
          createOption(keyword)
        ));
        setCreatedKeywords((prev) => [...prev, ...savedKeywords]);
        // setShowSelectBox(true);
      } else {
        // setSavedKeywords([]);
      }
    }
  }, [selectedImage]);

  // deleting previously saved keywords
  const handleSavedKeywordsDeletion = async (keyword) => {
    setLoading(true);
    try {
      const url = BASE_URL + 'image-gen/delete_keywords';
      const data = new FormData();
      if (selectedImage?.type === 'Uploaded') data.append('pid', selectedImage?.data?.pid);
      if (selectedImage?.type === 'Generated') data.append('gid', selectedImage?.data?.gid);
      data.append('keywords', keyword);
      const res = await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: LocalStorage.getItem()
        }
      });
      console.log(res);
      let savedKeywordsAfterDeletion = createdKeywords.filter(x => !(x.value === keyword));
      setCreatedKeywords(savedKeywordsAfterDeletion);
    } catch (err) {
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='font-jost p-[20px] h-full'>
      <Formik initialValues={{}}>
        <Form className={'rounded bg-white h-full overflow-y-auto'}>
          <div className='grid grid-cols-8 gap-5 mb-5'>
            <TabButton
              active={selectedTab === 'info'}
              onClick={() => {
                setSelectedTab('info');
              }}
              type='button'
              className={cn('font-jost text-[20px] p-1 col-span-4 gap-2 text-[#191919]',
                'flex justify-center items-center', selectedTab !== 'info' && 'mb-[3px]')}
              style={{ text: 'nowrap' }}
              activeClassName={{ borderBottom: '3px solid #191919' }}
            >
              <img src={InfoIcon} alt='' />
              <span>Info</span>
            </TabButton>
            <TabButton
              active={selectedTab !== 'info'}
              onClick={() => {
                setSelectedTab('keywords');
              }}
              type='button'
              className={cn('font-jost text-[20px] p-1 col-span-4 gap-2 text-[#191919]',
              'flex justify-center items-center', selectedTab !== 'keywords' && 'mb-[3px]')}
              style={{ text: 'nowrap' }}
              activeClassName={{ borderBottom: '3px solid #191919' }}
            >
              <img src={KeywordsIcon} alt='' />
              <span>Keywords</span>
            </TabButton>
          </div>
          <div className='mb-5 tab-content flex items-center'>
            {loading ? (
              <div className='flex flex-col items-center justify-center mt-6 spinner-border bg-white'
                style={{ height: '400px', width: '100%' }}>
                <ClipLoader color='#757D89' />
                <div className='lg:text-[14px] xl:text-[16px] xl-mid:text-[18px] 4xl:text-[20px] font-semibold mb-2'>
                  Loading...</div>
              </div>
            ) : (
              <>
                {selectedTab === 'info' && (
                  <table className='!shadow-none w-full'>
                    <tbody className='divide-y'>
                      {imageHistory?.info && Object.entries(imageHistory?.info)?.map((data, index) => {
                        return (
                          <tr key={index} className='bg-white font-jost text-[16px]'>
                            <td className='px-4 py-6 text-[#5F5F5F]'>
                              {data?.[0]}
                            </td>
                            <td className='px-4 py-6 text-[#2C2C2C]'>
                              {getTableValues(data?.[1])}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
                {selectedTab === 'keywords' && (
                  <div className='flex justify-start flex-col w-full px-4'>
                    {selectedImage?.data?.keywords?.ai?.length > 0 && (
                      <div className='mb-[30px] pt-6'>
                        <p className='font-jost font-[500] text-[16px] text-[#7D7D7D] pb-[10px]'>AI GENERATED</p>
                        <div className='flex flex-wrap gap-[10px]'>
                          {selectedImage?.data?.keywords?.ai?.map((keyword, index) => {
                            return (
                              <div key={index} className='bg-[#F8F8F8] rounded-[42px] h-[40px] px-[20px] flex 
                            items-center border-[1px] border-solid border-[#DBDBDB] whitespace-nowrap'>
                                <span className='font-jost font-[500] text-[14px] text-[#7D7D7D]'>{keyword}</span>
                              </div>
                            );
                          })}
                        </div>
                        <p
                          className='font-jost font-[500] text-[16px] py-[10px] text-[#333]'
                        >
                          {selectedImage?.data?.keyword_caption}
                        </p>
                      </div>
                    )}
                    <div className='font-jost font-[500] text-[16px] text-[#7D7D7D] mb-[30px]'>
                      <p className='py-[10px]'>KEYWORDS</p>
                      {!showSelectBox && !createdKeywords.length > 0 && (
                        <div className='flex items-baseline gap-2' 
                          onClick={() => (!shareKey && !projectShareData) && handleClickAddKeywords()}>
                          <img src={plusIcon} alt='' className='w-[12px] h-[12px]' />
                          <span>Click to add a tag</span>
                        </div>
                      )}
                      <div className='flex items-center'>
                        {showSelectBox && (
                          <ReactSelectCreatable
                            isFocussed={isFocussed}
                            setIsFocussed={setIsFocussed}
                            handleCreateKeyword={handleCreateKeyword}
                            createdKeywords={createdKeywords}
                            handleDeleteKeyword={handleDeleteKeyword}
                            setShowSelectBox={setShowSelectBox}
                          />
                        )}
                      </div>
                      {!showSelectBox && createdKeywords?.length > 0 && !isFocussed && (
                        <div className='flex flex-wrap gap-[10px]'>
                          {createdKeywords?.map((keyword, index) => {
                            return (
                              <div key={index} className='bg-[#F8F8F8] rounded-[42px] h-[40px] px-[20px] flex 
                              items-center border-[1px] border-solid border-[#DBDBDB] whitespace-nowrap'>
                                <span className='font-[500] text-[14px] text-[#7D7D7D]'>{keyword.value}</span>
                                <img src={closeIcon} alt='' width='9px' height='9px' className={`ml-[10px] 
                                 ${(shareKey || projectShareData) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                  onClick={() => (!shareKey && !projectShareData) && 
                                    handleSavedKeywordsDeletion(keyword.value)} />
                              </div>
                            );
                          })}
                          <div className={`flex items-baseline gap-2 self-center 
                            ${(shareKey || projectShareData) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                            onClick={() => (!shareKey && !projectShareData) && handleClickAddKeywords()}>
                            <img src={plusIcon} alt='' className='w-[13px] h-[14px]' />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ImageInfoAndKeywords;