import React from 'react';

const StatisticsCard = ({ icon, value, label }) => {
  return (
    <div className='flex items-center w-full h-48 gap-8 p-3 pl-12 text-gray-600
     bg-white rounded-lg card sm:h-36 sm:w-80'>
      <div>
        <i className='w-4 text-blue-500 text-primo'>{icon}</i>
      </div>
      <div className='text-primary'>
        <h4 className='text-2xl font-bold'>{value ?? 0}</h4>
        <p className='font-medium'>{label}</p>
      </div>
    </div>
  );
};

export default StatisticsCard;
