import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './components/Navbar';
// import Sidebar from 'pages/Sidebar';
import SidebarNew from 'pages/SidebarNew';

const mainNav = {
  left: [
    {
      id: 1,
      display: 'خانه',
      path: '/'
    },
    {
      id: 2,
      display: 'محصولات',
      path: '/products'
    },
    {
      id: 3,
      display: 'مدیریت',
      path: '/orders'
    }
  ],
  right: [
    {
      id: 4,
      display: <i className='bx bx-search'></i>,
      path: '/search'
    },
    {
      id: 5,
      display: <i className='bx bx-user'></i>,
      path: '/login'
    },
    {
      id: 6,
      display: <i className='bx bx-shopping-bag'></i>,
      path: '/cart'
    }
  ]
};

const DashboardUserLayout = () => {
  return (
    <main className='bg-white'>
      {/*<CustomCursor/>*/}
      <Navbar links={mainNav} />
      <div className='flex h-[calc(100vh-57px)]'>
        <SidebarNew />
        <main className='mx-auto 3xl:container w-full h-[calc(100vh-58px)] bg-white overflow-auto'>
          <Outlet />
        </main>
      </div>
    </main>
  );
};

export default DashboardUserLayout;
