import React, { useEffect, useState } from 'react';
import FilterIcon from 'assets/img/filterIcon.svg';
import closeIcon from 'assets/img/selectedFilterClearIcon.svg';
import FilterPopup from './FilterPopup';
import { BASE_URL } from 'utils/baseURL';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import DateFilterPopup from './DateFilterPopup';
import cn from 'classnames';
import NormalTagIcon from 'assets/img/tagIcon.svg';
import NormalProjectIcon from 'assets/img/projectsIcon.svg';
import NormalFileFilterIcon from 'assets/img/fileFilterIcon.svg';
import SelectedTagIcon from 'assets/img/selectedTagIcon.svg';
import SelectedProjectIcon from 'assets/img/selectedProjectIcon.svg';
import SelectedFileFilterIcon from 'assets/img/selectedFileFilterIcon.svg';
import NormalDateIcon from 'assets/img/normalDateIcon.svg';
import SelectedDateIcon from 'assets/img/selectedDateIcon.svg';
import NormalColorIcon from 'assets/img/colorIcon.svg';
import SelectedColorIcon from 'assets/img/selectedColorIcon.svg';
import plusIcon from 'assets/icons/accordionPlusIcon.svg';
import { useParams } from 'react-router-dom';

const FilterComponent = ({
  searchDataList,
  searchedText,
  selectedTab,
  setSearchDataList,
  setLoading,
  setIsSearched,
  searchedData,
  projectShareKey,
  projectShareProjectId
}) => {
  const { projectID } = useParams();

  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false); // show or close the filter popup
  const [selectedFilter, setSelectedFilter] = useState(); // selected filter
  const [dropdownValues, setDropdownValues] = useState(); // dropdown values in tag and project filters popup
  const [fileFormatList, setFileFormatList] = useState([]);
  const [searchValue, setSearchValue] = useState(); // value searched in select box of tag and project filters popup
  const [isDateFilterPopupOpen, setIsDateFilterPopupOpen] = useState(false); // show or close date filter popup
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [selectedTags, setSelectedTags] = useState([]); // selected tag filters
  const [selectedProjects, setSelectedProjects] = useState([]); // selected project filters
  const [selectedFileFormat, setSelectedFileFormat] = useState([]); // selected fileFormat filters
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedColor, setSelectedColor] = useState([]); // to store the selected colors
  // resetting tag and project filters dropdown after filter is applied
  const [resetDropdownValues, setResetDropdownValues] = useState(false);
  const [selectedDateUploadedValue, setSelectedDateUploadedValue] = useState([]);
  const [filterData, setFilterData] = useState(); // filter options

  // filter options
  const filterTypesData = [
    {
      id: 1,
      name: 'Tags',
      normalIcon: NormalTagIcon,
      selectedIcon: SelectedTagIcon,
      placeholder: 'Search for tags',
      noOptionMessage: 'No such tag exists',
      isFilterApplied: false,
      valueSearch: selectedTags
    },
    {
      id: 2,
      name: 'Projects',
      normalIcon: NormalProjectIcon,
      selectedIcon: SelectedProjectIcon,
      placeholder: 'Search for projects',
      noOptionMessage: 'No such project exists',
      isFilterApplied: false,
      valueSearch: selectedProjects
    },
    {
      id: 3,
      name: 'File format',
      normalIcon: NormalFileFilterIcon,
      selectedIcon: SelectedFileFilterIcon,
      placeholder: 'Select format',
      isFilterApplied: false,
      valueSearch: selectedFileFormat
    },
    {
      id: 4,
      name: 'Date',
      normalIcon: NormalDateIcon,
      selectedIcon: SelectedDateIcon,
      placeholder: 'Select date',
      isFilterApplied: false,
      valueSearch: selectedFileFormat
    },
    {
      id: 5,
      name: 'Color',
      normalIcon: NormalColorIcon,
      selectedIcon: SelectedColorIcon,
      placeholder: 'Select Color',
      isFilterApplied: false,
      valueSearch: selectedColor
    },
    {
      id: 6,
      name: 'More',
      normalIcon: plusIcon,
      selectedIcon: plusIcon,
      placeholder: 'More',
      isFilterApplied: false,
      valueSearch: selectedColor
    }
  ];

  // change the colour of the filter box and text if that filter is applied
  const resetFilters = (id, isClearFilter) => {
    if (id) {
      const tempFilterData = filterData;
      const currentFilter = tempFilterData?.find(filter => filter?.id === id);
      if (currentFilter) {
        if (currentFilter?.isFilterApplied && isClearFilter) {
          currentFilter.isFilterApplied = false;
        } else {
          currentFilter.isFilterApplied = true;
        }
      }
      setFilterData(tempFilterData);
    }
  };

  // preparing data to send to search api
  const setDataForSearch = (searchValue, filter, isClearFilter) => {
    const data = new FormData();
    if (projectID) {
      data.append('projectID', projectID);
    }
    if (projectShareKey) {
      data.append('projectID', projectShareProjectId);
    }
    data.append('keyword', searchValue || '');
    // append selected tag filters if tag filters are not being cleared
    if (selectedTags?.length > 0 && (!isClearFilter || (isClearFilter && filter?.name !== 'Tags'))) {
      const tags = selectedTags?.map((tag) => { return tag.value; });
      data.append('tags', tags);
    }
    // append selected project filters if project filters are not being cleared
    if (selectedProjects?.length > 0 && (!isClearFilter || (isClearFilter && filter?.name !== 'Projects'))) {
      const projects = selectedProjects?.map((project) => { return project.value; });
      data.append('project_name', projects);
    }
    // append selected fileFormat filters if fileFormat filters are not being cleared
    if (selectedFileFormat?.length > 0 && (!isClearFilter || (isClearFilter && filter?.name !== 'File format'))) {
      const fileFormats = selectedFileFormat?.map((project) => { return project.value; });
      data.append('file_format', fileFormats);
    }
    // append selected date uploaded value if date filters are not being cleared
    if (selectedDateUploadedValue && (!isClearFilter || (isClearFilter && filter?.name !== 'Date'))) {
      data.append('date_uploaded', selectedDateUploadedValue?.value);
    }
    // append selected start date uploaded value if date filters are not being cleared
    if (selectedStartDate && (!isClearFilter || (isClearFilter && filter?.name !== 'Date'))) {
      data.append('start_date', selectedStartDate);
    }
    // append selected end date uploaded value if date filters are not being cleared
    if (selectedEndDate && (!isClearFilter || (isClearFilter && filter?.name !== 'Date'))) {
      data.append('end_date', selectedEndDate);
    }

    // append selected colours filters if colours filters are not being cleared
    if (selectedColor?.length > 0 && (!isClearFilter || (isClearFilter && filter?.name !== 'Color'))) {
      data.append('colours', selectedColor.join(','));
    }
    return data;
  };

  const searchByKey = async (searchValue, filter, isClearFilter) => {
    setSearchDataList();
    setLoading(true);
    const data = setDataForSearch(searchValue, filter, isClearFilter);
    const url = BASE_URL + 'image-gen/search_keywords';
    try {
      const searchData = await axios.post(url, data, {
        headers: {
          token: LocalStorage.getItem()
        }
      });
      // if filters are being cleared reset the selected tags or selected projects based on filter cleared
      if (isClearFilter) {
        if (filter?.name === 'Tags') setSelectedTags([]);
        if (filter?.name === 'Projects') setSelectedProjects([]);
        if (filter?.name === 'File format') setSelectedFileFormat([]);
        if (filter?.name === 'Date') setSelectedDateUploadedValue([]);
      }
      resetFilters(filter?.id, isClearFilter);
      setResetDropdownValues(true);
      if (projectID) {
        const tempSearchData = searchData?.data?.data?.documents?.filter(item => item?.image_origin === 'Uploaded');
        setSearchDataList(tempSearchData);
      } else {
        setSearchDataList(searchData?.data?.data?.documents);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  // apply filter for tag and projects filters
  const handleApplyFilter = (filter, isClearFilter) => {
    setIsSearched(true);
    searchByKey(searchedText, filter, isClearFilter);
  };

  useEffect(() => {
    if (searchedText) {
      const timer = setTimeout(() => {
        searchByKey(searchedText);
        setIsSearched(true); // setting isSearched true only after searching
      }, 750); // Adjust the delay time (in milliseconds) as needed

      // Clean up the previous timer
      return () => clearTimeout(timer);
    }
  }, [searchedText]);

  useEffect(() => {
    //if searchKey is present in localStorage the we call the listAPI with that key
    if (searchedData) {
      setIsSearched(true);
      searchByKey(searchedData);
      setIsSearched(true); // setting isSearched true only after searching
      localStorage?.removeItem('searchKey');
    }
  }, [searchedData]);

  useEffect(() => {
    if (projectID) {
      const newFilterTypes = filterTypesData.filter(item => item.name !== 'Projects');
      setFilterData(newFilterTypes);
    } else {
      setFilterData(filterTypesData);
    }
  }, []);

  const getFileFormatList = async () => {
    try {
      const responseData = await axios.get(
        BASE_URL + 'image-gen/get_file_format'
      );
      const fileFormatListData = responseData?.data?.data?.file_format;
      setFileFormatList(Object.values(fileFormatListData));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getFileFormatList();
  }, []);

  useEffect(() => {
    setSearchValue();
    setDropdownValues();
  }, [resetDropdownValues]);

  // api to get dropdown values for tags and project filters popup
  const getSearchData = async () => {
    const url = BASE_URL + 'image-gen/get_search_data';
    const data = new FormData();
    if (selectedFilter?.name === 'Tags') data?.append('tags', searchValue);
    if (selectedFilter?.name === 'Projects') data?.append('project_name', searchValue);
    try {
      const res = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage?.getItem()
          }
        }
      );
      switch (selectedFilter?.name) {
        case 'Tags': return setDropdownValues(res?.data?.data?.tags);
        case 'Projects': return setDropdownValues(res?.data?.data?.project_name);
        default: break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  // handle on click of a filter option
  const handleOnClickFilter = (filter) => {
    setSelectedFilter(filter);
    setDropdownValues();
    switch (filter?.name) {
      case 'Date':
        setIsDateFilterPopupOpen(!isDateFilterPopupOpen);
        setIsFilterPopupOpen(false);
        break;
      default:
        setIsDateFilterPopupOpen(false);
        setIsFilterPopupOpen(!isFilterPopupOpen);
        break;
    }
  };

  // function to monitor the change in select input box
  const handleDropdownInputChange = (value) => {
    setSearchValue(value);
  };

  // whenever the input in the select box changes call the api to get dropdown values
  useEffect(() => {
    if (searchValue) {
      getSearchData();
    }
  }, [searchValue]);

  // on select of options from the dropdown of tags and projects filters popup update respective state
  const handleOnDropdownChange = (value) => {
    if (selectedFilter?.isFilterApplied && selectedFilter?.name !== 'Date') {
      if (!value?.length > 0) {
        handleApplyFilter(selectedFilter, true);
      }
    }
    switch (selectedFilter?.name) {
      case 'Tags':
        setSelectedTags(value);
        break;
      case 'Projects': setSelectedProjects(value);
        break;
      case 'File format': setSelectedFileFormat(value);
        break;
      case 'Date':
        setSelectedDateUploadedValue(value);
        if (value?.label === 'Custom date range') {
          setShowCustomDatePicker(true);
        } else {
          setStartDate();
          setEndDate();
          setSelectedStartDate();
          setSelectedEndDate();
          setShowCustomDatePicker(false);
        }
        break;
      default: break;
    }
  };

  // on click of Clear button clear the inputs in the select box
  const handleClear = (filter) => {
    setDropdownValues();
    if (filter?.isFilterApplied) handleApplyFilter(filter, true);
    switch (filter?.name) {
      case 'Tags':
        setSelectedTags([]);
        break;
      case 'Projects':
        setSelectedProjects([]);
        break;
      case 'File format':
        setSelectedFileFormat([]);
        break;
      case 'Date':
        setSelectedDateUploadedValue([]);
        setStartDate();
        setEndDate();
        setShowCustomDatePicker(false);
        break;
      case 'Color':
        setSelectedColor([]);
        break;
      default:
        break;
    }
  };

  // on click of Apply button
  const handleOnClickApply = (filter) => {
    setIsFilterPopupOpen(false);
    setIsDateFilterPopupOpen(false);
    handleApplyFilter(filter);
  };

  // on click of close icon to clear the filter selection
  const clearFilterSelection = (filter) => {
    handleClear(filter);
    // handleApplyFilter(filter, true);
  };

  // values to be sent to the dropdown in tags and projects filters popup
  const handleSelectFieldValues = (filter) => {
    let values;
    switch (filter?.name) {
      case 'Tags':
        values = selectedTags;
        break;
      case 'Projects':
        values = selectedProjects;
        break;
      case 'File format':
        values = selectedFileFormat;
        break;
      case 'Date':
        values = selectedDateUploadedValue;
        break;
      default:
        break;
    }
    return values;
  };

  const handleMouseLeave = () => {
    if (!selectedFilter?.isFilterApplied) {
      switch (selectedFilter?.name) {
        case 'Tags':
          setSelectedTags([]);
          break;
        case 'Projects':
          setSelectedProjects([]);
          break;
        case 'File format':
          setSelectedFileFormat([]);
          break;
        case 'Date':
          setSelectedDateUploadedValue([]);
          setStartDate();
          setEndDate();
          setShowCustomDatePicker(false);
          break;
        case 'Color':
          setSelectedColor([]);
          break;
        default:
          break;
      }
    }
  };

  const FilterTypeComponent = ({ filter }) => {
    return (
      <div className={cn(`h-6 px-2.5 ${filter?.isFilterApplied ? 'bg-[#0038FF1A] text-[#0038FF]' :
        (((filter?.name === selectedFilter?.name) && isFilterPopupOpen) ||
          ((filter?.name === selectedFilter?.name) && isDateFilterPopupOpen) ?
          'bg-[#D9D9D9]' : 'bg-[#F0F0F0]')} rounded-[5px] justify-center items-center inline-flex gap-[10px]
          ${(selectedTab === 'Templates' || filter?.name === 'More') ?
          'cursor-not-allowed opacity-50' : 'cursor-pointer'}`)}>
        <div onClick={() => selectedTab !== 'Templates' && handleOnClickFilter(filter)} >
          <div className="justify-center items-center gap-[5px] flex">
            <img
              src={filter?.isFilterApplied ? filter?.selectedIcon : filter?.normalIcon}
              alt=''
              className='w-[14px] h-[14px]'
            />
            <div className="text-neutral-600 text-[14px] font-normal leading-normal">{filter?.name}</div>
          </div>
        </div>
        {filter?.isFilterApplied && (
          <img
            src={closeIcon}
            alt=''
            className='w-[8px] h-[8px]'
            onClick={() => clearFilterSelection(filter)}
          />
        )}
      </div>
    );
  };

  return (
    <div
      className={cn('flex gap-[10px] font-jost relative',
        searchDataList?.length > 0 && filterData?.some((item) => item?.isFilterApplied) ? 'pb-[54px]' : 'pb-[40px]')}
      onMouseLeave={() => { handleMouseLeave(); setIsFilterPopupOpen(false); setIsDateFilterPopupOpen(false); }}
    >
      <img src={FilterIcon} alt='' className={`${selectedTab === 'Templates' && 'opacity-50'} w-[24px] h-[24px]`} />
      {filterData?.map((filter, index) => (
        <>
          <div key={index} className='relative z-[15]'>
            <FilterTypeComponent filter={filter} key={index} />
            {isFilterPopupOpen && (selectedFilter.name === filter?.name) && (selectedFilter.name !== 'More') && (
              <FilterPopup
                filter={filter}
                dropdownValues={dropdownValues}
                handleDropdownInputChange={handleDropdownInputChange}
                handleOnDropdownChange={handleOnDropdownChange}
                selectedTags={selectedTags}
                handleClear={() => handleClear(filter)}
                handleApplyFilter={() => handleOnClickApply(filter)}
                selectFieldValues={handleSelectFieldValues(filter)}
                selectedProjects={selectedProjects}
                fileFormatList={fileFormatList}
                selectedFilter={selectedFilter}
                selectedFileFormat={selectedFileFormat}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
              />
            )}
            {isDateFilterPopupOpen && (selectedFilter?.name === filter?.name) && (
              <DateFilterPopup
                filter={filter}
                handleDropdownInputChange={handleDropdownInputChange}
                handleOnDropdownChange={handleOnDropdownChange}
                handleClear={() => handleClear(filter)}
                handleApplyFilter={() => handleOnClickApply(filter)}
                selectFieldValues={handleSelectFieldValues(filter)}
                selectedDateUploadedValue={selectedDateUploadedValue}
                showCustomDatePicker={showCustomDatePicker}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            )}
          </div>
        </>
      ))}
      {searchDataList?.length > 0 && filterData?.some((item) => item?.isFilterApplied) ? (
        <div
          className="text-left text-[#7D7D7D] text-sm font-medium font-jost leading-normal
          absolute pt-[20px] pb-[10px] bottom-0"
        >
          {searchDataList?.length} Results
        </div>
      ) : <div
        className="text-left text-[#7D7D7D] text-sm font-medium font-jost leading-normal
          absolute pt-[20px] pb-[10px] bottom-0"
      >
        {searchDataList?.length > 0 && searchedText ? searchDataList?.length + ' Results' : ''}
      </div>
      }
    </div>
  );
};

export default FilterComponent;