import React, { useEffect } from 'react';
import GeneratedImagesSection from './GeneratedImagesSection';
import GeneratedCaptionsSection from './GeneratedCaptionsSection';
import PreviewScreen from './PreviewScreen';

const PreviewScreenAndGeneratedImages = ({
  clickedImage,
  generateLoading,
  canvasWidth,
  canvasStageRef,
  handleImagesPosition,
  canvasTransformerRef,
  imageForView,
  selectedImage,
  isMeshBg,
  generatedImagesData,
  setClickedImage,
  selectedTab,
  captionListData,
  recentGenImgList,
  setSelectedGenImg,
  recentGenImgLoading,
  setSelectedImage,
  selectedCaption,
  setSelectedCaption,
  generateCaptionLoading,
  setIsDeleted,
  handleCanvas,
  setDeletedImgIndex,
  isListingTemplates,
  selectedListingTempTab,
  handleImageEditorPopup,
  isDisabledBtn
}) => {

  useEffect(() => {
    if (captionListData && captionListData.length > 0) {
      setSelectedCaption(captionListData?.output[0]?.caption);
    }
  }, [captionListData]);

  return (
    <>
      <div className='h-[78%] flex flex-col justify-end'>
        <PreviewScreen
          clickedImage={clickedImage}
          generateLoading={generateLoading}
          canvasWidth={canvasWidth}
          canvasStageRef={canvasStageRef}
          handleImagesPosition={handleImagesPosition}
          canvasTransformerRef={canvasTransformerRef}
          imageForView={imageForView}
          selectedImage={selectedImage}
          isMeshBg={isMeshBg}
          selectedTab={selectedTab}
          captionListData={captionListData}
          selectedCaption={selectedCaption}
          setIsDeleted={setIsDeleted}
          handleCanvas={handleCanvas}
          isListingTemplates={isListingTemplates}
          selectedListingTempTab={selectedListingTempTab}
          handleImageEditorPopup={handleImageEditorPopup}
          isDisabledBtn={isDisabledBtn}
        />
      </div>
      <div className='h-[21%] border-t border-[#D9D9D9] rounded-md overflow-scroll'>
        {(selectedTab === 'text') ? (
          <GeneratedCaptionsSection
            captionListData={captionListData}
            setSelectedCaption={setSelectedCaption}
            selectedCaption={selectedCaption}
            generateCaptionLoading={generateCaptionLoading}
          />
        ) : (
          <GeneratedImagesSection
            clickedImage={clickedImage}
            setClickedImage={setClickedImage}
            setSelectedGenImg={setSelectedGenImg}
            generatedImagesData={generatedImagesData}
            selectedImage={selectedImage}
            selectedTab={selectedTab}
            recentGenImgList={recentGenImgList}
            recentGenImgLoading={recentGenImgLoading}
            setSelectedImage={setSelectedImage}
            generateLoading={generateLoading}
            setDeletedImgIndex={setDeletedImgIndex}
          />
        )}
      </div>
    </>
  );
};

export default PreviewScreenAndGeneratedImages;