import React, { useState } from 'react';
import { AppButton } from 'components/shared/AppButton';
import closeIcon from 'assets/icons/closeIcon.svg';
import blueTick from 'assets/img/Bluetick.svg';
import plusIcon from 'assets/icons/plusIcon.svg';
import { ClipLoader } from 'react-spinners';
import cn from 'classnames';

const ProjectSelectionPopup = (props) => {
  const {
    handleClose,
    handleSave,
    isProjectUpdating,
    setSelectedProject,
    projectList,
    projectListLoading,
    selectedProject,
    newProjectName,
    setNewProjectName
  } = props;

  const [editingProjectName, setEditingProjectName] = useState(); // to track the projectName edit
  // Handling selecting the project from the list
  const handleSelectedProject = (projectData) => {
    setSelectedProject(projectData);
  };

  // making editingProjectName flag as false after clicking on enter key
  const handleInputKeyDown = (e) => {
    if (e?.key === 'Enter') {
      if (!newProjectName) {
        setNewProjectName('untitled project');
      }
      setEditingProjectName(false);
    }
  };

  // assign the enter project name to state
  const handleInputChange = (e) => {
    setNewProjectName(e.target.value);
  };

  // OnBlur make the EditingProjectName false
  const handleInputBlur = () => {
    setEditingProjectName(false);
  };

  const handleInputFocus = (e) => {
    e?.target?.select();
  };

   return (
    <div className='relative z-50' aria-labelledby='modal-title' role='dialog' aria-modal='true'>
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>
      <div className='fixed inset-0 z-50 overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
            transition-all sm:my-8 sm:mx-4 w-[514px] h-[514px] p-[40px]'>
            <div className='bg-white flex flex-col gap-[20px]'>
              <div className='w-full'>
                <div className='text-left'>
                  <div className='grid grid-cols-12'>
                    <h3
                      className='font-jost font-[600] text-[24px] whitespace-nowrap col-span-11'
                      id='modal-title'
                    >
                      Select a project to save it in
                    </h3>
                    <span
                      className='text-right flex items-center justify-center w-[35px] h-[35px] 
                      rounded-[5px] cursor-pointer'
                      role="presentation"
                      onClick={() => { handleClose(); }}
                    >
                      <img src={closeIcon} alt='' width='20px' height='20px' />
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-[434px] h-[311px] rounded-[10px] border border-solid border-[#D9D9D9] 
                overflow-scroll p-[20px] flex flex-col gap-[10px]'>
                {!(isProjectUpdating || projectListLoading) ? (
                  <>
                    <div
                      className={cn('bg-white justify-between items-center inline-flex w-full',
                        'hover:bg-[#F0F0F0] cursor-pointer')}
                      onClick={() => { setSelectedProject(); !newProjectName && setEditingProjectName(true); }}
                    >
                      {editingProjectName ? (
                        <input
                          name='projectName'
                          className='bg-[#F0F0F0] border-0 w-full p-[10px]
                            active:outline-[2px] active:outline-orange'
                          type="text"
                          onKeyDown={handleInputKeyDown}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          onFocus={handleInputFocus}
                          defaultValue='untitled project'
                          autoFocus
                        />
                      )
                      : (newProjectName ? 
                        <div
                          className={cn('p-[10px] text-[16px] cursor-pointer rounded-[5px] relative w-full',
                            newProjectName && !selectedProject?.projectID ? 'bg-[#E5EBFF]' : 'hover:bg-[#F0F0F0]')}
                          onClick={() => { setSelectedProject(); }}
                        >
                          {newProjectName}
                          {!selectedProject && (
                            <img
                              src={blueTick}
                              alt=''
                              className='absolute right-[10px] top-[14px] w-[16px] h-[16px]'
                            />
                          )}
                        </div> :
                        <>
                          <div className="p-[10px] text-zinc-800 text-base font-normal font-['Jost']">
                            Save in new project
                          </div>
                          <div className="w-[10px] h-[10px] relative mr-[10px]">
                            <img src={plusIcon} alt='' width='10px' height='10px' />
                          </div>
                        </>
                      )
                    }
                    </div>
                    <>
                      {projectList?.map((projectItem, index) => (
                        <div
                          key={index}
                          className={cn('p-[10px] text-[16px] cursor-pointer rounded-[5px] relative',
                            selectedProject?.projectID === projectItem?.projectID ?
                              'bg-[#E5EBFF]' : 'hover:bg-[#F0F0F0]')}
                          onClick={() => { handleSelectedProject(projectItem); setEditingProjectName(false); }}
                        >
                          {projectItem?.project_name}
                          {selectedProject?.projectID === projectItem?.projectID && (
                            <img
                              src={blueTick}
                              alt=''
                              className='absolute right-[10px] top-[14px] w-[16px] h-[16px]'
                            />
                          )}
                        </div>
                      ))}
                    </>
                  </>
                ) : (
                  <div className='w-full h-full flex justify-center items-center'>
                    <ClipLoader color='#757D89' />
                  </div>
                )}
              </div>
              <div className='w-full flex justify-end items-center gap-[10px]'>
                <AppButton
                  onClick={() => { setEditingProjectName(false); handleClose(); }}
                  type='button'
                  variant='light'
                  extendClass={cn('bg-black hover:bg-black rounded-[5px] border-[0px]',
                    'text-[20px] h-[48px] px-5 2xl:px-7')}
                  disabled={isProjectUpdating}
                >
                  Cancel
                </AppButton>
                <AppButton
                  onClick={() => { handleSave(); }}
                  type='button'
                  variant='black'
                  extendClass={cn('rounded-[5px] text-[20px] h-[48px] px-5 2xl:px-7',
                    ((!((newProjectName && !editingProjectName) || selectedProject)) || isProjectUpdating) ?
                    'bg-[#D9D9D9] hover:bg-[#D9D9D9] !opacity-100' : 'bg-[#0038FF] hover:bg-[#0038FF]')}
                  disabled={(!((newProjectName && !editingProjectName)
                    || selectedProject)) || isProjectUpdating}
                >
                  Save
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSelectionPopup;