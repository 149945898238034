import React, { useEffect, useState } from 'react';
import { AppButton } from 'components/shared/AppButton';
import Warning from 'assets/imgFunctionIcons/warning.svg';
import { BASE_URL } from 'utils/baseURL';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import { useParams } from 'react-router-dom';

const DeleteImgModal = ({
  handleCloseBtn,
  imageClickData,
  multiImageSelectedList,
  getProjectImageList,
  reCallImageList
}) => {
  const { projectID } = useParams();
  const [isGeneratedImagesOnly, setGeneratedImagesOnly] = useState(false);

  useEffect(() => {
    const isGeneratedImages = multiImageSelectedList?.every((item) => item?.gid);
    setGeneratedImagesOnly(isGeneratedImages);
  }, [multiImageSelectedList]);

  const handDeleteBtn = async () => {
    const data = new FormData();
    if (imageClickData?.selected) {
      const gidArray = [];
      const pidArray = [];
      // Iterate through the data array
      multiImageSelectedList?.forEach(item => {
        if (item.gid) {
          gidArray.push(item?.gid);
        } else if (item.pid) {
          pidArray.push(item?.pid);
        }
      });
      if (gidArray?.length > 0) {
        data.append('gids', gidArray);
      }
      if (pidArray?.length > 0) {
        data.append('pids', pidArray);
      }
    } else if (imageClickData?.gid) {     // if generated img then send gid else send pid
      const imgGid = imageClickData?.gid;
      data.append('gids', [imgGid]);
    } else {
      const imgPid = imageClickData?.pid;
      data.append('pids', [imgPid]);
    }

    const config = {
      method: 'delete',
      url: BASE_URL + 'image-gen/delete-image',
      headers: {
        token: LocalStorage.getItem(),
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    try {
      const response = await axios(config);
      if (response && response.data) {
        if (projectID) {
          getProjectImageList();
        } else {
          reCallImageList();
        }
      }
    } catch (err) {
      console.log('Something went wrong');
    }
    handleCloseBtn();
  };

  return (
    <div
      className='relative z-50'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
      onClick={(e) => e?.stopPropagation()}
    >
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>
      <div className='fixed inset-0 z-50 overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center text-center sm:items-center text-[#000]'>
          <div
            className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 w-[483px] h-auto px-[40px] py-[40px]'
          >
            <div className='bg-white'>
              <div className='w-full'>
                <div className='text-left'>
                  <div className='flex justify-between items-center mb-[10px]'>
                    <span
                      className='font-jost font-[600] text-[20px] whitespace-nowrap'
                      id='modal-title'
                    >
                      {!imageClickData?.selected
                        ? 'Delete 1 asset'
                        : (
                          multiImageSelectedList?.length <= 1
                            ? 'Delete 1 asset'
                            : `Delete ${multiImageSelectedList?.length} assets`
                        )
                      }
                    </span>
                  </div>
                  <div className='text-[#7D7D7D]'>
                    {imageClickData?.gid && !imageClickData?.selected ? (
                      <p>You are about to delete the asset completely from Breeze</p>
                    ) : (imageClickData?.selected && !isGeneratedImagesOnly) && (
                      <p>
                        You are about to delete{' '}
                        <span className='font-[600] text-[#000]'>
                          {!imageClickData?.selected
                            ? '1 asset'
                            : `${multiImageSelectedList?.length <= 1
                              ? '1 asset' : `${multiImageSelectedList?.length} assets`
                            }`
                          }
                        </span> and
                        <span className='font-[600] text-[#000]'> all associated images</span> from Breeze
                      </p>
                    )
                    }
                  </div>
                  <div className='flex gap-[10px] mt-[30px]'>
                    <img src={Warning} alt='' className='w-[24px] h-[24px]' />
                    <div className='font-[600] text-[#C10000] text-[18px]' >
                      Deleted assets<span className='font-[600]'> CANNOT BE RECOVERED</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full flex justify-end items-center mt-[20px] gap-[10px]'>
              <AppButton
                onClick={() => handleCloseBtn() }
                type='button'
                variant='light'
                extendClass='bg-black hover:bg-black rounded-[5px] border-[0px] text-[20px] 
                w-[109px] h-[48px] px-[20px] py-[10px] flex items-center justify-center'
              >
                Cancel
              </AppButton>
              <AppButton
                onClick={() => handDeleteBtn() }
                type='button'
                variant='black'
                extendClass='bg-[#C00000] hover:bg-[#C00000] rounded-[5px] text-[20px] 
                w-[109px] h-[48px] px-[20px] py-[10px] flex items-center justify-center'
              >
                Delete
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default DeleteImgModal;