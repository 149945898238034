/* eslint-disable max-len */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import closeIcon from 'assets/icons/closeIcon.svg';
import ImageCanvas from './imageCanvas';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
// import { toast } from 'react-toastify';
import { LocalStorage } from 'services/localStorage';
import upscaleLoading from 'assets/img/upscale_Animation.gif';
import RenderImage from './renderImageList';
import { v4 as uuidv4 } from 'uuid';
import downloadIconGray from 'assets/icons/downloadIconGray.svg';
import deleteOutline from 'assets/icons/deleteOutline.svg';
import { Dropdown } from 'flowbite-react';
import RenderResizeImageOptionsComp from './renderResizeImageOptionComp';
import { handleTokenExpiryIssue } from 'utils/functions.util';
import { BASE_URL } from 'utils/baseURL';

const ImageResizing = (props) => {
  const [selectedOptSize, setSelectedOptSize] = useState();
  const {
    handleClose,
    selectedImage,
    creditPopupForResize,
    projectID,
    documentID
  } = props;
  const canvasEleHeight = document?.getElementById('canvasElement');
  // taking canvasEleHeight width and decresing 290px to accomodate the download and delete
  const canvasWidth = canvasEleHeight ? Math.ceil(canvasEleHeight?.offsetWidth) - 290 : 500;
  // taking 80% of the canvasEleHeight height
  const canvasHeight = canvasEleHeight ? Math.ceil(canvasEleHeight?.offsetHeight * 0.8) : 500;

  // to accomodate the square image checking the maximum dimension which we can take into account
  var finalDimension = 0;
  if (canvasWidth > canvasHeight) {
    finalDimension = canvasHeight;
  } else {
    finalDimension = canvasWidth;
  }

  const [changedCanvasWidth, setChangedCanvasWidth] = useState();
  const [changedCanvasHeight, setChangedCanvasHeight] = useState();
  const [imageResizeList, setImageResizeList] = useState();
  const [fetchImageSizeLoader, setFetchImageSizeLoader] = useState(false);
  const [resizeImageGenLoading, setResizeImageGenLoading] = useState(false);
  const [resizeImageUpscaleLoading, setResizeImageUpscaleLoading] = useState(false);
  const [generatedImageList, setGeneratedImageList] = useState([]);
  const [selectedResizedImage, setSelectedResizedImage] = useState();
  const [selectedImageId, setSelectedImageId] = useState();
  const [deleteImageLoader, setDeleteImageLoader] = useState();
  const canvasTransformerRef = useRef(null);
  const canvasStageRef = useRef(null);
  const imageRef = useRef(null);
  const [resizedCanWidth, setResizedCanWidth] = useState(canvasWidth);
  const [resizedCanHeight, setResizedCanHeigth] = useState(canvasHeight);

  // Func to render the image resize option list
  const getImageResizeList = async () => {
    setFetchImageSizeLoader(true);
    try {
      const imageResizeListData = await axios.get(BASE_URL + 'image-gen/resize-options');
      const imageResizeData = imageResizeListData?.data?.data?.options;
      setImageResizeList(imageResizeData);
      setSelectedOptSize(imageResizeData[0]);
    } catch (error) {
      console.log(error);
    }
    setFetchImageSizeLoader(false);
  };

  // Func to resize the image based on the selected the resize option
  const generateResizeImage = async () => {
    canvasTransformerRef?.current?.nodes([]);
    setResizeImageGenLoading(true);
    const data = new FormData();
    data.append('gid', selectedImage?.gid);
    data.append('image_url', selectedImage?.url);
    data.append('coordinates_str', '');
    data.append('canvas_height', changedCanvasHeight);
    data.append('canvas_width', changedCanvasWidth);
    data.append('new_height', selectedOptSize?.height);
    data.append('new_width', selectedOptSize?.width);
    if (projectID) data.append('projectID', projectID);
    if (documentID) data.append('documentID', documentID);

    const url = BASE_URL + 'image-gen/resize-image';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response.data && response.data.data) {
        setGeneratedImageList(prev => [
          {
            ...response?.data?.data,
            width: selectedOptSize?.width,
            height: selectedOptSize?.height
          }, ...prev]);
        setSelectedResizedImage({ ...response.data.data, width: selectedOptSize?.width, height: selectedOptSize?.height });
        setSelectedOptSize();
      } else if (
        response.data.status_code === 400 &&
        response.data.detail.message === 'Your token has expired, Please login again'
      ) {
        handleTokenExpiryIssue();
      } else if (response.data.status_code === 400) {
        console.log(response.data.detail.message);
      }
    } catch (err) {
      setResizeImageGenLoading(false);
      if (err?.response?.status === 402) {
        creditPopupForResize();
      }
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    }
    setResizeImageGenLoading(false);
  };

  const handleUpscaleImage = () => {
    const tempId = uuidv4();
    setGeneratedImageList(prev => [{ upscaled: true, id: tempId }, ...prev]);
    setSelectedImageId(tempId);
  };

  // Func to upscale the image
  const generateImageUpscale = async () => {
    setResizeImageUpscaleLoading(true);
    const data = new FormData();
    data.append('pid', selectedImage?.pid);
    data.append('gid', selectedImage?.gid);
    data.append('image_url', selectedImage?.url);
    if (projectID) data.append('projectID', projectID);
    if (documentID) data.append('documentID', documentID);

    const url = BASE_URL + 'image-gen/upscale';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response.data && response.data.data) {
        setGeneratedImageList(prev => [
          {
            upscaled: true,
            ...response?.data?.data,
            width: 2048,
            height: 2048
          }, ...prev.slice(1)]);
        setSelectedResizedImage({
          upscaled: true,
          ...response?.data?.data,
          width: 2048,
          height: 2048
        });
        setSelectedOptSize();
      } else if (
        response.data.status_code === 400 &&
        response.data.detail.message === 'Your token has expired, Please login again'
      ) {
        handleTokenExpiryIssue();
      } else if (response.data.status_code === 400) {
        console.log(response.data.detail.message);
      }
    } catch (err) {
      setResizeImageUpscaleLoading(false);
      if (err?.response?.status === 402) {
        creditPopupForResize();
      }
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    } finally {
      setResizeImageUpscaleLoading(false);
    }
  };

  useEffect(() => {
    getImageResizeList();
  }, []);

  // to adjust the canvas width and height on resizing the screen
  useEffect(() => {
    const handleResize = () => {
      const canvasResizedEleHeight = document?.getElementById('canvasElement');
      const updatedWidth = canvasResizedEleHeight ? Math.ceil(canvasResizedEleHeight?.offsetWidth) - 290 : 500;
      const updatedHeight = canvasResizedEleHeight ? Math.ceil(canvasResizedEleHeight?.offsetHeight * 0.8) : 500;

      // updating the adjusted canvas width and height after resizing the screen
      setResizedCanWidth(updatedWidth);
      setResizedCanHeigth(updatedHeight);
    };

    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (selectedOptSize) {
      const ratio = selectedOptSize.width / selectedOptSize.height;  // calc aspect ratio

      // based on aspect ratio calculating the max width and height possible to accomodate into the canvas
      const maxWidth = Math.min(resizedCanWidth, resizedCanHeight * ratio);
      const maxHeight = Math.min(resizedCanHeight, resizedCanWidth / ratio);

      setChangedCanvasHeight(Math.ceil(maxHeight));
      setChangedCanvasWidth(Math.ceil(maxWidth));
    } else {
      setChangedCanvasHeight(finalDimension);
      setChangedCanvasWidth(finalDimension);
    }
  }, [selectedOptSize, resizedCanHeight, resizedCanWidth]);

  // To create the image with selectedResizedImage
  const selectedResizedImageCanvas = useMemo(() => {
    const img = document.createElement('img');
    img.src = selectedResizedImage?.upscaled_url || selectedResizedImage?.resized_url;

    function onload() {
      const ratio = selectedResizedImage?.width / selectedResizedImage?.height;  // calc aspect ratio

      // based on aspect ratio calculating the max width and height possible to accomodate into the canvas
      const maxWidth = Math.min(resizedCanWidth, resizedCanHeight * ratio);
      const maxHeight = Math.min(resizedCanHeight, resizedCanWidth / ratio);

      img.height = Math.ceil(maxHeight);
      img.width = Math.ceil(maxWidth);
    }
    onload();
    img.onload = onload;
    const tempData = React.createElement(
      'img',
      {
        src: selectedResizedImage?.upscaled_url || selectedResizedImage?.resized_url,
        width: `${img.width}`,
        height: `${img.height}`,
        className: (selectedImage?.colour_image || selectedImage?.primary_image) && 'border-[1px] border-[#757D89]'
      },
      null
    );
    return tempData;
  }, [selectedResizedImage, resizedCanHeight, resizedCanWidth]);

  // Function to download the resized images
  const handleDownload = () => {
    const tempFileName = (selectedResizedImage?.upscaled_url || selectedResizedImage?.resized_url)?.split('/');
    const imageName = tempFileName[tempFileName.length - 1];
    const name = imageName || 'BreezeAppImage.png';
    fetch(selectedResizedImage?.upscaled_url || selectedResizedImage?.resized_url,
      {
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' }
      })
      .then(response => response?.blob())
      .then(blob => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';

        if (name?.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch((err) => console.log(err));
  };

  // Function to delete the resized images
  const handleDelete = async () => {
    setDeleteImageLoader(true);
    const imgGid = selectedResizedImage?.gid;
    const data = new FormData();
    data.append('gids', [imgGid]);

    const config = {
      method: 'delete',
      url: BASE_URL + 'image-gen/delete-image',
      headers: {
        token: LocalStorage.getItem(),
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    try {
      const response = await axios(config);
      if (response && response.data) {
        const tempImageList = generatedImageList;
        const genDeleteIndex = tempImageList?.findIndex((item) => item?.gid === selectedResizedImage?.gid);
        tempImageList?.splice(genDeleteIndex, 1);
        setGeneratedImageList(tempImageList);
        if (generatedImageList?.length) {
          if (genDeleteIndex >= generatedImageList?.length) {
            setSelectedResizedImage(generatedImageList[genDeleteIndex - 1]);
          } else if (genDeleteIndex < generatedImageList?.length) {
            setSelectedResizedImage(generatedImageList[genDeleteIndex]);
          }
        } else {
          setSelectedOptSize(imageResizeList[0]);
          setSelectedResizedImage();
        }
      }
    } catch (err) {
      console.log('Something went wrong');
    }
    setDeleteImageLoader(false);
  };

  const SelectedImageCanvas = () => {
    return (
      <>
        {resizeImageGenLoading ? (
          <div className='bg-canvasMeshBg flex items-center justify-center' style={{ width: changedCanvasWidth, height: changedCanvasHeight }}>
            <ClipLoader color='757D89' />
          </div>
        ) : (
          <>
            {selectedResizedImage && (
              <div className='relative'>
                {selectedResizedImageCanvas}
                {(
                  <div className='absolute right-[-110px] top-[0px] flex flex-col gap-[20px]'>
                    {/*Added Download_Gen_Image_Btn as Id for all the related elements for goggle event trigger */}
                    <div
                      className='flex items-start justify-start
                      text-black cursor-pointer gap-[5px] leading-[20px]'
                      role='presentation'
                      onClick={handleDownload}
                      id='Download_Gen_Image_Btn'
                    >
                      <img
                        src={downloadIconGray}
                        alt="downloadIcon"
                        width="20px"
                        height="20px"
                        id='Download_Gen_Image_Btn'
                      />
                      <span
                        className='text-[#7D7D7D] font-[400] text-[16px]'
                        id='Download_Gen_Image_Btn'
                      >
                        Download
                      </span>
                    </div>
                    <div
                      className='flex items-center justify-start
                      text-black cursor-pointer gap-[5px] leading-[20px]'
                    >
                      <Dropdown
                        className="deleteDropdown" // css added to position the hamburger dropdown
                        arrowIcon={false}
                        inline
                        label={<div className='flex gap-[5px]'>
                          <img
                            id='Delete_Gen_Image_Btn'
                            src={deleteOutline}
                            alt="deleteIcon"
                            width="20px"
                            height="20px"
                          />
                          <span className='text-[#7D7D7D] font-[400] text-[16px]'>
                            Delete
                          </span>
                        </div>}
                      >
                        <span className='text-[#EB4444] py-[6px] px-[12px] text-[14px] font-[400]'
                          onClick={!deleteImageLoader && handleDelete}>Confirm deletion</span>
                      </Dropdown>
                    </div>
                  </div>
                )}
              </div>
            )}
            {(!selectedResizedImage && !selectedOptSize?.info?.includes('Square')) && (
              <ImageCanvas
                stageRef={canvasStageRef}
                canvasTransformerRef={canvasTransformerRef}
                imageRef={imageRef}
                width={changedCanvasWidth || canvasWidth}
                height={changedCanvasHeight || canvasHeight}
                imgSrc={selectedImage?.url}
                isMeshBg
              />
            )}
            {!selectedResizedImage && selectedOptSize?.info?.includes('Square') && (
              <img
                width={finalDimension}
                height={finalDimension}
                src={selectedResizedImage?.upscaled_url || selectedResizedImage?.resized_url || selectedImage?.url}
                className={`${(selectedImage?.primary_image || selectedImage?.colour_image) && 'border-[1px] border-[#757D89]'}`}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div
      className="relative z-50 flex items-center justify-center font-jost"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="relative flex w-full h-full items-end justify-center px-[20px] py-[40px] text-center">
          <div
            className="sticky w-full h-full bottom-2/4 transform overflow-hidden rounded-lg bg-white
            text-left shadow-xl transition-all"
          >
            <div className="bg-white w-full h-full">
              <div className='flex h-full'>
                <div className='w-[482px] h-full border-r-2 px-[20px] pb-[20px]'>
                  <RenderResizeImageOptionsComp
                    fetchImageSizeLoader={fetchImageSizeLoader}
                    selectedOptSize={selectedOptSize}
                    handleUpscaleImage={handleUpscaleImage}
                    generateImageUpscale={generateImageUpscale}
                    imageResizeList={imageResizeList}
                    setSelectedOptSize={setSelectedOptSize}
                    setSelectedResizedImage={setSelectedResizedImage}
                    generateResizeImage={generateResizeImage}
                    resizeImageGenLoading={resizeImageGenLoading}
                    resizeImageUpscaleLoading={resizeImageUpscaleLoading}
                  />
                </div>
                <div className='flex-1 h-full'>
                  <div className='w-full h-[calc(100%-200px)] p-[20px] relative'>
                    <div className='flex justify-end pb-3'>
                      <div role='presentation' onClick={() => handleClose()}>
                        <img src={closeIcon} alt='' width='20px' height='20px' />
                      </div>
                    </div>
                    <div className='flex items-center justify-center w-full h-[calc(100%-67px)] overflow-auto' id='canvasElement'>
                      {fetchImageSizeLoader ?
                        <ClipLoader color='#757D89' /> :
                        <SelectedImageCanvas />
                      }
                    </div>
                    <div className='text-[20px] font-jost font-[600]  p-2 bottom-0 text-primaryColor h-[43px]'>
                      Generated Images
                    </div>
                  </div>
                  <div className='w-full h-[200px] fixed border-t-2 p-4 mb-[20px]'>
                    <div className='max-h-full w-full overflow-scroll'>
                      <div className='grid grid-cols-7 2xl:grid-cols-8 
                        grid-flow-row gap-2 items-center max-h-full w-full overflow-y-auto overflow-x-hidden'>
                        {generatedImageList?.map((image, index) => {
                          return (
                            <div key={index} className='col-span-1 group relative aspect-square'>
                              {(resizeImageUpscaleLoading && image.upscaled && image.id === selectedImageId) ? (
                                <div className='bg-[#F1F1F1] w-full m-auto h-full rounded-[2px] flex items-center justify-center'>
                                  <img
                                    src={selectedImage?.url}
                                    alt='loading...'
                                    className='w-full h-full'
                                  />
                                  <div
                                    className='absolute rounded-md w-full h-full'
                                    style={{
                                      background: 'rgba(0, 0, 0, 0.5)',
                                      top: '0px'
                                    }}
                                  >
                                    <div
                                      className='absolute items-center flex justify-center leading-normal 
                                      rounded-md w-full font-jost text-[10px] inline p-1 text-center text-[#fff]'
                                    >
                                      <img src={upscaleLoading} className='rounded' />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <RenderImage
                                  image={image}
                                  clickedImage={selectedResizedImage}
                                  setClickedImage={setSelectedResizedImage}
                                  setSelectedOptSize={setSelectedOptSize}
                                  index={index}
                                  generateLoading={resizeImageGenLoading}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageResizing;