/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import upload from 'assets/NewIcons/upload_new.svg';
import addIcon from 'assets/icons/addIcon_gray.svg';
import { uploadImgToRemoveBg } from 'utils/fileHelpers';
import { createFileFromImgUrl } from 'components/ImageEditor';
// import { toast } from 'react-toastify';
import Modal from 'components/Modal/index';
import loadingGif from 'assets/img/loaderAnimation.gif';
import CanvasScreen from 'components/shared/CanvasAdvanceScreen';
import BackgroundSelection from '../BackgroundSelection';
import FooterSection from '../FooterLayout';
import SocialMediaHeader from '../SocialMediaHeader';
import { useNavigate } from 'react-router-dom';

const ImageGeneration = () => {
  const inputFileRef = useRef(null);
  const navigate = useNavigate();
  const [generatingImgLoader, setGeneratingImgLoader] = useState(false);
  const [isPopUp, setIsPopUp] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState();
  const [selectedImgGen, setSelectedImgGen] = useState();
  const [imageListData, setImageListData] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState();
  const [prompt, setPrompt] = useState({ theme: '', prompt: '' });
  const [selectedImageStyle, setSelectedImageStyle] = useState();
  const canvasTransformerRef = useRef(null);
  const canvasStageRef = useRef(null);
  const canvasWidth = document?.getElementById('mobCanvasDiv')?.clientWidth || 200;
  const getSelectedImage = localStorage?.getItem('selectedImg');

  useEffect(() => {
    if (getSelectedImage) {
      setSelectedImgGen(JSON?.parse(getSelectedImage));
    }
  }, [getSelectedImage]);

  useEffect(() => {
    if (selectedFile && fileName) {
      uploadImage();
    }
  }, [selectedFile, fileName]);

  const uploadImage = () => {
    if (selectedFile.target.files) {
      if (selectedFile.target.files.length > 1) {
        setGeneratingImgLoader(true);
        return;
      }
      setGeneratingImgLoader(true);
      uploadImgToRemoveBg(selectedFile.target.files[0], false, fileName)
        .then(async res => {
          if (res === 'expired') {
            return;
          }
          const fileUrl = res.data?.remove_background_url;
          const file = await createFileFromImgUrl(fileUrl);
          setSelectedImgGen({ ...res.data, imgUrl: fileUrl, pid: res.data.pid, file });
        })
        .catch(() => {
          console.log('Oops, Something went wrong');
        })
        .finally(() => {
          setGeneratingImgLoader(false);
          setFileName();
        });
    }
  };

  const onFileChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setIsPopUp(true);
      setSelectedFile(e);
    }
  };

  const handleFileName = (fileData) => {
    setFileName(fileData);
  };

  const handleClose = () => {
    setFileName();
    setSelectedFile();
    setIsPopUp(false);
  };

  const handleModal = () => {
    setIsPopUp(false);
  };

  const selectSingleImageToUpload = () => {
    inputFileRef.current.click();
  };

  const handleImagesPosition = (listData) => {
    setImageListData(listData);
  };

  const resetSelectedImage = () => {
    localStorage.removeItem('selectedImg');
    setSelectedImgGen();
  };

  const handleGenerateBtn = () => {
    const listValues = imageListData?.map((item) => {
      return {
        pid: selectedImgGen?.pid,
        marginLeft: item?.current?.attrs?.x ? (item?.current?.attrs?.x + item?.x) : item?.x || 0,
        marginTop: item?.current?.attrs?.y ? (item?.current?.attrs?.y + item?.y) : item?.y || 0,
        scaleX: item?.current?.attrs?.scaleX || 1,
        scaleY: item?.current?.attrs?.scaleY || 1,
        angle: item?.current?.attrs?.rotation || 0
      };
    });
    return {
      prompt: prompt,
      canvasWidth: canvasWidth,
      imageListData: listValues,
      selectedImageToGen: selectedImgGen,
      canvasHeight: canvasWidth
    };
  };

  const handleAddFromLibrary = () => (
    navigate('/mobile/ImageLibrary', {
      state: {
        selectImageToGen: true,
        redirectPath: window?.location?.pathname
      }
    })
  );

  const ImageInputCard = () => (
    <div className='flex justify-center items-center'>
      <div className='grid grid-rows-3 gap-4'>
        <div
          className='row-span-1 flex justify-center items-center bg-white border-2 rounded-md w-[152px] h-[48px]'
          role='presentation'
          onClick={() => selectSingleImageToUpload()}
        >
          <div className='flex flex-row gap-2 font-jost font-[400] text-[#7D7D7D] cursor-pointer'>
            <div className='flex items-center justify-center'>
              <img
                src={upload}
                className='text-[20px] '
                alt=''
                width='15px'
                height='15px'
              />
            </div>
            <div className='text-[14px]'>
              Upload new
            </div>
            <input
              type='file'
              onChange={!generatingImgLoader && onFileChange}
              onDrop={!generatingImgLoader && onFileChange}
              ref={inputFileRef}
              name='files'
              id='file'
              className='hidden'
              accept='image/*'
            />
          </div>
        </div>
        <div
          className='row-span-1 flex justify-center items-center text-[#7D7D7D] text-[16px]'
        >
          or
        </div>
        <div
          className='row-span-1 flex justify-center items-center bg-white border-2 rounded-md w-[152px] h-[48px]'
          role='presentation'
          onClick={() => handleAddFromLibrary()}
        >
          <div className='flex flex-row gap-2 font-jost font-[400] text-[#7D7D7D] cursor-pointer'>
            <div className='flex items-center justify-center'>
              <img
                src={addIcon}
                className=''
                alt=''
                width='15px'
                height='15px'
              />
            </div>
            <div className='text-[14px]'>
              Add from library
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SocialMediaHeader socialMediaTab='image' />
      <div className='px-[20px] pt-[30px] mb-[56px]'>
        {isPopUp && (
          <Modal
            handleModal={handleModal}
            handleFileName={handleFileName}
            handleCloseBtn={handleClose}
          />
        )}
        <div className='w-full h-full flex items-cemter justify-center pb-4'>
          <div
            className='aspect-square bg-canvasMeshBg flex items-center justify-center w-[85%] sm1:w-[75%] sm2:w-[65%] md:w-[55%]'
            role='presentation'
            id='mobCanvasDiv'
          >
            {generatingImgLoader ? (
              <img src={loadingGif} alt='loading...' className='w-[50px]' />
            ) : (
              <>
                {selectedImgGen ? (
                  <CanvasScreen
                    stageRef={canvasStageRef}
                    handleImagesPosition={handleImagesPosition}
                    canvasTransformerRef={canvasTransformerRef}
                    width={canvasWidth}
                    height={canvasWidth}
                    imgSrc={selectedImgGen?.remove_background_url}
                    isMeshBg={true}
                    resetSelectedImage={resetSelectedImage}
                    isMobScreen
                  />
                  ) : <ImageInputCard />
                }
              </>
            )}
          </div>
        </div>
        <BackgroundSelection
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
          setPrompt={setPrompt}
          selectedImageStyle={selectedImageStyle}
          setSelectedImageStyle={setSelectedImageStyle}
        />
      </div>
      <FooterSection
        generateBtnData={handleGenerateBtn}
        isGenerateBtnDisabled={!((prompt?.prompt || prompt?.theme) && selectedImgGen)}
      />
    </>
  );
};

export default ImageGeneration;