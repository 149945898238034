/* eslint-disable max-len */
import React, { useState } from 'react';
import cn from 'classnames';
import likeOutLineIcon from 'assets/icons/likeOutLineIcon.svg';
import dislikeOutLineIcon from 'assets/icons/dislikeOutLineIcon.svg';
import likeIcon from 'assets/icons/likeIcon.svg';
import dislikeIcon from 'assets/icons/dislikeIcon.svg';
import RenderSmoothImage from 'components/ReactSmoothImageRendring';

const RenderImage = (props) => {
  const {
    image,
    clickedImage,
    handleDisLikingImg,
    handleSelectGenImg,
    handleLikingImg,
    setClickedImage,
    index,
    handleSelectedLikedData,
    handleSelectedDisLikedData,
    generateLoading,
    setDeletedImgIndex
  } = props;

  const [isImageLoading, setImageLoading] = useState(false);
  return (
    <>
      <div
        className={cn('flex items-center justify-center relative rounded shadow text-center',
          clickedImage && clickedImage.url === (image.url || image) && 'border-[2px] border-[#757D89]'
        )}
        role="presentation"
        onClick={() => { setClickedImage(image); handleSelectGenImg(image); setDeletedImgIndex(index); }}
      >
        <div
          className={cn('rounded shadow text-center h-full w-full',
            clickedImage && clickedImage.url === (image.url || image) && 'border-[2px] border-white'
          )}
        >
          <RenderSmoothImage
            src={image?.thumbnail || image}
            alt="loading..."
            objectFit="fill"
            id={`generated_images_${index}`}
            className='bg-[#F1F1F1] w-auto m-auto h-full'
            loader
            loadGif={generateLoading}
            imageData={image}
            setImageLoading={setImageLoading}
          />
        </div>
      </div>
      <div className="flex items-center justify-end w-full mt-1 absolute bottom-2 right-2 invisible group-hover:visible">
        <span className='m-[3px] p-[2px] bg-white bg-opacity-25 rounded-[2px]'>
          <img
            alt="likedLogo"
            className={`w-[20px] h-[20px], ${!isImageLoading && 'hidden'}`}
            src={image && (handleSelectedLikedData((image?.gid || image)) ? likeIcon : likeOutLineIcon)}
            onClick={() => handleLikingImg(image)}
          />
        </span>
        <span className='m-[3px] p-[2px] bg-white bg-opacity-25 rounded-[2px]'>
          <img
            alt="disLikedLogo"
            className={`w-[20px] h-[20px], ${!isImageLoading && 'hidden'}`}
            src={image && (handleSelectedDisLikedData((image?.gid || image)) ? dislikeIcon : dislikeOutLineIcon)}
            onClick={() => handleDisLikingImg(image)}
          />
        </span>
      </div>
    </>
  );
};

export default RenderImage;