import React, { useEffect, useState } from 'react';
import { Dropdown } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import avatar from 'assets/img/avatar.jpg';
// import logo from '../../../assets/ColorLogo-noBackground.png';
import { useQuery } from 'react-query';
import { getUserStatistics } from 'queryhook/accounts/getUserStatistics';
// import { sendUserInfo } from 'queryhook/accounts/sendUserInfo';
import AlertModal from 'components/AlertModal';
import newlogo from '../../../assets/NewIcons/Mask group.svg';
// import RigtArrow from '../../../assets/arrow-up.svg';
// import logo from '../../../public/assets/Commerceflow_FC_Logomark.png'
// import avatar from '../../../public/assets/avatar.jpg'
import { signOutUser } from '../../../utils/Firebase';
import BreadCrumb from './Breadcrumb';
const Navbar = () => {
  // const { setState } = useContext(MainContext)
  // const location = useLocation();
  // Check if the current pathname contains 'panel'
  // const isMatched = location.pathname.includes('panel');

  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const navigate = useNavigate();
  const { data } = useQuery('userStatistics', getUserStatistics);

  // commented the function to remove the popup
  // const getUserInfo = () => {
  //   console.log('[USER NAME]: ', data?.data?.user_name)
  //   if (data?.data?.industry === '' || data?.data?.company === '' || data?.data?.user_name === '' 
  //|| data?.data?.found_through === '' || data?.data?.content_type === '' || data?.data?.job_role === '') {
  //     console.log('industry, organization or name is empty')
  //     return setAlertModalOpen(true)
  //   }
  // }

  const UserInfoModalButton = (inputValues) => {
    const Name = inputValues.Name;
    const Company = inputValues.Company;
    const Industry = inputValues.Industry;
    const How_did_you_find_us = inputValues['How did you find us?'];
    const ContentType = inputValues['What types of content do you create / want help creating?'];
    const JobRole = inputValues['What is your job role?'];
    const handleClick = async () => {
      if (Name === '' || Company === '' || ContentType === '' || !Industry || !How_did_you_find_us || !JobRole) {
        // console.log('One or more fields are empty.');
      } else {
        // console.log(inputValues);
        try {
          // const response = await sendUserInfo(inputValues);
        } catch (error) {
          console.error('Error sending user info:', error);
        }
        setAlertModalOpen(false);
      }
    };
    return (
      <button
        type='button'
        className='mt-3 inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm 
        font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-primary-50 
        sm:mt-0 sm:w-auto text-white'
        onClick={() => { handleClick(); }}
      >
        Submit
      </button>
    );
  };

  const handleLogoutClick = () => {
    localStorage?.clear(); // clear the local storage
    signOutUser();
    navigate('/login');
  };

  // const userEmail = useRef('')
  const [userEmail, setUserEmail] = useState('');
  // const [userId, setUserId] = useState('');

  useEffect(() => {
    setUserEmail(localStorage.getItem('user-email') ?? '');
    // setUserId(localStorage.getItem('user-id') ?? '');
  }, []);

  // useEffect(() => {
  //   getUserInfo()
  // }, [data])

  const uploadImgGenLibraryAllData = JSON.parse(localStorage.getItem('uploadImgGenLibrary'));
  useEffect(() => {
    if (userEmail && uploadImgGenLibraryAllData?.userEmail !== userEmail) {
      localStorage.removeItem('uploadImgGenLibrary');
    }
  }, [userEmail]);

  return (
    <header className='w-full mx-auto card  rounded-3xl'>
      {data ? (
        <AlertModal
          modalOpen={alertModalOpen}
          setModalOpen={setAlertModalOpen}
          header={'Please tell us a little about yourself'}
          body={'(Fill all six to continue)'}
          additionalBtn={UserInfoModalButton}
          AdditionalInput={{
            'Name': data?.data?.user_name || '',
            'Company': data?.data?.company || '',
            'Industry': ['Ad/ Digital Agency', 'Ecommerce/ Retailer', 'Consumer Packaged Goods',
              'Media & Entertainment', 'Software/ Tech/ SaaS', 'Education/ Research', 'Other'],
            'What is your job role?': ['Business Owner', 'In-house marketer (content, social, brand, etc)',
              'Creative', 'Agency/ consultant', 'Student', 'Other'],
            'What types of content do you create / want help creating?': data?.data?.content_type || '',
            'How did you find us?': ['Social media', 'web search', 'Friend', 'Colleague',
              'Influencer', 'AI sites', 'Other']
          }}
          Static={true}
        />
      ) : (<></>)}
      <nav className='w-full h-[56px] px-[20px] py-[8px] bg-white dark:bg-gray-800 font-jost 
      border-b border-[#D9D9D9]'>
        <div className='flex flex-wrap items-center h-full gap-6 mx-auto justify-items-start justify-between'>
          <div className='flex items-center gap-[40px]'>
            {/* <button
              data-collapse-toggle='navbar-hamburger'
              type='button'
              className='inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg hover:bg-sky-50 
              focus:outline-none focus:ring-2 focus:ring-sky-200 dark:text-gray-400 dark:hover:bg-gray-700 
              dark:focus:ring-gray-600'
              aria-controls='navbar-hamburger'
              aria-expanded='false'
              // onClick={onHamburgerClick}
            >
              <span className='sr-only'>Open main menu</span>
              <svg
                className='w-8 h-8 text-primo lg:hidden'
                aria-hidden='true'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1
                   0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                  clipRule='evenodd'
                />
              </svg>
              <div className='hidden lg:block'></div>
            </button> */}
            <div className=''>
              <img src={newlogo} alt='logo' className={'w-[86px] h-[16px] cursor-pointer'}
                onClick={() => navigate('/imageLibrary')} />
            </div>
            <BreadCrumb />
          </div>
          <div className='z-40 flex flex-wrap items-center gap-8 text-blue-500 sm:flex-nowrap text-[15px]'>
            <div className='flex-wrap items-center gap-8 sm:flex-nowrap hidden md-mid:flex'>
              <div className='flex items-center justify-center flex-wrap gap-[20px] text-[400] lg:text-[12px] 
              xl:text-[14px] xl-mid:text-[16px] 3xl:text-[16px] 4xl:text-[16px]' >
                <a
                  className={'no-underline leading-normal text-[#7D7D7D]'}
                  href='https://help.breeze.ai'
                  target='_blank' rel='noreferrer'
                >
                  Help
                </a>
                <div
                  className={'no-underline leading-normal text-[#7D7D7D]'}
                >
                  Photos Left: {data?.data?.credits_remaining}
                </div>
                {/* <div className='w-[90px] h-[30px] bg-[#5C80FF] rounded-[5px] leading-[30px] 
                  flex justify-center cursor-pointer'
                  onClick={() => {
                    window.analytics.track('Upgrade_Plan_Clicked', {
                      email: userEmail,
                      userId: userId
                    });
                    const url = '/plans';
                    window.open(url, '_blank'); // Open URL in a new tab
                  }}>
                  <div className='text-[#FFF] lg:text-[12px] xl:text-[14px] xl-mid:text-[16px] font-[400]
                  3xl:text-[16px] 4xl:text-[16px]'>Upgrade</div>
                </div> */}
              </div>
            </div>
            <Dropdown
              className='headerDropdown'
              arrowIcon={false}
              inline
              label={<img alt='User settings' className='rounded-full avatar' width={40} src={avatar} />}
            >
              <Dropdown.Header>
                <span className='block text-sm font-medium truncate'>{userEmail}</span>
              </Dropdown.Header>
              <Dropdown.Item className='block text-sm font-medium truncate' onClick={() => {
                const url = '/account';
                window.open(url, '_blank'); // Open URL in a new tab
              }}>
                <span>Account</span>
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={() => navigate('/panel/images')}>Images</Dropdown.Item> */}

              <Dropdown.Divider />
              <Dropdown.Item className='block text-sm font-medium truncate'
                onClick={() => navigate('/recover-password')}>
                <Link to='/recover-password'>Reset password</Link>
              </Dropdown.Item>
              <Dropdown.Item className='block text-sm font-medium truncate'
                onClick={handleLogoutClick}>
                Sign out
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
