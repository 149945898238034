/* eslint-disable max-len */
import React, { useEffect, useLayoutEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import RegisterForm from 'components/RegisterForm';
// import RegisterFormImage from '../assets/img/RegisterFormSideImg.png';
import SignupBg from '../assets/img/SignupBg.jpg';
import { Helmet } from 'react-helmet';

const RegisterPage = () => {
  useEffect(() => {
    document.title = 'breeze.ai app register'; // Change the document title
    return () => {
      document.title = 'breeze.ai app'; // Reset the title when the component unmounts
    };
  }, []);

  // Function to call respective stripe payment plan for the loggedIn user
  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(window?.location?.search);
    const userId = localStorage.getItem('user-id');
    const userEmail = localStorage.getItem('user-email');
    const selectedPlan = searchParams?.get('selectedPlan');
    const origin = window.location.origin;
    const production = ['https://production-branch.d3osxcytsvsw8t.amplifyapp.com', 'https://app.breeze.ai'].includes(origin);
    let stripeLink;
    if (userId && userEmail) {
      switch (selectedPlan) {
        case 'starter-monthly':
          if (production) {
            stripeLink = `https://buy.stripe.com/cN2g2v6g7bc28qAaEH?client_reference_id=${userId}&prefilled_email=${userEmail}`;
          } else {
            stripeLink =`https://buy.stripe.com/test_3cs1813Pm0i17S0bIR?client_reference_id=${userId}&prefilled_email=${userEmail}`;
          }
          window.open(stripeLink, 'Stripe Payment');
          break;
        case 'starter-yearly':
          if (production) {
            stripeLink = `https://buy.stripe.com/5kA3fJ33V3JA4ak9AI?client_reference_id=${userId}&prefilled_email=${userEmail}`;
          } else {
            stripeLink =`https://buy.stripe.com/test_28oaIBeu00i1dck6oy?client_reference_id=${userId}&prefilled_email=${userEmail}`;
          }
          window.open(stripeLink, 'Stripe Payment');
          break;
        case 'advanced-monthly':
          if (production) {
            stripeLink = `https://buy.stripe.com/9AQaIb1ZReoe22ceV0?client_reference_id=${userId}&prefilled_email=${userEmail}`;
          } else {
            stripeLink =`https://buy.stripe.com/test_cN26sl99G1m50py3cn?client_reference_id=${userId}&prefilled_email=${userEmail}`;
          }
          window.open(stripeLink, 'Stripe Payment');
          break;
        case 'advanced-yearly':
          if (production) {
            stripeLink = `https://buy.stripe.com/bIY3fJ33V1Bs8qA6ov?client_reference_id=${userId}&prefilled_email=${userEmail}`;
          } else {
            stripeLink =`https://buy.stripe.com/test_7sIbMF1He6Gpb4c00c?client_reference_id=${userId}&prefilled_email=${userEmail}`;
          }
          window.open(stripeLink, 'Stripe Payment');
          break;
        case 'pro-monthly':
          if (production) {
            stripeLink = `https://buy.stripe.com/28o03x6g76VM6isaEI?client_reference_id=${userId}&prefilled_email=${userEmail}`;
          } else {
            stripeLink =`https://buy.stripe.com/test_eVa03XdpW2q98W46oB?client_reference_id=${userId}&prefilled_email=${userEmail}`;
          }
          window.open(stripeLink, 'Stripe Payment');
          break;
        case 'pro-yearly':
          if (production) {
            stripeLink = `https://buy.stripe.com/7sI5nR9sj2Fw5eo5kp?client_reference_id=${userId}&prefilled_email=${userEmail}`;
          } else {
            stripeLink =`https://buy.stripe.com/test_dR603X85C1m55JSeV8?client_reference_id=${userId}&prefilled_email=${userEmail}`;
          }
          window.open(stripeLink, 'Stripe Payment');
          break;
        default: break;
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta name='description'
          content='Create images, captions and marketing content with AI in seconds. Sign-up on Breeze.ai' />
      </Helmet>
      <main className='w-full h-screen'>
        <div className='fluid flex h-full rounded-lg'>
          {/* <div className='min-h-fit justify-center items-center w-[45%] max-w-[800px] hidden lg:flex'>
            <img src={RegisterFormImage} alt='side image' className='w-[80%]' />
          </div>
          <RegisterForm /> */}
          <div className='relative w-full h-full flex justify-center items-center backdrop-filter 
          backdrop-brightness-[0.2]'>
            <img src={SignupBg} alt='' className='w-full h-full absolute opacity-[0.5] top-0 left-0 object-cover' />
            <RegisterForm />
          </div>
        </div>
      </main>
    </>
  );
};

export default RegisterPage;
