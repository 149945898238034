import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
// import CaretLeft from 'assets/img/LeftArrow.png';
// import CaretRight from 'assets/img/RightArrow.png';
import ReactSelect from 'components/ReactSelectFormik';
import SelectImageStyles from './ImageStyleSelection';
import cn from 'classnames';
import { BASE_URL } from 'utils/baseURL';
import TabButton from 'components/shared/TabButton';

const BackgroundSelection = (props) => {
  const {
    selectedTheme,
    setSelectedTheme,
    setPrompt,
    selectedImageStyle,
    setSelectedImageStyle,
    generateLoading,
    isImageGenerator
  } = props;
  const [selectedTab, setSelectedTab] = useState('instant');
  const [themesLoading, setThemesLoading] = useState(false);
  const [themeCategories, setThemeCategories] = useState([]);
  // const [showCarousel, setShowCarousel] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [themes, setThemes] = useState([]);
  const [filteredThemes, setFilteredThemes] = useState();

  // const scrollContainerRef = useRef(null);

  const filterThemes = (category) => {
    setSelectedCategory(category);
    if (category === 'all') return setFilteredThemes(themes);

    const filteredThemesTemp = themes.filter(image => image.category === category);
    setFilteredThemes(filteredThemesTemp);
  };

  useEffect(() => {
    if (themes?.length)
      setFilteredThemes(themes);
  }, [themes]);

  const getThemesCategories = async () => {
    try {
      const categoriesData = await axios.get(
        BASE_URL + 'image-gen/theme-categories'
      );
      const categoriesListData = categoriesData?.data?.data?.categories;
      const categoriesList = categoriesListData.map((item) => (
        {
          label: item,
          value: item
        }
      ));
      setThemeCategories(categoriesList);
    } catch (err) {
      console.log(err);
    }
  };

  const getThemes = async () => {
    setThemesLoading(true);
    try {
      const themesData = await axios.get(BASE_URL + 'image-gen/themes-dummy');
      setThemes(themesData?.data?.data?.themes);
    } catch (error) {
      console.log(error);
    }
    setThemesLoading(false);
  };

  useEffect(() => {
    getThemes();
    getThemesCategories();
  }, []);

  useEffect(() => {
    if (selectedTab === 'instant') {
      getThemes();
      getThemesCategories();
    }
  }, [selectedTab]);

  useEffect(() => {
    if (isImageGenerator) {
      setSelectedTab('custom');
    } else {
      setSelectedTab('instant');
    }
  }, [isImageGenerator]);

  // const calculateScroll = () => {
  //   let scrollLeftOrRight = 0;
  //   const themeImage = document.getElementById('themeImage');
  //   const themeImageWidth = themeImage?.clientWidth;
  //   const scrollableDivWidth = scrollContainerRef?.current?.clientWidth;
  //   let noOfImagesInView = scrollableDivWidth / themeImageWidth;
  //   noOfImagesInView = noOfImagesInView % 1 >= 0.99 ? Math.ceil(noOfImagesInView) : Math.floor(noOfImagesInView);
  //   scrollLeftOrRight = (noOfImagesInView * themeImageWidth) + (4 * noOfImagesInView);
  //   return scrollLeftOrRight;
  // };

  // const scrollToLeft = () => {
  //   const scrollLeft = calculateScroll();
  //   if (scrollContainerRef.current) {
  //     scrollContainerRef.current.scrollBy({
  //       top: 0,
  //       left: -scrollLeft,
  //       behavior: 'smooth'
  //     });
  //   }
  // };

  // const scrollToRight = () => {
  //   const scrollLeft = calculateScroll();
  //   if (scrollContainerRef.current) {
  //     scrollContainerRef.current.scrollBy({
  //       top: 0,
  //       left: scrollLeft,
  //       behavior: 'smooth'
  //     });
  //   }
  // };

  // const getThemeScrollableDivSize = () => {
  //   const themeDiv = document.getElementById('themeContent');
  //   const themeImage = document.getElementById('themeImage');
  //   const themesDataWidth = filteredThemes?.length * themeImage?.clientWidth;
  //   const gap  = 4 * filteredThemes?.length; // Gap between the images is 4px
  //   if ((themesDataWidth + gap) > themeDiv?.clientWidth) {
  //     setShowCarousel(true);
  //   } else {
  //     setShowCarousel(false);
  //   }
  // };

  // useEffect(() => {
  //   getThemeScrollableDivSize();
  // }, [filteredThemes]);

  return (
    <div className='pb-[20px] rounded bg-white w-full'>
      {!isImageGenerator &&
        <>
          <div className='rounded bg-white'>
            <div className='text-[20px] font-jost font-[600] mb-2
             text-primaryColor'>
              Select a background
            </div>
            <div className='flex flex-row justify-start gap-4 pb-3' id='image-selector'>
              <TabButton
                active={selectedTab === 'instant'}
                onClick={() => {
                  setSelectedTab('instant');
                  setSelectedTheme();
                  setSelectedCategory('');
                }}
                className='text-[20px] font-jost font-[400] text-[#2C2C2C]'
                type='button'
                style={{ padding: 0, text: 'nowrap' }}
                activeClassName={{ color: '#111', borderBottom: '2px solid #111' }}
              >
                Instant
              </TabButton>
              <TabButton
                active={selectedTab !== 'instant'}
                onClick={() => {
                  setSelectedTab('custom');
                  setSelectedTheme();
                  setPrompt({ theme: '', prompt: '' });
                  setSelectedCategory('');
                }}
                type='button'
                className='text-[20px] font-jost font-[400] text-[#2C2C2C]'
                activeClassName={{ color: '#111', borderBottom: '2px solid #111' }}
              >
                Custom
              </TabButton>
            </div>
          </div>
        </>
      }
      <div className='tab-content'>
        {selectedTab === 'instant' && (
          <>
            <Formik initialValues={{}}>
              <Form>
                <ReactSelect
                  options={themeCategories || []}
                  name='themeCategories'
                  customId='value'
                  onChange={(e) => filterThemes(e.value)}
                  placeholder='Choose your product category'
                // defaultValue={themeCategories[0]?.name}
                />
              </Form>
            </Formik>
            <div className='relative grid grid-cols-12 pt-[20px] gap-2' id='themeContent'>
              {/* {!themesLoading && filteredThemes && showCarousel && (
                <div className='col-span-1 flex'>
                  <button className='absolute -left-[4px] flex self-center' onClick={() => scrollToLeft()}>
                    <img src={CaretLeft} height='17px' width='17px' alt='' />
                  </button>
                </div>
              )} */}
              {/* <div className=''>
                <div className='flex relative'>
                  <div className='flex flex-rows overflow-hidden gap-1'> */}
              {themesLoading ? (
                <div className='flex justify-center '>
                  <div className='spinner-border ' role='status'>
                    <ClipLoader color='#757D89' />
                  </div>
                </div>
              ) : (filteredThemes?.map((image, index) => (
                <div className={`col-span-3 aspect-square group relative w-full h-full
                        ${selectedCategory === '' && 'pointer-events-none opacity-[0.5]'}`}
                  id='themeImage' key={index} >
                  <div
                    className={`relative flex items-center justify-center shadow text-center 
                           h-full w-full ${!generateLoading && 'cursor-pointer'} rounded-md`}
                    key={index}
                    style={{
                      border:
                        selectedTheme?.image_url === image.image_url
                          ? '2px solid #757D89'
                          : '',
                      margin: '2px'
                    }}
                    onClick={() => {
                      if (!generateLoading) {
                        setSelectedTheme(image);
                        setPrompt({ theme: image?.theme, prompt: '' });
                      }
                    }}
                  >
                    <div
                      className={`relative aspect-square
                              ${!generateLoading && 'cursor-pointer'} rounded-md h-full`}
                      key={index}
                      style={{
                        border:
                          selectedTheme?.image_url === image.image_url
                            ? '2px solid white'
                            : ''
                      }}
                    >
                      <img
                        src={image?.image_url}
                        alt={image?.theme}
                        style={{ width: '100%', height: '100%' }}
                        className='w-full h-full rounded-md object-cover'
                      />
                      <div
                        className={`absolute ${!generateLoading && 'cursor-pointer'} rounded-md w-full h-full`}
                        style={{
                          background: 'linear-gradient(0deg, #000 0.01%, rgba(217, 217, 217, 0.00) 55.06%)',
                          top: '0px'
                        }}
                      >
                        <div
                          className={`absolute bottom-0 ${!generateLoading && 'cursor-pointer'}  leading-normal 
                                rounded-md w-full font-jost text-[10px] flex justify-center
                                p-1 text-center text-[#fff]`}
                          onClick={() =>
                            !generateLoading && setSelectedTheme(image)
                          }
                        >
                          {image.theme}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )))}
              {/* </div>
                </div>
              </div> */}
              {/* {!themesLoading && filteredThemes && showCarousel && (
                <div className='col-span-1 flex'>
                  <button className='absolute -right-[4px] flex self-center' onClick={() => scrollToRight()}>
                    <img src={CaretRight} height='17px' width='17px' alt='' />
                  </button>
                </div>
              )} */}
            </div>
          </>
        )}
        {selectedTab === 'custom' && (
          <div className='flex flex-wrap gap-2'>
            <div className='w-full h-full'>
              <Formik>
                {() => (
                  <Form className='lg:text-[12px] xl:text-[14px] 2xl:text-[16px] font-jost flex flex-wrap'>
                    <label className={cn('font-[400] text-[#000] text-[16px] mb-2',
                      isImageGenerator
                      && 'text-[20px] font-jost font-[600] text-[#000]')}>
                      {isImageGenerator ? 'Describe the image you want to generate'
                        : 'Describe your background'}
                    </label>
                    <Field
                      type='text'
                      name='customBackGroundDetails'
                      className='customTextBoxColor block w-full px-2 py-1 mb-1 lg:text-[12px] xl:text-[14px] 
                      2xl:text-[16px] leading-normal text-gray-800 bg-white border border-[#94A3B8]
                      rounded-[4px] appearance-none pt-[10px]'
                      component='textarea'
                      rows='2'
                      placeholder='e.g. On a wooden table surrounded by a lake.'
                      style={{ resize: 'none' }}
                      onChange={e => e.target.value ? setPrompt({ prompt: e.target.value, theme: '' })
                        : setPrompt({ prompt: '', theme: '' })}
                    />
                  </Form>
                )}
              </Formik>
            </div>
            <SelectImageStyles
              selectedImageStyle={selectedImageStyle}
              setSelectedImageStyle={setSelectedImageStyle}
              generateLoading={generateLoading}
            />
          </div >
        )}
      </div >
    </div >
  );
};

export default BackgroundSelection;