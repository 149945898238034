import React from 'react';
import DynamicForm from 'components/DesktopComponents/DynamicForm';

const GoogleSearchAdForm = ({
  selectedTemp,
  updatedCaptionListData,
  generateCaptionLoading,
  setGenerateCaptionLoading
}) => {

  return (
    <div className="h-[calc(100vh-179px)] w-full overflow-scroll">
      <div className="p-[10px]">
        <DynamicForm
          templateValue={selectedTemp}
          templateKey={selectedTemp?.t_name}
          updatedCaptionListData={updatedCaptionListData}
          generateCaptionLoading={generateCaptionLoading}
          setGenerateCaptionLoading={setGenerateCaptionLoading}
        />
      </div>
    </div>
  );
};

export default GoogleSearchAdForm;