import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertModal from 'components/AlertModal';
import ListingTemplateDynamicForm from './ListingTemplateDynamicForm';
import TabButton from 'components/shared/TabButton';
import DescriptionList from './DescriptionList';

const ListingTemplateDetails = () => {
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState({ theme: '', prompt: '' });
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [generateCaptionLoading, setGenerateCaptionLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('description');
  const [descriptionListData, setDescriptionListData] = useState();
  const selectedTemp = JSON.parse(localStorage.getItem('selectedTemp'));

  const updatedCaptionListData = (captionList) => {
    setDescriptionListData(captionList);
  };

  // Resetting the descriptionListData on tab switch
  useEffect(() => {
    setDescriptionListData();
  }, [selectedTab]);

  const ModalUpgradeCtaButton = () => {
    return (
      <button
        type='button'
        className='mt-3 inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold
         text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-primary-50 sm:mt-0 sm:w-auto text-white'
        onClick={() => {
          setAlertModalOpen(false);
          navigate('/plans');
        }}
      >
        Upgrade
      </button>
    );
  };

  const handleTemplateValue = () => {
    let templateValue;
    if (selectedTemp?.name === 'Walmart listings') {
      if (selectedTab === 'description') {
        templateValue = 'Walmart_description';
      } else templateValue = 'Walmart_feature';
    } else if (selectedTemp?.name === 'Amazon listings') {
      if (selectedTab === 'description') {
        templateValue = 'Amazon_description';
      } else templateValue = 'Amazon_feature';
    }
    return templateValue;
  };

  return (
    <>
      <AlertModal modalOpen={alertModalOpen} setModalOpen={setAlertModalOpen}
        header={'Your photo credit limit is exhausted!'}
        body='Get 1000 photos and more advanced features starting at $9.99'
        additionalBtn={ModalUpgradeCtaButton}
      />
      <div className='flex font-jost'>
        <div className='w-[34.5%] h-[calc(100vh-99px)] relative my-[20px] mx-[10px]'>
          <div className=''>
            <div className='text-[20px] font-[400] p-4 text-[#000]'>
              {selectedTemp?.subname ? selectedTemp?.name + ' - ' + selectedTemp?.subname : selectedTemp?.name}
            </div>
          </div>
          <div className='h-[calc(100vh-200px)]'>
            <div className="flex flex-row justify-start gap-[10px] border-b border-[#D9D9D9]">
              <TabButton
                active={selectedTab === 'description'}
                onClick={() => {
                  setSelectedTab('description');
                }}
                className="lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost font-[400] 
                !px-[42px] !py-[11px] h-[46px] text-primaryColor whitespace-nowrap	"
                type="button"
                style={{ padding: 0, text: 'nowrap' }}
              >
                Description
              </TabButton>
              <TabButton
                active={selectedTab === 'feature'}
                onClick={() => {
                  setSelectedTab('feature');
                }}
                className="lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost font-[400] 
                !px-[42px] !py-[11px] h-[46px] text-primaryColor whitespace-nowrap	"
                type="button"
                style={{ padding: 0, text: 'nowrap' }}
              >
                Feature List
              </TabButton>
            </div>
            <ListingTemplateDynamicForm
              setPrompt={setPrompt}
              prompt={prompt}
              isBulkUpload={false}
              selectedTemp={selectedTemp}
              generateCaptionLoading={generateCaptionLoading}
              setGenerateCaptionLoading={setGenerateCaptionLoading}
              templateValue={handleTemplateValue()}
              isListingTemplate
              updatedCaptionListData={updatedCaptionListData}
              selectedTab={selectedTab}
            />
          </div>
        </div>
        <div className='w-[65%] h-[calc(100vh-58px)] border-l border-[#D9D9D9]'>
          <div>
            <DescriptionList
              descriptionListData={descriptionListData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingTemplateDetails;