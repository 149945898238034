import React, { useEffect, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';

const ReactSelectCreatable = ({
  isFocussed,
  setIsFocussed,
  handleCreateKeyword,
  createdKeywords,
  handleDeleteKeyword,
  setShowSelectBox
}) => {
  const components = {
    DropdownIndicator: null,
    indicatorContainer: null
  };

  const customStyles = {
    clearIndicator: (provided) => ({
      ...provided,
      display: 'none !important'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      color: '#333333',
      '&:hover': {
        backgroundColor:  !state.isSelected && '#f1f1f1'
      },
      '&:active': {
        backgroundColor: '#f1f1f1'
      }
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: '#F8F8F8',
      borderRadius: '42px',
      border: '1px solid #DBDBDB',
      marginRight: '8px',
      padding: '0px 10px 0px 10px',
      height: '36px',
      alignItems: 'center'
    }),
    control: provided => ({
      ...provided,
      border: !isFocussed && 'none',
      borderColor: isFocussed ? '#757D89' : 'none',
      boxShadow: 'none',
      height: '100%',
      '&:hover': {
        borderColor: '#757D89'
      }
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '6px 0px 6px 0px !important'
    }),
    multiValueRemove: provided => ({
      ...provided,
      color: '#848482',
      '&:hover': {
        backgroundColor: '#F8F8F8',
        color: '#848482'
      }
    }),
    container: provided => ({
      ...provided,
      maxHeight: '100%',
      width: isFocussed && '100%'
    })
  };

  const handleBlur = () => {
    setIsFocussed(false);
    // if (createdKeywords.length === 0) {
    setShowSelectBox(false);
    // }
  };

  const handleFocus = () => {
    setIsFocussed(true);
  };

  const selectRef = useRef(null);

  useEffect(() => {
    if (isFocussed) {
      selectRef?.current?.focus();
    }
  }, [isFocussed]);

  const customFormatCreateLabel = (inputValue) => `+ Create '${inputValue}'?`;
  return (
    <CreatableSelect
      menuPlacement='auto'
      ref={selectRef}
      components={components}
      placeholder=''
      isMulti
      options={[]}
      isClearable
      styles={customStyles}
      noOptionsMessage={() => null}
      formatCreateLabel={customFormatCreateLabel}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleDeleteKeyword}
      value={createdKeywords}
      onCreateOption={handleCreateKeyword}
    />
  );
};

export default ReactSelectCreatable;