import React, { useEffect, useState } from 'react';
import { AppButton } from 'components/shared/AppButton';
import SelectTheme from '../ImageAndTextSection/SelectTheme';
import FileUploadAndDisplay from '../ImageAndTextSection/FileUploadAndDisplay';
import { useNavigate } from 'react-router-dom';
import TabButton from 'components/shared/TabButton';

const ListingTemplates = ({
  selectedImage,
  handleSelectedImage,
  selectedTheme,
  setSelectedTheme,
  setPrompt,
  prompt,
  isBulkUpload,
  selectedImageStyle,
  setSelectedImageStyle,
  setSelectedImage,
  setImageForView,
  handleGenerateButtonClick,
  selectWalmartTab,
  generateLoading,
  isImgGenerator,
  setSelectedImageToGen,
  setGenerateLoading,
  imageGenerationLoading,
  handlePrimaryImageGeneration,
  selectedColor,
  setSelectedColor,
  projectID,
  documentID
}) => {
  const [selectedTab, setSelectedTab] = useState();
  const [selectedBackground, setSelectedBackground] = useState();
  const selectedTemp = JSON.parse(localStorage.getItem('selectedTemp'));
  const selectedListingTab = JSON.parse(localStorage.getItem('selectedListingTab'));

  const navigate = useNavigate();

  const handleBackToTemplates = () => {
    navigate('/Templates');
  };

  useEffect(() => {
    if (selectedListingTab) {
      setSelectedTab(selectedListingTab);
    } else {
      setSelectedTab('primary');
    }
  }, []);

  useEffect(() => {
    if (selectedTab) {
      selectWalmartTab(selectedTab);
      localStorage.setItem('selectedListingTab', JSON.stringify(selectedTab));
    }
  }, [selectedTab]);

  // btnId for google analytics for each generate magic button
  const handleGenerateBtnId = () => {
    const currentUrl = window?.location?.pathname;
    let tempId = '';
    switch (currentUrl) {
      case '/ImageGenerator':
        tempId = 'ImageGenPage_clickGen';
        break;
      case '/PhotoStudio':
        tempId = 'PhotoStudioPage_clickGen';
        break;
      case '/InstagramPost':
        tempId = 'InstaPage_clickGen_image';
        break;
      case '/FacebookPost':
        tempId = 'FBPage_clickGen_image';
        break;
      case '/LinkedInPost':
        tempId = 'LinkedinPage_clickGen_image';
        break;
      default: break;
    }
    return tempId;
  };

  const handleOnClickGeneration = () => {
    if (selectedTab === 'primary') {
      handlePrimaryImageGeneration();
    } else {
      handleGenerateButtonClick();
    }
  };

  return (
    <div className="h-[calc(100vh-200px)] w-full">
      <div className="flex flex-row justify-start gap-[10px] border-b border-[#D9D9D9]">
        <TabButton
          active={selectedTab === 'primary'}
          onClick={() => {
            setSelectedTab('primary');
          }}
          className="lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost font-[400] 
          !px-[42px] !py-[11px] h-[46px] text-primaryColor whitespace-nowrap	"
          type="button"
          style={{ padding: 0, text: 'nowrap' }}
        >
          Primary image
        </TabButton>
        <TabButton
          active={selectedTab === 'lifestyle'}
          onClick={() => {
            setSelectedTab('lifestyle');
          }}
          className="lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost font-[400] 
          !px-[42px] !py-[11px] h-[46px] text-primaryColor whitespace-nowrap	"
          type="button"
          style={{ padding: 0, text: 'nowrap' }}
        >
          Lifestyle images
        </TabButton>
      </div>
      <div className="h-[calc(100vh-200px)] p-[10px] pb-[50px] overflow-scroll">
        <div className="flex flex-wrap gap-4">
          <FileUploadAndDisplay
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            setImageForView={setImageForView}
            handleSelectedImage={handleSelectedImage}
            generateLoading={generateLoading}
            setSelectedImageToGen={setSelectedImageToGen}
            setGenerateLoading={setGenerateLoading}
            projectID={projectID}
            documentID={documentID}
          />
          {selectedTab === 'lifestyle' && (
            <SelectTheme
              selectedTheme={selectedTheme}
              setSelectedTheme={setSelectedTheme}
              setPrompt={setPrompt}
              isBulkUpload={isBulkUpload}
              selectedImageStyle={selectedImageStyle}
              setSelectedImageStyle={setSelectedImageStyle}
              generateLoading={generateLoading}
              isImgGenerator={isImgGenerator}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              selectedImage={selectedImage}
            />
          )}
          {selectedTab === 'primary' && (
            <div>
              <p className='lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost
                font-[600] py-[10px] text-primaryColor'>
                {selectedTemp?.name === 'Walmart listings' ?
                  'Pick Walmart white background' :
                  'Pick Amazon white background'
                }
              </p>
              <div className='relative w-[160px] h-[160px]'>
                <div
                  className={`relative flex items-center justify-center shadow text-center 
                    max-h-full max-w-full w-full h-full rounded-md cursor-pointer`}
                  style={{
                    border:
                      selectedBackground === 'white'
                        ? '3px solid #000'
                        : '1px solid #000',
                    padding: '2px'
                  }}
                  onClick={() =>
                    setSelectedBackground('white')
                  }
                >
                  <div
                    className='absolute rounded-md w-full h-full'
                    style={{
                      background: 'white',
                      top: '0px'
                    }}
                  >
                    <div
                      className={`absolute bottom-0 leading-normal 
                        rounded-md w-full font-jost lg:text-[20px] xl:text-[20px] 2xl:text-[20px] flex
                          font-[600] p-1 text-center text-black justify-center`}
                      onClick={() =>
                        setSelectedBackground('white')
                      }
                    >
                      White
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='absolute bottom-[-20px] bg-white z-10 px-[20px] py-[10px] pr-0 w-full'>
        <div className="flex justify-end items-center">
          <AppButton
            onClick={() => handleBackToTemplates()}
            type="button"
            variant=''
            extendClass='!bg-[#00000008] !text-black hover:bg-[#00000008] rounded-[5px] !text-[16px] 
            font-[400] h-[48px] w-[180px] !py-[2px] !px-[20px] mr-[10px] border-[1px] border-solid !border-[#000]'
          >
            Back to templates
          </AppButton>
          <AppButton
            onClick={() => handleOnClickGeneration()}
            isLoading={imageGenerationLoading}
            type="button"
            variant='black'
            extendClass='bg-black hover:bg-black rounded-[5px]
              text-[16px] font-[400] h-[48px] w-[180px] !py-[2px] !px-[20px]'
            disabled={imageGenerationLoading ||
              (selectedTab === 'primary' ?
                (!selectedImage || !selectedBackground) :
                (!selectedImage || !(prompt?.theme || prompt?.prompt)))}
            id={handleGenerateBtnId()}
          >
            Generate
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default ListingTemplates;