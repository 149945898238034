import React, { useEffect, useState } from 'react';
import plusIcon from 'assets/icons/accordionPlusIcon.svg';
import dummyProjectImage from 'assets/img/dummyProjectImage.svg';
import verticalOptionIcon from 'assets/icons/optionsVertical.svg';
import renameOutline from 'assets/icons/renameOutline.svg';
import deleteIcon from 'assets/icons/deleteIconBlack.svg';
import { BASE_URL } from 'utils/baseURL';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import ProjectNamePopup from './projectNamePopUp';
import DeleteConfirmPopup from './deleteConfirmPopup';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { handleProjectModifiedDate } from 'utils/commonFunctions';

const Projects = () => {
  const [menuOptionOpen, setMenuOptionOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [existingProjectLists, setExistingProjectLists] = useState([]);
  const [projectLoading, setProjectLoading] = useState(false);
  const [projectNamePopUp, setProjectNamePopUp] = useState(false);
  const [reNamePopUp, setRenamePopUp] = useState(false);
  const [deleteConfirmPopUp, setDeleteConfirmPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const getExistingProjectLists = async () => {
    setProjectLoading(true);
    const url = BASE_URL + 'image-gen/projects';
    try {
      const projectListData = await axios.get(
        url,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      setExistingProjectLists(projectListData?.data?.data?.projects);
    } catch (err) {
      console.log(err);
    }
    setProjectLoading(false);
  };

  useEffect(() => {
    getExistingProjectLists();
  }, []);

  const handleRefreshProjectList = () => {
    getExistingProjectLists();
  };

  const toggleMenuOption = () => {
    setMenuOptionOpen(!menuOptionOpen);
  };

  const handleClosePopup = () => {
    setProjectNamePopUp(false);
    setRenamePopUp(false);
  };

  const handleDeletePopup = (status) => {
    setDeleteConfirmPopUp(status);
  };

  const handleOnClickProject = (project) => {
    localStorage.setItem('selectedProject', project?.project_name);
    navigate(`/Projects/${project?.projectID}`);
  };

  const renameProject = async (formValue) => {
    setIsLoading(true);
    const url = BASE_URL + 'image-gen/renameProject';
    const data = new FormData();
    data?.append('project_name', formValue?.projectName);
    data?.append('projectID', selectedProject?.projectID);
    try {
      await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage?.getItem()
          }
        }
      );
      handleClosePopup();
      handleRefreshProjectList();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRenameSubmit = (data) => {
    if (reNamePopUp) {
      renameProject(data);
    } else {
      createNewProject(data?.projectName);
      setProjectNamePopUp(false);
    }
  };

  const handleDeleteSubmit = async () => {
    setIsLoading(true);
    const data = new FormData();
    data?.append('projectID', selectedProject?.projectID);
    const config = {
      method: 'delete',
      url: BASE_URL + 'image-gen/deleteProject',
      headers: {
        token: LocalStorage.getItem(),
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };
    try {
      await axios(config);
      handleDeletePopup(false);
      handleRefreshProjectList();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const createNewProject = async (projectName) => {
    const url = BASE_URL + 'image-gen/newProject';
    const data = new FormData();
    data?.append('project_name', projectName);
    try {
      const responseData = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage?.getItem()
          }
        }
      );
      const createdProjectData = responseData?.data?.data;
      localStorage.setItem('selectedProject', projectName);
      localStorage.setItem('selectedDocument', createdProjectData?.documents?.document_name || 'Untitled');
    } catch (err) {
      console.log(err);
    } finally {
      getExistingProjectLists();
    }
  };

  return (
    <div className='font-jost p-[40px]'>
      {(projectNamePopUp || reNamePopUp) && (
        <ProjectNamePopup
          projectNamePopUp={projectNamePopUp}
          reNamePopUp={reNamePopUp}
          selectedProject={selectedProject}
          handleClosePopup={handleClosePopup}
          handleRefreshProjectList={handleRefreshProjectList}
          handleSubmit={handleRenameSubmit}
          isLoading={isLoading}
        />
      )}
      {deleteConfirmPopUp && (
        <DeleteConfirmPopup
          handleDeletePopup={handleDeletePopup}
          handleSubmit={handleDeleteSubmit}
          isLoading={isLoading}
        />
      )}
      <div className=''>
        <div className='flex justify-between items-center pb-[40px]'>
          <div className='text-[30px] font-[400] text-[#000000]
          bg-white'>
            Projects
          </div>
          <div
            className='w-[158px] h-12 px-[20px] py-[10px] rounded-[4px] border border-[#333] justify-center 
            items-center gap-[10px] inline-flex cursor-pointer'
            onClick={() => setProjectNamePopUp(true)}
          >
            <img alt='plusIcon' className='w-[20px] h-[20px]' src={plusIcon} />
            <div className='text-[#000] text-base font-medium font-jost'>
              New Project
            </div>
          </div>
        </div>
        {projectLoading ? (
          <div className='flex justify-center items-center'>
            <div className='spinner-border flex flex-col justify-center items-center' role='status'>
              <ClipLoader color='#757D89' />
              <div className='lg:text-[14px] xl:text-[16px] xl-mid:text-[18px] 4xl:text-[20px] font-semibold mb-2'>
                Loading...
              </div>
            </div>
          </div>
        ) : (
          <div className='flex flex-wrap justify-start mx-auto gap-[20px]'>
            {existingProjectLists?.map((projectItem, index) => (
              <>
                <div
                  key={index}
                  className='border-solid border-2 border-[#D3D3D3] w-[198px] h-[263px]
                  hover:cursor-pointer justify-center rounded-[5px] relative'
                  onMouseLeave={() => { setMenuOptionOpen(false); }}
                >
                  <div
                    className='flex flex-col'
                    onClick={() => projectItem?.projectID !== '1' && handleOnClickProject(projectItem)}
                  >
                    <div className='aspect-square flex items-center justify-center bg-[#F0F0F0] rounded-t-[3px]'>
                      <img
                        src={projectItem?.image || dummyProjectImage}
                        alt=''
                        className='max-w-[194px] max-h-[194px] m-auto rounded-t-[3px]'
                      />
                    </div>
                    <div className="w-[194px] h-[65px] px-5 py-2.5 bg-white justify-start items-center inline-flex">
                      <div className="w-[145px] flex-col justify-start items-start gap-[5px] inline-flex">
                        <div className="w-[145px] font-[400] text-[16px] text-[#000000] font-jost truncate ...">
                          {projectItem?.project_name}
                        </div>
                        <div className="w-[145px] font-[400] text-[12px] text-[#7D7D7D] font-jost truncate ...">
                          {handleProjectModifiedDate(projectItem?.modifiedAt)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='absolute right-[10px] bottom-[20px]'>
                    <div className='relative'>
                      <img
                        src={verticalOptionIcon}
                        width={24}
                        height={24}
                        alt=''
                        id={projectItem?.projectID}
                        onClick={toggleMenuOption}
                        onMouseEnter={() => { setSelectedProject(projectItem); }}
                      />
                      {menuOptionOpen && (selectedProject?.projectID === projectItem?.projectID) && (
                        <div
                          className='border-solid border-1 border-[#D3D3D3] w-[118px] h-[96px]
                          hover:cursor-pointer justify-center absolute top-[28px] z-10 bg-white
                          left-[-9px] rounded-[5px]'
                          style={{ boxShadow: '0px 4px 10px 0px #0000004D' }}
                        >
                          <ul>
                            <li
                              className='px-[20px] py-[10px] flex flex-row items-center gap-[10px]
                                h-[47px] hover:bg-[#F0F0F0]'
                              onClick={() => projectItem?.projectID !== '1' && setRenamePopUp(true)}
                            >
                              <img
                                src={renameOutline}
                                width={20}
                                height={20}
                                alt=''
                                className='w-[20px] h-[20px] flex items-center' />
                              <div className='text-[14px] font-[400] text-[#000000] leading-[28.9px]'>Rename</div>
                            </li>
                          </ul>
                          <ul>
                            <li
                              className='px-[20px] py-[10px] flex flex-row items-center gap-[10px]
                                h-[47px] hover:bg-[#F0F0F0]'
                              onClick={() => projectItem?.projectID !== '1' && setDeleteConfirmPopUp(true)}
                            >
                              <img
                                src={deleteIcon}
                                width={20}
                                height={20}
                                alt=''
                                className='w-[20px] h-[20px] flex items-center' />
                              <div className='text-[14px] font-[400] text-[#000000] leading-[28.9px]'>Delete</div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;