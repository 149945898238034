import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import 'styles/player.css';

const NotFound = () => {
  const navigate = useNavigate();
  const handleMainPage = () => {
    if (window?.innerWidth < 992) {
      navigate('/mobile/Templates');
    } else {
      navigate('/imageLibrary');
    }
  };
  return (
    <main className='p-10'>
      <section className={'not-found'}>
        {/* <TypeAnimation
          sequence={[
            'Page', // Types 'صفحه'
            1000, // Waits 1s
            'Page was', // Deletes 'One' and types 'Two'
            2000, // Waits 2s
            'Page was not found', // Types 'Three' without deleting 'Two'
            2000, // Waits 2s

            () => {}
          ]}
          wrapper='div'
          cursor={true}
          repeat={Infinity}
          style={{ fontSize: '2em' }}
        /> */}

        <div className='flex justify-center text-3xl font-bold text-blue-400'
          onClick={handleMainPage}>
          Go to Main Page
        </div>
        <Player
          className={'not-found__player'}
          autoplay
          loop
          src='https://assets3.lottiefiles.com/packages/lf20_cr9slsdh.json'
        >
          <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
        </Player>
      </section>
    </main>
  );
};

export default NotFound;
