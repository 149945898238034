/* eslint-disable no-async-promise-executor */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import uploadFile from 'assets/img/uploadFile.svg';
import collectContent from 'assets/img/collectContent.svg';
import oneDrive from 'assets/img/oneDrive.svg';
import googleDrive from 'assets/img/googleDrive.svg';
import cn from 'classnames';
import useDrivePicker from 'react-google-drive-picker';

const AddImages = ({
  setIsUpload,
  setSelectedFile,
  setIsUploadFolder,
  showCollectContent,
  handleCollectContentPopUp,
  setGoogleDriveImportLoading,
  setGoogleDriveImportError,
  setImageUploadingOrigin
}) => {
  const inputFileRef = useRef(null);
  const [enableMouseLeave, setEnableMouseLeave] = useState(true);
  const googleDriveConfig = {
    clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
    developerKey: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY
  };

  const onFileChange = e => {
    e?.preventDefault();
    if (e?.target?.files && e?.target?.files?.length > 0) {
      setIsUpload(false);
      const uploadedImageList = Array?.from(e?.target?.files);
      // Code to remove images which do not have image types such as .jpg, .jpeg, .png, .webp, .ico, .tiff or .bmp
      const uniqueImageUploadedList = uploadedImageList?.filter((item) =>
      ['jpg','jpeg','png','webp','ico','tiff','bmp']
        .includes(item?.name?.split('.')[1])
    );
      // to have setSelectedFile as an array of files
      setSelectedFile(uniqueImageUploadedList);

      // to clear the input value to allow selecting the same files again
      document.getElementById('file').value = '';
      if (uniqueImageUploadedList?.length > 0) setIsUploadFolder(true);
    }
  };

  const clientLoad = async (image) => {
    try {
      return new Promise(async (resolve, reject) => {
        window?.gapi?.load('client', async function () {
          try {
            await window?.gapi?.client?.load('drive', 'v3');
            
            const response = await window?.gapi?.client?.drive?.files?.get({
              fileId: image?.id,
              supportsAllDrives: true,
              mimeType: image?.mimeType,
              alt: 'media'
            });
            const binaryString = response.body;

            // Convert the binary string to a Uint8Array
            const binaryData = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              binaryData[i] = binaryString.charCodeAt(i);
            }

            // Create a Blob from the binary data
            const blob = new Blob([binaryData]);

            // Create a File object from the Blob
            const sendingFile = new File([blob], image?.name, {
              type: image?.mimeType
            });

            // Now 'file' is a JavaScript File object that you can use
            resolve(sendingFile);
          } catch (error) {
            console.error('Error in inner try-catch block:', error);
            reject(error);
          }
        });
      });
    } catch (error) {
      console.error('Error in outer try-catch block:', error);
      throw error;
    }
  };
 
  const handleGoogleDriveCallBack = async (dataList) => {
    if (dataList && dataList?.docs && dataList[window?.google?.picker?.Response?.ACTION] === window?.google?.picker?.Action?.PICKED) {
      setGoogleDriveImportLoading(true);
      try {
        const tempData = [];
        for (const imageData of dataList.docs) {
          const result = await clientLoad(imageData);
          tempData.push(result);
        }
        setSelectedFile(tempData);
        setGoogleDriveImportLoading(false);
        setImageUploadingOrigin('Google Drive');
        setIsUploadFolder(true);
        window?.gapi?.client?.setToken(''); // logging out the user
      } catch (error) {
        setGoogleDriveImportError(true);
        setGoogleDriveImportLoading(false);
        console.error('Error:', error);
        window?.gapi?.client?.setToken(''); // logging out the user
      }
    }
  };

  const [openPicker] = useDrivePicker();
  const handleOpenPicker = () => {
    window?.gapi?.load('client:auth2', () => {      
      window?.gapi?.client
        .init({
          apiKey: googleDriveConfig?.developerKey
        }).then(() => {
          let currentTokenInfo = window?.gapi?.auth?.getToken();
          const sharedWithMe = new window.google.picker.DocsView()
            .setMimeTypes('image/jpeg', 'image/png', 'image/webp', 'image/bmp', 'image/x-icon', 'image/tiff')
            .setLabel('Shared With Me')
            .setOwnedByMe(false);

          // const sharedDrive = new window.google.picker.DocsView()
          //   .setMimeTypes('image/jpeg', 'image/png', 'image/webp', 'image/bmp', 'image/x-icon', 'image/tiff')
          //   .setLabel('Shared Drive')
          //   .setEnableDrives(true)
          //   .setIncludeFolders(true);

          const myDrive = new window.google.picker.DocsView()
            .setMimeTypes('image/jpeg', 'image/png', 'image/webp', 'image/bmp', 'image/x-icon', 'image/tiff')
            .setLabel('My Drive')
            .setOwnedByMe(true);
          
          const pickerConfig = {
            clientId: googleDriveConfig?.clientId,
            developerKey: googleDriveConfig?.developerKey,
            viewId: 'DOCS',
            viewMimeTypes: 'image/jpeg,image/png,image/gif',
            token: currentTokenInfo ? currentTokenInfo.access_token : null,
            showUploadView: false,
            disableDefaultView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: true,
            customViews: [myDrive, sharedWithMe],
            customScopes: [
              'https://www.googleapis.com/auth/drive.file'
            ],
            callbackFunction: async (data) => {
              if(data?.action === 'cancel') {
                window?.gapi?.client?.setToken(''); // logging out the user
              } else handleGoogleDriveCallBack(data);
            }
            };
          openPicker(pickerConfig);
        });
    });
  };

  return (
    <div
      className='w-[220px] h-[269px] justify-center absolute top-[60px] z-30
      bg-white rounded-[5px] font-jost'
      style={{ boxShadow: '0px 4px 4px 4px rgba(0, 0, 0, 0.10)', border: '1px solid #D9D9D9' }}
      onMouseLeave={() => enableMouseLeave && setIsUpload(false)}
    >
      <div className="pl-[20px] pr-[20px] pt-[10px] pb-[10px] flex flex-col text-[14px] text-[#333]">
        <div className='flex gap-[10px] w-[180px] h-[44px] pt-[10px] pb-[10px] cursor-pointer'
          onClick={() => { inputFileRef.current.click(); setEnableMouseLeave(false); }}
        >
          <img src={uploadFile} alt='' width={24} height={24} />
          <div className='leading-[24px]'>Upload Images</div>
          <input
            type='file'
            onChange={onFileChange}
            onDrop={onFileChange}
            ref={inputFileRef}
            name='files'
            id='file'
            className='hidden'
            accept=".jpg, .jpeg, .png, .webp, .ico, .tiff, .bmp"
            multiple
          />
        </div>
        <div
          className={cn('flex gap-[10px] w-[180px] h-[85px] pt-[10px] pb-[10px]',
            showCollectContent ? 'cursor-pointer' : 'opacity-50 cursor-not-allowed')}
          onClick={() => showCollectContent && handleCollectContentPopUp(true)}
        >
          <img src={collectContent} alt='' className='w-[24px] h-[24px]' />
          <div className='flex flex-col gap-[5px]'>
            <div>
              Collect Content
            </div>
            <div className='text-[12px] text-[#7D7D7D]'>
              Collect images directly from anyone.
            </div>
          </div>
        </div>
        <div className='w-full h-[40px] pt-[10px] pb-[10px] border-t-[1px] border-[#D9D9D9]'>Import images from</div>
        <div className='flex items-center gap-[10px] w-full h-[40px] pt-[10px] pb-[10px] cursor-pointer'
          onClick={() => handleOpenPicker()}>
          <img src={googleDrive} alt='' className='w-[18px] h-[16px]' />
          <div className='leading-[21px]'>Google Drive</div>
        </div>
        <div className='flex items-center gap-[10px] w-full h-[40px] pt-[10px] pb-[10px] opacity-50 cursor-not-allowed'>
          <img src={oneDrive} alt='' className='w-[18px] h-[12px]' />
          <div className='leading-[21px]'>Microsoft OneDrive</div>
        </div>
      </div>
    </div>
  );
};

export default AddImages;