import React, { useEffect, useState } from 'react';
import { LocalStorage } from 'services/localStorage';
import { ClipLoader } from 'react-spinners';
import ImageGallery from './ImageGallery';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { BASE_URL } from 'utils/baseURL';
import TabButton from 'components/shared/TabButton';

const ImageLibrary = () => {
  const [selectedTab, setSelectedTab] = useState();
  const [loading, setLoading] = useState(false);
  const [allImagesList, setAllImagesList] = useState([]);
  const [uploadImagesList, setUploadedImagesList] = useState([]);
  const selectedTabLocalStorage = localStorage.getItem('selectedImageLibraryTab');
  const navigate = useNavigate();
  const location = useLocation();
  const selectImageToGen = location?.state?.selectImageToGen;
  const redirectPath = location?.state?.redirectPath;

  useEffect(() => {
    if (selectImageToGen) {
      setSelectedTab('uploads'); // pre-select the my upload tag for select image from library flow
    } else if (selectedTabLocalStorage) {
      setSelectedTab(selectedTabLocalStorage);
    } else {
      setSelectedTab('all');
    }
  }, [selectImageToGen]);

  const getAllImages = async () => {
    setLoading(true);
    try {
      const sampleImages = await axios.get(BASE_URL + 'image-gen/genHistory', {
        headers: {
          'Content-Type': 'multipart/form-data',
          newest_first: true,
          token: LocalStorage.getItem()
        }
      });
      const imageList = sampleImages?.data?.data;
      setAllImagesList(imageList);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getMyUpload = async () => {
    setUploadedImagesList();
    setLoading(true);
    try {
      const sampleImages = await axios.get(BASE_URL + 'image-gen/uploadHistory', {
        headers: {
          'Content-Type': 'multipart/form-data',
          startIdx: 1,
          Num_records: 10,
          token: LocalStorage.getItem()
        }
      });
      const imageList = sampleImages?.data?.data;
      setUploadedImagesList(imageList);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllImages();
    getMyUpload();
  }, []);

  const handleImageSelect = (image) => {
    const imageData = {
      ...image,
      url: (selectedTab === 'uploads' ? image?.remove_background_url : image?.input_image_url) || image?.url
    };
    localStorage.setItem('selectedImageLibraryTab', selectedTab);
    // passing selectImageToGen and redirect path for select image from library flow
    navigate('/mobile/ImageLibrary/imageView', {
      state:
      {
        imageData: imageData,
        selectImageToGen: selectImageToGen,
        redirectPath: redirectPath
      }
    }
    );
  };

  return (
    <div>
      <div className='grid grid-cols-12 fixed w-full bg-white top-[65px] z-10'>
        <TabButton
          active={selectedTab === 'all'}
          onClick={() => {
            // disabling click for select image from library flow
            !selectImageToGen && setSelectedTab('all');
          }}
          className={cn('text-[16px] font-jost font-[400] p-[10px] text-[#191919] col-span-6',
            selectImageToGen && 'opacity-50 cursor-not-allowed')}
          type='button'
          style={{ padding: 0, text: 'nowrap' }}
          activeClassName={{ color: '#111', background: '#D9D9D9' }}
        >
          All Images
        </TabButton>
        <TabButton
          active={selectedTab === 'uploads'}
          onClick={() => {
            setSelectedTab('uploads');
          }}
          type='button'
          className='text-[16px] font-jost font-[400] p-[10px] text-[#191919] col-span-6'
          activeClassName={{ color: '#111', background: '#D9D9D9' }}
        >
          My Uploads
        </TabButton>
      </div>
      {loading ? (
        <div className='w-full h-full flex items-center justify-center'>
          <div className='spinner-border' role='status'>
            <ClipLoader color='#757D89' />
          </div>
        </div>
      ) : (
        <>
          <div className=''>
            <ImageGallery
              imageData={selectedTab === 'all' ? allImagesList : uploadImagesList}
              loading={loading}
              handleImageSelect={handleImageSelect}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ImageLibrary;