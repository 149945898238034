import React from 'react';
import { Formik, Form, Field } from 'formik';
import { AppButton } from 'components/shared/AppButton';
import closeIcon from 'assets/icons/closeIcon.svg';
import { BASE_URL } from 'utils/baseURL';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import { useParams } from 'react-router-dom';

const RenameImgModal = ({
  handleCloseBtn,
  setUpdatedImgName,
  updatedImgName,
  imageClickData,
  getProjectImageList,
  reCallImageList
}) => {
  const { projectID } = useParams();

  const handRenameBtn = async () => {
    try {
      let data;
      data = new FormData();
      data.append('image_name', updatedImgName);
      // if generated img then send gid else send pid
      if (imageClickData?.gid) {
        data.append('gid', imageClickData?.gid);
      } else {
        data.append('pid', imageClickData?.pid);
      }
      const url = BASE_URL + 'image-gen/renameImage';
      await axios.post(
        url,
        data,
        {
          headers: {
            token: LocalStorage.getItem()
          }
        });
      if (projectID) {
        getProjectImageList();
      } else {
        reCallImageList();
      }
    } catch (error) {
      console.log(error);
    }
    handleCloseBtn();
  };

  return (
    <div
      className='relative z-50'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
      onClick={(e) => e?.stopPropagation()}
    >
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>
      <div className='fixed inset-0 z-50 overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center text-center sm:items-center text-[#000]'>
          <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
          transition-all sm:my-8 sm:mx-4 w-[462px] h-auto px-[40px] py-[20px]'>
            <div className='bg-white'>
              <div className='w-full'>
                <div className='text-left'>
                  <div className='flex justify-between items-center mb-[20px]'>
                    <span
                      className='font-jost font-[600] text-[20px] whitespace-nowrap'
                      id='modal-title'
                    >
                      Rename
                    </span>
                    <span
                      className='text-right flex items-center justify-center w-[35px] h-[35px] 
                      hover:bg-[#F0F0F0] rounded-[5px] cursor-pointer'
                      role="presentation"
                      onClick={() => handleCloseBtn()}
                    >
                      <img src={closeIcon} alt='' width='20px' height='20px' />
                    </span>
                  </div>
                  <Formik initialValues={{}}>
                    <Form>
                      <Field
                        name='fileName'
                        type='text'
                        placeholder='File name'
                        autoComplete='off'
                        className='customTextBoxColor block w-full h-[48px] px-[20px] py-1 text-[16px] leading-normal
                         text-gray-800 bg-white border
                          border-gray-200 rounded appearance-none'
                        onChange={(e) => setUpdatedImgName(e?.target.value)}
                        value={updatedImgName}
                      />
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
            <div className='w-full flex justify-end items-center mt-[20px]'>
              <AppButton
                onClick={() => { handRenameBtn(); }}
                type='button'
                variant='black'
                extendClass='bg-[#0038FF] hover:bg-[#0038FF] rounded-[5px] text-[20px] 
                w-[80px] h-[48px] px-[20px] py-[10px]'
                disabled={!updatedImgName}
              >
                Save
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenameImgModal;