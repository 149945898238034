import React from 'react';

const CommonButton = ({
  children,
  active,
  style,
  disabled,
  notActiveColorRequired,
  activeClassName,
  fontWeight,
  ...props
}) => {
  return (
    <button
      className="bg-white h-[40px] 2xl:h-[50px]"
      style={{
        border: 0,
        background: 'transparent',
        // fontWeight: "bold",
        ...(disabled ? { cursor: 'not-allowed' } : {}),
        ...(active ? (activeClassName ? {...activeClassName}  : { backgroundColor: '#F1F1F1' } ) : {}),
        ...(!active && notActiveColorRequired ? {...notActiveColorRequired} : {}),
        ...style,
        fontWeight: fontWeight || '400'
      }}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default CommonButton;