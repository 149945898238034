import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { BASE_URL } from 'utils/baseURL';
import { LocalStorage } from 'services/localStorage';
import ProjectSelection from './ProjectSelection';

const Create = () => {
  const navigate = useNavigate();
  const [socialTemp, setSocialTemp] = useState([]);
  const [id, setId] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [isProjectUpdating, setProjectUpdating] = useState(false);
  const [projectList, setProjectList] = useState();
  const [projectListLoading, setProjectListLoading] = useState(false);
  const [openProjectList, setOpenProjectList] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [newProjectName, setNewProjectName] = useState('');

  // To call the template list
  const getSocialTemplate = async () => {
    try {
      const templateData = await axios.get(BASE_URL + 'image-gen/all_templates');
      setSocialTemp(templateData?.data?.data?.templates);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSocialTemplate();
  }, []);

  // to get the existing project list
  const getExistingProjectLists = async () => {
    setProjectListLoading(true);
    const url = BASE_URL + 'image-gen/projects';
    try {
      const projectListData = await axios.get(
        url,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      setProjectList(projectListData?.data?.data?.projects);
    } catch (err) {
      console.log(err);
    } finally {
      setProjectListLoading(false);
    }
  };

  // calling the project selection popup on selecting any template
  useEffect(() => {
    if (selectedTemplate) {
      setSelectedProject();
      getExistingProjectLists();
      setOpenProjectList(true);
    }
  }, [selectedTemplate]);

  // handle the project list popup close
  const handleClose = () => {
    setOpenProjectList(false);
    setNewProjectName();
    setSelectedTemplate();
  };

  // tempId for google analytics for each template
  const handleTemplateId = (data) => {
    switch (data?.name) {
      case 'Image Generator':
        return 'Template_clickImageGen';
      case 'Photo Studio':
        return 'Template_clickPhotoStudio';
      case 'Instagram':
        return 'Template_clickInsta';
      case 'Facebook':
        return 'Template_clickFB';
      case 'Linkedin':
        return 'Template_clickLinkedin';
      default: break;
    }
  };

  // Navigating to respective template based on the selected template with project and documents
  const handleNavigation = (data, projectId, documentId) => {
    localStorage.removeItem('selectedListingTab');
    if (data) localStorage.setItem('selectedTemp', JSON.stringify(data));
    switch (data?.name) {
      case 'Image Generator':
        navigate(`/Projects/${projectId}/Document/${documentId}/ImageGenerator`);
        break;
      case 'Photo Studio':
        navigate(`/Projects/${projectId}/Document/${documentId}/PhotoStudio`);
        break;
      case 'Instagram':
        navigate(`/Projects/${projectId}/Document/${documentId}/InstagramPost`);
        break;
      case 'Facebook':
        navigate(`/Projects/${projectId}/Document/${documentId}/FacebookPost`);
        break;
      case 'Linkedin':
        navigate(`/Projects/${projectId}/Document/${documentId}/LinkedInPost`);
        break;
      case 'Google Search':
        navigate(`/Projects/${projectId}/Document/${documentId}/GoogleSearchAd`);
        break;
      case 'Walmart listings':
        if (data?.subname === 'Images') {
          navigate(`/Projects/${projectId}/Document/${documentId}/WalmartListingsImages`);
        } else navigate(`/Projects/${projectId}/Document/${documentId}/WalmartListingsDetails`);
        break;
      case 'Amazon listings':
        if (data?.subname === 'Images') {
          navigate(`/Projects/${projectId}/Document/${documentId}/AmazonListingsImages`);
        } else navigate(`/Projects/${projectId}/Document/${documentId}/AmazonListingsDetails`);
        break;
      default: break;
    }
  };

  // Create new project with templated for select new project flow
  const createNewProject = async () => {
    setProjectUpdating(true);
    const url = BASE_URL + 'image-gen/newProject';
    const data = new FormData();
    data?.append('project_name', newProjectName);
    data?.append('template_name', selectedTemplate?.t_name);
    try {
      const responseData = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage?.getItem()
          }
        }
      );
      const createdProjectData = responseData?.data?.data;
      localStorage.setItem('selectedProject', newProjectName);
      localStorage.setItem('selectedDocument', createdProjectData?.documents?.document_name || 'Untitled');
      const projectId = createdProjectData?.documents?.projectID || selectedProject?.projectID;
      const documentId = createdProjectData?.documents?.documentID;
      handleNavigation(selectedTemplate, projectId, documentId);
    } catch (err) {
      console.log(err);
    } finally {
      setProjectUpdating(false);
    }
  };

  // Adding the selected templated to the selected project
  const handleTemplateDataToProject = async () => {
    setProjectUpdating(true);
    const url = BASE_URL + 'image-gen/newDocument';
    const data = new FormData();
    data?.append('projectID', selectedProject?.projectID);
    data?.append('template_name', selectedTemplate?.t_name);
    try {
      const responseData = await axios.post(
      url,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: LocalStorage?.getItem()
        }
      }
      );
      const createdDocumentData = responseData?.data?.data;
      localStorage.setItem('selectedProject', selectedProject?.project_name);
      localStorage.setItem('selectedDocument', createdDocumentData?.documents?.document_name || 'Untitled');
      const projectId = createdDocumentData?.documents?.projectID || selectedProject?.projectID;
      const documentId = createdDocumentData?.documents?.documentID;
      handleNavigation(selectedTemplate, projectId, documentId);
    } catch (err) {
      console.log(err);
    } finally {
      setProjectUpdating(false);
    }
  };

  // For new or existing projects, use the app call to specify where selected templates should be assigned.
  const handleSave = () => {
    if (selectedProject) {
      handleTemplateDataToProject();
    } else if (newProjectName) {
      createNewProject();
    }
  };

  return (
    <div className='mx-[20px] my-[20px] border border-[#D9D9D9] rounded-[10px] h-[calc(100%-40px)] 
    flex flex-col items-center text-[#000] font-jost'>
      <div className='h-[calc(100%-40px)] overflow-y-scroll'>
        {socialTemp.length <= 0 ? (
          <div className='flex justify-center items-center h-full'>
            <div className='spinner-border ' role='status'>
              <ClipLoader color='#757D89' />
            </div>
          </div>
        ) : (
          <div className='mt-[8%]'>
            <div className='text-[30px] xl-mid:text-[39px] font-[400] mb-[20px]'>
              What do you want to create?
            </div>
            <div className='grid grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 gap-[20px]'>
              {socialTemp.map((resp, index) => (
                <div key={index} role='presentation' onClick={() => setSelectedTemplate(resp)}>
                  <div
                    key={index}
                    data-tooltip-id={index + 1}
                    className={`w-[160px] h-[160px] xl-mid:w-[200px] xl-mid:h-[200px] border border-[#94A3B8] 
                    rounded-[5px] flex flex-col items-center cursor-pointer text-primaryColor 
                    ${id === index && 'bg-[#f1f1f1] !text-[#5C80FF]'}`}
                    onMouseEnter={() => setId(index)}
                    onMouseLeave={() => setId('')}
                    id={handleTemplateId(resp)}
                  >
                    <img
                      className='mt-[23%] mb-[16px] h-[40px] w-[40px] xl-mid:h-[60px] xl-mid:w-[60px]'
                      src={id === index ? resp?.image_color : resp?.image_bw}
                      alt='single'
                      id={handleTemplateId(resp)}
                    />
                    <div className='text-[20px] xl-mid:text-[25px]' id={handleTemplateId(resp)}>{resp?.name}</div>
                    <div className='text-[16px] xl-mid:text-[20px]' id={handleTemplateId(resp)}>{resp?.subname}</div>
                  </div>
                  <Tooltip
                    id={index + 1}
                    place='bottom-start'
                    variant='light'
                    noArrow='true'
                    style={{
                      width: '250px', marginTop: '20px',
                      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                    }}
                  >
                    {resp?.info}
                  </Tooltip>
                </div>
              ))}
            </div>
          </div>)}
      </div>
      {openProjectList && (
        <ProjectSelection
          isProjectUpdating={isProjectUpdating}
          projectList={projectList}
          handleSave={handleSave}
          handleClose={handleClose}
          setSelectedProject={setSelectedProject}
          projectListLoading={projectListLoading}
          selectedProject={selectedProject}
          newProjectName={newProjectName}
          setNewProjectName={setNewProjectName}
          setSelectedTemplate={setSelectedTemplate}
        />
      )}
    </div>
  );
};

export default Create;