import React, { useState, useEffect } from 'react';
import moment from 'moment/moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import verticalOptionIcon from 'assets/icons/optionsVertical.svg';
import ImageClickFunctions from '../../ImageClickFunctions'; // on image click PopUp Compoenent
import cn from 'classnames';
import { getDropdownPosition } from 'utils/commonFunctions';
import useClickOutsideHandler from 'components/customHooks/useClickOutsideHandler';
import _ from 'lodash';
import sortingArrow from 'assets/icons/sortingArrow.svg';

const ProjectListView = (props) => {
  const {
    projectImgList,
    handleImageClick,
    seImageClickData,
    setIsImgClickPopUps,
    isImgClickPopUps,
    setMultiImageSelectedList,
    isSearched,
    searchDataList,
    loading,
    setShareLinkKeyData,
    setShareLinkLoading,
    setIsAllowDownload,
    isFilesUploading,
    projectShareKey,
    projectShareAllowDownload
  } = props;
  const [isValidSrc, setIsValidSrc] = useState(true);
  const [imgFunctionPopUp, setImgFunctionPopUp] = useState(false);
  const [imgIndex, setImgIndex] = useState('');
  const [selectedAllImages, setSelectedAllImages] = useState([]); // to store selected images
  const [filteredImageList, setFilteredImageList] = useState([]); // storing modified searchResult data
  const [imageList, setImageList] = useState([]); // storing image list
  const [sortOrder, setSortOrder] = useState({
    'image_name': false,
    'size': false
  }); // to hold the sorting order ('asc' or 'desc')
  const [sortedField, setSortedField] = useState();
  const [showSortingArrowOn, setShowSortingArrowOn] = useState('image_name'); 
  const [selectedField, setSelectedFiled] = useState();

  // Custom hook to handle the deselecting the selected image if the user click outside
  const clickImageCardRef = useClickOutsideHandler(
    'clickImageList',
    () => {
      const imageListData = isSearched ? filteredImageList : imageList;
      if (imageListData?.length > 0 && selectedAllImages?.length > 0) {
        const updatedImages = imageListData?.map((item) => ({
          ...item,
          selected: false
        }));
        if (isSearched) {
          setFilteredImageList(updatedImages);
        } else setImageList(updatedImages);
        setSelectedAllImages([]);
      }
    },
    [imageList, selectedAllImages, isFilesUploading, isSearched, filteredImageList]
  );

  // reset the sorting order on sorting field changes
  useEffect(() => {
    setSortOrder(false);
  }, [sortedField]);

  // Clearing the selectedImages
  useEffect(() => {
    setSelectedAllImages([]);
  }, []);

  useEffect(() => {
    setMultiImageSelectedList(selectedAllImages);
  }, [selectedAllImages]);

  const handleIndexData = (listData) => {
    const tempData = listData?.map((item, index) => {
      if (item) {
        item['index'] = index;
        item['selected'] = false;
      }
      return item;
    });
    return tempData;
  };

  // Altering the search list data for multiSelect feature
  useEffect(() => {
    if (searchDataList?.length > 0) {
      setFilteredImageList(handleIndexData(searchDataList));
    } else {
      setFilteredImageList([]);
    }
  }, [searchDataList]);

  useEffect(() => {
    if (projectImgList) {
      setImageList(handleIndexData(projectImgList?.history));
    }
  }, [projectImgList]);

  const handleImageSize = (imgSize) => {
    if (imgSize > 1023) {
      return `${Math.ceil(Number(imgSize / 1024))} MB`;
    } else return `${Math.ceil(Number(imgSize).toFixed(2))} KB`;
  };

  const handleDropdownPosition = (imageIndex, item) => {
    const imageElement = document.querySelector(`#Image-${imageIndex}`);
    const containerElement = document.getElementById('imageContainer');
    const dropdownHeight = item?.selected ? '120' : '350';
    const dropdownPosition = getDropdownPosition(imageElement, containerElement, dropdownHeight);
    return dropdownPosition;
  };

  const handleMouseEnter = (index) => {
    setImgIndex(index);
  };

  const handleMouseLeave = () => {
    setImgIndex('');
    setImgFunctionPopUp(false);
  };

  // Function to handle multiSelection of images
  const handleMultiImageSelection = (imageData, event) => {
    const isSelected = selectedAllImages?.some((item) => {
      if (imageData?.gid) {
        return item?.gid === imageData?.gid;
      } else if (imageData?.pid) {
        return item?.pid === imageData?.pid;
      }
      return false;
    });

    const imageListData = isSearched ? filteredImageList : imageList;
    let selectedImageList = [];
    const specialKeySelected = event.altKey || event.ctrlKey || event.shiftKey;
    const selectedImageIndex = imageListData?.findIndex((item) => item === imageData);

    const updateSelectedState = (images, index, selected) => {
      const updatedImages = [...images];
      updatedImages[index].selected = selected;
      return updatedImages;
    };

    if (isSelected && specialKeySelected) {
      // If the image is already selected and a special key is pressed,
      // remove the selected item when clicking it again
      setSelectedAllImages((prevSelectedImages) =>
        prevSelectedImages.filter(
          (item) => !(item.gid === imageData.gid || item.pid === imageData.pid)
        )
      );
      selectedImageList = (updateSelectedState(imageListData, selectedImageIndex, false));
    } else if (!isSelected && specialKeySelected) {
      // Select the image only if it's not already selected and a special key is pressed
      setSelectedAllImages((prevSelectedImages) => [
        ...prevSelectedImages,
        { ...imageData, selected: true }
      ]);
      selectedImageList = (updateSelectedState(imageListData, selectedImageIndex, true));
    } else if (!specialKeySelected) {
      // Deselect all other images and select the current image if no special key is pressed
      const updatedImages = imageListData?.map((item) => ({ ...item, selected: false }));
      selectedImageList = (updateSelectedState(updatedImages, selectedImageIndex, true));
      setSelectedAllImages([{ ...imageData, selected: true }]);
    }

    if (selectedImageList?.length > 0) {
      if (isSearched) {
        setFilteredImageList(selectedImageList);
      } else setImageList(selectedImageList);
    }
  };

  // Function to handle multiSelection of images count
  const handleMultiSelectCount = () => {
    const countValue = selectedAllImages?.length;
    return countValue;
  };

  const toggleSortingField = (field) => {
    setSortOrder((prevSortOrder) => ({
      ...prevSortOrder,
      [field]: !prevSortOrder?.[field]
    }));
  };

  // Function to handle the sorting the give field in ascending or descending order
  const handleGeneralSorting = (sortingField) => {
    const sortingOrder = sortOrder[sortingField] ? 'asc' : 'desc';
    const imageListData = isSearched ? filteredImageList : imageList;
    const sortingFieldData = sortingField === 'image_name' ?
      imageListData => imageListData[sortingField].toLowerCase() : sortingField;
    const sortedData = _.orderBy(imageListData, [sortingFieldData], [sortingOrder]);
    if (isSearched) {
      setFilteredImageList(sortedData);
    } else setImageList(sortedData);
    toggleSortingField(sortingField);
    setShowSortingArrowOn(sortedField);
  };

  return (
    <>
      {(isSearched ? filteredImageList?.length > 0 : imageList?.length > 0) && (
        <div id='imageContainer' style={{ height: '74vh', overflow: 'scroll' }}>
          <table
            className="border-collapse font-jost table w-full rounded table-fixed"
            id='clickImageList'
            ref={clickImageCardRef}
          >
            <thead className='border-[1px] border-[#D9D9D9] text-[#666]'>
            <tr className='top-0 w-full sticky z-10 gap-[10px]
              h-[65px] bg-[white] outline outline-1 outline-offset-0 outline-[#D9D9D9] py-[16px] px-[10px]'>
              <th
                className='text-left w-2/6 pl-4'
              >
                <div
                  className={cn('flex flex-row gap-[1px] cursor-pointer')}
                  onMouseEnter={() => {
                    setSortedField('image_name');
                    setSelectedFiled('image_name');
                  }}
                  onMouseLeave={() => setSelectedFiled()}
                >
                  <div
                    className={cn('w-auto h-[33px] px-2.5 py-[5px] bg-zinc-100',
                      'rounded-tl-[5px] rounded-bl-[5px] justify-start items-center gap-2.5 inline-flex',
                      selectedField === 'image_name' && 'bg-[#F0F0F0]'
                    )}
                  >
                    <div className='text-[16px] font-[500]'>File Name</div>
                  </div>
                  {(selectedField || showSortingArrowOn) === 'image_name' && (
                    <div
                      className={cn('bg-zinc-100 rounded-tr-[5px] rounded-br-[5px] w-[29px] h-[33px] px-2.5 py-[5px]',
                        'justify-start items-center gap-2.5 inline-flex',
                        selectedField === 'image_name' && 'bg-[#F0F0F0]')}
                      onClick={() => {
                        handleGeneralSorting('image_name');
                      }}
                    >
                      <div
                        className={cn((selectedField || showSortingArrowOn) === 'image_name'
                          && sortOrder['image_name'] && '-rotate-180')}
                      >
                        <img
                          src={sortingArrow}
                          alt='icon'
                          width='9px'
                          height='16px'
                        />
                      </div>
                    </div>
                  )}
                </div>
              </th >
              <th className='text-left'>
                <span className='p-3 text-[16px] font-[500]'>File Format</span>
              </th>
              <th
                className='text-left'
              >
                <div
                  className={cn('flex flex-row gap-[1px] cursor-pointer')}
                  onMouseEnter={() => {
                    setSortedField('size');
                    setSelectedFiled('size');
                  }}
                  onMouseLeave={() => setSelectedFiled()}
                >
                  <div
                    className={cn('w-auto h-[33px] px-2.5 py-[5px] bg-zinc-100',
                      'rounded-tl-[5px] rounded-bl-[5px] justify-start items-center gap-2.5 inline-flex',
                      selectedField === 'size' && 'bg-[#F0F0F0]'
                    )}
                  >
                    <div className='text-[16px] font-[500]'>Size</div>
                  </div>
                  {(selectedField || showSortingArrowOn) === 'size' && (
                    <div
                      className={cn('bg-zinc-100 rounded-tr-[5px] rounded-br-[5px]',
                        'justify-start items-center gap-2.5 inline-flex w-[29px] h-[33px] px-2.5 py-[5px]',
                        selectedField === 'size' && 'bg-[#F0F0F0]')}
                      onClick={() => {
                        handleGeneralSorting('size');
                      }}
                    >
                      <div
                        className={cn((selectedField || showSortingArrowOn) === 'size'
                          && sortOrder['size'] && '-rotate-180')}
                      >
                        <img
                          src={sortingArrow}
                          alt='icon'
                          width='9px'
                          height='16px'
                        />
                      </div>
                    </div>
                  )}
                </div>
              </th >
              <th className='text-left'>
                <span className='p-3 text-[16px] font-[500]'>Date Uploaded</span>
              </th>
              <th className='text-left'>
                <span className='p-3 text-[16px] font-[500]'>Date Created</span>
              </th>
              <th className='text-left'>
                <span className='p-3 text-[16px] font-[500]'>Dimensions</span>
              </th>
            </tr>
          </thead>
            <tbody>
              {(isSearched ? filteredImageList : imageList)?.map((item, index) => (
                <tr
                  key={index} id={`Image-${index}`}
                  className={cn('font-[500]', item?.selected && 'bg-[#0038FF] bg-opacity-10 text-black')}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave()}
                  onClick={(event) => item && handleMultiImageSelection(item, event)}
                  onMouseDown={(e) => e?.shiftKey && e?.preventDefault()}
                >
                  <td className='text-left p-2 w-full flex justify-between items-center'>
                    <div className='flex flex-wrap items-center'>
                      <span className='h-[56px] w-[56px] bg-[#F1F1F1] flex justify-center items-center rounded'>
                        <LazyLoadImage
                          src={(isValidSrc && item?.thumbnail) || item?.input_image_url}
                          alt={index}
                          className='max-w-full max-h-full rounded-[2px]'
                          onError={() => setIsValidSrc(false)}
                        />
                      </span>
                      <span className='flex-1 items-center pl-2 truncate max-w-[320px]'>{item?.image_name}</span>
                    </div>
                    <div className={cn('flex items-center justify-center rounded-[5px] cursor-pointer relative',
                      'mr-[32px] w-[28px] h-[28px]',
                      item?.selected ? 'hover:!bg-[#0038FF] hover:!bg-opacity-20' : 'hover:bg-[#F0F0F0]')}
                      onClick={(e) => {
                        e.stopPropagation(); setImgFunctionPopUp(!imgFunctionPopUp), seImageClickData(item);
                      }}
                    >
                      <img src={verticalOptionIcon} alt='' width={20} height={20} />
                      {/*on image click PopUp Compoenent*/}
                      {imgFunctionPopUp && imgIndex === index && (
                        <ImageClickFunctions
                          setImgFunctionPopUp={setImgFunctionPopUp}
                          handleImageClick={() => handleImageClick(item)}
                          imageData={item}
                          dropdownPosition={handleDropdownPosition(index, item)}
                          viewType={'list'}
                          setIsImgClickPopUps={setIsImgClickPopUps}
                          isImgClickPopUps={isImgClickPopUps}
                          multiSelectCount={handleMultiSelectCount()}
                          isUploadImagesOnly={selectedAllImages?.length > 0}
                          multiSelectedItems={selectedAllImages}
                          setShareLinkKeyData={setShareLinkKeyData}
                          setShareLinkLoading={setShareLinkLoading}
                          setIsAllowDownload={setIsAllowDownload}
                          projectShareKey={projectShareKey}
                          projectShareAllowDownload={projectShareAllowDownload}
                        />
                      )}
                    </div>
                  </td>
                  <td className='text-left p-2'>{item?.image_type}</td>
                  <td className='text-left p-2'>{item?.size && handleImageSize(item?.size)}</td>
                  <td className='text-left p-2'>{item?.time && moment(item?.time).format('MMM DD, YYYY')}</td>
                  <td className='text-left p-2'>
                    {item?.created_on && moment(item?.created_on).format('MMM DD, YYYY')}
                  </td>
                  <td className='text-left p-2'>{item?.dimensions &&
                    `${item?.dimensions[0]} x ${item?.dimensions[1]}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {(isSearched ? filteredImageList?.length === 0 : imageList?.length === 0) && (
        !loading && <div className='w-full h-full font-jost font-[600] text-[18px] whitespace-nowrap'>
          No record found
        </div>
      )}
    </>
  );
};

export default ProjectListView;