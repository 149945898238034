import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { AppButton } from 'components/shared/AppButton';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import cn from 'classnames';
import closeIcon from 'assets/icons/closeIcon.svg';
import { BASE_URL } from 'utils/baseURL';

const Modal = (props) => {
  const { handleModal, handleFileName, handleCloseBtn } = props;
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [error, setError] = useState(true);
  const [isAPICalling, setIsAPICalling] = useState(false);

  // to delay the function by 300ms
  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  // to check the image name is valide or not
  const getImageNameValidator = async () => {
    setIsAPICalling(true);
    const data = new FormData();
    data.append('image_name', uploadedFileName);
    const url = BASE_URL + 'image-gen/image_name';
    try {
      const responseData = await axios.post(url, data, {
        headers: {
          token: LocalStorage.getItem()
        }
      });
      if (responseData?.data?.data?.name_available) {
        setError(false);
      } else {
        setError(true);
      }
      setErrorMsg(responseData?.data?.message);
      setIsAPICalling(false);
    } catch (error) {
      setIsAPICalling(false);
      console.log(error);
    }
  };

  const updateFileNameValue = debounce((data) => {
    setErrorMsg();
    setUploadedFileName('');
    if (data) {
      // Check if the input contains only spaces or the first character is a space
      if (/^\s/.test(data)) {
        setError(true);
        setErrorMsg('Filename cannot start with a space.');
      } else if (/\s$/.test(data)) {
        setError(true);
        setErrorMsg('Filename cannot end with a space.');
      } else {
        // const trimmedData = data.trim();
        setUploadedFileName(data);
      }
    }
  });

  useEffect(() => {
    if (uploadedFileName) {
      getImageNameValidator();
    }
  }, [uploadedFileName]);

  const handleSaveBtn = () => {
    if (!error) {
      handleModal();
      handleFileName(uploadedFileName);
    }
  };

  const handleClose = () => {
    handleCloseBtn();
  };

  return (
    <div className='relative z-50' aria-labelledby='modal-title' role='dialog' aria-modal='true'>
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>
      <div className='fixed inset-0 z-50 overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
          transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-lg'>
            <div className='bg-white p-3'>
              <div className='w-full'>
                <div className='text-left'>
                  <div className='grid grid-cols-12'>
                    <h3
                      className='font-jost font-[600] lg:text-[13px] xl:text-[16px] xl-mid:text-[18px]
                      4xl:text-[20px] whitespace-nowrap mb-2 col-span-11'
                      id='modal-title'
                    >
                      Add name for image
                    </h3>
                    <span
                      className='col-span-1 text-right flex items-start justify-end'
                      role="presentation"
                      onClick={() => handleClose()}
                    >
                      <img src={closeIcon} alt='' width='12px' height='12px' />
                    </span>
                  </div>
                  <Formik initialValues={{}}>
                    <Form>
                      <Field
                        name='fileName'
                        type='text'
                        placeholder='e.g. red sofa'
                        autoComplete='off'
                        className='customTextBoxColor block w-full h-[48px] px-2 py-1 mb-1 lg:text-[10px] xl:text-[12px]
                         xl-mid:text-[14px] 4xl:text-[14px] leading-normal text-gray-800 bg-white border
                          border-gray-200 rounded appearance-none'
                        onChange={(e) => updateFileNameValue(e?.target.value)}
                      />
                    </Form>
                  </Formik>
                </div>
              </div>
              {errorMsg && (
                <div className='w-full font-jost font-[400] lg:text-[12px] xl:text-[14px] 
                xl-mid:text-[16px] 4xl:text-[18px] whitespace-nowrap mb-'>
                  <div className={cn(!error ? 'text-green-500 nameErrorText' : 'text-red-600 nameErrorText')}>
                    {errorMsg}</div>
                </div>
              )}
            </div>
            <div className='w-full flex justify-center items-center p-2'>
              <AppButton
                onClick={() => { handleSaveBtn(); }}
                type='button'
                variant='black'
                isLoading={isAPICalling}
                extendClass='bg-black hover:bg-black rounded lg:text-[12px] xl:text-[14px] xl-mid:text-[16px] 
                4xl:text-[18px] h-[48px] w-[180px] px-5 2xl:px-7'
                disabled={!uploadedFileName || error}
              >
                Save
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;