import { LocalStorage } from 'services/localStorage';
// import { toast } from 'react-toastify';
// import { refeshToken }  from 'utils/Firebase';

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export const CheckUserValid = () => {
  const token = LocalStorage.getItem();
  if (!token) return false;
  const { exp } = parseJwt(token);
  const remainingTime = Date.now() - exp * 1000;
  if (remainingTime > 0) {
    LocalStorage.removeItem();
    // Removing userId and emailId after token expiries
    localStorage.removeItem('user-id');
    localStorage.removeItem('user-email');
    console.log('Your login has expired');
    return false;
  } else {
    return exp;
  }
};

// Commented below function since we are using custom token for validation
// export const CheckUserValid =  () => {
//   const token = LocalStorage.getItem();
//   if (!token) return false;
//   const { exp } = parseJwt(token);
//   const remainingTime = exp * 1000 - Date.now();
//   let seconds = Math.floor(remainingTime / 1000);
//   let minutes = Math.floor(seconds / 60);
//   localStorage.setItem('tokenExpiryTime', minutes);
//   if (minutes <= 5) {
//     refeshToken();
//     const isRereshed =  localStorage.getItem('isRereshed');
//     if (isRereshed === 'true') {
//       return true;
//     } else {
//       LocalStorage.removeItem();
//       toast.error('Your login has expired');
//       return false;
//     }
//   } else {
//     localStorage.setItem('isRereshed', false);
//     return exp;
//   }
// };

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
