import React from 'react';
// import { Button } from 'flowbite-react';
// import { HiArrowDown } from 'react-icons/hi';
// import { BsTrash3 } from 'react-icons/bs';
// import { FaFolderPlus } from 'react-icons/fa';
import Product from 'components/Product';
// import { useQuery } from 'react-query';
import useImagesHistory, { getBulkImageHistory } from 'queryhook/useImagesHistory';
import AppLoader from 'components/shared';
import { useEffect, useState } from 'react';
import { AppButton } from 'components/shared/AppButton';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import imgVector from '../assets/img/image.png';
import loadingGif from '../assets/img/new_loading.gif';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faHeart, faImage, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { LocalStorage } from 'services/localStorage';
import { faArrowLeft, faChevronLeft, faChevronRight, faCircleInfo, faPencil, faWandMagicSparkles }
  from '@fortawesome/free-solid-svg-icons';
// import { toast } from 'react-toastify';
import { handleTokenExpiryIssue } from 'utils/functions.util';
import { BASE_URL } from 'utils/baseURL';
function ImageHistory() {
  const [isDescending, setIsDescending] = useState(true);
  let { pageNumber = 0 } = useParams();
  const [pageSize] = useState(10);
  const [generatedImageHistory, setGeneratedImageHistory] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');
  const [libraryCategory, setLibraryCategory] = useState('edited');
  const [imageAllDetails, setImageAllDetails] = useState({});
  const [imageHistoryType, setImageHistoryType] = useState('single');
  const [bulkImageHistory, setBulkImageHistory] = useState({});
  const [selectedBulkCollection, setSelectedBulkCollection] = useState({});
  const [isBulkCollectionOpen, setIsBulkCollectionOpen] = useState(false);
  const [bulkCollectionGeneratedImages, setBulkCollectionGeneratedImages] = useState([]);
  const navigate = useNavigate();

  console.log(generatedImageHistory, setIsDescending);

  const favUrl =
    BASE_URL + 'image-gen/add-to-favourite';

  const unFavUrl =
    BASE_URL + 'image-gen/unfavourite';

  const onClickNextPage = () => {
    if (pageNumber * pageSize > imageHistory?.data?.total_records) return;
    navigate(`/panel/images/${+pageNumber + 1}`);
    setTimeout(refetchHistory, 0);
  };

  const onClickLastPage = () => {
    if (pageNumber * pageSize === 0) return;
    navigate(`/panel/images/${+pageNumber - 1}`);
    setTimeout(refetchHistory, 0);
  };

  const fetchGeneratedImageHistory = async (pid) => {
    try {
      const res = await axios.get(
        BASE_URL + `image-gen/product-history?pid=${pid}`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      setImageAllDetails({ ...res.data.data });
    } catch (error) {
      console.log(error);
    }
  };

  const addFavorite = async (pid, imgUrl, isFav) => {
    const data = new FormData();
    data.set('image_url', imgUrl);
    data.set('pid', pid);
    try {
      if (!isFav) {
        await axios.post(favUrl, data, {
          headers: {
            token: LocalStorage.getItem()
          }
        });
        fetchGeneratedImageHistory(pid);
      } else {
        await axios.post(unFavUrl, data, {
          headers: {
            token: LocalStorage.getItem()
          }
        });
        fetchGeneratedImageHistory(pid);
      }
    } catch (err) {
      if (err.response.status === 440) {
        handleTokenExpiryIssue();
      }
    }
  };

  const { imageHistory, isImageHistoryFetching, deleteImageMutation, refetchHistory } = useImagesHistory(
    pageSize,
    pageNumber * pageSize,
    imageHistoryType
  );

  const bulkHistoryGetter = async () => {
    const res = await getBulkImageHistory(pageSize, pageNumber * pageSize);
    setBulkImageHistory(res);
    //console.log(res)
  };

  const getDateString = (date) => {
    const splitted = date.split('-');
    const day = splitted[0];
    const dummyDate = new Date();
    dummyDate.setMonth(parseInt(splitted[1]) - 1);

    const month = dummyDate.toLocaleString('en-US', { month: 'long' });
    const year = splitted[2];

    return `${day} ${month}, ${year}`;
  };

  useEffect(() => {
    setGeneratedImageHistory([]);
    if (imageHistory?.data?.history && imageHistoryType === 'single' && imageHistory?.data?.history.length > 0) {
      setSelectedImage(imageHistory?.data?.history[0].pid);
    } else if (selectedBulkCollection?.products && imageHistoryType === 'bulk') {
      setSelectedImage(selectedBulkCollection.products[0].pid);
      // setBulkCollectionGeneratedImages(selectedBulkCollection.products[0].generated_images[0].generated_images)
    }
  }, [imageHistory, imageHistoryType]);

  useEffect(() => {
    setImageAllDetails({});
    if (selectedImage && imageHistoryType === 'single')
      fetchGeneratedImageHistory(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (isBulkCollectionOpen && selectedBulkCollection?.products) {
      setBulkCollectionGeneratedImages(selectedBulkCollection.products[0].generated_images[0]);
    }
  }, [isBulkCollectionOpen]);

  useEffect(() => {
    pageNumber = 0;
    if (imageHistoryType === 'single') {
      // console.log('do nothing');
    } else {
      bulkHistoryGetter();
    }
    navigate('/panel/images/');
  }, [imageHistoryType]);

  if (isImageHistoryFetching)
    return (
      <h2 className=''>
        {' '}
        <AppLoader isLoading={isImageHistoryFetching} size={70} />
      </h2>
    );

  return (
    <div className='contentContainer'>
      <header className='header'>
        {/* <select
          name='filters'
          id='filter'
          className='rounded-xl focus:shadow-[0_0_rgba(0,0,0/0%)] focus:border-black border-2 border-black 
          col-span-4 md:col-span-3 lg:col-span-2 xl:col-span-2'
          onChange={event => setIsDescending(!!+event.target.value)}
        >
          <option value={1}>Newest First</option>
          <option value={0}>Oldest First</option>
        </select> */}
      </header>
      <>
        {(imageHistoryType === 'single' || isBulkCollectionOpen) ? (
          <div className='w-full mx-auto h-10 flex max-w-[1440px] gap-[40px]'>
            <div className='w-[340px]'>
              <div className='flex justify-center items-center bg-white py-2 gap-2 rounded-md'>
                <img src={imgVector} className='w-[17px]' />
                <p className='text-lg'>
                  {imageHistoryType === 'single' ?
                    'Your Image History'
                    :
                    selectedBulkCollection.collection_name
                  }
                </p>
              </div>
              <div className='mt-5 bg-white p-5 pt-6 rounded-md'>
                {imageHistoryType === 'single' ?
                  <div className='w-full px-4 py-2 mb-6 flex justify-between items-center border border-[1px]
                   border-[#cbd5e1] rounded-sm'>
                    <div>Select Period</div>
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                  :
                  <div className='flex gap-2 items-center p-2 mb-2 cursor-pointer'
                    onClick={() => setIsBulkCollectionOpen(false)}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    Go Back
                  </div>
                }
                <div className='flex justify-between flex-wrap items-center'>
                  {imageHistoryType === 'single' && imageHistory?.data?.history
                    ?.sort((imgDataA, imgDataB) => {
                      const order = isDescending ? 1 : -1;
                      return order * (moment(imgDataB.time, 'DD-MM-YYYY hh:mm:ss') - moment(imgDataA.time,
                        'DD-MM-YYYY hh:mm:ss'));
                    })
                    ?.map(imgData => (
                      <div className={`bg-[#F5F8FE] rounded-md w-[140px] h-[150px] mb-4 cursor-pointer 
                      ${selectedImage === imgData.pid && 'border border-[3px] border-[#5555e8]'}`}
                        onClick={() => setSelectedImage(imgData.pid)} key={imgData.pid}>
                        <img src={imgData?.input_image_url} className='w-full h-full object-contain' />
                      </div>
                    )
                    )}
                  {imageHistoryType !== 'single' && isBulkCollectionOpen
                    && selectedBulkCollection?.products?.map(eachProduct => (
                      <div className={`bg-[#F5F8FE] rounded-md w-[140px] h-[150px] mb-4 cursor-pointer 
                    ${selectedImage === eachProduct.pid && 'border border-[3px] border-[#5555e8]'}`} onClick={() => {
                          setSelectedImage(eachProduct.pid);
                          setBulkCollectionGeneratedImages(eachProduct.generated_images[0]);
                        }} key={eachProduct.pid}>
                        <img src={eachProduct.input_image_url} className='w-full h-full object-contain' />
                      </div>
                    ))}
                  {imageHistoryType === 'single' &&
                    <div className='flex justify-between gap-2 w-full'>
                      <AppButton onClick={onClickLastPage} extendClass='w-40' variant='primary'
                        disabled={pageNumber * pageSize === 0}>
                        <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '10px' }} /> Previous
                      </AppButton>
                      <AppButton
                        onClick={onClickNextPage}
                        extendClass='w-40'
                        variant='primary'
                        disabled={pageNumber * pageSize > imageHistory?.data?.total_records}
                      >
                        Next <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: '10px' }} />
                      </AppButton>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className='max-w-[1060px] w-full'>
              <div className='flex justify-between'>
                <div className='flex gap-3'>
                  <div className={`cursor-pointer rounded-full flex gap-3 ${imageHistoryType === 'single'
                    ? 'bg-[#5555e8] text-white' : 'bg-[#f0f0ff] text-[#5555e8]'} items-center h-[42px] px-7`}
                    onClick={() => setImageHistoryType('single')}>
                    <FontAwesomeIcon icon={faImage} style={{
                      color: imageHistoryType === 'single'
                        ? '#ffffff' : '#5555e8'
                    }} />
                    Single Images
                  </div>
                  <div className={`cursor-pointer rounded-full flex gap-3 ${imageHistoryType === 'single'
                    ? 'bg-[#f0f0ff] text-[#5555e8' : 'bg-[#5555e8] text-white'} items-center h-[42px] px-7`}
                    onClick={() => setImageHistoryType('bulk')}>
                    <FontAwesomeIcon icon={faImage} style={{
                      color: imageHistoryType === 'single'
                        ? '#5555e8' : '#ffffff'
                    }} />
                    Bulk Uploads
                  </div>
                </div>
                <div className='flex gap-6'>
                  <div className='flex items-center justify-between w-[200px] bg-white p-2.5 border border-[1px]
                   border-[#CBD5E1] rounded'>
                    <label htmlFor='oldest'>Show Oldest</label>
                    <input type='checkbox' id='oldest' />
                  </div>
                  <div className='bg-[#5555e8] px-[40px] flex items-center text-white rounded-full gap-3 cursor-pointer'
                    onClick={() => navigate('/panel')}>
                    <FontAwesomeIcon icon={faWandMagicSparkles} style={{ color: 'white' }} />
                    Generate Again
                  </div>
                </div>
              </div>
              {imageHistoryType === 'single' &&
                <div className='w-full bg-white p-3 rounded-md mt-5'>
                  <div className='flex justify-around bg-[#f5f8fe] rounded-md'>
                    <div className={`h-full cursor-pointer ${libraryCategory === 'edited'
                      && 'border-b border-b-[3px] border-[#5555E8] text-[#5555e8]'} px-4 py-2.5`}
                      onClick={() => setLibraryCategory('edited')}>Edited Images</div>
                    <div className={`h-full cursor-pointer ${libraryCategory === 'resized'
                      && 'border-b border-b-[3px] border-[#5555E8] text-[#5555e8]'} px-4 py-2.5`}
                      onClick={() => setLibraryCategory('resized')}>Resized Images</div>
                    <div className={`h-full cursor-pointer ${libraryCategory === 'favorites'
                      && 'border-b border-b-[3px] border-[#5555E8] text-[#5555e8]'} px-4 py-2.5`}
                      onClick={() => setLibraryCategory('favorites')}>Favorites Only</div>
                    <div className={`h-full cursor-pointer ${libraryCategory === 'all'
                      && 'border-b border-b-[3px] border-[#5555E8] text-[#5555e8]'} px-4 py-2.5`}
                      onClick={() => setLibraryCategory('all')}>All Images</div>
                    <div className={`h-full cursor-pointer ${libraryCategory === 'deleted'
                      && 'border-b border-b-[3px] border-[#5555E8] text-[#5555e8]'} px-4 py-2.5`}
                      onClick={() => setLibraryCategory('deleted')}>Deleted Images</div>
                  </div>
                </div>
              }
              {imageHistoryType === 'single' ?
                <div className='products'>
                  {(libraryCategory === 'edited' || libraryCategory === 'all') &&
                    imageAllDetails?.generated_images && imageAllDetails?.generated_images.map(eachGenerations => {
                      return eachGenerations.generated_images?.map(imgData => {
                        return (
                          <div key={imgData?.pid} style={{ height: 'fit-content' }}>
                            <Product
                              hasGenerateButton
                              hasDeleteButton
                              hasDownloadButton
                              id={imageAllDetails?.pid}
                              inputImageUrl={imgData}
                              removeBackgroundUrl={imageAllDetails?.input_image_url}
                              deleteImageMutation={deleteImageMutation}
                              createdDate={imageAllDetails?.time}
                              addFavorite={addFavorite}
                              favoriteImages={imageAllDetails?.favourites}
                            />
                          </div>
                        );
                      });
                    })
                  }
                  {(libraryCategory === 'resized' || libraryCategory === 'all') &&
                    imageAllDetails?.resized_images?.map(imgData => {
                      return (
                        <div key={imgData?.pid} style={{ height: 'fit-content' }}>
                          <Product
                            hasGenerateButton
                            hasDeleteButton
                            hasDownloadButton
                            id={imgData?.pid}
                            inputImageUrl={imgData?.resized_image}
                            removeBackgroundUrl={imageAllDetails?.input_image_url}
                            deleteImageMutation={deleteImageMutation}
                            createdDate={imageAllDetails?.time}
                            downloadImageUrl={imgData?.resized_image}
                            addFavorite={addFavorite}
                            favoriteImages={imageAllDetails?.favourites}
                          />
                        </div>
                      );
                    })
                  }
                  {(libraryCategory === 'favorites') &&
                    imageAllDetails?.favourites?.map(imgData => {
                      return (
                        <div key={imgData?.pid} style={{ height: 'fit-content' }}>
                          <Product
                            hasGenerateButton
                            hasDeleteButton
                            hasDownloadButton
                            id={imgData?.pid}
                            inputImageUrl={imgData?.image_url}
                            removeBackgroundUrl={imageAllDetails?.input_image_url}
                            deleteImageMutation={deleteImageMutation}
                            createdDate={imageAllDetails?.time}
                            addFavorite={addFavorite}
                            favoriteImages={imageAllDetails?.favourites}
                          />
                        </div>
                      );
                    })
                  }
                </div>
                :
                <div className='products'>
                  {bulkCollectionGeneratedImages?.generated_images?.map((imgData, index) => {
                    return (
                      <div key={index} style={{ height: 'fit-content' }}>
                        <Product
                          hasGenerateButton
                          hasDeleteButton
                          hasDownloadButton
                          id={selectedImage}
                          inputImageUrl={imgData}
                          removeBackgroundUrl={imgData}
                          deleteImageMutation={deleteImageMutation}
                          createdDate={bulkCollectionGeneratedImages.time}
                        />
                      </div>
                    );
                  })}
                </div>
              }
            </div>
          </div>)
          :
          <div className='max-w-[1440px] mx-auto'>
            <div className='flex justify-between'>
              <div className='w-[340px]'>
                <div className='flex justify-center items-center bg-white py-2 gap-2 rounded-md'>
                  <img src={imgVector} className='w-[17px]' />
                  <p className='text-lg'>Your Image History</p>
                </div>
              </div>
              <div className='max-w-[1060px] w-full'>
                <div className='flex justify-between'>
                  <div className='flex gap-3'>
                    <div className='cursor-pointer rounded-full flex gap-3 bg-[#f0f0ff] text-[#5555e8] 
                    items-center h-[42px] px-7' onClick={() => setImageHistoryType('single')}>
                      <FontAwesomeIcon icon={faImage} style={{ color: '#5555e8' }} />
                      Single Images
                    </div>
                    <div className='cursor-pointer rounded-full flex gap-3 bg-[#5555e8] text-white 
                    items-center h-[42px] px-7'>
                      <FontAwesomeIcon icon={faImage} style={{ color: '#ffffff' }} />
                      Bulk Uploads
                    </div>
                  </div>
                  <div className='flex gap-6'>
                    <div className='flex items-center justify-between w-[200px] bg-white p-2.5 border border-[1px]
                     border-[#CBD5E1] rounded'>
                      <label htmlFor='oldest'>Show Oldest</label>
                      <input type='checkbox' id='oldest' />
                    </div>
                    <div className='bg-[#5555e8] px-[40px] flex items-center text-white rounded-full gap-3 
                    cursor-pointer' onClick={() => navigate('/panel')}>
                      <FontAwesomeIcon icon={faWandMagicSparkles} style={{ color: 'white' }} />
                      Generate Again
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full bg-white p-3 rounded-md mt-5'>
              <div className='flex items-center justify-between'>
                <div className='w-[320px] px-4 py-2 flex justify-between items-center border border-[1px]
                 border-[#cbd5e1] rounded-sm'>
                  <div>Select Period</div>
                  <FontAwesomeIcon icon={faCalendar} />
                </div>
                <div className='flex justify-around bg-[#f5f8fe] rounded-md max-w-[1060px] w-full'>
                  <div className='h-full cursor-pointer border-b border-b-[3px] border-[#5555E8] text-[#5555e8]
                   px-9 py-2.5' onClick={() => setLibraryCategory('edited')}>All Collections</div>
                  <div className='h-full cursor-pointer px-9 py-2.5'
                    onClick={() => setLibraryCategory('favorites')}>Favorites</div>
                  <div className='h-full cursor-pointer px-9 py-2.5'
                    onClick={() => setLibraryCategory('deleted')}>Deleted Images</div>
                  {/* <div className={`h-full cursor-pointer ${libraryCategory === 'edited' && 'border-b
                   border-b-[3px] border-[#5555E8] text-[#5555e8]'} px-9 py-2.5`} 
                   onClick={() => setLibraryCategory('edited')}>All Collections</div>
                  <div className={`h-full cursor-pointer ${libraryCategory === 'favorites' && 'border-b 
                  border-b-[3px] border-[#5555E8] text-[#5555e8]'} px-9 py-2.5`} 
                  onClick={() => setLibraryCategory('favorites')}>Favorites</div>
                  <div className={`h-full cursor-pointer ${libraryCategory === 'deleted' && 'border-b 
                  border-b-[3px] border-[#5555E8] text-[#5555e8]'} px-9 py-2.5`} 
                  onClick={() => setLibraryCategory('deleted')}>Deleted Images</div> */}
                </div>
              </div>
            </div>
            <div className='grid grid-cols-1 gap-6 mt-4 mb-0 lg:grid-cols-3 sm:my-7 md:grid-cols-2'>
              {bulkImageHistory?.data?.history?.map((imgData, index) => (
                <div key={index} className='bg-white p-6 px-2 cursor-pointer' onClick={() => {
                  setSelectedBulkCollection(imgData);
                  setSelectedImage(imgData?.products[0].pid);
                  setIsBulkCollectionOpen(true);
                }}>
                  {(imgData.jobs.length && (imgData.jobs[0].completed !== imgData.collection_size)) ?
                    <div className='flex justify-center gap-4'>
                      <img src={loadingGif} className='w-[40px] h-[40px] mt-2' />
                      <div>
                        <div className='flex flex-col items-end'>
                          <div className='text-sm text-[#6a6aeb]'>
                            {imgData.jobs[0].completed}/{imgData.collection_size} Completed</div>
                          <div className='relative w-[250px] h-[10px] rounded-full bg-[#f5f8fe] mb-2'>
                            <div className={`absolute top-0 left-0
                             w-[${(parseInt(imgData.jobs[0].completed) / parseInt(imgData.collection_size)) * 100}%] 
                             h-full rounded-full bg-[#5555e8]`}></div>
                          </div>
                        </div>
                        <div className='text-center text-[#757D89]'>Generating Magic</div>
                      </div>
                    </div>
                    :
                    <div className='flex gap-2 justify-center'>
                      {imgData?.products?.slice(0, 5).map(eachProduct => (
                        <>
                          <img src={eachProduct.input_image_url} className='h-[80px] w-[80px] contain' />
                        </>
                      ))}
                    </div>
                  }
                  <div className='flex justify-between px-2 mt-4'>
                    <div>
                      <div className='flex gap-2 items-center mb-1'>
                        <FontAwesomeIcon icon={faPencil} color='#5555e8' />
                        <div className='text-[17px]'>
                          {imgData.collection_name}
                        </div>
                      </div>
                      <div className='text-sm'>
                        Created at : <span className='text-[#5555e8]'>{getDateString(imgData.time.split(' ')[0])}</span>
                      </div>
                    </div>
                    <div className='flex items-center gap-4'>
                      <div className='cursor-pointer bg-[#f5f8fe] rounded-full p-2 px-3'>
                        <FontAwesomeIcon fontSize={18} icon={faHeart} />
                      </div>
                      <div className='cursor-pointer relative bg-[#f5f8fe] rounded-full p-2 px-3 image-history-info'>
                        <FontAwesomeIcon fontSize={18} icon={faCircleInfo} />

                        <div className='created-at hidden absolute bottom-[54px] text-center -right-3 w-[200px] 
                        p-1 rounded-md bg-white shadow'>
                          Created at : <span className='text-[#5555E8]'>
                            {getDateString(imgData.time.split(' ')[0])}</span>
                        </div>
                      </div>
                      <div className='cursor-pointer bg-[#f5f8fe] rounded-full p-2 px-3'>
                        <FontAwesomeIcon fontSize={18} icon={faTrashCan} color='red' />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
      </>
      {/* <div className='flex justify-center gap-2'>
        <AppButton onClick={onClickLastPage} extendClass='w-60' variant='primary' 
        disabled={pageNumber * pageSize === 0}>
          Previous Page
        </AppButton>
        <AppButton
          onClick={onClickNextPage}
          extendClass='w-60'
          variant='primary'
          disabled={pageNumber * pageSize > imageHistory?.data?.total_records}
        >
          Next Page
        </AppButton>
      </div> */}
    </div>
  );
}

export default ImageHistory;
