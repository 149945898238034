import React, { useState } from 'react';
import BackgroundSelection from '../BackgroundSelection';
import FooterSection from '../FooterLayout';

const ImageGenerator = () => {
  const [selectedTheme, setSelectedTheme] = useState();
  const [prompt, setPrompt] = useState({ theme: '', prompt: '' });
  const [selectedImageStyle, setSelectedImageStyle] = useState();

  const handleGenerateBtn = () => {
    return {
      prompt: prompt,
      selectedImageStyle: selectedImageStyle,
      isImgGenerator: true
    };
  };

  return (
    <>
      <div className='p-[20px] pb-[56px]'>
        <BackgroundSelection 
          isImageGenerator={true}
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
          setPrompt={setPrompt}
          selectedImageStyle={selectedImageStyle}
          setSelectedImageStyle={setSelectedImageStyle}
        />
      </div>
      <FooterSection
        generateBtnData={handleGenerateBtn}
        isGenerateBtnDisabled={!prompt?.prompt}
      />
    </>
  );
};

export default ImageGenerator;