/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import rightArrow from 'assets/icons/rightArrow.svg';
import { AppButton } from 'components/shared/AppButton';
import cn from 'classnames';
import { ClipLoader } from 'react-spinners';

const renderResizeImageOptionComp = (props) => {
  const [isUpscaleVisible, setIsUpscaleVisible] = useState(false);
  const {
    fetchImageSizeLoader,
    selectedOptSize,
    handleUpscaleImage,
    generateImageUpscale,
    imageResizeList,
    setSelectedOptSize,
    setSelectedResizedImage,
    generateResizeImage,
    resizeImageGenLoading,
    resizeImageUpscaleLoading
  } = props;

  useEffect(() => {
    if (selectedOptSize?.title === 'Default/Original') {
      setIsUpscaleVisible(true);
    } else setIsUpscaleVisible(false);
  }, [selectedOptSize]);

  const handleSelectOpt = (item) => {
    if (!(resizeImageGenLoading || resizeImageUpscaleLoading)) {
      setSelectedOptSize(item);
      setSelectedResizedImage();
    }
  };

  const handleUpscaleImageOpt = () => {
    if (selectedOptSize?.title === 'Default/Original') {
      handleUpscaleImage();
      generateImageUpscale();
    }
  };

  return (
    <>
      <div className='text-[30px] font-jost font-[400] border-b mt-[48px] pb-[12.5px]'>
        Resize
      </div>
      {fetchImageSizeLoader ? (
        <div className='flex items-center justify-center w-full h-full'>
          <ClipLoader color='#757D89' />
        </div>
      ) : (
        <div>
          {/*Added Upscale_Image_Tab as Id for all the related elements for goggle event trigger */}
          <div
            className={cn('py-[9px] my-[20px] flex items-center justify-start gap-[40px]', (resizeImageUpscaleLoading || !isUpscaleVisible) ? 'opacity-60 cursor-not-allowed' : 'cursor-pointer')}
            role='presentation'
            disabled={resizeImageUpscaleLoading || !isUpscaleVisible}
            id='Upscale_Image_Tab'
            onClick={() => !resizeImageUpscaleLoading && handleUpscaleImageOpt()}
          >
            <div className="flex items-center">
              <div
                className='text-[20px] font-[600] mr-[5px]'
                id='Upscale_Image_Tab'
              >
                Upscale image
              </div>
              <div id='Upscale_Image_Tab'>
                <span className='text-[16px] font-[400]' id='Upscale_Image_Tab'>
                  (2048x2048)
                </span>
              </div>
            </div>

            <div className='col-span-1' id='Upscale_Image_Tab'>
              <span id='Upscale_Image_Tab'>
                <img src={rightArrow} width={8} height={15} id='Upscale_Image_Tab' />
              </span>
            </div>
          </div>
          <div className='text-[20px] font-[600] py-[10px]'>
            Select Canvas Size
          </div>
          <div className='mt-[5px] mb-[31px]'>
            {imageResizeList?.map((item, index) => (
              <div
                role='presentation'
                onClick={() => { handleSelectOpt(item); }}
                className={cn('grid grid-cols-12 flex items-center cursor-pointer py-[9px] px-[20px] my-[5px]',
                  selectedOptSize?.title === item?.title && 'bg-[#F0F0F0] rounded')}
                key={index}
              >
                <div
                  className='col-span-6 flex justify-start text-[16px] font-[400] text-[#000000]'
                >
                  {item?.title}
                </div>
                <div
                  className='col-span-6 flex justify-end text-[16px] font-[400] text-[#7D7D7D]'
                >
                  {item.info}
                </div>
              </div>
            ))}
          </div>
          <div className='flex justify-end'>
            <AppButton
              type='button'
              variant='black'
              id='Resize_Gen_Btn'
              onClick={() => generateResizeImage()}
              isLoading={resizeImageGenLoading || resizeImageUpscaleLoading}
              disabled={fetchImageSizeLoader || selectedOptSize?.title === 'Default/Original' || !selectedOptSize}
              extendClass='bg-black hover:bg-black rounded text-[16px] font-[400] h-[48px] w-[180px] !py-[2px] !px-[20px] ds3:mt-[80px] mt-[60px]'
            >
              Generate
            </AppButton>
          </div>
        </div>
      )}
    </>
  );
};

export default renderResizeImageOptionComp;