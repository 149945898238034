import React, { useEffect, useRef, useState } from 'react';
import styles from 'styles/Home.module.css';
// import { toast } from 'react-toastify';
import EditableImage from 'components/EditableImage';
// import { useNavigate } from 'react-router-dom';
import Results from './Results';
// import Loader from 'components/Loader';
import { uploadBulkImgToRemoveBg, uploadImgToRemoveBg } from 'utils/fileHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Icon from '@mdi/react';
// import { mdiCloseCircle } from '@mdi/js';
import { ClipLoader } from 'react-spinners';
import { faArrowUpFromBracket, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import ReplaceVector from '../assets/img/Replace.png';
// import axios from 'axios';
import CollectionNameModal from './CollectionNameModal';

export async function createFileFromImgUrl(imgUrl, setName) {
  // needs bypassing to avoid cors
  // let url = `https://cors-anywhere.herokuapp.com/${imgUrl}`;
  let url = imgUrl;
  let response = await fetch(url ,
    {
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' }
    });

  let data = await response.blob();
  let metadata = {
    type: 'image/png'
  };
  let file = new File([data], setName || 'filename.png', metadata);
  return file;
}
const ImageUploader = ({
  // imageForEnlargeViewMode,
  setImageForEnlargeViewMode,
  // setImageEnlargeViewOption,
  // imageEnlargeViewOption,
  canvasStageRef,
  canvasTransformerRef,
  // input,
  // image,
  setImage,
  imageForView,
  setImageForView,
  // prompt,
  setPrompt,
  // negative_prompt,
  setNegative_prompt,
  loading,
  setLoading,
  // imageResponse,
  setImageResponse,
  // socialContent,
  setSocialContent,
  // loadingBg,
  // setLoadingBg,
  // shadowOption,
  // setShadowOption,
  // lightingOption,
  // setLightingOption,
  // productDescription,
  setProductDescription,
  // pid,
  setPid,
  inputFileRef,
  setProductLibrary,
  setSelectedImage,
  // super_resolution,
  // setSuper_resolution,
  setResultsLibrary,
  canvasWidth,
  // screen,
  bulkImagesBgRemoved,
  setBulkImagesBgRemoved,
  setIsBulkEnabled,
  setCid
}) => {
  // const navigate = useNavigate();
  const doMock = false;
  const [collectionName, setCollectionName] = useState('');
  const [isCollectionNameModalOpen, setIsCollectionNameModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // const dragOverHandler = e => {
  //   e.preventDefault();
  // };

  const onFileChange = e => {
    e.preventDefault();
    resetData();
    setBulkImagesBgRemoved([]);
    setResultsLibrary([null, null, null, null]);
    if (e.target.files) {
      if (e.target.files.length > 1) {
        setLoading(true);
        setUploadedFiles(Object.values(e.target.files));
        setIsCollectionNameModalOpen(true);
        return;
      }
      const imgUrl = URL.createObjectURL(e.target.files[0]);
      setLoading(true);
      uploadImgToRemoveBg(e.target.files[0], doMock)
        .then(async res => {
          if (res === 'expired') {
            return;
          }
          const fileUrl = res.data?.remove_background_url;
          const file = await createFileFromImgUrl(
            fileUrl
            // `${Math.random() * 10000}`
          );
          //console.log(res, file, 'and uri', fileUrl)
          setPid(res.data.pid);
          // setImage(file);
          // setImageForView(URL.createObjectURL(file));
          setProductLibrary(prev => {
            //console.log('RUDRA', { imgUrl: URL.createObjectURL(file), file, pid: res.data.pid }, prev)
            return [{ imgUrl: URL.createObjectURL(file), file, pid: res.data.pid }, ...prev.slice(0, 3)];
          });
          setSelectedImage(0);
        })
        .catch(err => {
          console.error(err);
          console.log('Oops, Something went wrong');
        })
        .finally(() => {
          setLoading(false);
        });
      setImageForView(imgUrl);
    } else if (e.dataTransfer && e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...e.dataTransfer.items].forEach((item) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile();

          // const imgUrl = URL.createObjectURL(file);
          setLoading(true);

          // setImage(file);
          // setImageForView(imgUrl);
          setProductLibrary(prev => [{ imgUrl: URL.createObjectURL(file), file }, ...prev.slice(0, 3)]);
          setSelectedImage(0);

          uploadImgToRemoveBg(file)
            .then(async res => {
              const fileUrl = res.data?.remove_background_url;
              const file = await createFileFromImgUrl(fileUrl);
              setPid(res.data.pid);

              // setImage(file);
              // setImageForView(URL.createObjectURL(file));
              setProductLibrary(prev => [{ imgUrl: URL.createObjectURL(file), file }, ...prev.slice(0, 3)]);
              setSelectedImage(0);
            })
            .catch(() => {
              //console.log(err)
              console.log('Oops, Something went wrong');
            })
            .finally(() => {
              setLoading(false);
            });
        }
      });
    } else if (e.dataTransfer && e.dataTransfer.files) {
      // Use DataTransfer interface to access the file(s)
      [...e.dataTransfer.files].forEach((file) => {
        // setImage(file);
        // setImageForView(URL.createObjectURL(file));
        setProductLibrary(prev => [{ imgUrl: URL.createObjectURL(file), file }, ...prev.slice(0, 3)]);
        setSelectedImage(0);
      });
    }
  };

  // const generateImage = async () => {
  //   if (!prompt) {
  //     return alert('Please enter a prompt');
  //   }

  //   canvasTransformerRef?.current?.nodes([]);

  //   let imageData = canvasStageRef?.current?.toDataURL();
  //   if (!imageData) return;
  //   let file = dataURLtoFile(imageData, image.name);
  //   setLoading(true);

  //   const newImage = file;

  //   if (newImage && newImage.name && prompt) {
  //     setImageResponse(null);
  //     setImageForEnlargeViewMode(null);
  //     getSocialMediaContent(setSocialContent);
  //   }
  //   setLoading(false);
  // };
  //console.log('DEBUG', imageForView)
  function resetData() {
    setImageForView(null);
    setPid(null);
    setImage(null);
    setProductDescription('');
    setImageResponse(null);
    setPrompt('');
    setNegative_prompt('');
    setSocialContent(null);
    setImageForEnlargeViewMode(null);
    setLoading(false);
    setSelectedImage(null);
  }

  useEffect(() => {
    if (!isCollectionNameModalOpen && uploadedFiles.length) {
      uploadBulkImgToRemoveBg(uploadedFiles, collectionName)
        .then(async res => {
          if (res === 'expired') {
            return;
          }
          // console.log({cid: res.data.cid})
          setCid(res.data.cid);
          setLoading(false);
          let allBgRemovedImages = [];
          for (let i = 0; i < res?.data?.products?.length; i++) {
            allBgRemovedImages.push(res?.data?.products[i].input_image_url);
          }
          setBulkImagesBgRemoved(allBgRemovedImages);
          setIsBulkEnabled(true);
          // console.log('yeaaaaaaaaaaaahhhhhhhh', res.data)
        });
    }
  }, [isCollectionNameModalOpen]);

  return (
    <div className='w-full' style={{}}>
      <CollectionNameModal
        modalOpen={isCollectionNameModalOpen}
        setModalOpen={setIsCollectionNameModalOpen}
        collectionName={collectionName}
        setCollectionName={setCollectionName}
      />
      {loading && (
        //   <div className='spinner-border' role='status'>
        //   <span className='sr-only'>Loading...</span>
        // </div>
        <div className='flex flex-col items-center justify-center mt-6 spinner-border'>
          <div className='text-[17px] font-semibold mb-2'>Removing Background...</div>
          <ClipLoader color='#757D89' />
        </div>
      )}
      {(!loading && bulkImagesBgRemoved.length) ?
        <>
          <div className='flex absolute top-8 gap-2 items-center 2xl:-mt-[45px] justify-center bg-[#f0f0ff] 
          rounded-full w-[300px] xl-mid:w-[400px] 2xl:w-[512px] py-2 mb-4 cursor-pointer text-[#5555E8]'
            onClick={() => {
              resetData();
              setIsBulkEnabled();
              setBulkImagesBgRemoved([]);
            }}
          >
            <img src={ReplaceVector} width={20} height={20} />
            Replace Image
          </div>
          <div className='w-full h-full flex gap-4 justify-center items-center flex-wrap'>
            {bulkImagesBgRemoved?.slice(0, 3).map((imgSrc, index) => (
              <img key={index} src={imgSrc} className='w-[90px] h-[90px] contain' />
            ))}
            {bulkImagesBgRemoved.length > 3 && (
              '+' + (bulkImagesBgRemoved.length - 3) + ' Images'
            )}
          </div>
          <div className='text-center mt-4 text-[20px] font-semibold'>
            <span className='text-[#5555E8]'>{bulkImagesBgRemoved.length} Images</span> Uploaded
          </div>
        </>
        :
        (!loading && imageForView) ? (
          <>
            <div className='flex gap-2 items-center 2xl:-mt-[45px] justify-center bg-[#f0f0ff] 
            rounded-full w-[300px] xl-mid:w-[400px] 2xl:w-[512px] py-2 mb-4 cursor-pointer text-[#5555E8]'
              onClick={() => {
                resetData();
                setResultsLibrary([null, null, null, null]);
              }}
            >
              <img src={ReplaceVector} width={20} height={20} />
              Replace Image
            </div>
            <div
              className='relative w-full rounded-md main-image-container '
              style={{ marginTop: '0px', zIndex: '0', marginLeft: '0px' }}
            >
              <EditableImage
                stageRef={canvasStageRef}
                canvasTransformerRef={canvasTransformerRef}
                width={canvasWidth}
                height={canvasWidth}
                imgSrc={imageForView}
              />
            </div>
          </>
        ) : (
          <div
            onDrop={onFileChange}
            className={`mt-2 flex bg-[#f5f8fe] w-full h-[300px] xl-mid:w-[400px] xl-mid:h-[400px] 2xl:h-[512px] 
            2xl:w-[512px] flex-col items-center cursor-pointer align-items-middle ${loading ? 'hidden' : ''}`}
            // style={{ height: '512px' }}
            onClick={() => inputFileRef.current.click()}
          >
            <div className='flex flex-col justify-center items-center h-full'>
              <div className='flex flex-col justify-center items-center px-4 md-mid:px-[60px] py-[50px] mx-4 
              xl-mid:mx-0 border border-[#5555e8] border-dashed rounded-md'>
                <button className='bg-[#5555e8] w-[50px] h-[50px] rounded-full mb-4'>
                  <FontAwesomeIcon icon={faArrowUpFromBracket} className='text-white text-[25px]' />
                </button>
                <p className='text-center hidden md-mid:block'>
                  <span className='text-[#5555e8] underline inline-block'>Choose files to upload</span>
                </p>
                <p className='text-center md-mid:hidden mx-8'>Please upload your file</p>
                <button className='bg-[#5555e8] h-[44px] text-white px-10 rounded-full mt-4'>Upload File</button>
              </div>
            </div>
            <input
              type='file'
              onChange={onFileChange}
              ref={inputFileRef}
              name='files'
              id='file'
              className='hidden'
              accept='image/*'
              multiple='multiple'
            />
          </div>
        )}
    </div>
  );
};

const ImageEditor = ({
  title,
  images,
  isUploader,
  // image,
  // imageForView,
  setImage,
  setImageForView,
  setProductLibrary,
  selectedImage,
  setSelectedImage,
  pid,
  setPid,
  setSuper_resolution,
  super_resolution,
  canvasStageRef,
  canvasTransformerRef,
  startupLoading,
  setResultsLibrary,
  canvasWidth,
  socialContent,
  setSocialContent,
  generateLoading,
  setGenerateLoading,
  themePrompt,
  screen,
  themes,
  setPromptText,
  bulkImagesBgRemoved,
  setBulkImagesBgRemoved,
  setIsBulkEnabled,
  isBulkEnabled,
  setCid
}) => {
  const [imageForEnlargeViewMode, setImageForEnlargeViewMode] = useState(null);
  const [imageEnlargeViewOption, setImageEnlargeViewOption] = useState('preview');

  const inputFileRef = useRef(null);
  const [prompt, setPrompt] = useState('');
  const [negative_prompt, setNegative_prompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageResponse, setImageResponse] = useState(null);
  const [loadingBg, setLoadingBg] = useState(false);
  const [shadowOption, setShadowOption] = useState('');
  const [lightingOption, setLightingOption] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [previewOption, setPreviewOption] = useState('preview');
  const [resizedImages, setResizedImages] = useState({});
  function resetData() {
    setImageForView(null);
    setPid(null);
    setImage(null);
    setProductDescription('');
    setImageResponse(null);
    setPrompt('');
    setNegative_prompt('');
    setSocialContent(null);
    setImageForEnlargeViewMode(null);
    setLoading(false);
    setSelectedImage(null);
  }

  useEffect(() => {
    //console.log({themes})
    if (themes.length)
      setPromptText(themes[0].prompt);
  }, [themes]);

  return (
    <div
      className={`${isUploader && 'shrinker'}  relative flex flex-col 2xl:items-center max-w-full 2xl:w-[460px] 
      min-h-[400px] 2xl:min-w-[512px] 2xl:min-h-[512px] ${isUploader ? (screen === 'result' ?
          'hidden md-mid:flex' : '') : (screen === 'upload' ? 'hidden md-mid:flex' : '')}`}
    // className='relative flex flex-col 2xl:items-center max-w-full w-[335px] md-mid:w-[364px] xl-mid:min-w-[464px] 
    // 2xl:w-[460px] min-h-[400px] 2xl:min-w-[512px] 2xl:min-h-[512px]'
    // style={{
    //   minWidth: '512px',
    //   minHeight: '512px'
    // }}
    >
      <div className='flex gap-[16px]'>

      </div>
      <div
        // eslint-disable-next-line max-len
        className={`flex flex-col justify-center relative  ${isUploader ? 'rounded-l-xl bg-white py-8 px-4 md-mid:p-8 2xl:-left-[34px] h-[310px] xl-mid:min-h-[505px] min-h-[380px] md-mid:min-h-[400px] 2xl:min-h-[612px]' : 'rounded-r-xl 2xl:bg-white 2xl:w-[580px] 2xl:py-2'} 2xl:min-h-[700px] border-0 min-w-[330px] 2xl:min-w-[512px] `}
        style={{
          // backgroundColor: '#fff',
          // minWidth: '512px',
          // minHeight: '612px'
        }}
      >
        {isUploader ? (
          <>
            {(selectedImage === null && !loading && !bulkImagesBgRemoved.length)
              && <h4 className='mb-3 mt-0 md-mid:mt-6 2xl:-mt-10'>Upload multiple images to generate magic in one go
              </h4>}

            <ImageUploader
              canvasStageRef={canvasStageRef}
              canvasTransformerRef={canvasTransformerRef}
              image={images[selectedImage]?.file}
              imageEnlargeViewOption={imageEnlargeViewOption}
              imageForEnlargeViewMode={imageForEnlargeViewMode}
              imageForView={images[selectedImage]?.imgUrl}
              imageResponse={imageResponse}
              inputFileRef={inputFileRef}
              lightingOption={lightingOption}
              loading={loading}
              loadingBg={loadingBg}
              negative_prompt={negative_prompt}
              pid={pid}
              productDescription={productDescription}
              prompt={prompt}
              setProductLibrary={setProductLibrary}
              setImage={setImage}
              setImageEnlargeViewOption={setImageEnlargeViewOption}
              setImageForView={setImageForView}
              setImageResponse={setImageResponse}
              setLightingOption={setLightingOption}
              setLoading={setLoading}
              setLoadingBg={setLoadingBg}
              setNegative_prompt={setNegative_prompt}
              setPid={setPid}
              setProductDescription={setProductDescription}
              setPrompt={setPrompt}
              setShadowOption={setShadowOption}
              setSocialContent={setSocialContent}
              shadowOption={shadowOption}
              setImageForEnlargeViewMode={setImageForEnlargeViewMode}
              socialContent={socialContent}
              setSelectedImage={setSelectedImage}
              setSuper_resolution={setSuper_resolution}
              super_resolution={super_resolution}
              setResultsLibrary={setResultsLibrary}
              canvasWidth={canvasWidth}
              screen
              bulkImagesBgRemoved={bulkImagesBgRemoved}
              setBulkImagesBgRemoved={setBulkImagesBgRemoved}
              setIsBulkEnabled={setIsBulkEnabled}
              isBulkEnabled={isBulkEnabled}
              setCid={setCid}
            />
          </>
        ) : (
          <>
            <Results
              canvasStageRef={canvasStageRef}
              canvasTransformerRef={canvasTransformerRef}
              state={{
                imageResponse: images.map(img => img?.imgUrl),
                setImageForEnlargeViewMode,
                socialContent,
                imageEnlargeViewOption,
                setImageEnlargeViewOption,
                imageForEnlargeViewMode: images[selectedImage]?.imgUrl,
                pid,
                generateLoading,
                setGenerateLoading,
                themePrompt,
                previewOption,
                setPreviewOption,
                resizedImages,
                setResizedImages,
                screen
              }}
            />
          </>
        )}
      </div>
      <div className=''></div>
      <div className={`flex flex-col 
      ${isUploader ? 'w-[382px] xl-mid:w-[464px]' : 'w-[360px] md-mid:w-[480px] xl-mid:w-[464px]'} max-w-full`}>
        <div className={'mb-2 bg-white mt-3 p-5 rounded-xl w-fit-content'}>
          <h1 style={{ height: '36px', textAlign: 'left', fontSize: '20px', fontWeight: 400 }}
            className={styles['font-family-poppins']}>
            {' '}
            {title}
          </h1>
          <div style={{}} className='flex flex-row gap-2 my-2 max-w-full overflow-auto'>
            {startupLoading && (
              <div className='spinner-border' role='status'>
                <ClipLoader color='#757D89' />
              </div>
            )}
            {!startupLoading &&
              images?.map((img, i) =>
                !img ? (
                  <div className={''} key={i}>
                    <div
                      className={`${styles.imageHolderContainer} 
                      ${isUploader ? 'w-[80px] xl-mid:w-[100px]' : 'w-[100px]'} bg-[#f5f8fe] flex items-center 
                      justify-center`}
                      style={{
                        boxShadow: '',
                        height: '100px'
                      }}
                    >
                      <FontAwesomeIcon icon={faWandMagicSparkles} className='text-[#5555e8] text-lg' />
                    </div>
                  </div>
                ) : (
                  <div className={''} key={i}>
                    <div
                      className={`${styles.imageHolderContainer} ${isUploader ? 'w-[80px] xl-mid:w-[100px]'
                        : 'w-[100px]'} cursor-pointer flex items-center`}
                      style={{
                        border: selectedImage === i ? '3px solid rgb(104, 75, 255)' : '',
                        height: '100px'
                        // width: '100px'
                      }}
                      onClick={() => {
                        //console.log('kiret',i);
                        if (isUploader) {
                          resetData();
                          setResultsLibrary([null, null, null, null]);
                          setPid(img.pid);
                        }
                        !isUploader && setPreviewOption('preview');
                        setSelectedImage(i);
                      }}
                    >
                      {/* {i === images.length - 1 && isUploader ? (
                        <Link to='/'>
                          <div
                            className={`${styles.imageHolderContainer} pt-4`}
                            style={{
                              boxShadow: '',
                              backgroundColor: '#F5F5F5',
                              color: 'black',
                              marginTop: 'auto',
                            }}
                          >
                            <p style={{ marginTop: '14.5px' }}>View all...</p>
                          </div>
                        </Link>
                      ) : (
                        <img
                          src={img.imgUrl}
                          alt={i}
                          style={{
                            zIndex: '0',
                            maxHeight: '100%',
                            width: 'auto',
                            overflow: 'hidden',
                            margin: 'auto',
                            borderRadius: '0.7rem',
                          }}
                        />
                      )} */}
                      <img
                        src={img.imgUrl}
                        alt={i}
                        style={{
                          zIndex: '0',
                          maxHeight: '100%',
                          width: 'auto',
                          overflow: 'hidden',
                          margin: 'auto',
                          borderRadius: '0.7rem'
                        }}
                      />
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
      <div className={`${(screen === 'upload' && isUploader) ? 'block md-mid:hidden' : 'hidden'} mb-[60px]`}>
        <p>Choose a theme :</p>
        <select onChange={(e) => setPromptText(e.target.value)} className='w-full rounded'>
          {themes?.map((theme, i) => (
            <option key={i} value={theme.prompt}>{theme.theme}</option>
          ))}
        </select>
      </div>
    </div>
  );
};
export default ImageEditor;
