/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import TextField from 'components/commonFields/TextFieldFormik';
import TextArea from 'components/commonFields/TextAreaFormik';
import Accordion from 'components/commonFields/Accordion';
import ReactSelect from 'components/ReactSelectFormik';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
// import { toast } from 'react-toastify';
import SocialMediaHeader from '../SocialMediaHeader';
import FooterSection from '../FooterLayout';
import _ from 'lodash';
import * as Yup from 'yup';
import { handleTokenExpiryIssue } from 'utils/functions.util';
import { BASE_URL } from 'utils/baseURL';

const TextGeneration = () => {
  const [templateData, setTemplateData] = useState([]);
  const [validateForm, setValidateForm] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [generateCaptionLoading, setGenerateCaptionLoading] = useState(false);
  const [captionList, setCaptionList] = useState();
  const [submitData, setSubmitData] = useState();
  const [templateValue, setTemplateValue] = useState();
  const selectedGenImg = JSON.parse(localStorage.getItem('selectedImage'));
  const selectedTemp = JSON.parse(localStorage.getItem('selectedTemp'));
  const navigate = useNavigate();
  const formRef = useRef();
  const path = window.location.pathname;

  useEffect(() => {
    if (selectedTemp && !templateValue) {
      setTemplateValue(selectedTemp?.t_name);
    }
  }, []);

  const getFormDataByTemplate = async () => {
    const data = new FormData();
    data.append('template', templateValue);
    const url = BASE_URL + 'image-gen/templates';

    try {
      const template = await axios.post(url, data, {
        headers: {
          token: LocalStorage.getItem()
        }
      });
      setTemplateData(template?.data?.data?.fields);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (templateValue) {
      getFormDataByTemplate();
    }
  }, [templateValue]);

  useEffect(() => {
    setTimeout(() => { setLoadingForm(false); }, 1000);
    setLoadingForm(true);
  }, []);

  // To segregate the required field from the backend response
  const handleValidationData = (listData) => {
    let tempData = [];
    listData?.map((item) => {
      if (item.type === 'accordion') {
        tempData.push(...tempData, item.fields);
      } else tempData.push(...tempData, item);
      return tempData;
    });
    const uniqueSet = new Set(tempData.flat());
    const uniqueArray = Array.from(uniqueSet).map(item => item);
    const valideData = uniqueArray.filter((item) => item.required && item);
    setValidateForm(valideData);
  };

  useEffect(() => {
    if (templateData) {
      handleValidationData(templateData);
    }
  }, [templateData]);

  // Func to render the each field validation based on required field value
  const handleEachFieldValidation = (item) => {
    const withRequiredField = ['dropDown', 'dropDownWithArrow'].includes(item.type) ?
      Yup.object().required('Required field').nullable() : (
        (item.type === 'textField' && item.fieldType === 'number') ?
          Yup.number().min(0).integer().required('Required field').nullable() :
          Yup.string().required('Required field').nullable()
      );
    const withoutRequiredField = item.type === ['dropDown', 'dropDownWithArrow'].includes(item.type) ?
      Yup.object().nullable() : ((item.type === 'textField' && item.fieldType === 'number') ?
        Yup.number().min(0).integer().nullable() : Yup.string().nullable()
      );
    return item?.required ? withRequiredField : withoutRequiredField;
  };

  // To construct the validationSchema for the required fields
  const handleValidateFormData = (list) => {
    const validateSchema = Yup.object().shape(
      Object.fromEntries(list.map((field) => [field.name, handleEachFieldValidation(field)]))
    );
    return validateSchema;
  };

  useEffect(() => {
    if (validateForm?.length > 0) {
      handleValidateFormData(validateForm);
    }
  }, [validateForm]);

  // To constuct the dropdown options from the backend values
  const handleOptions = (optList) => {
    const dropDownOptions = optList?.map((item) => (
      {
        label: _.upperFirst(item),
        value: item
      }
    ));
    return dropDownOptions;
  };

  // To construct the form data based on the backend response
  const handleSelectForm = (data) => {
    switch (data?.type) {
      case 'textField':
        return (
          <TextField
            label={data?.label}
            name={data?.name}
            type='text'
            required={data?.required || ''}
            placeholder={data?.placeholder}
          />
        );
      case 'textArea':
        return (
          <TextArea
            label={data?.label}
            name={data?.name}
            placeholder={data?.placeholder}
            required={data?.required || ''}
          />
        );
      case 'accordion':
        return (
          <Accordion
            data={handleDynamicForm(data?.fields)}
            label={data.label}
          />
        );
      case 'dropDown':
        return (
          <ReactSelect
            options={handleOptions(data?.value) || []}
            placeholder={data?.placeholder}
            label={data?.label}
            name={data?.name}
            required={data?.required || ''}
            noDropDownIndicator
          />
        );
      case 'dropDownWithArrow':
        return (
          <ReactSelect
            options={handleOptions(data?.value) || []}
            placeholder={data?.placeholder}
            label={data?.label}
            name={data?.name}
            required={data?.required || ''}
          />
        );
      default: break;
    }
  };

  // To call the form construct function
  const handleDynamicForm = (listData) => {
    const tempFormData = listData?.map((item) => (
      handleSelectForm(item)
    ));
    return tempFormData;
  };

  // to transfer the form data on submitting
  const handleSubmit = (data) => {
    // console.log('data', data);
    const dataToSend = Object.keys(data).reduce((a, v) => (
      { ...a, [v]: typeof (data[v]) === 'object' ? data[v].value : data[v] }
    ), {});
    setSubmitData(dataToSend);
  };

  const generateCaption = async (dataToSend) => {
    setGenerateCaptionLoading(true);
    try {
      const url = BASE_URL + 'image-gen/generate_captions';
      const dataValue = {
        'social_platform': templateValue?.name,
        'variations': 4,
        'pid': (selectedGenImg?.pid),
        'gid': (selectedGenImg?.gid)
      };
      const sendingData = { ...dataValue, ...dataToSend };
      const res = await axios.post(url, sendingData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: LocalStorage.getItem()
        }
      });
      setCaptionList(res?.data?.data);
    } catch (err) {
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      } else {
        console.log('Something went wrong');
      }
    } finally {
      setGenerateCaptionLoading(false);
    }
  };

  useEffect(() => {
    if (submitData) {
      generateCaption(submitData);
    }
  }, [submitData]);

  useEffect(() => {
    if (captionList) {
      localStorage.setItem('captionList', JSON.stringify(captionList.output));
      if (path.includes('InstagramPost')) {
        navigate('/mobile/InstagramPost/Text/Output');
      } else if (path.includes('FacebookPost')) {
        navigate('/mobile/FacebookPost/Text/Output');
      }
    }
  }, [captionList]);

  const handleGenerateBtn = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <>
      <SocialMediaHeader socialMediaTab='text' />
      {(loadingForm || generateCaptionLoading) ? (
        <div className='flex flex-col items-center justify-center mt-6 spinner-border bg-white'
          style={{ height: '400px', width: '100%' }}>
          <ClipLoader color='#757D89' />
          {/* <div className='lg:text-[14px] xl:text-[16px] xl-mid:text-[18px] 4xl:text-[20px] font-semibold mb-2'>
            Loading...</div> */}
        </div>
      ) : (
        <>
          <div className='grid grid-cols gap-4 pt-[30px] px-[20px] mb-[56px]'>
            <Formik
              innerRef={formRef}
              initialValues={{}}
              validationSchema={validateForm && handleValidateFormData(validateForm)}
              onSubmit={(data) => handleSubmit(data)}
            >
              <Form>
                <div className='grid grid-cols gap-4'>
                  {templateData && handleDynamicForm(templateData)}
                </div>
              </Form>
            </Formik>
          </div>
          <FooterSection
            isGenerateBtnDisabled={generateCaptionLoading}
            isOutputBtnDisabled={!localStorage.getItem('captionList')}
            generateBtnData={handleGenerateBtn}
            generateCaption={true}
          />
        </>
      )}
    </>
  );
};

export default TextGeneration;