import React from 'react';
import rightArrow from 'assets/icons/rightArrow.svg';
import { AppButton } from 'components/shared/AppButton';
import cn from 'classnames';
import { ClipLoader } from 'react-spinners';

const renderResizeImageOptionComp = (props) => {
  const {
    fetchImageSizeLoader,
    selectedOptSize,
    handleUpscaleImage,
    generateImageUpscale,
    imageResizeList,
    setSelectedOptSize,
    setSelectedResizedImage,
    generateResizeImage,
    resizeImageGenLoading,
    resizeImageUpscaleLoading
  } = props;

  const handleSelectOpt = (item) => {
    if (!(resizeImageGenLoading || resizeImageUpscaleLoading)) {
      setSelectedOptSize(item);
      setSelectedResizedImage();
    }
  };

  return (
    <>
      {fetchImageSizeLoader ? (
        <div className='flex items-center justify-center w-full h-full'>
          <ClipLoader color='#757D89' />
        </div>
      ) : (<>
        <div className='pb-[55px]'>
          <div
            className={`grid grid-cols-12 pb-4 flex items-center justify-center 
              ${selectedOptSize?.title === 'Default/Original' ? 'cursor-pointer' : 'opacity-60'}`}
            role="presentation"
            onClick={() => {
              selectedOptSize?.title === 'Default/Original' && handleUpscaleImage();
              selectedOptSize?.title === 'Default/Original' && generateImageUpscale();
            }}
          >
            <div className='col-span-6'>
              <div className='text-[16px] sm:text-[16px] md:text-[18px] font-[600]'>
                Upscale image
              </div>
            </div>
            <div className='col-span-5'>
              <span className='text-[16px] sm:text-[16px] md:text-[18px] font-[400]'>
                (2048x2048)
              </span>
            </div>
            <div className='col-span-1'>
              <span>
                <img src={rightArrow} width={8} height={15} />
              </span>
            </div>
          </div>
          <div className='text-[16px] sm:text-[16px] md:text-[18px] font-[600] mb-[10px]'>
            Select Canvas Size
          </div>
          <div className='lg:p-2 xl:p-2'>
            {imageResizeList?.map((item, index) => (
              <div
                role='presentation'
                onClick={() => { handleSelectOpt(item); }}
                className={cn('grid grid-cols-12 flex items-center px-2 cursor-pointer',
                  selectedOptSize?.title === item?.title && 'bg-[#F0F0F0] rounded')}
                key={index}
              >
                <div className='col-span-5 flex justify-start py-[9px]
                text-[16px] sm:text-[16px] md:text-[18px] font-[400]'
                >
                  {item?.title}
                </div>
                <div
                  className='col-span-7 flex justify-end py-[9px]
                  text-[14px] sm:text-[14px] md:text-[16px] text-[#7D7D7D]'
                >
                  {item.info}
                </div>
              </div>
            ))}
          </div>

        </div>
        <div className='fixed bottom-0 bg-white w-[100%] flex justify-end py-2 pr-[40px]'>
          <AppButton
            type='button'
            variant='black'
            onClick={() => generateResizeImage()}
            isLoading={resizeImageGenLoading || resizeImageUpscaleLoading}
            disabled={fetchImageSizeLoader || selectedOptSize?.title === 'Default/Original' || !selectedOptSize}
            extendClass='bg-black hover:bg-black rounded text-[16px] font-[400] h-[40px] !py-[2px] !px-[20px]'
          >
            Generate
          </AppButton>
        </div>
      </>)}
    </>
  );
};

export default renderResizeImageOptionComp;