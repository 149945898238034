import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import RenderSmoothImage from 'components/ReactSmoothImageRendring';

const ImageGallery = ({
  imageData,
  handleImageSelect,
  loading
}) => {
  const RenderImage = ({ image, handleImageSelect }) => {
    return (
      <>
        <div
          className='flex items-center justify-center relative rounded shadow text-center'
          role="presentation"
          onClick={() => { handleImageSelect(image); } }
        >
          <div className='rounded shadow text-center h-full w-full'>
            <RenderSmoothImage
              src={image?.thumbnail || image}
              alt="loading..."
              objectFit="fill"
              // id={`image_library_${index}`}
              className='bg-[#F1F1F1] w-auto m-auto h-full'
              imageData={image}
              loadGif={loading}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='p-[20px] h-full w-full'>
      <div className='grid sm:grid-cols-2 md:grid-cols-3 grid-cols-4 
      grid-flow-row gap-2 items-center h-full max-w-full'>
        {imageData?.history?.map((image, index) => {
          return (
            <div key={index} className='col-span-1 group relative aspect-square'>
              <RenderImage
                image={image}
                handleImageSelect={handleImageSelect}
                index={index}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageGallery;