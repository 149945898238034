import React, { useEffect, useState } from 'react';
import PreviewIcon from '../../assets/img/PreviewIcon.png';
import PreviewIconSelect from '../../assets/img/PreviewIconActive.svg';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import TabButton from 'components/shared/TabButton';

const SocialMediaHeader = ({
  socialMediaTab,
  generateLoading
}) => {
  const [selectedTab, setSelectedTab] = useState();
  const [socialMedia, setSocialMedia] = useState();
  const [handleTabClick, setHandleTabClick] = useState(true);
  const path = window.location.pathname;
  const selectedImage = JSON.parse(localStorage.getItem('selectedImage'));
  const navigate = useNavigate();

  useEffect(() => {
    if (path.includes('/InstagramPost')) {
      setSocialMedia('InstagramPost');
    } else if(path.includes('/FacebookPost')) {
      setSocialMedia('FacebookPost');
    }
    if(socialMediaTab) {
      setHandleTabClick(false);
      localStorage.setItem('selectedSocialMediaTab', socialMediaTab);
      setSelectedTab(socialMediaTab);
    } else {
      localStorage.setItem('selectedSocialMediaTab', 'image');
      setSelectedTab('image');
    }
  }, [path]);

  const handleTabSelection = () => {
    let changedPath = '';
    switch(selectedTab) {
      case 'image':
        changedPath = 'Image';
        break;
      case 'text':
        if (localStorage.getItem('captionList')) {
          changedPath = 'Text/Output';
        } else {
          changedPath = 'Text';
        }
        break;
      case 'preview':
        changedPath = 'Preview';
        break;
    }
    localStorage.setItem('selectedSocialMediaTab', selectedTab);
    navigate(`/mobile/${socialMedia}/${changedPath}`);
  };

  useEffect(() => {
    if (selectedTab && handleTabClick) {
      handleTabSelection();
    }
  }, [selectedTab]);

  return (
    <div className='grid grid-cols-12 fixed w-full bg-white top-[65px] z-10'>
      <TabButton
        active={selectedTab === 'image'}
        onClick={() => {
          setHandleTabClick(true);
          setSelectedTab('image');
        }}
        className='text-[16px] font-jost font-[400] p-[10px] text-[#191919] col-span-4'
        type='button'
        style={{ padding: 0, text: 'nowrap' }}
      >
        Image
      </TabButton>
      <TabButton
        active={selectedTab === 'text'}
        onClick={() => {
          setHandleTabClick(true);
          setSelectedTab('text');
        }}
        type='button'
        className='text-[16px] font-jost font-[400] p-[10px] text-[#191919] col-span-4'
        disabled={!selectedImage || generateLoading}
      >
        Text
      </TabButton>
      <TabButton
        active={selectedTab === 'preview'}
        onClick={() => {
          setHandleTabClick(true);
          setSelectedTab('preview');
        }}
        type='button'
        className='text-[16px] font-jost font-[400] p-[10px] text-[#191919] flex items-center col-span-4 justify-center'
        disabled={!selectedImage || generateLoading}
        
      >
        <img src={selectedTab === 'preview' ? PreviewIconSelect : PreviewIcon} alt=''
          className={cn(selectedTab === 'preview' ? 'w-[24px] h-[24px]' : 'w-[19px] h-[11.4px]')} />
        <span className='ml-[5px]'>Preview</span>
      </TabButton>
    </div>
  );
};

export default SocialMediaHeader;