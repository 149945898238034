import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertModal from 'components/AlertModal';
import GoogleSearchAdForm from './GoogleSearchAdForm';
import CaptionAndHeadline from './CaptionAndHeadline';

const GoogleSearchAd = () => {
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState({ theme: '', prompt: '' });
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [generateCaptionLoading, setGenerateCaptionLoading] = useState(false);
  const [captionListData, setCaptionListData] = useState();

  const selectedTemp = JSON.parse(localStorage.getItem('selectedTemp'));

  const updatedCaptionListData = (captionList) => {
    setCaptionListData(captionList);
  };

  const ModalUpgradeCtaButton = () => {
    return (
      <button
        type='button'
        className='mt-3 inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold
         text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-primary-50 sm:mt-0 sm:w-auto text-white'
        onClick={() => {
          setAlertModalOpen(false);
          navigate('/plans');
        }}
      >
        Upgrade
      </button>
    );
  };

  return (
    <>
      <AlertModal modalOpen={alertModalOpen} setModalOpen={setAlertModalOpen}
        header={'Your photo credit limit is exhausted!'}
        body='Get 1000 photos and more advanced features starting at $9.99'
        additionalBtn={ModalUpgradeCtaButton}
      />
      <div className='flex font-jost'>
        <div className='w-[34.5%] h-[calc(100vh-99px)] relative m-[20px]'>
          <div className=''>
            <div className='text-[20px] font-[400] p-4 text-[#000]'>
              {selectedTemp?.subname ? selectedTemp?.name + ' ' + selectedTemp?.subname : selectedTemp?.name}
            </div>
          </div>
          <GoogleSearchAdForm
            setPrompt={setPrompt}
            prompt={prompt}
            isBulkUpload={false}
            selectedTemp={selectedTemp}
            generateCaptionLoading={generateCaptionLoading}
            setGenerateCaptionLoading={setGenerateCaptionLoading}
            updatedCaptionListData={updatedCaptionListData}
          />
        </div>
        <div className='w-[65%] h-[calc(100vh-58px)] border-l border-[#D9D9D9]'>
          <div>
            <CaptionAndHeadline
              captionListData={captionListData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleSearchAd;