import React, { useEffect, useState } from 'react';
import { AppButton } from 'components/shared/AppButton';
import SelectTheme from './SelectTheme';
import FileUploadAndDisplay from './FileUploadAndDisplay';
import { useNavigate } from 'react-router-dom';
import DynamicForm from 'components/DesktopComponents/DynamicForm';
import ToggleBtn from 'components/commonFields/ToggleBtnFormik';
import { Formik, Form } from 'formik';
import TabButton from 'components/shared/TabButton';

const ImageAndTextSection = ({
  selectedImage,
  handleSelectedImage,
  selectedTheme,
  setSelectedTheme,
  setPrompt,
  prompt,
  isBulkUpload,
  selectedImageStyle,
  setSelectedImageStyle,
  setSelectedImage,
  setImageForView,
  handleGenerateButtonClick,
  onlyImageTab,
  selectedTemp,
  clickedImage,
  updatedCaptionListData,
  selectedTabData,
  generateLoading,
  isImgGenerator,
  selectedGenImg,
  setIsImgGenerator,
  generateCaptionLoading,
  setGenerateCaptionLoading,
  setSelectedImageToGen,
  setGenerateLoading,
  imageGenerationLoading,
  selectedColor,
  setSelectedColor,
  projectID,
  documentID
}) => {
  const [selectedTab, setSelectedTab] = useState('images');
  const [isToggleSelected, setIsToggleSelected] = useState(true);
  const navigate = useNavigate();

  const handleBackToTemplates = () => {
    navigate('/Templates');
  };

  useEffect(() => {
    if (selectedTab) selectedTabData(selectedTab);
  }, [selectedTab]);

  const handleToggleChange = () => {
    setIsToggleSelected(!isToggleSelected);
    // resetting the selected value while switching the toggle btn
    setPrompt({ theme: '', prompt: '' });
    setSelectedTheme();
    setSelectedImageStyle();
  };

  useEffect(() => {
    if (!isToggleSelected) {
      setIsImgGenerator(true);
      setSelectedImage();
    } else {
      setIsImgGenerator(false);
    }
  }, [isToggleSelected]);

  // btnId for google analytics for each generate magic button
  const handleGenerateBtnId = () => {
    const currentUrl = window?.location?.pathname;
    let tempId = '';
    switch (currentUrl) {
      case '/ImageGenerator':
        tempId = 'ImageGenPage_clickGen';
        break;
      case '/PhotoStudio':
        tempId = 'PhotoStudioPage_clickGen';
        break;
      case '/InstagramPost':
        tempId = 'InstaPage_clickGen_image';
        break;
      case '/FacebookPost':
        tempId = 'FBPage_clickGen_image';
        break;
      case '/LinkedInPost':
        tempId = 'LinkedinPage_clickGen_image';
        break;
      default: break;
    }
    return tempId;
  };

  return (
    <div className="h-[calc(100vh-200px)] w-full">
      <div className="flex flex-row justify-start gap-[10px] border-b border-[#D9D9D9]">
        <TabButton
          active={selectedTab === 'images'}
          onClick={() => {
            setSelectedTab('images');
          }}
          className="lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost font-[400] 
          !px-[42px] !py-[11px] h-[46px] text-primaryColor"
          type="button"
          style={{ padding: 0, text: 'nowrap' }}
          notActiveColorRequired={{ color: '#A8A6A6' }}
        >
          Image
        </TabButton>
        {!onlyImageTab && (
          <TabButton
            active={selectedTab !== 'images'}
            disabled={!clickedImage}
            onClick={() => {
              clickedImage && setSelectedTab('text');
            }}
            className="lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost font-[400] 
            !px-[42px] !py-[11px] h-[46px] text-primaryColor"
            type="button"
            style={{ padding: 0, text: 'nowrap' }}
            notActiveColorRequired={{ color: '#A8A6A6' }}
          >
            Text
          </TabButton>
        )}
      </div>
      <div className="h-[calc(100vh-200px)] p-[10px] pb-[50px] overflow-scroll">
        {selectedTab === 'images' && (
          <>
            <div className="flex flex-wrap gap-4">
              {!onlyImageTab && (
                <div className="flex justify-between items-end w-full">
                  <p className="lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost font-[600] 
                  pt-[20px] text-primaryColor">Are you displaying a product/image?</p>
                  <Formik>
                    <Form>
                      <ToggleBtn
                        name="toggleButton"
                        values={{ checked: isToggleSelected }}
                        handleToggleChange={handleToggleChange}
                        disabled={imageGenerationLoading}
                      />
                    </Form>
                  </Formik>
                </div>
              )}
              {!isImgGenerator &&
                <FileUploadAndDisplay
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  setImageForView={setImageForView}
                  handleSelectedImage={handleSelectedImage}
                  generateLoading={generateLoading}
                  setSelectedImageToGen={setSelectedImageToGen}
                  setGenerateLoading={setGenerateLoading}
                  projectID={projectID}
                  documentID={documentID}
                />}
              <SelectTheme
                selectedTheme={selectedTheme}
                setSelectedTheme={setSelectedTheme}
                setPrompt={setPrompt}
                isBulkUpload={isBulkUpload}
                selectedImageStyle={selectedImageStyle}
                setSelectedImageStyle={setSelectedImageStyle}
                generateLoading={generateLoading}
                isImgGenerator={isImgGenerator}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                selectedImage={selectedImage}
                imageGenerationLoading={imageGenerationLoading}
              />
            </div>
          </>
        )}
        {!onlyImageTab && selectedTab === 'text' && (
          <DynamicForm
            templateValue={selectedTemp}
            templateKey={selectedTemp?.t_name}
            selectedImage={selectedImage}
            updatedCaptionListData={updatedCaptionListData}
            selectedGenImg={selectedGenImg}
            generateCaptionLoading={generateCaptionLoading}
            setGenerateCaptionLoading={setGenerateCaptionLoading}
          />
        )}
      </div>
      {selectedTab === 'images' && (
        <div className='absolute bottom-[-20px] bg-white z-10 px-[20px] py-[10px] pr-0 w-full'>
          <div className="flex justify-end items-center">
            <AppButton
              onClick={() => handleBackToTemplates()}
              type="button"
              variant=''
              extendClass='!bg-[#00000008] !text-black hover:bg-[#00000008] rounded-[5px] !text-[16px] 
              font-[400] h-[48px] w-[180px] !py-[2px] px-[0px] mr-[10px] border-[1px] border-solid !border-[#000]'
            >
              Back to templates
            </AppButton>
            <AppButton
              onClick={() => handleGenerateButtonClick()}
              isLoading={imageGenerationLoading}
              type="button"
              variant='black'
              extendClass='bg-black hover:bg-black rounded-[5px]
                text-[16px] font-[400] h-[48px] w-[180px] !py-[2px] !px-[20px]'
              disabled={imageGenerationLoading ||
                (isImgGenerator ? (!prompt?.prompt) : (!selectedImage || !(prompt?.theme || prompt?.prompt)))}
              id={handleGenerateBtnId()}
            >
              Generate
            </AppButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageAndTextSection;