/* eslint-disable max-len */
import React from 'react';
import { AppButton } from 'components/shared/AppButton';
import { Formik, Form, Field } from 'formik';
import closeIcon from 'assets/icons/closeIcon.svg';

const ProjectNamePopup = (props) => {
  const {
    reNamePopUp,
    selectedProject,
    handleClosePopup,
    handleSubmit,
    isFileRename,
    isLoading,
    isDocumentRename
  } = props;

  return (
    <div
      className="relative z-50 flex items-center justify-center font-jost"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="relative flex h-full items-end justify-center p-4 text-center">
          <div
            className="sticky bottom-[calc(50%-103px)] transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all"
          >
            <div className="bg-white">
              <div className="w-[501px] px-[40px] py-[40px] flex flex-col gap-[20px]">
                <div className='grid grid-cols-12'>
                  <div
                    className='font-jost font-[600] text-[20px] whitespace-nowrap col-span-11 text-[#000000]'
                    id='modal-title'
                  >
                    {reNamePopUp ? 'Rename' : 'Add Project name'}
                  </div>
                  <div
                    className='col-span-1 text-right flex items-center justify-center
                    hover:bg-[#F0F0F0] rounded-[5px] cursor-pointer'
                    role="presentation"
                    onClick={() => handleClosePopup()}
                  >
                    <img src={closeIcon} alt='' width='20px' height='20px' />
                  </div>
                </div>
                <div className='text-[16px] text-[#7D7D7D]'>
                  Project folders keep groups of files organised in one place
                </div>
                <div className="text-left text-[20px] font-[400]">
                  <Formik
                    initialValues={isFileRename ? { projectName: selectedProject?.document_name } : (reNamePopUp ? { projectName: selectedProject?.project_name } : { projectName: '' })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                  >
                    {formData => (
                      <Form>
                        <Field
                          name="projectName"
                          placeholder={isDocumentRename && !selectedProject?.document_name ? 'Untitled' : 'Enter project name'}
                          type='text'
                          autoComplete='off'
                          className='customTextBoxColor block w-full h-[48px] px-[20px] text-[14px] font-[400] leading-normal
                            border-[#D9D9D9] border-[1px] border-solid rounded appearance-none'
                        />
                        <div className='w-full flex justify-end items-center pt-[20px] gap-[10px]'>
                          {!reNamePopUp && <AppButton
                            onClick={() => handleClosePopup()}
                            type='button'
                            variant='light'
                            extendClass='bg-black hover:bg-black rounded-[5px] border-[0px] text-[20px] h-[48px] px-5 2xl:px-7'
                          >
                            Cancel
                          </AppButton>}
                          <AppButton
                            type="submit"
                            isLoading={isLoading}
                            disabled={!formData?.values?.projectName}
                            variant='black'
                            extendClass={` rounded-[5px] text-[20px] h-[48px] px-5 2xl:px-7
                            ${!formData?.values?.projectName ? 'bg-[#D9D9D9] !opacity-100' : 'bg-[#0038FF]'}`}
                          >
                            {reNamePopUp ? 'Confirm' : 'Save'}
                          </AppButton>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectNamePopup;