import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
// import { toast } from 'react-toastify';
import { LocalStorage } from 'services/localStorage';
import RelatedImages from './RelatedImages';
import ImageInfoAndKeywords from './ImageInfoAndKeywords';
import { ClipLoader } from 'react-spinners';
import { handleTokenExpiryIssue } from 'utils/functions.util';
import { BASE_URL } from 'utils/baseURL';
import { AppButton } from 'components/shared/AppButton';
import ShareLinkModal from '../ImageClickFunctions/ShareLinkModal';
import breezeLogo from 'assets/NewIcons/Mask group.svg';
import LinkExpiredImage from 'assets/img/linkExpired.svg';
import closeIcon from 'assets/icons/closeIcon.svg';

const ImageLibrarySingleImageDetails = () => {
  const { pid, gid, key } = useParams();
  const navigate = useNavigate();
  const [imageHistory, setImageHistory] = useState();
  const [relatedImagesData, setRelatedImagesData] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [initializeData, setInitializeData] = useState(true);
  const [imageHistoryLoading, setImageHistoryLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('info');
  // const [isDeleted, setIsDeleted] = useState(false);
  // const [deletedImgIndex, setDeletedImgIndex] = useState(0);
  // const [isOpen, setIsOpen] = useState(false);
  // const deleteRef = useRef(null);
  const [isShareLink, setIsShareLink] = useState(false);
  const [isAllowDownload, setIsAllowDownload] = useState();
  const [isLinkError, setLinkError] = useState(false);
  const [shareLinkKeyData, setShareLinkKeyData] = useState();
  const [shareLinkKeyLoading, setShareLinkLoading] = useState(false);
  const showCreateModule = JSON.parse(localStorage.getItem('showCreateModule') || true);
  const projectShareData = JSON.parse(localStorage.getItem('projectShareKeyData'));
  const isProjectShareAllowDownload = projectShareData ?
    (projectShareData?.projectShareKey && projectShareData?.allow_download) : true;

  useEffect(() => {
    // it is set the initial value of setDeletedImgIndex when we first
    // lands to the imade detail screen in library
    if (relatedImagesData) {
      relatedImagesData.findIndex((data) => {
        return data?.data?.url === selectedImage?.data?.url;
      });
      // if (displayedImageIndex !== -1) {
      //   setDeletedImgIndex(displayedImageIndex);
      // }
    }
  }, [relatedImagesData]);

  const getImageHistory = async () => {
    const url = BASE_URL + `image-gen/productHistory?pid=${pid}`;
    setImageHistoryLoading(true);
    try {
      const res = await axios.get(url, {
        headers: {
          token: LocalStorage.getItem()
        }
      });
      setImageHistory(res?.data?.data);
      // setIsDeleted(true);
    } catch (err) {
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    } finally {
      setImageHistoryLoading(false);
    }
  };
  useEffect(() => {
    if (pid) {
      getImageHistory();
    }
  }, [pid]);

  useEffect(() => {
    if (relatedImagesData) {
      if (initializeData) {
        // if (isDeleted) {
        //   //to check if the length of the generated image array to show the next image after deletion
        //   if (relatedImagesData?.length === 0) {
        //     navigate('/imageLibrary');
        //   } else if (relatedImagesData?.length >= deletedImgIndex + 1) {
        //     setSelectedImage(relatedImagesData[deletedImgIndex]);
        //     setDeletedImgIndex(deletedImgIndex);
        //   } else {
        //     setSelectedImage(relatedImagesData[deletedImgIndex - 1]);
        //     setDeletedImgIndex(deletedImgIndex - 1);
        //   }
        // }
        if (gid) {
          const generatedImage = relatedImagesData.find((d) => d?.data?.gid === gid);
          if (generatedImage) setSelectedImage(generatedImage);
        } else {
          setSelectedImage(relatedImagesData[0]);
        }
      }
      setInitializeData(true);
    }
  }, [relatedImagesData]);

  const handleOnClickImage = (image) => {
    setSelectedImage(image);
    setInitializeData(false);
    if (!key) {
      getImageHistory();
    }
    setSelectedTab(selectedTab);
  };

  // api to delete the image
  // const handleDelete = async () => {
  //   const data = new FormData();
  //   // if generated img then send gid else send pid
  //   if (selectedImage?.data.gid) {
  //     const imgGid = selectedImage?.data.gid;
  //     data.append('gid', imgGid);
  //   } else {
  //     const imgPid = selectedImage?.data.pid;
  //     data.append('pid', imgPid);
  //   }

  //   const config = {
  //     method: 'delete',
  //     url: BASE_URL + 'image-gen/delete-image',
  //     headers: {
  //       token: LocalStorage.getItem(),
  //       'Content-Type': 'multipart/form-data'
  //     },
  //     data: data
  //   };

  //   try {
  //     const response = await axios(config);
  //     if (response && response.data) {
  //       setSelectedImage();
  //       getImageHistory();
  //       setIsDeleted(true);
  //       console.log(response.data.message);
  //       setIsOpen(false);
  //     }
  //   } catch (err) {
  //     console.log('Something went wrong');
  //   }
  // };

  const handleDownload = () => {
    const tempFileName = selectedImage?.data?.url.split('/');
    const imageName = tempFileName[tempFileName.length - 1];
    const name = imageName || 'BreezeAppImage.png';
    fetch(selectedImage?.data?.url,
      {
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' }
      })
      .then(response => response?.blob())
      .then(blob => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';

        if (name?.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch((err) => console.log(err));
  };

  // const toggleConfirmBox = () => {
  //   setIsOpen(!isOpen);
  // };

  // const handleClickOutside = (event) => {
  //   if (deleteRef.current && !deleteRef.current.contains(event.target)) {
  //     setIsOpen(false);
  //   }
  // };

  // Attach event listener when the component mounts
  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);

  //   // Cleanup the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  const getShareLinkImageData = async () => {
    const url = BASE_URL + `share_link/getData?key=${key}`;
    setImageHistoryLoading(true);
    try {
      const res = await axios.get(url, {
        headers: {
          token: LocalStorage.getItem()
        }
      });
      if (res?.data?.status === 404) {
        setLinkError(true);
      } else {
        setImageHistory(res?.data?.data);
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        setLinkError(true);
      } else if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    } finally {
      setImageHistoryLoading(false);
    }
  };

  useEffect(() => {
    if (key) {
      getShareLinkImageData();
    }
  }, [key]);

  const handleClose = () => {
    setIsShareLink(false);
  };

  const handleAllowDownload = async () => {
    try {
      const data = new FormData();
      const formGid = key ? imageHistory?.share_link_info?.gid : gid;
      if (imageHistory?.pid && !formGid) data.append('pid', imageHistory?.pid);
      if (formGid) data.append('gid', formGid);
      data.append('allow_download', isAllowDownload);
      const url = BASE_URL + 'share_link/allowDownload';
      await axios.post(
        url,
        data,
        {
          headers: {
            token: LocalStorage.getItem()
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if ((isAllowDownload === true) || (isAllowDownload === false)) {
      handleAllowDownload();
    }
  }, [isAllowDownload]);

  const getShareLinkKeyData = async () => {
    setShareLinkKeyData();
    setShareLinkLoading(true);
    try {
      const data = new FormData();
      if (selectedImage?.data?.pid && !selectedImage?.data?.gid) data.append('pid', selectedImage?.data?.pid);
      if (selectedImage?.data?.gid) data.append('gid', selectedImage?.data?.gid);
      const url = BASE_URL + 'share_link/getShareKey';
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            token: LocalStorage.getItem()
          }
        });
      if (response && response.data) {
        setShareLinkKeyData(response?.data?.data);
        setIsAllowDownload(response?.data?.data?.allow_download);
      }
    } catch (error) {
      console.log(error);
    }
    setShareLinkLoading(false);
  };

  useEffect(() => {
    if (imageHistory) {
      const relatedImages = imageHistory?.generated_images?.map((image) => (
        {
          type: 'Generated',
          data: {
            ...image,
            created_on: image?.time,
            time: ''
          }
        }
      ));
      let uploadedImage = [];
      if (imageHistory?.pid && !imageHistory?.gid) {
        uploadedImage.push({
          type: 'Uploaded',
          data: {
            dimensions: imageHistory?.dimensions,
            pid: imageHistory?.pid,
            image_type: imageHistory?.image_type,
            keywords: imageHistory?.keywords,
            size: imageHistory?.size,
            time: imageHistory?.time,
            url: imageHistory?.input_image_url,
            user_name: imageHistory?.user_name,
            image_name: imageHistory?.image_name,
            image_origin: imageHistory?.image_origin,
            keyword_caption: imageHistory?.keyword_caption,
            created_on: imageHistory?.created_on
          }
        });
      }
      setRelatedImagesData(relatedImages ? uploadedImage.concat(relatedImages) : uploadedImage);
    }
  }, [imageHistory]);

  return (
    <div className='w-full pb-[20px] bg-white'>
      {isShareLink && (
        <ShareLinkModal
          handleCloseBtn={handleClose}
          shareLinkKeyData={key ? imageHistory?.share_link_info : shareLinkKeyData}
          isAllowDownload={isAllowDownload}
          setIsAllowDownload={setIsAllowDownload}
          shareLinkKeyLoading={shareLinkKeyLoading}
        />
      )}
      {(isLinkError && !imageHistoryLoading) ? (
        <div className='flex flex-col justify-center items-center font-jost'>
          <img src={LinkExpiredImage} alt='' className='w-[501px] h-[501px]' />
          <div className='text-[#0038FF] text-[36px] font-[600] pt-[40px]'>Sorry, the link has expired.</div>
          <div className='text-[#0038FF] text-[24px] font-[400] pt-[20px]'>
            Please contact the person who shared the link with you
          </div>
        </div>
      ) :
        ((!isLinkError) && (
          <>
            {key && (
              <div
                className="w-full h-14 px-5 py-2 bg-white border-b 
              border-[#D9D9D9] justify-between items-center inline-flex"
              >
                <div className="grid grid-cols-12 w-full">
                  <div className='col-span-6'>
                    <img src={breezeLogo} alt='logo' className={'w-[86px] h-[16px] cursor-pointer'} />
                  </div>
                  <div
                    className="col-span-6 text-right text-[#7D7D7D] text-base font-normal font-jost"
                  >
                    {imageHistory?.user_name}
                  </div>
                </div>
              </div>
            )}
            <div className='mx-[40px]'>
              <div className={`font-jost font-[600] lg:text-[12px] xl:text-[16px] xl-mid:text-[18px] 
            4xl:text-[20px] py-[20px] flex gap-[40px] items-center justify-between`}>
                <div className='text-[30px] font-[400] text-[#000]'>{imageHistory?.image_name}</div>
                {(!key || (key && imageHistory?.share_link_info?.allow_download)) && (
                  <div className='flex gap-[20px] items-center'>
                    {isProjectShareAllowDownload && (
                      <>
                        <AppButton
                          onClick={() => { setIsShareLink(true); getShareLinkKeyData(); }}
                          type="button"
                          variant='light'
                          extendClass={`text-[#333333] bottom-[5px] right-[5px] !bg-[#F0F0F0] rounded-[5px] 
                      h-[43px] flex justify-center items-center px-[20px] py-[10px] !text-[16px] font-[400]`}
                        >
                          Share
                        </AppButton>
                        <AppButton
                          onClick={() => handleDownload()}
                          type="button"
                          variant='light'
                          extendClass={`bottom-[5px] right-[5px] !bg-[#0038FF] rounded-[5px] font-[500]
                      h-[43px] flex justify-center items-center px-[20px] py-[10px] !text-white !text-[16px]
                      ${!key && 'mr-[20px]'}`}
                        >
                          Download
                        </AppButton>
                      </>
                    )}
                    {!key && (
                      <>
                        {/* <div className='border-[2px] border-[#D9D9D9] h-[39px]' /> */}
                        <div className='w-[26px] h-[26px] cursor-pointer' onClick={() => navigate(-1)}>
                          <img src={closeIcon} alt='' />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className='flex items-center justify-center grid grid-cols-12 gap-5 h-full'>
                <div className='lg:col-span-6 col-span-12 bg-white h-[595px] rounded-2xl border border-[#D9D9D9]'>
                  <div className='flex justify-center items-center h-full'>
                    <div className='flex items-center justify-center p-[20px] max-w-full m-auto h-full'>
                      {imageHistoryLoading ? (
                        <div className='flex flex-col items-center justify-center spinner-border bg-white'
                          style={{ height: '590px' }}>
                          <ClipLoader color='#757D89' />
                          <div className='lg:text-[14px] xl:text-[16px] xl-mid:text-[18px] 4xl:text-[20px] 
                        font-semibold mb-2'>Loading...</div>
                        </div>
                      ) : (
                        <img src={selectedImage?.data?.url} alt='' className='w-full m-auto max-h-full' />
                      )}
                    </div>
                  </div>
                </div>
                <div className='lg:col-span-6 col-span-12 bg-white h-[595px] rounded-2xl border border-[#D9D9D9]'>
                  {imageHistoryLoading ? (
                    <div className='flex flex-col items-center justify-center spinner-border bg-white'
                      style={{ height: '590px' }}>
                      <ClipLoader color='#757D89' />
                      <div className='lg:text-[14px] xl:text-[16px] xl-mid:text-[18px] 4xl:text-[20px] 
                    font-semibold mb-2'>Loading...</div>
                    </div>
                  ) : (
                    <ImageInfoAndKeywords
                      selectedImage={selectedImage}
                      imageHistory={imageHistory}
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      shareKey={key}
                      projectShareData={projectShareData}
                    />
                  )}
                </div>
              </div>
              {showCreateModule && (
                <div>
                  <p className='font-jost font-[600] text-[20px] text-[#333] pt-[32px] pb-[16px]'>Related Images</p>
                  <div className='w-full h-[200px] bg-white'>
                    <RelatedImages
                      relatedImagesData={relatedImagesData}
                      selectedImage={selectedImage}
                      handleOnClickImage={handleOnClickImage}
                      imageHistoryLoading={imageHistoryLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        ))}
    </div>
  );
};

export default ImageLibrarySingleImageDetails;