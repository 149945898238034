import React, { useState, useRef, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import cn from 'classnames';
import { uploadImgToRemoveBg } from 'utils/fileHelpers';
import { createFileFromImgUrl } from 'components/ImageEditor';
import upload from 'assets/NewIcons/upload_new.svg';
import plusIcon from 'assets/NewIcons/plusIcon.svg';
import { useNavigate } from 'react-router-dom';
import RenderSmoothImage from 'components/ReactSmoothImageRendring';

const FileUploadAndDisplay = ({
  selectedImage,
  handleSelectedImage,
  setSelectedImage,
  generateLoading,
  setSelectedImageToGen,
  setGenerateLoading,
  projectID,
  documentID
}) => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState();
  const [imageToShow, setImageToShow] = useState();
  const [removingBg, setRemovingBg] = useState(false);
  const doMock = false;
  const inputFileRef = useRef(null);

  const selectSingleImageToUpload = () => {
    inputFileRef.current.click();
  };

  const onFileChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e);
    }
  };

  const uploadImage = () => {
    if (selectedFile.target.files) {
      if (selectedFile.target.files.length > 1) {
        setGenerateLoading(true);
        return;
      }
      setGenerateLoading(true);
      setRemovingBg(true);
      uploadImgToRemoveBg(selectedFile.target.files[0], doMock, documentID, projectID)
        .then(async res => {
          if (res === 'expired') {
            return;
          }
          const fileUrl = res.data?.remove_background_url;
          const file = await createFileFromImgUrl(fileUrl);
          setSelectedImage({ ...res.data, imgUrl: fileUrl, pid: res.data.pid, file });
          setSelectedImageToGen({ imgUrl: fileUrl, pid: res.data.pid, file });
        })
        .catch(() => {
          console.log('Oops, Something went wrong');
        })
        .finally(() => {
          setGenerateLoading(false);
          setRemovingBg(false);
        });
    } else if (selectedFile.dataTransfer && selectedFile.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...selectedFile.dataTransfer.items].forEach((item) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile();
          setGenerateLoading(true);
          setRemovingBg(true);
          uploadImgToRemoveBg(file)
            .then(async res => {
              const fileUrl = res.data?.remove_background_url;
              const file = await createFileFromImgUrl(fileUrl);
              setSelectedImage({ ...res.data, imgUrl: URL.createObjectURL(file), file, pid: res.data.pid });
              setSelectedImageToGen({ ...res.data, imgUrl: URL.createObjectURL(file), file, pid: res.data.pid });
            })
            .catch(() => {
              console.log('Oops, Something went wrong');
            })
            .finally(() => {
              setGenerateLoading(false);
              setRemovingBg(false);
            });
        }
      });
    } else if (selectedFile.dataTransfer && selectedFile.dataTransfer.files) {
      [...selectedFile.dataTransfer.files].forEach((file) => {
        setSelectedImage({ imgUrl: URL.createObjectURL(file), file });
        setSelectedImageToGen({ imgUrl: URL.createObjectURL(file), file });
      });
    }
  };

  useEffect(() => {
    if (selectedFile) {
      uploadImage();
    }
  }, [selectedFile]);

  const handleImageSelect = (data) => {
    if (!generateLoading) {
      handleSelectedImage(data);
      setSelectedImageToGen(data);
    }
  };

  const uploadImgGenLibraryAllData = JSON.parse(localStorage.getItem('uploadImgGenLibrary'));
  useEffect(() => {
    handleSelectedImage(uploadImgGenLibraryAllData?.selectedImgData);
    setSelectedImageToGen(uploadImgGenLibraryAllData?.selectedImgData);
  }, []);

  useEffect(() => {
    if (selectedImage) {
      setImageToShow(selectedImage);
    }
  }, [selectedImage]);

  const redirectToImgLibrary = () => {
    if (uploadImgGenLibraryAllData) {
      uploadImgGenLibraryAllData.libraryImgUpload = true;
      uploadImgGenLibraryAllData.redirectedUrl = window.location.pathname;
      uploadImgGenLibraryAllData.userEmail = localStorage.getItem('user-email') ?? '';
      uploadImgGenLibraryAllData.projectID = projectID;
    }
    const isAddFromLibrary = true;
    localStorage.setItem('isAddFromLibrary', JSON.stringify(isAddFromLibrary));
    localStorage.setItem('uploadImgGenLibrary', JSON.stringify(uploadImgGenLibraryAllData));
    navigate('/imageLibrary');
  };

  return (
    <div>
      <div className=''>
        <p className='lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost font-[600] py-[10px] text-primaryColor'>
          Pick product/image that you want to display</p>
        <div className='flex flex-wrap gap-2'>
          <div
            className='w-[95px] lg:w-[95px] 2xl:w-[110px] 3xl:w-[110px]
            h-[95px] lg:h-[95px] 2xl:h-[110px] 3xl:h-[110px] flex flex-col gap-2
            items-center justify-center bg-white relative
            text-center border-[1px] rounded-[5px] border-[#A0A0A0] cursor-pointer'
            onClick={!generateLoading && selectSingleImageToUpload}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => onFileChange(e)}
          >
            <div className={`text-center ${!generateLoading && 'cursor-pointer'}`}>
              <img src={upload} className='text-[20px] font-jost font-[400] text-[#7D7D7D]' alt='' />
            </div>
            <div
              className='text-center lg:text-[12px] 3xl:text-[14px] font-[400] font-jost text-[#7D7D7D]
              whitespace-nowrap absolute bottom-[10%]'
            >
              Upload new
            </div>
            <input
              type='file'
              onChange={!generateLoading && onFileChange}
              onDrop={!generateLoading && onFileChange}
              ref={inputFileRef}
              name='files'
              id='file'
              className='hidden'
              accept='image/*'
            />
          </div>
          <div
            className='w-[95px] lg:w-[95px] 2xl:w-[110px] 3xl:w-[110px]
            h-[95px] lg:h-[95px] 2xl:h-[110px] 3xl:h-[110px] flex flex-col gap-2
            items-center justify-center bg-white relative
            text-center border-[1px] rounded-[5px] border-[#A0A0A0] cursor-pointer'
            onClick={redirectToImgLibrary}
          >
            <div className={`text-center ${!generateLoading && 'cursor-pointer'}`}>
              <img src={plusIcon} className='text-[20px] font-jost font-[400] text-[#7D7D7D]' alt='' />
            </div>
            <div
              className='text-center lg:text-[12px] 3xl:text-[14px] font-[400] font-jost text-[#7D7D7D]
              whitespace-nowrap absolute bottom-[10%]'
            >
              Add from library
            </div>
          </div>
          {(imageToShow || removingBg) && (
            <div
              className={cn(`w-[95px] lg:w-[95px] 2xl:w-[110px] 3xl:w-[110px]
              h-[95px] lg:h-[95px] 2xl:h-[110px] 3xl:h-[110px] flex flex-col gap-2
              items-center justify-center bg-white relative
              text-center border-[1px] rounded-[5px] border-[#A0A0A0] cursor-pointer`,
                selectedImage && 'border-[2px] border-[#757D89]')}
              role='presentation'
              onClick={() => !removingBg && handleImageSelect(imageToShow)}
            >
              <div
                className={
                  cn('bg-[#F1F1F1] rounded flex items-center justify-center h-full w-full bg-canvasMeshBg',
                    selectedImage && 'p-1 border-[2px] border-white'
                  )}
                role='presentation'
              >
                {removingBg ? (
                  <div className='spinner-border ' role='status'>
                    <ClipLoader color='#757D89' />
                  </div>
                ) : (
                  <RenderSmoothImage
                    src={imageToShow?.remove_background_url}
                    alt='images'
                    noBackGround
                    className={`w-auto m-auto max-h-full ${!(generateLoading || removingBg) && 'cursor-pointer'}`}
                  />
                )}
              </div>
            </div>)}
        </div>
      </div>
    </div>
  );
};

export default FileUploadAndDisplay;