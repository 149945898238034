import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
import { LocalStorage } from 'services/localStorage';
import loadingGif from 'assets/img/loaderAnimation.gif';
import 'react-lazy-load-image-component/src/effects/blur.css';
import RenderImage from './RenderImage';
import { handleTokenExpiryIssue } from 'utils/functions.util';
import { BASE_URL } from 'utils/baseURL';

const GeneratedImagesSection = ({
  clickedImage,
  setClickedImage,
  generatedImagesData,
  setSelectedGenImg,
  setSelectedImage,
  generateLoading,
  setDeletedImgIndex
}) => {
  const [likedImg, setLikedImg] = useState([]);
  const [disLikedImg, setDisLikedImg] = useState([]);
  const [selectedData, setSelectedData] = useState();
  // const navigate = useNavigate();

  const handleLikeAndDislikeCalls = async () => {
    try {
      const url = selectedData?.type === 'liked' ?
        BASE_URL + 'image-gen/like' :
        BASE_URL + 'image-gen/dislike';
      const data = new FormData();
      data.append('gid', selectedData?.gid);
      await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: LocalStorage.getItem()
        }
      });
    } catch (err) {
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
    }
  };

  useEffect(() => {
    if (selectedData) {
      handleLikeAndDislikeCalls();
    }
  }, [selectedData]);

  const handleLikingImg = (data) => {
    setSelectedData({ ...data, type: 'liked' });
    const status = likedImg.find((val) => val === data?.gid);
    const status2 = disLikedImg.find((val) => val === data?.gid);
    if (status2) {
      setDisLikedImg(oldValues => (oldValues.filter(val => val !== data?.gid)));
    }
    if (!status) {
      setLikedImg((prev) => [...prev, data?.gid]);
    }
  };

  const handleDisLikingImg = (data) => {
    setSelectedData({ ...data, type: 'disLiked' });
    const status = disLikedImg.find((val) => val === data?.gid);
    const status2 = likedImg.find((val) => val === data?.gid);
    if (status2) {
      setLikedImg(oldValues => (oldValues.filter(val => val !== data?.gid)));
    }
    if (!status) {
      setDisLikedImg((prev) => [...prev, data?.gid]);
    }
  };

  const handleSelectedLikedData = (item) => {
    const temp = likedImg.find((val) => val === item);
    return temp;
  };

  const handleSelectedDisLikedData = (item) => {
    const temp = disLikedImg.find((val) => val === item);
    return temp;
  };

  const handleSelectGenImg = (data) => {
    if (data?.pid) {
      setSelectedGenImg(data);
      setSelectedImage();
    }
  };

  useEffect(() => {
    if (generatedImagesData) {
      generatedImagesData.map((data) => {
        if (data?.like === true) {
          setLikedImg((prev) => [...prev, data?.gid]);
        } else if (data?.like === false) {
          setDisLikedImg((prev) => [...prev, data?.gid]);
        }
      });
    }
  }, [generatedImagesData]);

  return (
    <div className='p-[20px] h-full w-full'>
      <div className='grid lg:grid-cols-5 xl:grid-cols-5 xl-mid:grid-cols-6 2xl:grid-cols-7 
      grid-flow-row gap-2 items-center h-full max-w-full'>
        {generatedImagesData?.map((image, index) => {
          return (
            <div key={index} className='col-span-1 group relative aspect-square'>
              <>
                {image === null ? (
                  <div className='bg-[#F1F1F1] w-full m-auto h-full rounded-[2px] flex items-center justify-center'>
                    <img src={loadingGif} alt='loading...' className='w-[36px]' />
                  </div>
                ) : (
                  <>
                    <RenderImage
                      image={image}
                      clickedImage={clickedImage}
                      handleDisLikingImg={handleDisLikingImg}
                      handleLikingImg={handleLikingImg}
                      handleSelectGenImg={handleSelectGenImg}
                      setClickedImage={setClickedImage}
                      index={index}
                      generateLoading={generateLoading}
                      handleSelectedDisLikedData={handleSelectedDisLikedData}
                      handleSelectedLikedData={handleSelectedLikedData}
                      setDeletedImgIndex={setDeletedImgIndex}
                    />
                  </>
                )}
              </>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GeneratedImagesSection;