import React, { useState, useRef, useEffect } from 'react';
import { Image as KonvaImage, Group } from 'react-konva';
import addIcon from 'assets/icons/addIconBtn.svg';
import deleteIcon from 'assets/icons/deleteIconBtn.svg';

export const IndividualImage = (props) => {
  const {
    image,
    keyValue,
    addImageToStage,
    onDelete,
    handleList,
    selectedImageUrl,
    generateImage,
    imageItemRef,
    clickedOutSide,
    setClickedOutSide,
    isMobScreen,
    images
  } = props;
  const imageRef = useRef(null);
  const groupImgRef = imageItemRef;
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [transformHeight, setTransformHeight] = useState(null);
  
  handleList();
  useEffect(() => {
    image.current = imageRef?.current;
    handleList();
  }, [imageRef]);

  useEffect(() => {
    if (clickedOutSide) {
      setShowActionButtons(false);
    }
  }, [clickedOutSide]);

  return (
    <Group
      draggable={true}
      x={image.x}
      y={image.y}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={() => {
        setIsDragging(false);
      }}
    >
      <KonvaImage
        ref={imageRef}
        alt='Image'
        className='rounded-5'
        style={{ zIndex: 1 }}
        image={generateImage(selectedImageUrl)}
        onTransform={(e) => {
          const currentHeight = e?.target?.height() * e?.target?.scaleY();
          setTransformHeight(currentHeight);
        }}
        onClick={(e) => {
          const currentHeight = e?.target?.height() * e?.target?.scaleY();
          setTransformHeight(currentHeight);
          e.cancelBubble = true;
          image.current = e.currentTarget;
          setShowActionButtons(true);
          groupImgRef?.current?.nodes([imageRef?.current]);
          setClickedOutSide(!clickedOutSide);
        }}
        onTap={(e) => {
          const currentHeight = e?.target?.height() * e?.target?.scaleY();
          setTransformHeight(currentHeight);
          e.cancelBubble = true;
          setShowActionButtons(true);
          image.current = e.currentTarget;
          groupImgRef?.current?.nodes([imageRef?.current]);
          setClickedOutSide(!clickedOutSide);
        }}
        draggable={true}
      />
      {showActionButtons && !isDragging && (
        <>
          <KonvaImage
            onTap={() => { addImageToStage(keyValue); }}
            onClick={() => { addImageToStage(keyValue); }}
            image={generateImage(addIcon)}
            width={isMobScreen ? 20 : 27}
            height={isMobScreen ? 20 : 27}
            offsetX={-((imageRef?.current?.attrs?.x - 30) || -30)}
            offsetY={-((imageRef?.current?.attrs?.y + (isMobScreen ? transformHeight - 20 : transformHeight - 25)
              || (isMobScreen ? transformHeight - 20 : transformHeight - 25)))}
          />
          {isMobScreen ? (
            <KonvaImage
              onTouchStart={() => { onDelete(keyValue); }}
              onClick={() => { onDelete(keyValue); }}
              image={generateImage(deleteIcon)}
              width={20}
              height={20}
              offsetX={-((imageRef?.current?.attrs?.x - 30) || -30)}
              offsetY={-(imageRef?.current?.attrs?.y || 0)}
            />) : (images.length > 1 && (
              <KonvaImage
                onTouchStart={() => { onDelete(keyValue); }}
                onClick={() => { onDelete(keyValue); }}
                image={generateImage(deleteIcon)}
                width={27}
                height={27}
                offsetX={-((imageRef?.current?.attrs?.x - 30) || -30)}
                offsetY={-(imageRef?.current?.attrs?.y || 0)}
              />
            ))}
        </>
      )}
    </Group>
  );
};