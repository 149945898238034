/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import TabButton from 'components/shared/TabButton';
import { Formik, Form, Field } from 'formik';
import ProjectNamePopup from '../projectNamePopUp';
import DeleteConfirmPopup from '../deleteConfirmPopup';
import plusIconAccord from 'assets/icons/accordionPlusIcon.svg';
import dummyProjectImg from 'assets/img/dummyProjectImage.svg';
import verticalOptionIcon from 'assets/icons/optionsVertical.svg';
import renameOutline from 'assets/icons/renameOutline.svg';
import deleteIcon from 'assets/icons/deleteIconBlack.svg';
import { BASE_URL } from 'utils/baseURL';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import { ClipLoader } from 'react-spinners';
import { handleMultiImageDownload, handleProjectModifiedDate } from 'utils/commonFunctions';
import { useParams, useNavigate } from 'react-router-dom';
import dummyTextImage from 'assets/img/dummyTextImage.svg';
import format_list_bulleted from 'assets/icons/format_list_bulleted.svg';
import format_list from 'assets/icons/format_list.svg';
import grid_view_filled from 'assets/icons/grid_view_filled.svg';
import grid_view from 'assets/icons/grid_view.svg';
import ProjectGalleryView from './ProjectGalleryView';
import ProjectListView from './ProjectListView';
import plusIcon from 'assets/icons/whitePlusIcon.svg';
import TemplatePopup from '../templatePopup';
import AddImages from 'components/DesktopComponents/Common/AddImages';
import UploadImgFolderModal from 'components/DesktopComponents/Common/UploadImgFolderModal';
import CollectContentPopUp from '../../CollectContent/CollectContentPopup';
import ModalPopup from 'components/commonComponents/ModalPopup';
import closeIcon from 'assets/icons/closeIcon.svg';
import cn from 'classnames';
import googleDriveIcon from 'assets/img/googleDrive.svg';
import FilterComponent from '../../ImageLibrary/FilterComponent';
import searchIcon from 'assets/icons/searchIcon.svg';
import ImageClickPopUpCollections from '../../ImageClickFunctions/ImageClickPopUpCollections';
import ShareProjectPopup from '../shareProjectPopup';
import breezeLogo from 'assets/NewIcons/Mask group.svg';
import { handleTokenExpiryIssue } from 'utils/functions.util';
import LinkExpiredImage from 'assets/img/linkExpired.svg';
import { AppButton } from 'components/shared/AppButton';

const ProjectDocuments = () => {
  const [selectedTab, setSelectedTab] = useState('Images');
  const [menuOptionOpen, setMenuOptionOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState();
  const [existingDocumentLists, setExistingDocumentLists] = useState([]);
  const [projectLoading, setProjectLoading] = useState(false);
  const [reNamePopUp, setRenamePopUp] = useState(false);
  const [deleteConfirmPopUp, setDeleteConfirmPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedView, setSelectedView] = useState('galleryView');
  const [projectImgList, setProjectImgList] = useState([]);

  const [showTemplatePopup, setShowTemplatePopup] = useState(false);
  const { projectID, projectShareKey } = useParams();
  const [isFilesUploading, setIsFilesUploading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isUploadFolder, setIsUploadFolder] = useState(false);
  const [projectId, setProjectID] = useState(projectID);
  const [selectedFile, setSelectedFile] = useState();
  const [showCollectContentPopup, setShowCollectContentPopup] = useState(false);
  const [projectShareKeyLoading, setProjectShareKeyLoading] = useState(false);
  const [projectSharedKeyData, setProjectSharedKeyData] = useState();

  //states related to imageClick functions PopUp
  const [updatedImgName, setUpdatedImgName] = useState('');
  const [imageClickData, seImageClickData] = useState({});
  const [projectIdsList, setProjectIDsList] = useState([]);
  const [shareLinkKeyData, setShareLinkKeyData] = useState();
  const [shareLinkKeyLoading, setShareLinkLoading] = useState(false);
  const [isAllowDownload, setIsAllowDownload] = useState();

  //states related to project share
  const [isProjectShareLinkLoading, setIsProjectShareLinkLoading] = useState(false);
  const [isAllowDownloadForProject, setIsAllowDownloadForProject] = useState();
  const [projectShareKeyData, setProjectShareKeyData] = useState();
  const [isLinkError, setLinkError] = useState(false);

  const [isImgClickPopUps, setIsImgClickPopUps] = useState({
    shareLink: false,
    addTo: false,
    moveTo: false,
    renameImg: false,
    removeImg: false,
    deleteImg: false
  });

  const [googleDriveImportLoading, setGoogleDriveImportLoading] = useState(false);
  const [googleDriveImportError, setGoogleDriveImportError] = useState(false);

  const [multiImageSelectedList, setMultiImageSelectedList] = useState([]);

  const searchedData = JSON?.parse(localStorage?.getItem('searchKey'));
  const [searchedText, setSearchedText] = useState(''); // to store the searched key
  const [searchDataList, setSearchDataList] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [imgCount, setImgCount] = useState();
  const [loading, setLoading] = useState(false);
  const showCreateModule = JSON.parse(localStorage.getItem('showCreateModule') || true);
  const [imageUploadingOrigin, setImageUploadingOrigin] = useState();

  const [isShareProjectPopupOpen, setIsShareProjectPopupOpen] = useState(false);

  useEffect(() => {
    setUpdatedImgName(imageClickData?.image_name);
  }, [imageClickData]);

  const navigate = useNavigate();

  const handleImageUpload = async (listData) => {
    // setIsFilesUploading(true);
    const data = new FormData();
    if (selectedFile?.length > 0) {
      for (var i = 0; i < selectedFile?.length; i++) {
        data.append('images', selectedFile[i]);
      }
    }
    if (projectId) {
      data.append('projectID', projectId);
    }
    if (imageUploadingOrigin) {
      data.append('origin', imageUploadingOrigin);
    }
    const url = BASE_URL + 'image-gen/upload_multiple_images';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response?.data) {
        if (projectID === projectId) {
          const tempData = listData?.history;
          for (let i = 0; i < response?.data?.data?.products?.length; i++) {
            tempData[i] = { ...response?.data?.data?.products[i] };
          }
          setProjectImgList({ history: tempData });
        }
      }
    } catch (err) {
      console.log('Something went wrong');
    }
    setImageUploadingOrigin();
    setIsFilesUploading(false);
  };

  const handleMultipleImagesUpload = () => {
    setIsFilesUploading(true);
    setIsUploadFolder(false);
    if (projectID === projectId) {
      let tempListData = { ...projectImgList };
      for (let i = 0; i < selectedFile?.length; i++) {
        tempListData?.history?.unshift(null);
        setProjectImgList(tempListData);
      }
      handleImageUpload(tempListData);
    } else {
      handleImageUpload();
    }
  };

  const getProjectDetails = async () => {
    setProjectLoading(true);
    const url = BASE_URL + 'image-gen/projectDetails';
    const data = new FormData();
    data.append('projectID', projectID);
    try {
      const projectListData = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      setExistingDocumentLists(projectListData?.data?.data);
    } catch (err) {
      console.log(err);
    }
    setProjectLoading(false);
  };

  const getProjectShareKey = async () => {
    setProjectShareKeyLoading(true);
    const url = BASE_URL + 'collect_content/getShareKey';
    const data = new FormData();
    data.append('projectID', projectID);
    try {
      const projectSharedKeyData = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      setProjectSharedKeyData(projectSharedKeyData?.data?.data);
    } catch (err) {
      console.log(err);
    }
    setProjectShareKeyLoading(false);
  };

  const handleRefreshProjectList = () => {
    getProjectDetails();
  };

  const toggleMenuOption = () => {
    setMenuOptionOpen(!menuOptionOpen);
  };

  const handleClosePopup = () => {
    setRenamePopUp(false);
  };

  const handleDeletePopup = (status) => {
    setDeleteConfirmPopUp(status);
  };

  const handleConfirm = () => {
    handleClosePopup();
  };

  useEffect(() => {
    if (projectID) {
      getProjectDetails();
    }
  }, [projectID]);

  const handleRenameSubmit = async (formValue) => {
    setIsLoading(true);
    const url = BASE_URL + 'image-gen/renameDocument';
    const data = new FormData();
    data?.append('document_name', formValue?.projectName);
    data?.append('documentID', selectedDocument?.documentID);
    data.append('projectID', projectID);
    try {
      await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage?.getItem()
          }
        }
      );
      handleClosePopup();
      handleRefreshProjectList();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteSubmit = async () => {
    setIsLoading(true);
    const data = new FormData();
    data?.append('documentID', selectedDocument?.documentID);
    const config = {
      method: 'delete',
      url: BASE_URL + 'image-gen/deleteDocument',
      headers: {
        token: LocalStorage.getItem(),
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };
    try {
      await axios(config);
      handleDeletePopup(false);
      handleRefreshProjectList();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseTemplatePopup = () => {
    setShowTemplatePopup(false);
  };

  const handleNavigationToTemplatePage = (template, document) => {
    if (template) localStorage.setItem('selectedTemp', JSON.stringify(template));
    localStorage.setItem('selectedDocument', document?.document_name || 'Untitled');
    switch (template?.name) {
      case 'Image Generator':
        navigate(`/Projects/${existingDocumentLists?.project?.projectID}/Document/${document?.documentID}/ImageGenerator`, { state: document });
        break;
      case 'Photo Studio':
        navigate(`/Projects/${existingDocumentLists?.project?.projectID}/Document/${document?.documentID}/PhotoStudio`, { state: document });
        break;
      case 'Instagram':
        navigate(`/Projects/${existingDocumentLists?.project?.projectID}/Document/${document?.documentID}/InstagramPost`, { state: document });
        break;
      case 'Facebook':
        navigate(`/Projects/${existingDocumentLists?.project?.projectID}/Document/${document?.documentID}/FacebookPost`, { state: document });
        break;
      case 'Linkedin':
        navigate(`/Projects/${existingDocumentLists?.project?.projectID}/Document/${document?.documentID}/LinkedInPost`, { state: document });
        break;
      case 'Google Search':
        navigate(`/Projects/${existingDocumentLists?.project?.projectID}/Document/${document?.documentID}/GoogleSearchAd`, { state: document });
        break;
      case 'Walmart listings':
        if (template?.subname === 'Images') {
          navigate(`/Projects/${existingDocumentLists?.project?.projectID}/Document/${document?.documentID}/WalmartListingsImages`, { state: document });
        } else navigate(`/Projects/${existingDocumentLists?.project?.projectID}/Document/${document?.documentID}/WalmartListingsDetails`, { state: document });
        break;
      case 'Amazon listings':
        if (template?.subname === 'Images') {
          navigate(`/Projects/${existingDocumentLists?.project?.projectID}/Document/${document?.documentID}/AmazonListingsImages`, { state: document });
        } else navigate(`/Projects/${existingDocumentLists?.project?.projectID}/Document/${document?.documentID}/AmazonListingsDetails`, { state: document });
        break;
      default: break;
    }
  };

  const getProjectImageList = async () => {
    setLoading(true);
    try {
      let data;
      data = new FormData();
      data.append('projectID', projectID);
      const url = BASE_URL + 'image-gen/uploadHistory';
      const sampleImages = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            startIdx: 1,
            Num_records: 10,
            token: LocalStorage.getItem()
          }
        });
      const allImageList2 = sampleImages?.data?.data;
      setProjectImgList(allImageList2);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isSearched) {
      setImgCount(searchDataList?.length);
    } else {
      setImgCount(projectImgList?.history?.length);
    }
  }, [projectImgList, searchDataList]);

  useEffect(() => {
    if (projectID) {
      getProjectImageList();
    }
  }, [projectID]);

  const handleImageClick = (image) => {
    if (projectShareKey) {
      const projectShareData = {
        projectShareKey,
        allow_download: projectImgList?.share_link_info?.allow_download
      };
      localStorage.setItem('projectShareKeyData', JSON.stringify(projectShareData));
    }
    if (image?.pid) {
      if (image?.gid) {
        navigate(`/imageLibrary/${image?.pid}/${image?.gid}`);
      } else {
        navigate(`/imageLibrary/${image?.pid}`);
      }
    }
  };

  const handleClose = () => {
    setSelectedFile();
    setIsUploadFolder(false);
  };

  const handleCollectContentPopUp = (status) => {
    if (status) {
      getProjectShareKey();
    }
    setShowCollectContentPopup(status);
  };

  const handleAllowDownload = async () => {
    try {
      const data = new FormData();
      if (imageClickData?.pid && !imageClickData?.gid) data.append('pid', imageClickData?.pid);
      if (imageClickData?.gid) data.append('gid', imageClickData?.gid);
      data.append('allow_download', isAllowDownload);
      const url = BASE_URL + 'share_link/allowDownload';
      await axios.post(
        url,
        data,
        {
          headers: {
            token: LocalStorage.getItem()
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if ((isAllowDownload === true) || (isAllowDownload === false)) {
      handleAllowDownload();
    }
  }, [isAllowDownload]);

  const handleSearchData = (data) => {
    const searchValue = data?.target?.value;
    if (searchValue) {
      setSearchedText(searchValue);
      setIsSearched(true);
    } else {
      setSearchedText('');
      setIsSearched(false);
      setSearchDataList([]);
    }
  };

  const getProjectShareLinkKey = async () => {
    setProjectShareKeyData();
    setIsProjectShareLinkLoading(true);
    try {
      const data = new FormData();
      if (projectId) data.append('projectID', projectID);
      const url = BASE_URL + 'share_link/getShareKey';
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            token: LocalStorage.getItem()
          }
        });
      if (response && response.data) {
        setProjectShareKeyData(response?.data?.data);
        setIsAllowDownloadForProject(response?.data?.data?.allow_download);
      }
    } catch (error) {
      console.log(error);
    }
    setIsProjectShareLinkLoading(false);
  };

  const getProjectShareData = async () => {
    const url = BASE_URL + `share_link/getData?key=${projectShareKey}`;
    setProjectLoading(true);
    try {
      const res = await axios.get(url, {
        headers: {
          token: LocalStorage.getItem()
        }
      });
      if (res?.data?.status === 404) {
        setLinkError(true);
      } else {
        setProjectImgList(res?.data?.data);
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        setLinkError(true);
      } else if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    } finally {
      setProjectLoading(false);
    }
  };

  useEffect(() => {
    if (projectShareKey) {
      getProjectShareData();
    }
  }, [projectShareKey]);

  const handleDownloadAll = () => {
    if (projectImgList) {
      handleMultiImageDownload(projectImgList?.history);
    }
  };

  useEffect(() => {
    localStorage.removeItem('projectShareKeyData');
  }, []);

  return (
    <div className='font-jost'>
      {googleDriveImportLoading && (
        <ModalPopup>
          {googleDriveImportError && (
            <div
              className='flex items-start justify-end'
              role="presentation"
              onClick={() => {
                setGoogleDriveImportLoading(false);
                setGoogleDriveImportError(false);
              }}
            >
              <img src={closeIcon} alt='' width='12px' height='12px' />
            </div>
          )}
          <div className='p-8'>
            <div className='flex justify-center items-center pb-4'>
              <img src={googleDriveIcon} alt="icon.." width='30px' height='32px' />
            </div>
            <div className={cn('text-[20px] font-jost font-semibold', !googleDriveImportError && 'pb-4')}>
              {googleDriveImportError ?
                'Something went wrong, please try again after some time' :
                'Importing Image from Google Drive...'
              }
            </div>
            {!googleDriveImportError && <ClipLoader color='#757D89' />}
          </div>
        </ModalPopup>
      )}
      {reNamePopUp && (
        <ProjectNamePopup
          reNamePopUp={reNamePopUp}
          selectedProject={selectedDocument}
          handleClosePopup={handleClosePopup}
          handleConfirm={handleConfirm}
          handleRefreshProjectList={handleRefreshProjectList}
          handleSubmit={handleRenameSubmit}
          isFileRename={true}
          isLoading={isLoading}
          isDocumentRename
        />
      )}
      {deleteConfirmPopUp && (
        <DeleteConfirmPopup
          handleDeletePopup={handleDeletePopup}
          handleSubmit={handleDeleteSubmit}
          isLoading={isLoading}
        />
      )}
      {showTemplatePopup && (
        <TemplatePopup
          projectID={projectID}
          projectName={existingDocumentLists?.project?.project_name}
          handleCloseTemplatePopup={handleCloseTemplatePopup}
          handleRefreshProjectList={handleRefreshProjectList}
          isNewDocument
        />
      )}
      {isUploadFolder && (
        <UploadImgFolderModal
          handleCloseBtn={handleClose}
          setProjectID={setProjectID}
          projectId={projectId}
          isFilesUploading={isFilesUploading}
          handleMultipleImagesUpload={handleMultipleImagesUpload}
        />
      )}
      {showCollectContentPopup && (
        <CollectContentPopUp
          handleCollectContentPopUp={handleCollectContentPopUp}
          projectSharedKeyData={projectSharedKeyData}
          projectShareKeyLoading={projectShareKeyLoading}
          projectName={existingDocumentLists?.project?.project_name}
        />
      )}
      <ImageClickPopUpCollections
        isImgClickPopUps={isImgClickPopUps}
        setIsImgClickPopUps={setIsImgClickPopUps}
        shareLinkKeyData={shareLinkKeyData}
        shareLinkKeyLoading={shareLinkKeyLoading}
        isAllowDownload={isAllowDownload}
        setIsAllowDownload={setIsAllowDownload}
        setProjectIDsList={setProjectIDsList}
        projectIdsList={projectIdsList}
        imageClickData={imageClickData}
        getProjectImageList={getProjectImageList}
        multiImageSelectedList={multiImageSelectedList}
        existingDocumentLists={existingDocumentLists}
        updatedImgName={updatedImgName}
        setProjectID={setProjectID}
        setUpdatedImgName={setUpdatedImgName}
        projectId={projectId}
      />
      {isShareProjectPopupOpen && (
        <ShareProjectPopup
          setIsShareProjectPopupOpen={setIsShareProjectPopupOpen}
          isAllowDownloadForProject={isAllowDownloadForProject}
          setIsAllowDownloadForProject={setIsAllowDownloadForProject}
          projectShareKeyData={projectShareKeyData}
          isProjectShareLinkLoading={isProjectShareLinkLoading}
          projectID={projectID}
        />
      )}
      {(isLinkError && !projectLoading) ? (
        <div className='flex flex-col justify-center items-center font-jost'>
          <img src={LinkExpiredImage} alt='' className='w-[501px] h-[501px]' />
          <div className='text-[#0038FF] text-[36px] font-[600] pt-[40px]'>Sorry, the link has expired.</div>
          <div className='text-[#0038FF] text-[24px] font-[400] pt-[20px]'>
            Please contact the person who shared the link with you
          </div>
        </div>
      ) : 
      ((!isLinkError) && (
        <>
          {projectShareKey && (
            <div
            className="w-full h-14 px-5 py-2 bg-white border-b 
              border-[#D9D9D9] justify-between items-center inline-flex"
          >
            <div className="grid grid-cols-12 w-full">
              <div className='col-span-6'>
                <img src={breezeLogo} alt='logo' className={'w-[86px] h-[16px] cursor-pointer'} />
              </div>
              <div
                className="col-span-6 text-right text-[#7D7D7D] text-base font-normal font-jost"
              >
                {projectImgList?.share_link_info?.user_name}
              </div>
            </div>
          </div>
          )}
          <div className='pl-[40px] pt-[40px] pr-[40px]'>
            <div className='flex flex-col gap-[20px]' id='imageLibraryFilter'>
              <div className='text-[30px] font-[400] text-[#000000]'>
                {projectShareKey ? projectImgList?.project_name : existingDocumentLists?.project?.project_name}
              </div>
              <div className='grid grid-cols-6'>
                <div className='col-span-2 h-[48px] flex justify-start left-0 border-2 rounded-[4px]'>
                  <Formik
                    initialValues={searchedData ? { searchField: searchedData } : {}}
                    enableReinitialize
                  >
                    <Form className={`w-full h-full ${selectedTab === 'Templates' && 'cursor-not-allowed'}`}>
                      <div className='flex flex-row px-[10px] h-full gap-[10px]'>
                        <div className='flex items-center justify-center'>
                          <img
                            src={searchIcon}
                            alt='searchIcon'
                            className='w-[20px] h-[20px]'
                          />
                        </div>
                        <div className='flex items-center justify-start w-full'>
                          {!(selectedTab === 'Templates') && <Field
                            type='search'
                            className='w-full px-0 text-base font-normal font-jost customTextBoxColor leading-normal text-gray-800 bg-white border-0 appearance-none'
                            name='searchField'
                            placeholder='Search...'
                            onChange={handleSearchData}
                            autoComplete='off'
                          />}
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
                {projectShareKey && projectImgList?.share_link_info?.allow_download && (
                  <div className='flex col-start-6 justify-self-end'>
                    <AppButton
                      onClick={() => handleDownloadAll()}
                      type="button"
                      variant='light'
                      extendClass='bottom-[5px] right-[5px] !bg-[#0038FF] rounded-[5px] font-[500]
                    h-[43px] flex justify-center items-center px-[20px] py-[10px] !text-white !text-[16px]'
                    >
                      Download all
                    </AppButton>
                  </div>
                )}
                {!projectShareKey && (
                  <>
                    <div className='flex col-start-6 gap-[20px] justify-self-end'>
                      <div className='cursor-pointer' onClick={() => { getProjectShareLinkKey(); setIsShareProjectPopupOpen(true); }}>
                        <div className='h-[48px] w-[79px] rounded-[5px] py-[10px] px-[20px] bg-[#F0F0F0]'>
                          <div className='text-[16px] text-[#333333]'>
                            Share
                          </div>
                        </div>
                      </div>
                      <div className='items-center relative'>
                        <div className='relative'>
                          <div
                            className='w-[158px] h-12 px-[20px] py-[10px] bg-[#333] rounded border border-black justify-center 
                          items-center gap-[10px] inline-flex cursor-pointer'
                            onClick={() => setIsUpload(!isUpload)}
                          >
                            <img
                              alt='plusIcon'
                              className='w-[20px] h-[20px]'
                              src={plusIcon}
                            />
                            <div
                              className='text-white text-base font-medium font-jost'
                            >
                              Add Image
                            </div>
                          </div>
                          {isUpload && (
                            <div className='absolute top-0 left-[-60px]'>
                              <AddImages
                                setIsUpload={setIsUpload}
                                setSelectedFile={setSelectedFile}
                                setIsUploadFolder={setIsUploadFolder}
                                showCollectContent
                                handleCollectContentPopUp={handleCollectContentPopUp}
                                setGoogleDriveImportLoading={setGoogleDriveImportLoading}
                                setGoogleDriveImportError={setGoogleDriveImportError}
                                setImageUploadingOrigin={setImageUploadingOrigin}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <FilterComponent
                searchDataList={searchDataList}
                searchedText={searchedText}
                selectedTab={selectedTab}
                setSearchDataList={setSearchDataList}
                setLoading={setLoading}
                setIsSearched={setIsSearched}
                searchedData={searchedData}
              /> 
            </div>
            <hr className={cn('border-[1px] border-[#D9D9D9]')} />
          <div className='grid grid-cols-6 pt-[20px] pb-[40px]'>
            <div className='col-span-4 flex flex-row justify-start gap-[20px]' id='image-selector'>
              <TabButton
                active={selectedTab === 'Images'}
                onClick={() => {
                  setSelectedTab('Images');
                }}
                className='text-[20px]
                  font-jost font-[400] h-[48px] px-[20px] py-[10px] text-[#000]'
                type='button'
                style={{ text: 'nowrap' }}
                activeClassName={{ color: '#000', borderBottom: '3px solid #000' }}
                fontWeight={selectedTab === 'Images' ? '600' : '400'}
              >
                Images {imgCount > 0 && `(${imgCount})`}
              </TabButton>
              {!projectShareKey && showCreateModule && (
                <TabButton
                  active={selectedTab === 'Templates'}
                  onClick={() => {
                    setSelectedTab('Templates');
                  }}
                  type='button'
                  className='text-[20px]
                    font-jost font-[400] h-[48px] px-[20px] py-[10px] text-[#000]'
                  activeClassName={{ color: '#000', borderBottom: '3px solid #000' }}
                  fontWeight={selectedTab === 'Templates' ? '600' : '400'}
                >
                  Templates created
                </TabButton>
              )}
            </div>
            {selectedTab === 'Images' && (
              <div className='col-start-7 col-span-1 flex flex-row justify-end gap-[10px]' id='image-selector'>
                <div
                  className='w-[24px] flex justify-center items-center cursor-pointer'
                  role='presentation'
                  onClick={() => setSelectedView('galleryView')}
                >
                  <img
                    src={selectedView === 'galleryView' ? `${grid_view_filled}` : `${grid_view}`}
                    alt=''
                    className='w-[24px] h-[24px]'
                  />
                </div>
                <div
                  className='w-[24px] flex justify-center items-center cursor-pointer'
                  role='presentation'
                  onClick={() => setSelectedView('listView')}
                >
                  <img
                    src={selectedView === 'listView' ? `${format_list_bulleted}` : `${format_list}`}
                    alt=''
                    className='w-[24px] h-[24px]'
                  />
                </div>
              </div>
            )}
          </div>
            {selectedTab === 'Templates' && (projectLoading ? (
              <div className='flex justify-center items-center'>
                <div className='spinner-border flex flex-col justify-center items-center' role='status'>
                  <ClipLoader color='#757D89' />
                  <div className='lg:text-[14px] xl:text-[16px] xl-mid:text-[18px] 4xl:text-[20px] font-semibold mb-2'>
                    Loading...
                  </div>
                </div>
              </div>
            ) : (
              <div className='flex flex-wrap gap-[20px] mb-[40px]'>
                <div
                  className="w-[198px] h-[263px] pl-[17px] pr-[18px] pt-[54.50px] pb-[55.50px] rounded-[5px] border-2 border-dashed border-[#7D7D7D] flex-col justify-center items-center inline-flex cursor-pointer"
                  onClick={() => setShowTemplatePopup(true)}
                >
                  <div className="self-stretch flex-col justify-start items-center gap-5 inline-flex">
                    <div className="w-12 h-12 relative">
                      <img src={plusIconAccord} className="w-[48px] h-[48px]" width="48px" height="48px" alt='' />
                    </div>
                    <div className="flex-col justify-start items-center gap-2.5 flex">
                      <div className="text-black text-xl font-normal font-jost">New document</div>
                      <div className="w-[163px] text-center text-[#7D7D7D] text-base font-normal font-jost">Create from list of templates</div>
                    </div>
                  </div>
                </div>
                {existingDocumentLists?.documents?.map((documentItem, index) => (
                  <>
                    <div
                      className='border-solid border-2 border-[#D3D3D3] w-[198px] h-[263px]
                      hover:cursor-pointer justify-center rounded-[5px] relative'
                      onMouseLeave={() => { setMenuOptionOpen(false); }}
                      key={index}
                    >
                      <div className='flex flex-col'
                        onClick={() => handleNavigationToTemplatePage(documentItem?.template, documentItem)} >
                        <div className='flex items-center justify-center bg-[#F0F0F0] rounded-t-[3px] w-[194px] h-[175px]'>
                          <img
                            src={documentItem?.template?.type === 'image' ? (documentItem?.image || dummyProjectImg) : dummyTextImage}
                            alt=''
                            className='max-w-[194px] max-h-[175px] m-auto rounded-t-[3px]'
                          />
                        </div>
                        <div className="w-full h-[84px] px-5 py-2.5 bg-white justify-between items-center inline-flex rounded-b-[5px]">
                          <div className="flex-col justify-start items-start gap-[5px] inline-flex">
                            <div className="flex-col justify-start items-start flex">
                              <div className="text-black text-[16px] font-normal font-jost max-w-[135px] truncate ...">
                                {documentItem?.document_name || 'Untitled'}
                              </div>
                              <div className='text-[#7D7D7D] text-[16px] font-normal font-jost max-w-[135px] truncate ...'>
                                {documentItem?.template?.subname ? documentItem?.template?.name + ' ' +
                                  documentItem?.template?.subname : documentItem?.template?.name}
                              </div>
                            </div>
                            <div className="text-[#7D7D7D] text-xs font-normal font-jost">
                              {handleProjectModifiedDate(documentItem?.modifiedAt)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='absolute right-[20px] bottom-[32px]'>
                        <div className='relative'>
                          <img
                            src={verticalOptionIcon}
                            width={24}
                            height={24}
                            alt=''
                            id={documentItem?.documentID}
                            onClick={toggleMenuOption}
                            onMouseEnter={() => { setSelectedDocument(documentItem); }}
                          />
                          {menuOptionOpen && (selectedDocument?.documentID === documentItem?.documentID) && (
                            <div
                              className='border-solid border-1 border-[#D3D3D3] w-[118px] h-[96px]
                            hover:cursor-pointer justify-center absolute top-[28px] z-10 bg-white
                            left-[-9px] rounded-[5px]'
                              style={{ boxShadow: '0px 4px 10px 0px #0000004D' }}
                            >
                              <ul>
                                <li
                                  className='px-[20px] py-[10px] flex flex-row items-center gap-[10px] h-[47px]'
                                  onClick={() => setRenamePopUp(true)}
                                >
                                  <img
                                    src={renameOutline}
                                    width={20}
                                    height={20}
                                    alt=''
                                    className='w-[20px] h-[20px] flex items-center' />
                                  <div className='text-[14px] font-[400] text-[#000000] leading-[28.9px]'>Rename</div>
                                </li>
                              </ul>
                              <ul>
                                <li
                                  className='px-[20px] py-[10px] flex flex-row items-center gap-[10px] h-[47px]'
                                  onClick={() => setDeleteConfirmPopUp(true)}
                                >
                                  <img
                                    src={deleteIcon}
                                    width={20}
                                    height={20}
                                    alt=''
                                    className='w-[20px] h-[20px] flex items-center' />
                                  <div className='text-[14px] font-[400] text-[#000000] leading-[28.9px]'>Delete</div>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            ))}
            {selectedTab === 'Images' && selectedView === 'galleryView' && (
              <ProjectGalleryView
                handleImageClick={handleImageClick}
                projectImgList={projectImgList}
                seImageClickData={seImageClickData}
                setIsImgClickPopUps={setIsImgClickPopUps}
                isImgClickPopUps={isImgClickPopUps}
                setMultiImageSelectedList={setMultiImageSelectedList}
                isSearched={isSearched}
                searchDataList={searchDataList}
                loading={loading}
                setShareLinkKeyData={setShareLinkKeyData}
                setShareLinkLoading={setShareLinkLoading}
                setIsAllowDownload={setIsAllowDownload}
                isFilesUploading={isFilesUploading}
                projectShareKey={projectShareKey}
                projectShareAllowDownload={projectImgList?.share_link_info?.allow_download}
              />
            )}
            {selectedTab === 'Images' && selectedView === 'listView' && (
              <ProjectListView
                handleImageClick={handleImageClick}
                projectImgList={projectImgList}
                seImageClickData={seImageClickData}
                setIsImgClickPopUps={setIsImgClickPopUps}
                isImgClickPopUps={isImgClickPopUps}
                setMultiImageSelectedList={setMultiImageSelectedList}
                isSearched={isSearched}
                searchDataList={searchDataList}
                loading={loading}
                setShareLinkKeyData={setShareLinkKeyData}
                setShareLinkLoading={setShareLinkLoading}
                setIsAllowDownload={setIsAllowDownload}
                isFilesUploading={isFilesUploading}
                projectShareKey={projectShareKey}
                projectShareAllowDownload={projectImgList?.share_link_info?.allow_download}
              />
            )}
          </div>
        </>
      ))}
    </div>
  );
};

export default ProjectDocuments;