import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ClipLoader } from 'react-spinners';
import 'react-lazy-load-image-component/src/effects/blur.css';
import horizontalOptionIcon from 'assets/imgFunctionIcons/horizontalOptionIcon.svg';
import OpenIcon from 'assets/imgFunctionIcons/OpenHover.svg';
import ImageClickFunctions from '../../ImageClickFunctions';
import cn from 'classnames';
import { getDropdownPosition } from 'utils/commonFunctions';
import useClickOutsideHandler from 'components/customHooks/useClickOutsideHandler';

const ProjectGalleryView = (props) => {
  const {
    handleImageClick,
    projectImgList,
    seImageClickData,
    setIsImgClickPopUps,
    isImgClickPopUps,
    setMultiImageSelectedList,
    isSearched,
    searchDataList,
    loading,
    setShareLinkKeyData,
    setShareLinkLoading,
    setIsAllowDownload,
    isFilesUploading,
    projectShareKey,
    projectShareAllowDownload
  } = props;
  const [isValidSrc, setIsValidSrc] = useState(true);

  const [hovered, setHovered] = useState(false);
  const [imgIndex, setImgIndex] = useState('');
  const [imgFunctionPopUp, setImgFunctionPopUp] = useState(false);
  const [selectedAllImages, setSelectedAllImages] = useState([]); // to store selected images
  const [imageList, setImageList] = useState([]); // storing image list
  const [filteredImageList, setFilteredImageList] = useState([]); // storing modified searchResult data
  
  // Custom hook to handle the deselecting the selected image if the user click outside
  const clickImageCardRef = useClickOutsideHandler(
    'clickImageList',
    () => {
      if (imageList?.length > 0 && selectedAllImages?.length > 0) {
        const updatedImages = imageList?.map((item) => ({
          ...item,
          selected: false
        }));
        setImageList(updatedImages);
        setSelectedAllImages([]);
      }
    },
    [imageList, selectedAllImages, isFilesUploading]
  );

  // Clearing the selectedImages
  useEffect(() => {
    setSelectedAllImages([]);
  }, []);

  // DeSelecting the selected Images if we click out side the image list 
  useEffect(() => {
    clickImageCardRef.current = document.getElementById('clickImageList');
  }, []);

  useEffect(() => {
    setMultiImageSelectedList(selectedAllImages);
  }, [selectedAllImages]);

  const handleIndexData = (listData) => {
    const tempData = listData?.map((item, index) => {
      if (item) {
        item['index'] = index;
        item['selected'] = false;
      }
      return item;
    });
    return tempData;
  };

  // Altering the search list data for multiSelect feature
  useEffect(() => {
    if (searchDataList?.length > 0) {
      setFilteredImageList(handleIndexData(searchDataList));
    } else {
      setFilteredImageList([]);
    }
  }, [searchDataList]);

  useEffect(() => {
    if (projectImgList) {
      setImageList(handleIndexData(projectImgList?.history));
    }
  }, [projectImgList]);

  const handleMouseEnter = (index) => {
    setHovered(true);
    setImgIndex(index);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setImgIndex('');
  };

  // to get the last index of each row to set the popUp position
  const getLastRowItemIndex = (index) => {
    var columns = 4;
    if (window.innerWidth > 1700) {
      columns = 6;
    } else if (window.innerWidth > 1200) {
      columns = 5;
    } else {
      columns = 4;
    }
    return Math.floor(index / columns) * columns + columns - 1;
  };

  const handleDropdownPosition = (imageIndex, item) => {
    const imageElement = document.querySelector(`#Image-${imageIndex}`);
    const containerElement = document.getElementById('imageContainer');
    const dropdownHeight = item?.selected ? '120' : '350';
    const dropdownPosition = getDropdownPosition(imageElement, containerElement, dropdownHeight);
    return dropdownPosition;
  };

  // Function to handle multiSelection of images
  const handleMultiImageSelection = (imageData, event) => {
    const isSelected = selectedAllImages?.some((item) => {
      if (imageData?.gid) {
        return item?.gid === imageData?.gid;
      } else if (imageData?.pid) {
        return item?.pid === imageData?.pid;
      }
      return false;
    });

    const imageListData = isSearched ? filteredImageList : imageList;
    let selectedImageList = [];
    const specialKeySelected = event.altKey || event.ctrlKey || event.shiftKey;
    const selectedImageIndex = imageListData?.findIndex((item) => item === imageData);

    const updateSelectedState = (images, index, selected) => {
      const updatedImages = [...images];
      updatedImages[index].selected = selected;
      return updatedImages;
    };

    if (isSelected && specialKeySelected) {
      // If the image is already selected and a special key is pressed,
      // remove the selected item when clicking it again
      setSelectedAllImages((prevSelectedImages) =>
        prevSelectedImages.filter(
          (item) => !(item.gid === imageData.gid || item.pid === imageData.pid)
        )
      );
      selectedImageList = (updateSelectedState(imageListData, selectedImageIndex, false));
    } else if (!isSelected && specialKeySelected) {
      // Select the image only if it's not already selected and a special key is pressed
      setSelectedAllImages((prevSelectedImages) => [
        ...prevSelectedImages,
        { ...imageData, selected: true }
      ]);
      selectedImageList = (updateSelectedState(imageListData, selectedImageIndex, true));
    } else if (!specialKeySelected) {
      // Deselect all other images and select the current image if no special key is pressed
      const updatedImages = imageListData?.map((item) => ({ ...item, selected: false }));
      selectedImageList = (updateSelectedState(updatedImages, selectedImageIndex, true));
      setSelectedAllImages([{ ...imageData, selected: true }]);
    }

    if (selectedImageList?.length > 0) {
      if (isSearched) {
        setFilteredImageList(selectedImageList);
      } else setImageList(selectedImageList);
    }
  };

  // Function to handle multiSelection of images count
  const handleMultiSelectCount = () => {
    const countValue = selectedAllImages?.length;
    return countValue;
  };

  return (
    <>
      {(isSearched ? filteredImageList?.length > 0 : imageList?.length > 0) && (
        <div id='imageContainer' style={{ height: '60vh', overflow: 'scroll' }}>
          <div
            className='grid grid-cols-6 lg:grid-cols-4 xl:grid-cols-5  xl-mid:grid-cols-5 2xl:grid-cols-6 gap-4 
              z-10 w-full p-[5px]'
            id='clickImageList'
            ref={clickImageCardRef}
          >
            {(isSearched ? filteredImageList : imageList)?.map((item, index) => (
              <div key={index} id={`Image-${index}`}
                className={cn('aspect-square flex justify-center rounded-[5px] bg-[#F1F1F1] flex-none',
                  'hover:border-[6px] hover:border-[#d9d9d9]',
                  item?.selected && `rounded-[10px] outline outline-offset-0 outline-3 !border-[0px]
              !outline-blue-700 p-[7px]`)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave()}
                onClick={(event) => item && handleMultiImageSelection(item, event)}
                onMouseDown={(e) => e?.shiftKey && e?.preventDefault()}
              >
                <div className='w-full h-auto flex justify-center relative'>
                  <div className='w-full h-auto flex justify-center items-center'>
                    {(item === null) ? (
                      <div className='bg-[#F1F1F1] max-w-full max-h-full rounded-[2px]
                    flex flex-col items-center justify-center m-auto'>
                        <ClipLoader color='#7D7D7D' />
                        <div className='text-[#7D7D7D] text-[20px] font-[600] pt-[10px] font-jost'>
                          Loading
                        </div>
                      </div>
                    ) : (
                      <LazyLoadImage
                        src={(isValidSrc && item?.thumbnail ? item?.thumbnail : (item?.input_image_url || item?.url))}
                        alt={index}
                        className='max-w-full max-h-full rounded-[2px]'
                        onError={() => setIsValidSrc(false)}
                      />
                    )}
                    {hovered && imgIndex === index && (
                      <>
                        <div
                          className="absolute inset-0"
                          style={{
                            background: 'linear-gradient(0deg, #222 0.13%, rgba(217, 217, 217, 0.00) 67.15%)',
                            pointerEvents: 'none',
                            zIndex: 10
                          }}
                        ></div>
                        <div className="absolute top-[13px] right-[13px] flex gap-[5px] z-40">
                          <div className='w-[28px] h-[28px] bg-[#000000a6] rounded-[5px] hover:bg-[#000]'
                            onClick={(e) => { e.stopPropagation(); handleImageClick(item); }}
                          >
                            <img alt='icon' src={OpenIcon} />
                          </div>
                          <div className={`w-[28px] h-[28px] bg-[#000000a6] rounded-[5px] hover:bg-[#000] 
                        ${imgFunctionPopUp && 'bg-[#000]'}`}
                            onClick={(e) => {
                              e.stopPropagation(); setImgFunctionPopUp(!imgFunctionPopUp), seImageClickData(item);
                            }}
                          >
                            <img alt='icon' src={horizontalOptionIcon} />
                          </div>
                          {/*on image click PopUp Component */}
                          {imgFunctionPopUp && (
                            <ImageClickFunctions
                              setImgFunctionPopUp={setImgFunctionPopUp}
                              handleImageClick={() => handleImageClick(item)}
                              imageData={item}
                              position={getLastRowItemIndex(index) === index}
                              dropdownPosition={handleDropdownPosition(index, item)}
                              setIsImgClickPopUps={setIsImgClickPopUps}
                              isImgClickPopUps={isImgClickPopUps}
                              multiSelectCount={handleMultiSelectCount()}
                              isUploadImagesOnly={selectedAllImages?.length > 0}
                              multiSelectedItems={selectedAllImages}
                              setShareLinkKeyData={setShareLinkKeyData}
                              setShareLinkLoading={setShareLinkLoading}
                              setIsAllowDownload={setIsAllowDownload}
                              projectShareKey={projectShareKey}
                              projectShareAllowDownload={projectShareAllowDownload}
                            />
                          )}
                        </div>
                        <div className="absolute bottom-[35px] left-[20px] text-[#FFFFFF] z-[10] text-[20px] 
                      w-[90%] overflow-hidden whitespace-nowrap overflow-ellipsis">
                          {item?.image_name || 'File name'}
                        </div>
                        <div className="absolute bottom-[10px] left-[20px] h-[24px] text-[#FFFFFF] text-[16px] z-[10]
                      flex justify-center items-center gap-[6px]">
                          <div>{item?.image_type}</div>
                          <div className='mb-[10px]'>.</div>
                          <div>{`${item?.dimensions[0]} x ${item?.dimensions[1]}`}</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {(isSearched ? filteredImageList?.length === 0 : imageList?.length === 0) && (
        !loading && <div className='w-full h-full font-jost font-[600] text-[18px] whitespace-nowrap'>
          No record found
        </div>
      )}
    </>
  );
};

export default ProjectGalleryView;