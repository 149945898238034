import React from 'react';
import { AppButton } from 'components/shared/AppButton';
import { BASE_URL } from 'utils/baseURL';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';

const RemoveImgModal = ({
  handleCloseBtn,
  existingDocumentLists,
  imageClickData,
  getProjectImageList
}) => {

  const handRemoveBtn = async () => {
    const data = new FormData();
    const imgPid = imageClickData?.pid;
    data.append('pids', [imgPid]);
    data.append('projectID', existingDocumentLists?.project?.projectID);

    const config = {
      method: 'delete',
      url: BASE_URL + 'image-gen/removeFromProject',
      headers: {
        token: LocalStorage.getItem(),
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    try {
      const response = await axios(config);
      if (response && response.data) {
        getProjectImageList();
      }
    } catch (err) {
      console.log('Something went wrong');
    }
    handleCloseBtn();
  };

  return (
    <div
      className='relative z-50'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
      onClick={(e) => e?.stopPropagation()}
    >
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>
      <div className='fixed inset-0 z-50 overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center text-center sm:items-center text-[#000]'>
          <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
          transition-all sm:my-8 sm:mx-4 w-[462px] h-[214px] px-[40px] py-[40px]'>
            <div className='bg-white'>
              <div className='w-full'>
                <div className='text-left'>
                  <div className='flex justify-between items-center mb-[20px]'>
                    <span
                      className='font-jost font-[600] text-[20px] whitespace-nowrap'
                      id='modal-title'
                    >
                      Remove from {existingDocumentLists?.project?.project_name}?
                    </span>
                  </div>
                  <div className='mt-[10px] text-[#7D7D7D]'>
                    Assets will still be available in main Library
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full flex justify-end items-center gap-[10px] mt-[20px]'>
              <AppButton
                onClick={() => handleCloseBtn()}
                type='button'
                variant='light'
                extendClass='bg-black hover:bg-black rounded-[5px] border-[0px] text-[20px] 
                w-[99px] h-[48px] px-[20px] py-[10px]'
              >
                Cancel
              </AppButton>
              <AppButton
                onClick={() => { handRemoveBtn(); }}
                type='button'
                variant='black'
                extendClass='bg-[#C00000] hover:bg-[#C00000] rounded-[5px] text-[20px] 
                w-[107px] h-[48px] px-[20px] py-[10px]'
              >
                Remove
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveImgModal;