import React from 'react';
import RenameImgModal from './RenameImgModal';
import RemoveImgModal from './RemoveImgModal';
import DeleteImgModal from './DeleteImgModal';
import AddToModal from './AddToModal';
import MoveToModal from './MoveToModal';
import ShareLinkModal from './ShareLinkModal';

const ImageClickPopUpCollections = ({
  shareLinkKeyData,
  shareLinkKeyLoading,
  isAllowDownload,
  setIsAllowDownload,
  setProjectIDsList,
  projectIdsList,
  imageClickData,
  getProjectImageList,
  multiImageSelectedList,
  existingDocumentLists,
  updatedImgName,
  setProjectID,
  setUpdatedImgName,
  projectId,
  isImgClickPopUps,
  setIsImgClickPopUps,
  reCallImageList
}) => {
  const handleClose = () => {
    setIsImgClickPopUps({
      shareLink: false,
      addTo: false,
      moveTo: false,
      renameImg: false,
      removeImg: false,
      deleteImg: false
    });
  };
  return (
    <>
      {isImgClickPopUps?.shareLink && (
        <ShareLinkModal
          handleCloseBtn={handleClose}
          shareLinkKeyData={shareLinkKeyData}
          shareLinkKeyLoading={shareLinkKeyLoading}
          isAllowDownload={isAllowDownload}
          setIsAllowDownload={setIsAllowDownload}
        />
      )}
      {isImgClickPopUps?.addTo && (
        <AddToModal
          handleCloseBtn={handleClose}
          setProjectIDsList={setProjectIDsList}
          projectIdsList={projectIdsList}
          currProjectID={imageClickData?.projectID}
          multiImageSelectedList={multiImageSelectedList}
          imageClickData={imageClickData}
          getProjectImageList={getProjectImageList}
          reCallImageList={reCallImageList}
        />
      )}
      {isImgClickPopUps?.moveTo && (
        <MoveToModal
          handleCloseBtn={handleClose}
          setProjectID={setProjectID}
          projectId={projectId}
          imageClickData={imageClickData}
          getProjectImageList={getProjectImageList}
        />
      )}
      {isImgClickPopUps?.renameImg && (
        <RenameImgModal
          handleCloseBtn={handleClose}
          setUpdatedImgName={setUpdatedImgName}
          imageClickData={imageClickData}
          updatedImgName={updatedImgName}
          getProjectImageList={getProjectImageList}
          reCallImageList={reCallImageList}
        />
      )}
      {isImgClickPopUps?.removeImg && (
        <RemoveImgModal
          handleCloseBtn={handleClose}
          imageClickData={imageClickData}
          existingDocumentLists={existingDocumentLists}
          getProjectImageList={getProjectImageList}
        />
      )}
      {isImgClickPopUps?.deleteImg && (
        <DeleteImgModal
          handleCloseBtn={handleClose}
          imageClickData={imageClickData}
          multiImageSelectedList={multiImageSelectedList}
          getProjectImageList={getProjectImageList}
          reCallImageList={reCallImageList}
        />
      )}
    </>
  );
};

export default ImageClickPopUpCollections;