import React, { useState } from 'react';
import loadingGif from 'assets/img/loaderAnimation.gif';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ClipLoader } from 'react-spinners';
import cn from 'classnames';

const RenderSmoothImageComponent = (props) => {
  const {
    src,
    alt = 'notFound',
    objectFit = 'contain',
    setImageLoading,
    loader,
    isLazyLoading,
    noBackGround,
    loadGif,
    upscaled
  } = props;
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isValidSrc, setIsValidSrc] = useState(!!src);

  const ImageComponent = () => {
    return isLazyLoading ? (
      <>
        <LazyLoadImage
          className={`smooth-image max-w-full max-h-full ${imageLoaded ? 'opacity-100' : 'hidden'}`}
          style={{ objectFit }}
          src={src}
          alt={alt}
          onLoad={() => { setImageLoaded(true); loader && setImageLoading(true); }}
          onError={() => setIsValidSrc(false)}
        />
        {(upscaled && imageLoaded) && 
          <div
            className='absolute rounded-md w-full h-full'
            style={{
              background: 'rgba(0, 0, 0, 0.5)',
              top: '0px'
            }}
          >
            <div
              className='absolute items-center flex justify-center leading-normal 
              rounded-md w-full h-full font-jost text-[20px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]
              font-[600] inline p-1 text-center text-[#FFFFFF]'
            >
              <span className='opacity-80'>Upscaled</span>
            </div>
          </div>
        }
      </>
    ) : (
      <>
        <img
          className={`smooth-image max-w-full max-h-full ${imageLoaded ? 'opacity-100' : 'hidden'}`}
          style={{ objectFit }}
          src={src}
          alt={alt}
          onLoad={() => { setImageLoaded(true); loader && setImageLoading(true); }}
          onError={() => setIsValidSrc(false)}
        />
        {(upscaled && imageLoaded) && 
          <div
            className='absolute rounded-md w-full h-full'
            style={{
              background: 'rgba(0, 0, 0, 0.5)',
              top: '0px'
            }}
          >
            <div
              className='absolute items-center flex justify-center leading-normal 
              rounded-md w-full h-full font-jost text-[20px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]
              font-[600] inline p-1 text-center text-[#FFFFFF]'
            >
              <span className='opacity-80'>Upscaled</span>
            </div>
          </div>
        }
      </>
    );
  };

  return (
    <div className={cn(!noBackGround && 'bg-[#F1F1F1]',
    'aspect-square flex items-center justify-center relative w-full max-h-full')}>
      {isValidSrc ? (
        <ImageComponent />
      ) : (
        <div className={cn(!noBackGround && 'bg-[#F1F1F1]',
          'm-auto rounded-[2px] flex items-center justify-center aspect-square w-full h-full'
        )}>
          {loadGif ? (
            <img src={loadingGif} alt="loading..." className="w-[36px]"/>
          ) : (
            <ClipLoader color='#757D89' />
          )}
        </div>
      )}
      {isValidSrc && !imageLoaded && (
        <div className={cn(!noBackGround && 'bg-[#F1F1F1]',
          'm-auto rounded-[2px] flex items-center justify-center aspect-square w-full h-full'
        )}>
          {loadGif ? (
            <img src={loadingGif} alt="loading..." className="w-[36px]"/>
          ) : (
            <ClipLoader color='#757D89' />
          )}
        </div>
      )}
    </div>
  );
};

export default RenderSmoothImageComponent;
