import React from 'react';
import { useField, useFormikContext } from 'formik';
import cn from 'classnames';

const TextField = ({ disabled, customClass, label, ...props }) => {
  const formData = useFormikContext();
  const [field, meta] = useField(props);
  return (
    <div className='font-jost'>
      {label && (
        <label className='flex w-full text-left text-[20px] lg:text-[16px]
          xl:text-[18px] 2xl:text-[20px] font-[400] text-[#2C2C2C]'>
          {label}
        </label>
      )}
      <input
        className={cn(customClass, 'customTextBoxColor w-full rounded-md border-[#D9D9D9] mt-[5px]',
          'lg:text-[12px] xl:text-[14px] 2xl:text-[16px]', disabled && 'cursor-not-allowed')}
        {...field}
        {...props}
      />
      {formData?.submitCount > 0
        || (meta.error && meta.touched) ? (
        <div className='error text-red-500 text-[12px]'>{meta.error}</div>
      ) : null}
    </div>
  );
};

export default TextField;