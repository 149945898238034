import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { BriefcaseIcon } from '@heroicons/react/24/solid';
import StatisticsCard from 'components/StatisticsCard';
// import PlanCard from 'components/PlanCard';
import AppLoader from 'components/shared';
import { getUserStatistics } from 'queryhook/accounts/getUserStatistics';

const cardIcon = <BriefcaseIcon className='w-10 h-10' aria-hidden />;

const Accounts = () => {
  const { data, isLoading } = useQuery('userStatistics', getUserStatistics);

  useEffect(() => {
    window.analytics.page('Account Page', '/account', {
      userId: localStorage.getItem('user-id'),
      email: localStorage.getItem('user-email')
    });
  }, []);

  if (isLoading)
    return (
      <h2 className=''>
        {' '}
        <AppLoader isLoading={isLoading} size={70} />
      </h2>
    );

  return (
    <div className='h-full px-3'>
      <div className='flex flex-wrap justify-start gap-4 mb-7 lg:gap-12 mb-[25px]'>
        <StatisticsCard value={data?.data?.input_images_count} label='Input Image Count' icon={cardIcon} />
        <StatisticsCard value={data?.data?.generated_images_count} label='Generated Images Count' icon={cardIcon} />
        <StatisticsCard value={data?.data?.credits_used} label='Credits Used' icon={cardIcon} />
        <StatisticsCard
          value={data?.data?.credits_remaining}
          label='Credits remaining'
          icon={cardIcon}
        />
      </div>
      {/* <stripe-pricing-table pricing-table-id='prctbl_1N3I9ZE3QGdTkzHbuuPyiHAz'
        publishable-key=
        'pk_live_51MgBS4E3QGdTkzHbO129sUVP0WdgqdqJWvtPDpyEVstW6Tl1nUXcl8RCoGkiGUJ2aHaYIywwwoCh5oP8mBpYfng800AMg0yI08'
        client-reference-id={data?.data?.userID}
      >
      </stripe-pricing-table> */}
      {/* <div className='flex justify-between gap-5 my-7'>
				<PlanCard
					title='Starter Plan'
					price={data?.data?.credits_used}
					balance={data?.data?.total_credits}
					email={data?.data?.email}
					id={data?.data?.userID}
				/>
			</div> */}
    </div>
  );
};

export default Accounts;
