/* eslint-disable react/react-in-jsx-scope */
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MainProvider } from 'Contexts/MainProvider';

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 3,
            cacheTime: 0,
            retryDelay: 5000
          }
        }
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <MainProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
        <ToastContainer />
      </MainProvider>
    </QueryClientProvider>
  );
}

export default App;
