import { LocalStorage } from 'services/localStorage';
import { AUTH_API } from '../base';

export const loginRequest = async (formData) => {
	const { data } = await AUTH_API.post('auth/login', {
		...formData
	});
	localStorage.setItem('user-email', formData.email);
	LocalStorage.setItem(data?.token);
	return data;
};

export const emailLoginRequest = async (email) => {
	const formData = new FormData();
	formData.set('email', email);

	const { data } = await AUTH_API.post('auth/email-login', formData);
	localStorage.setItem('user-id', data.userID);
	localStorage.setItem('user-email', email);
	LocalStorage.setItem(data?.token);
	return {data, email};
};

export const registerRequest = async (formData) => {
	const { data } = await AUTH_API.post('auth/sign-up', {
		...formData
	});
	return data;
};

export const resetPassword = async (email) => {
	const formData = new FormData();
	formData.set('email', email.email);

	const { data } = await AUTH_API.post('auth/reset-password', formData);
	return data;
};

