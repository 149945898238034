import React from 'react';

const DeleteContentPopup = (props) => {
  const {
    handleDelete,
    setDeleteConfirmPopup,
    selectedImage
  } = props;

  return (
    <div
      className="relative z-50 flex items-center justify-center font-jost"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="relative flex h-full items-center justify-center p-4 text-center">
          <div
            className="transform overflow-hidden rounded-lg
            bg-white text-left shadow-xl transition-all"
          >
            <div className="bg-white">
              <div className="w-full">
                <div className="w-[462px] h-60 p-10 bg-white rounded-[10px]
                  border border-black border-opacity-20
                  flex-col justify-center items-end gap-5 inline-flex"
                >
                  <div className="flex-col justify-start items-center gap-2.5 flex">
                    <div className="flex-col justify-start items-center gap-2.5 flex">
                      <div className="w-[382px] text-black text-xl font-semibold font-jost">
                        {selectedImage?.filter(item => item?.selected)?.length === 1 ? 'Delete item?' :
                          'Delete items?'}
                      </div>
                    </div>
                    <div className="flex-col justify-start items-center gap-2.5 flex">
                      <div
                        className="w-[382px] text-[#7D7D7D] text-lg font-normal font-jost"
                      >
                          These will be removed from your content submission unless you re-upload them
                      </div>
                    </div>
                  </div>
                  <div className="justify-end items-start gap-2.5 inline-flex">
                      <div
                        className="px-5 py-2.5 rounded-[5px] border justify-end items-start flex cursor-pointer"
                        onClick={() => setDeleteConfirmPopup(false)}
                      >
                        <div className="text-center text-black text-xl font-normal font-jost">
                          Cancel
                        </div>
                      </div>
                      <div
                        className="px-5 py-2.5 bg-red-700 rounded-[5px] justify-end items-start flex cursor-pointer"
                        onClick={() => handleDelete()}
                      >
                        <div className="text-center text-white text-xl font-normal font-jost">
                          Confirm
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteContentPopup;