import React, { useEffect, useState } from 'react';
import { AppButton } from 'components/shared/AppButton';
import closeIcon from 'assets/icons/closeIcon.svg';
import { BASE_URL } from 'utils/baseURL';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import { ClipLoader } from 'react-spinners';
import blueTick from 'assets/img/Bluetick.svg';

const UploadImgFolderModal = ({
  handleCloseBtn,
  projectId,
  setProjectID,
  isFilesUploading,
  handleMultipleImagesUpload
}) => {

  const [projectList, setProjectList] = useState();
  const [isImgLibSelected, setIsImgLibSelected] = useState(projectId ? false : true);

  const getExistingProjectLists = async () => {
    const url = BASE_URL + 'image-gen/projects';
    try {
      const projectListData = await axios.get(
        url,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      setProjectList(projectListData?.data?.data?.projects);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getExistingProjectLists();
  }, []);

  const handleClose = () => {
    setProjectID();
    setIsImgLibSelected(false);
    handleCloseBtn();
  };

  const handleImglocation = (location) => {
    if (location === 'imgLibrary') {
      setIsImgLibSelected(true);
      setProjectID();
    } else {
      setProjectID(location?.projectID);
      setIsImgLibSelected(false);
    }
  };

  return (
    <div className='relative z-50' aria-labelledby='modal-title' role='dialog' aria-modal='true'>
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>
      <div className='fixed inset-0 z-50 overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 w-[514px] h-[514px] p-[40px]'>
            <div className='bg-white flex flex-col gap-[20px]'>
              <div className='w-full'>
                <div className='text-left'>
                  <div className='grid grid-cols-12'>
                    <h3
                      className='font-jost font-[600] text-[24px] whitespace-nowrap col-span-11'
                      id='modal-title'
                    >
                      Upload files to...
                    </h3>
                    <span
                      className='text-right flex items-center justify-center w-[35px] h-[35px] 
                      hover:bg-[#F0F0F0] rounded-[5px] cursor-pointer'
                      role="presentation"
                      onClick={() => handleCloseBtn()}
                    >
                      <img src={closeIcon} alt='' width='20px' height='20px' />
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-[434px] h-[311px] rounded-[10px] border border-solid border-[#D9D9D9] 
                  overflow-scroll p-[20px] flex flex-col gap-[10px]'>
                {projectList ? (
                  <>
                    <>
                      {projectList?.map((projectItem, index) => (
                        <div key={index} className={`p-[10px] text-[16px] cursor-pointer rounded-[5px]
                        ${!(projectId === projectItem?.projectID) && 'hover:bg-[#F0F0F0]'} 
                        ${projectId === projectItem?.projectID && 'bg-[#E5EBFF]'} relative`}
                          onClick={() => handleImglocation(projectItem)}
                        >
                          {projectItem?.project_name}
                          {projectId === projectItem?.projectID && (
                            <img
                              src={blueTick}
                              alt=''
                              className='absolute right-[10px] top-[14px] w-[16px] h-[16px]'
                            />
                          )}
                        </div>
                      ))}
                    </>
                    <div className={`p-[10px] text-[16px]  cursor-pointer rounded-[5px] relative
                      ${isImgLibSelected && 'bg-[#E5EBFF]'} ${!isImgLibSelected && 'hover:bg-[#F0F0F0]'}`}
                      onClick={() => handleImglocation('imgLibrary')}
                    >
                      Image Library
                      {isImgLibSelected && (
                        <img
                          src={blueTick}
                          alt=''
                          className='absolute right-[10px] top-[14px] w-[16px] h-[16px]'
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <div className='w-full h-full flex justify-center items-center'>
                    <ClipLoader color='#757D89' />
                  </div>
                )}
              </div>
              <div className='w-full flex justify-end items-center gap-[10px]'>
                <AppButton
                  onClick={() => handleClose()}
                  type='button'
                  variant='light'
                  extendClass='bg-black hover:bg-black rounded-[5px] border-[0px] text-[20px] h-[48px] px-5 2xl:px-7'
                  disabled={isFilesUploading}
                >
                  Cancel
                </AppButton>
                <AppButton
                  onClick={() => { handleMultipleImagesUpload(); }}
                  type='button'
                  variant='black'
                  extendClass={` rounded-[5px] text-[20px] h-[48px] px-5 2xl:px-7
                  ${((!projectId && !isImgLibSelected) || isFilesUploading) ?
                    'bg-[#D9D9D9] hover:bg-[#D9D9D9] !opacity-100' : 'bg-[#0038FF] hover:bg-[#0038FF]'} `}
                  disabled={(!projectId && !isImgLibSelected) || isFilesUploading}
                >
                  Upload
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadImgFolderModal;