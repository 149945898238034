import React, { useState } from 'react';
import minusIcon from 'assets/icons/minusIcon.svg';
import plusIcon from 'assets/icons/plusIcon.svg';

const AccordionData = ({ label, data }) => {
  const [toggleData, setToggleData] = useState(false);
  return (
    <div className='rounded-md text-[14px] font-jost'>
      <div
        role='presentation'
        className='rounded-[4px] flex items-center justify-between w-full p-1 font-medium text-left border-gray-200
         focus:ring-4 focus:ring-gray-200 bg-gray-100'
        onClick={() => setToggleData(!toggleData)}
      >
        <div className='grid grid-cols-12 w-full px-2 py-1'>
          <span className='col-span-10 flex items-center justify-start h-full text-[20px] lg:text-[16px] xl:text-[18px] 
          2xl:text-[20px] font-[400]'>
            {label}
          </span>
          <span className='col-span-2 flex items-center justify-end text-[20px] px-2'>
            {!toggleData ?
              <img src={plusIcon} alt='' className='w-[13px] h-[13px]' /> :
              <img src={minusIcon} alt='' className='w-[13px] h-[13px]' />
            }
          </span>
        </div>
      </div>
      <div className={!toggleData ? 'hidden' : ''}>
        <div className='grid grid-cols gap-4 p-2 pt-3'>
          {data}
        </div>
      </div>
    </div>
  );
};

export default AccordionData;