import React, { useEffect, useState } from 'react';
import { Dropdown } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import Group from 'assets/mobileIcons/Group.svg';
import { useQuery } from 'react-query';
import { getUserStatistics } from 'queryhook/accounts/getUserStatistics';
import AlertModal from 'components/AlertModal';
import newlogo from 'assets/NewIcons/Mask group.svg';
import hamburger from 'assets/mobileIcons/hamburger.svg';
import Create from 'assets/mobileIcons/Create Icon.svg';
import Library from 'assets/mobileIcons/Library Icon.svg';
import Vector from 'assets/mobileIcons/Vector.svg';
import cn from 'classnames';

const MobNavbar = () => {
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const navigate = useNavigate();
  const { data } = useQuery('userStatistics', getUserStatistics);
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [title, setTitle] = useState('');

  const path = window.location.pathname;
  const isSocialMediaHeader = (path.includes('InstagramPost') || path.includes('FacebookPost')
    || path.includes('ImageLibrary')) 
  && !path.includes('imageView');

  const UserInfoModalButton = (inputValues) => {
    const Name = inputValues.Name;
    const Company = inputValues.Company;
    const Industry = inputValues.Industry;
    const How_did_you_find_us = inputValues['How did you find us?'];
    const ContentType = inputValues['What types of content do you create / want help creating?'];
    const JobRole = inputValues['What is your job role?'];
    const handleClick = async () => {
      if (Name === '' || Company === '' || ContentType === '' || !Industry || !How_did_you_find_us || !JobRole) {
        // console.log('One or more fields are empty.');
      } else {
        // console.log(inputValues);
        try {
          // const response = await sendUserInfo(inputValues);
        } catch (error) {
          console.error('Error sending user info:', error);
        }
        setAlertModalOpen(false);
      }
    };
    return (
      <button
        type='button'
        className='mt-3 inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm 
        font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-primary-50 
        sm:mt-0 sm:w-auto text-white'
        onClick={() => { handleClick(); }}
      >
        Submit
      </button>
    );
  };

  const handleLogoutClick = () => {
    localStorage?.clear(); // clear the local storage
    navigate('/login');
  };

  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    setUserEmail(localStorage.getItem('user-email') ?? '');
    if (path.includes('ImageGenerator') || path.includes('PhotoStudio') ||
      path.includes('InstagramPost') || path.includes('FacebookPost') || path.includes('Resize') ||
      path.includes('ImageLibrary')) {
      setShowLogo(false);
      if (path.includes('Resize')) {
        setTitle('Resize');
      } else if (path.includes('PhotoStudio')) {
        setTitle('Photo Studio');
      } else if (path.includes('ImageGenerator')) {
        setTitle('Image Generator');
      } else if (path.includes('InstagramPost')) {
        setTitle('Instagram post');
      } else if (path.includes('FacebookPost')) {
        setTitle('Facebook post');
      } else if (path.includes('ImageLibrary')) {
        setTitle('Image Library');
      }
    } else {
      setShowLogo(true);
    }
  }, [path]);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);

    if (profileOpen) {
      setProfileOpen(false);
    }
  };

  const toggleProfile = () => {
    setProfileOpen(!profileOpen);

    if (hamburgerOpen) {
      setHamburgerOpen(false);
    }
  };

  return (
    <header className='w-full mx-auto card rounded-3xl fixed z-20'>
      {data ? (
        <AlertModal
          modalOpen={alertModalOpen}
          setModalOpen={setAlertModalOpen}
          header={'Please tell us a little about yourself'}
          body={'(Fill all six to continue)'}
          additionalBtn={UserInfoModalButton}
          AdditionalInput={{
            'Name': data?.data?.user_name || '',
            'Company': data?.data?.company || '',
            'Industry': ['Ad/ Digital Agency', 'Ecommerce/ Retailer', 'Consumer Packaged Goods',
              'Media & Entertainment', 'Software/ Tech/ SaaS', 'Education/ Research', 'Other'],
            'What is your job role?': ['Business Owner', 'In-house marketer (content, social, brand, etc)',
              'Creative', 'Agency/ consultant', 'Student', 'Other'],
            'What types of content do you create / want help creating?': data?.data?.content_type || '',
            'How did you find us?': ['Social media', 'web search', 'Friend', 'Colleague',
              'Influencer', 'AI sites', 'Other']
          }}
          Static={true}
        />
      ) : (<></>)}
      <nav className={cn('w-full h-[67px] px-[14px] py-[10px] bg-white dark:bg-gray-800 font-jost relative', 
      !isSocialMediaHeader && 'border-b border-[#D9D9D9]')}>
        <div className='grid grid-cols-12 h-full mx-auto'>
          <div className='col-span-1 flex items-center'>
            <div className='z-40 flex flex-wrap items-center gap-8 sm:flex-nowrap text-[15px]'>
              <Dropdown
                className="hamburgerPosition shadow-none" // css added to position the hamburger dropdown
                arrowIcon={false}
                inline
                label={<img alt='User settings' className='rounded-full avatar' width={34} src={hamburger} />}
                isOpen={hamburgerOpen}
                onToggle={toggleHamburger}
              >
                <Dropdown.Item className='flex gap-[20px] truncate !items-end'
                  onClick={() => navigate('/mobile/Templates')}>
                  <img src={Create} alt='imageCreateIcon' />
                  <span className='text-[#7D7D7D]'>Create</span>
                </Dropdown.Item>
                <Dropdown.Item className='flex gap-[20px] truncate !items-end'
                  onClick={() => {
                    localStorage.removeItem('selectedImageLibraryTab');
                    localStorage.removeItem('resizedPath');
                    navigate('/mobile/ImageLibrary');
                  }}>
                  <img src={Library} alt='imageLibraryIcon' className='w-[19px] h-[23px]' />
                  <span className='text-[#7D7D7D]'>Library</span>
                </Dropdown.Item>
                {/* <Dropdown.Item className='flex gap-[20px] text-sm font-medium truncate'
                  onClick={() => navigate('/mobile/Library')}>
                  <img src={Library} alt='imageLibraryIcon' />
                  <span className='text-[#7D7D7D]'>Library</span>
                </Dropdown.Item> */}
              </Dropdown>
            </div>
          </div>
          <div className='col-span-10 flex items-center'>
            {showLogo ? (
              <div className='w-full grid grid-cols-12'>
                <div className='col-span-6 flex justify-start'>
                  <img
                    src={newlogo}
                    alt='logo'
                    className={'w-[105px] h-[22px] cursor-pointer'}
                    onClick={() => navigate('/mobile/Templates')}
                  />
                </div>
                <div
                  className='col-span-6 flex justify-end pr-[12px] no-underline text-[14px] text-[#7D7D7D]'
                >
                  {data?.data?.credits_remaining} photos left
                </div>
              </div>
            ) :
              (
                <div className='w-full grid grid-cols-12 relative'>
                  {!path.includes('ImageLibrary') && (
                    <div className='flex items-center justify-end absolute left-0 ml-8 h-full'>
                      <img
                        src={Vector}
                        alt='logo'
                        className={'w-[8px] h-[15px] cursor-pointer self-center'}
                        onClick={() => path.includes('Resize') ? navigate(-1) : navigate('/mobile/Templates')}
                      />
                    </div>
                  )}
                  <div className='col-span-12 flex justify-center items-center text-[20px] font-[400] text-[#000]'>
                    {title}
                  </div>
                </div>
              )}
          </div>
          <div className='col-span-1 flex items-center justify-end'>
            <div className='z-40 flex flex-wrap items-center gap-8 sm:flex-nowrap text-[15px]'>
              <Dropdown
                className="profilePosition shadow-none" // css added to position the profile dropdown
                arrowIcon={false}
                inline
                label={<img alt='User settings' className='rounded-full avatar' width={28} src={Group} />}
                isOpen={profileOpen}
                onToggle={toggleProfile}
              >
                <Dropdown.Item className='text-left'>
                  <span className='block font-jost text-[#7D7D7D] truncate'>{userEmail}</span>
                </Dropdown.Item>
                <Dropdown.Item className='text-left block font-jost truncate' onClick={() => {
                  const url = '/mobile/account';
                  window.open(url, '_blank');
                }}>
                  <span className='text-[#7D7D7D]'>Account</span>
                </Dropdown.Item>
                <Dropdown.Item className='text-left block font-jost truncate'
                  onClick={() => navigate('/recover-password')}>
                  <span className='text-[#7D7D7D]'>Reset password</span>
                </Dropdown.Item>
                <Dropdown.Item className='text-left block truncate'
                  onClick={() => {
                    const url = 'https://help.breeze.ai';
                    window.open(url, '_blank');
                  }}>
                  <span className={'no-underline leading-normal text-[#7D7D7D]'}>
                    Help
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className='text-left block truncate'
                  onClick={() => {
                    const url = '/mobile/plans';
                    window.open(url, '_blank');
                  }}>
                  <span className='text-[#5C80FF] font-[500] font-jost'>Upgrade Plan</span>
                </Dropdown.Item>
                <Dropdown.Item className='text-left block truncate'
                  onClick={handleLogoutClick}>
                  <span className='text-[#7D7D7D]'>Sign out</span>
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
        </div>
      </nav>
    </header >
  );
};

export default MobNavbar;
