import React from 'react';
// import LoginForm from 'components/LoginForm';
import ForgetPassowrd from 'components/ForgetPassowrd';
import RegisterFormImage from '../assets/img/RegisterFormSideImg.png';
import cn from 'classnames';

const ForgetPasswordPage = () => {
  return (
    <main className='w-full h-screen font-jost bg-white'>
      <div 
        className={ cn('container mx-auto grid h-full grid-cols-1 gap-4 bg-white rounded-lg lg:grid-cols-2', 
          window?.innerWidth < 992 ? 'p-4' : 'p-12' )}
      >
        {window?.innerWidth > 992 && (
          <div className= {cn('fluid flex h-full rounded-lg')}>
            <div className='min-h-fit justify-center items-center w-[100%] max-w-[800px] hidden lg:flex'>
              <img src={RegisterFormImage} alt='side image' className='w-[80%]' />
            </div>
          </div>
        )}
        <ForgetPassowrd />
      </div>
    </main>
  );
};

export default ForgetPasswordPage;
