import React, { useState } from 'react';
import { TextInput } from 'flowbite-react';
import { AppButton } from 'components/shared/AppButton';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { resetPassword } from 'queryhook/auth/auth';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import logo from '../assets/ColorLogo-noBackground.png';
import cn from 'classnames';

const LoginSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required')
});

const ForgetPassowrd = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: ''
  });

  const resetPasswordMutation = useMutation((email) => resetPassword(email), {
    onSuccess: (data) => {
      setLoading(false);
      toast.success(data.message);
      localStorage?.clear(); // clear the local storage
      navigate('/login');
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error.response.data.error);
    }
  });

  const onFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await LoginSchema.validate(formData);
    } catch (error) {
      toast.error('Invalid email');
      setLoading(false);
      return;
    }
    resetPasswordMutation.mutate(formData);
  };

  return (
    <div className={cn('flex flex-col justify-center gap-8 text-center', window?.innerWidth > 992 ? 'px-24' : '')}>
      <img src={logo} alt='logo' className='w-[160px] mx-auto' />
      <div className='h-2/3'>
      <div 
        className={cn(window?.innerWidth < 992 ? 'text-[30px] font-[600] text-black mb-[30px]' : 'mb-10 text-3xl')}
      >
        Password Assistance
      </div>
      <form className='flex flex-col gap-2' onSubmit={onFormSubmit}>
        <TextInput 
          className={cn(window?.innerWidth < 992 ? cn('reg-inputs input') : '')} 
          id='email' 
          type='email' 
          placeholder='Email' 
          required={true} 
          onChange={e => setFormData({ ...formData, email: e.target.value })} 
        />
        <AppButton
          type='submit'
          className='flex px-10 py-2 mx-auto my-4 text-lg text-white bg-primary rounded-3xl'
          isFull
          isLoading={loading}
          disabled={loading}
        >
          Reset
        </AppButton>
      </form>
      <div>
        <p className={ `${window?.innerWidth < 992 ? cn('mb-2 text-sm font-semibold text-gray-600'): 
            cn('mb-2 text-md font-semibold text-gray-600') }`}>
          An email with a link to reset your password will be sent to your mail. Click on the password reset link to
          reset your password!
        </p>
        <Link 
          to='/login' 
          className={cn('block font-semibold underline', window?.innerWidth < 992 ? 'text-base' : 'text-xl' )} 
          href=''
        >
          Login Again
        </Link>
      </div>
      </div>
    </div>
  );
};

export default ForgetPassowrd;
