import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { AppButton } from 'components/shared/AppButton';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import cn from 'classnames';
import horizontalOptionIcon from 'assets/imgFunctionIcons/horizontalOptionIcon.svg';
import OpenIcon from 'assets/imgFunctionIcons/OpenHover.svg';
import ImageClickFunctions from '../ImageClickFunctions'; // on image click PopUp Compoenent
import { getDropdownPosition } from 'utils/commonFunctions';
import useClickOutsideHandler from 'components/customHooks/useClickOutsideHandler';

const GalleryView = (props) => {
  const {
    selectedTab,
    imageList,
    uploadedImages,
    isSearched,
    searchDataList,
    handleImageClick,
    loading,
    searchFilterDivHeight,
    seImageClickData,
    setMultiImageSelectedList,
    setIsImgClickPopUps,
    setShareLinkKeyData,
    setShareLinkLoading,
    setIsAllowDownload,
    isFilesUploading
  } = props;

  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const [imgIndex, setImgIndex] = useState('');
  const [imgFunctionPopUp, setImgFunctionPopUp] = useState(false);
  const [isValidSrc, setIsValidSrc] = useState(true);
  const [selectedAllImages, setSelectedAllImages] = useState([]); // to store selected images
  const [filteredImageList, setFilteredImageList] = useState([]); // storing modified searchResult data
  const [libraryImageList, setLibraryImageList] = useState([]); // storing image list
  
  // Custom hook to handle the deselecting the selected image if the user click outside
  const clickImageCardRef = useClickOutsideHandler(
    'clickImageList',
    () => {
      const imageListData = isSearched ? filteredImageList : libraryImageList;
      if (imageListData?.length > 0 && selectedAllImages?.length > 0) {
        const updatedImages = imageListData?.map((item) => ({
          ...item,
          selected: false
        }));
        if (isSearched) {
          setFilteredImageList(updatedImages);
        } else setLibraryImageList(updatedImages);
        setSelectedAllImages([]);
      }
    },
    [libraryImageList, selectedAllImages, isFilesUploading, isSearched, filteredImageList]
  );

  // Clearing the selectedImages while toggling the tabs
  useEffect(() => {
    setSelectedAllImages([]);
  }, [selectedTab]);

  // assigning the selected images to parent value
  useEffect(() => {
    setMultiImageSelectedList(selectedAllImages);
  }, [selectedAllImages, selectedTab]);

  // Adding index and selected values for all the data for multiSelecting feature
  const handleIndexData = (listData) => {
    const tempData = listData?.map((item, index) => {
      if (item) {
        item['index'] = index;
        item['selected'] = false;
      }
      return item;
    });
    return tempData;
  };

  // Altering the images list data for multiSelect feature
  useEffect(() => {
    if (selectedTab === 'allImages') {
      setLibraryImageList(handleIndexData(imageList?.history));
    } else {
      setLibraryImageList(handleIndexData(uploadedImages?.history));
    }
  }, [selectedTab, imageList, uploadedImages]);

  // Altering the search list data for multiSelect feature
  useEffect(() => {
    if (searchDataList?.length > 0) {
      let returnDataList = [];
      if (selectedTab === 'allImages') {
        returnDataList = searchDataList?.filter(item => item?.image_origin !== 'Uploaded');
      } else {
        returnDataList = searchDataList?.filter(item => item?.image_origin === 'Uploaded');
      }
      setFilteredImageList(handleIndexData(returnDataList));
    }
  }, [searchDataList, selectedTab]);

  const handleMouseEnter = (index) => {
    setHovered(true);
    setImgIndex(index);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setImgIndex('');
    setImgFunctionPopUp(false);
  };

  const uploadImgGenLibrary = JSON.parse(localStorage.getItem('uploadImgGenLibrary'));
  const isAddFromLibrary = JSON.parse(localStorage.getItem('isAddFromLibrary'));

  const handleGenerate = (item) => {
    uploadImgGenLibrary.libraryImgUpload = false;
    uploadImgGenLibrary.selectedImgData = item;
    // delete projectID entry so that if coming directly from Image Library  projectID is not passed to the api
    delete uploadImgGenLibrary?.projectID;
    localStorage.setItem('uploadImgGenLibrary', JSON.stringify(uploadImgGenLibrary));
    localStorage.setItem('isAddFromLibrary', JSON.stringify(false));
    navigate(uploadImgGenLibrary.redirectedUrl, { state: { retainSelectedImage: true } });
  };

  // to get the last index of each row to set the popUp position
  const getLastRowItemIndex = (index) => {
    var columns = 4;
    if (window.innerWidth > 1700) {
      columns = 6;
    } else if (window.innerWidth > 1200) {
      columns = 5;
    } else {
      columns = 4;
    }
    return Math.floor(index / columns) * columns + columns - 1;
  };

  const handleDropdownPosition = (imageIndex, item) => {
    const imageElement = document.querySelector(`#Image-${imageIndex}`);
    const containerElement = document.getElementById('clickImageList');
    const dropdownHeight = item?.selected ? '120' : '250'; 
    const dropdownPosition = getDropdownPosition(imageElement, containerElement, dropdownHeight);
    return dropdownPosition;
  };

  // Function to handle multiSelection of images
  const handleMultiImageSelection = (imageData, event) => {
    const isSelected = selectedAllImages?.some((item) => {
      if (imageData?.gid) {
        return item?.gid === imageData?.gid;
      } else if (imageData?.pid) {
        return item?.pid === imageData?.pid;
      }
      return false;
    });

    const imageListData = isSearched ? filteredImageList : libraryImageList;
    let selectedImageList = [];
    const specialKeySelected = event.altKey || event.ctrlKey || event.shiftKey;
    const selectedImageIndex = imageListData?.findIndex((item) => item === imageData);

    const updateSelectedState = (images, index, selected) => {
      const updatedImages = [...images];
      updatedImages[index].selected = selected;
      return updatedImages;
    };

    if (isSelected && specialKeySelected) {
      // If the image is already selected and a special key is pressed,
      // remove the selected item when clicking it again
      setSelectedAllImages((prevSelectedImages) =>
        prevSelectedImages.filter(
          (item) => !(item.gid === imageData.gid || item.pid === imageData.pid)
        )
      );
      selectedImageList = (updateSelectedState(imageListData, selectedImageIndex, false));
    } else if (!isSelected && specialKeySelected) {
      // Select the image only if it's not already selected and a special key is pressed
      setSelectedAllImages((prevSelectedImages) => [
        ...prevSelectedImages,
        { ...imageData, selected: true }
      ]);
      selectedImageList = (updateSelectedState(imageListData, selectedImageIndex, true));
    } else if (!specialKeySelected) {
      // Deselect all other images and select the current image if no special key is pressed
      const updatedImages = imageListData?.map((item) => ({ ...item, selected: false }));
      selectedImageList = (updateSelectedState(updatedImages, selectedImageIndex, true));
      setSelectedAllImages([{ ...imageData, selected: true }]);
    }

    if (selectedImageList?.length > 0) {
      if (isSearched) {
        setFilteredImageList(selectedImageList);
      } else setLibraryImageList(selectedImageList);
    }
  };

  // Function to handle multiSelection of images count
  const handleMultiSelectCount = () => {
    const countValue = selectedAllImages?.length;
    return countValue;
  };

  // Function to handle selected images are only upload or not
  const handleSelectedImagesUploadOnly = () => {
    let isUploadedImages;
    if (selectedTab === 'allImages') {
      isUploadedImages = selectedAllImages?.every((item) => !item?.gid);
    } else isUploadedImages = selectedAllImages?.length > 0;
    return isUploadedImages;
  };

  // Function to handle selected images are only generated or not
  const handleSelectedImagesGeneratedOnly = () => {
    const isGeneratedImages = selectedAllImages?.every((item) => item?.gid);
    return isGeneratedImages;
  };

  return (
    <>
      {(isSearched ? filteredImageList?.length > 0 : libraryImageList?.length > 0) && (
        <div
          className='overflow-auto'
          id='clickImageList'
          ref={clickImageCardRef}
          style={{ height: `calc(100vh - ${searchFilterDivHeight}px)` }}
        >
          <div
            className={cn('grid grid-cols-6 lg:grid-cols-4 xl:grid-cols-5  xl-mid:grid-cols-5 2xl:grid-cols-6 gap-4',
              'z-10 w-full p-[5px]')}
          >
            {selectedTab === 'allImages' && (
              (isSearched ? filteredImageList : libraryImageList)?.map((item, index) => (
                <div
                  key={index} id={`Image-${index}`}
                  className={cn('aspect-square flex justify-center rounded-[10px] bg-[#F1F1F1] flex-none',
                    'hover:border-[6px] hover:border-[#d9d9d9]',
                    item?.selected
                    && `rounded-[10px] outline outline-offset-0 outline-3 !border-[0px]
                  !outline-blue-700 p-[7px]`)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave()}
                  onClick={(event) => item && handleMultiImageSelection(item, event)}
                  onMouseDown={(e) => e?.shiftKey && e?.preventDefault()}
                >
                  <div className='w-full h-auto flex justify-center relative'>
                    <div className='w-full h-auto flex justify-center items-center'>
                      {(item === null) ? (
                        <div className='bg-[#F1F1F1] max-w-full max-h-full rounded-[2px]
                          flex flex-col items-center justify-center m-auto'>
                          <ClipLoader color='#7D7D7D' />
                          <div className='text-[#7D7D7D] text-[20px] font-[600] pt-[10px] font-jost'>
                            Loading
                          </div>
                        </div>
                      ) : (
                        <LazyLoadImage
                          src={(isValidSrc && item?.thumbnail ? item?.thumbnail : (item?.input_image_url || item?.url))}
                          alt={index}
                          className='max-w-full max-h-full rounded-[2px]'
                          onError={() => setIsValidSrc(false)}
                        />
                      )}
                      {!isAddFromLibrary && hovered && imgIndex === index && (
                        <>
                          <div
                            className="absolute inset-0"
                            style={{
                              background: 'linear-gradient(0deg, #222 0.13%, rgba(217, 217, 217, 0.00) 67.15%)',
                              pointerEvents: 'none',
                              zIndex: 10
                            }}
                          ></div>
                          <div className="absolute top-[13px] right-[13px] flex gap-[5px] z-40">
                            <div className='w-[28px] h-[28px] bg-[#000000a6] rounded-[5px] hover:bg-[#000]'
                              onClick={(e) => { e.stopPropagation(); handleImageClick(item); }}
                            >
                              <img alt='icon' src={OpenIcon} />
                            </div>
                            <div className={`w-[28px] h-[28px] bg-[#000000a6] rounded-[5px] hover:bg-[#000] 
                              ${imgFunctionPopUp && 'bg-[#000]'}`}
                              onClick={(e) => {
                                e.stopPropagation(); setImgFunctionPopUp(!imgFunctionPopUp); seImageClickData(item);
                              }}
                            >
                              <img alt='icon' src={horizontalOptionIcon} />
                            </div>
                            {/*on image click PopUp Compoenent*/}
                            {imgFunctionPopUp && (
                              <ImageClickFunctions
                                setImgFunctionPopUp={setImgFunctionPopUp}
                                handleImageClick={() => handleImageClick(item)}
                                imageData={item}
                                position={getLastRowItemIndex(index) === index}
                                dropdownPosition={handleDropdownPosition(index, item)}
                                multiSelectCount={handleMultiSelectCount()}
                                isUploadImagesOnly={handleSelectedImagesUploadOnly()}
                                isGeneratedImagesOnly={handleSelectedImagesGeneratedOnly()}
                                multiSelectedItems={selectedAllImages}
                                setIsImgClickPopUps={setIsImgClickPopUps}
                                setShareLinkKeyData={setShareLinkKeyData}
                                setShareLinkLoading={setShareLinkLoading}
                                setIsAllowDownload={setIsAllowDownload}
                              />
                            )}
                          </div>
                          <div className="absolute bottom-[35px] left-[20px] text-[#FFFFFF] z-[10] text-[20px] 
                            w-[90%] overflow-hidden whitespace-nowrap overflow-ellipsis">
                            {item?.image_name || 'File name'}
                          </div>
                          <div className="absolute bottom-[10px] left-[20px] h-[24px] text-[#FFFFFF] text-[16px] z-[10]
                            flex justify-center items-center gap-[6px]">
                            <div>{item?.image_type}</div>
                            <div className='mb-[10px]'>.</div>
                            {item?.dimensions && (
                              <div>{`${item?.dimensions[0]} x ${item?.dimensions[1]}`}</div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
            {selectedTab === 'myUploads' && (
              (isSearched ? filteredImageList : libraryImageList)?.map((item, index) => (
                <div
                  key={index}
                  className={cn('aspect-square flex justify-center rounded-[10px] bg-[#F1F1F1] flex-none',
                    'hover:border-[6px] hover:border-[#d9d9d9]',
                    item?.selected && `rounded-[10px] outline outline-offset-0 outline-3
                  !border-[0px] !outline-blue-700 p-[7px]`)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave()}
                  onClick={(event) => handleMultiImageSelection(item, event)}
                  id={`Image-${index}`}
                >
                  <div className='w-full h-auto flex justify-center relative'>
                    <div className='w-full h-auto flex justify-center items-center'>
                      {(item === null) ? (
                        <div className='bg-[#F1F1F1] max-w-full max-h-full rounded-[2px]
                        flex flex-col items-center justify-center m-auto'>
                          <ClipLoader color='#7D7D7D' />
                          <div className='text-[#7D7D7D] text-[20px] font-[600] pt-[10px] font-jost'>
                            Loading
                          </div>
                        </div>
                      ) : (
                        <LazyLoadImage
                          src={(isValidSrc && item?.thumbnail ? item?.thumbnail : (item?.input_image_url || item?.url))}
                          alt={index}
                          className='max-w-full max-h-full rounded-[2px]'
                          onError={() => setIsValidSrc(false)}
                        />
                      )}
                      {isAddFromLibrary && (
                        <AppButton
                          onClick={() => handleGenerate(item)}
                          isLoading={false}
                          type="button"
                          variant='light'
                          extendClass={`absolute text-[#000] bottom-[5px] right-[5px] bg-[white] rounded-[2px] 
                          w-24 h-[30px] flex justify-center items-center z-40
                          ${(hovered && imgIndex === index) ? 'opacity-100' : 'opacity-0'}`}
                          disabled={false}
                        >
                          Add
                        </AppButton>)}
                      {isAddFromLibrary && hovered && imgIndex === index && (
                        <div
                          className="absolute inset-0"
                          style={{
                            background: 'linear-gradient(0deg, #171717 0%, rgba(0, 0, 0, 0) 100%)',
                            pointerEvents: 'none',
                            zIndex: 10
                          }}
                        ></div>
                      )}
                      {!isAddFromLibrary && hovered && imgIndex === index && (
                        <>
                          <div
                            className="absolute inset-0"
                            style={{
                              background: 'linear-gradient(0deg, #222 0.13%, rgba(217, 217, 217, 0.00) 67.15%)',
                              pointerEvents: 'none',
                              zIndex: 10
                            }}
                          ></div>
                          <div className="absolute top-[10px] right-[10px] flex gap-[5px] z-40">
                            <div className='w-[28px] h-[28px] bg-[#000000a6] rounded-[5px] hover:bg-[#000]'
                              onClick={(e) => { e.stopPropagation(); handleImageClick(item); }}
                            >
                              <img alt='icon' src={OpenIcon} />
                            </div>
                            <div className={`w-[28px] h-[28px] bg-[#000000a6] rounded-[5px] hover:bg-[#000] 
                              ${imgFunctionPopUp && 'bg-[#000]'}`}
                              onClick={(e) => {
                                e.stopPropagation(); setImgFunctionPopUp(!imgFunctionPopUp), seImageClickData(item);
                              }}
                            >
                              <img alt='icon' src={horizontalOptionIcon} />
                            </div>
                            {/*on image click PopUp Compoenent */}
                            {imgFunctionPopUp && (
                              <ImageClickFunctions
                                setImgFunctionPopUp={setImgFunctionPopUp}
                                handleImageClick={() => handleImageClick(item)}
                                imageData={item}
                                position={getLastRowItemIndex(index) === index}
                                multiSelectCount={handleMultiSelectCount()}
                                isUploadImagesOnly={handleSelectedImagesUploadOnly()}
                                isGeneratedImagesOnly={handleSelectedImagesGeneratedOnly()}
                                multiSelectedItems={selectedAllImages}
                                dropdownPosition={handleDropdownPosition(index, item)}
                                setIsImgClickPopUps={setIsImgClickPopUps}
                                setShareLinkKeyData={setShareLinkKeyData}
                                setShareLinkLoading={setShareLinkLoading}
                                setIsAllowDownload={setIsAllowDownload}
                              />
                            )}
                          </div>
                          <div className="absolute bottom-[35px] left-[20px] text-[#FFFFFF] z-[10] text-[20px] 
                            w-[90%] overflow-hidden whitespace-nowrap overflow-ellipsis">
                            {item?.image_name || 'File name'}
                          </div>
                          <div className="absolute bottom-[10px] left-[20px] h-[24px] text-[#FFFFFF] text-[16px] z-[10]
                            flex justify-center items-center gap-[6px]">
                            <div>{item?.image_type}</div>
                            <div className='mb-[10px]'>.</div>
                            {item?.dimensions && (
                              <div>{`${item?.dimensions[0]} x ${item?.dimensions[1]}`}</div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
      {(isSearched ? filteredImageList?.length === 0 : libraryImageList?.length === 0) && (
        !loading && <div className='w-full h-full font-jost font-[600] text-[18px] whitespace-nowrap'>
          No record found
        </div>
      )}
    </>
  );
};

export default GalleryView;