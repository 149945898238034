import React, { useRef, useMemo } from 'react';
import { Image, Transformer, Layer, Stage } from 'react-konva';
import cn from 'classnames';

const ImageCanvasMob = ({
  imgSrc = '',
  width,
  height,
  stageRef,
  canvasTransformerRef,
  isMeshBg,
  isMobScreen,
  imageRef
}) => {
  let imageSize = null;
  const trRef = canvasTransformerRef;
  const containerRef = useRef(null);

  const imgEl = useMemo(() => {
    const img = document.createElement('img');
    img.src = imgSrc;

    function onload() {
      const { naturalWidth, naturalHeight } = img;
      const imageWidth = naturalWidth || 1024;
      const imageHeight = naturalHeight || 1024;
      const widthFactor = imageWidth / width;
      const heightFactor = imageHeight / height;
      if (widthFactor > heightFactor) {
        img.height = (1 * imageHeight) / widthFactor;
        img.width = (1 * imageWidth) / widthFactor;
      } else {
        img.height = (1 * naturalHeight) / heightFactor;
        img.width = (1 * imageWidth) / heightFactor;
      }
      imageSize = { width: img.width, height: img.height };
    }
    onload();
    img.onload = onload;

    return img;
  }, [imgSrc, width, height]);

  return (
    <div
      ref={containerRef}
      className={cn('rounded-md bg-white m-0', isMeshBg && 'bg-canvasMeshBg')}
      style={{ width: width, height: height }}
    >
      <Stage
        onClick={() => {
          trRef?.current?.nodes([]);
        }}
        onTap={() => {
          trRef?.current?.nodes([]);
        }}
        ref={stageRef}
        width={width}
        height={height}
        backgroundColor='green'
      >
        <Layer>
          <Transformer
            enabledAnchors={[
              'top-left',
              'top-right',
              'bottom-left',
              'bottom-right'
            ]}
            keepRatio={true}
            aspect
            ref={trRef}
            style={{
              zIndex: '0'
            }}
            anchorStroke='black'
            anchorSize={isMobScreen && 6}
            borderStroke='black'
            className='rounded-5'
          ></Transformer>
          <Image
            ref={imageRef}
            alt='Image'
            className='rounded-5'
            style={{ zIndex: 0 }}
            image={imgEl}
            x={imageSize?.width ? (width/2 - imageSize?.width/2) : 0}
            y={imageSize?.height ? (height/2 - imageSize?.height/2) : 0}
          />
        </Layer>
      </Stage>
    </div>
  );
};

export default ImageCanvasMob;
