import React, { useState, useRef, useEffect } from 'react';
import ImageAndTextSection from 'components/DesktopComponents/ImageAndTextSection';
import axios from 'axios';
// import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LocalStorage } from 'services/localStorage';
import AlertModal from 'components/AlertModal';
import PreviewScreenAndGeneratedImages from 'components/DesktopComponents/PreviewScreenAndGeneratedImages';
import { v4 as uuidv4 } from 'uuid';
import ImageResize from 'components/DesktopComponents/ImageResizing';
import { handleTokenExpiryIssue } from 'utils/functions.util';
import ListingTemplatesImages from './ListingTemplatesImages';
import { BASE_URL } from 'utils/baseURL';
import ImageEditor from 'components/DesktopComponents/ImageEditor';

const DesktopComponent = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageToGen, setSelectedImageToGen] = useState(null);
  const [imageForView, setImageForView] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState();
  const [prompt, setPrompt] = useState({ theme: '', prompt: '' });
  const [selectedImageStyle, setSelectedImageStyle] = useState();
  const [imageListData, setImageListData] = useState([]);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [generatedImagesData, setGeneratedImagesData] = useState([]);
  const [clickedImage, setClickedImage] = useState();
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [captionListData, setCaptionListData] = useState();
  const [selectedTab, setSelectedTab] = useState('');
  const [onlyImageTabVal, setOnlyImageTabVal] = useState(false);
  const [recentGenImgList, setRecentGenImgList] = useState([]);
  const [selectedGenImg, setSelectedGenImg] = useState();
  const [recentGenImgLoading, setRecentGenImgLoading] = useState(false);
  const [isImgGenerator, setIsImgGenerator] = useState(false);
  const [selectedCaption, setSelectedCaption] = useState();
  const [changeClickedImage, setChangeClickedImage] = useState(false);
  const [generateCaptionLoading, setGenerateCaptionLoading] = useState(false);
  const [apiCallIndex, setApiCallIndex] = useState();
  const [imageByTextPid, setImageByTextPid] = useState();
  const [imageGenerationLoading, setImageGenerationLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showResize, setShowResize] = useState(false);
  const [selectedListingTempTab, setSelectedListingTempTab] = useState('primary');
  const [showImgEditor, setShowImgEditor] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#FFFFFF');
  // to disable resize, edit, delete btn when image generation is in progress
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);
  const { projectID, documentID } = useParams();

  const canvasTransformerRef = useRef(null);
  const canvasStageRef = useRef(null);
  const canvasHeight = 400;
  const canvasWidth = 400;

  const selectedTemp = JSON.parse(localStorage.getItem('selectedTemp'));
  const [deletedImgIndex, setDeletedImgIndex] = useState(0);
  const isListingTemplates = ['Walmart listings', 'Amazon listings'].includes(selectedTemp?.name);
  const selectedColorLocalStorage = JSON.parse(localStorage.getItem('selectedColor'));
  const currentLocation = useLocation();

  useEffect(() => {
    if (['Image Generator', 'Photo Studio'].includes(selectedTemp?.name)) {
      setOnlyImageTabVal(true);
    } else setOnlyImageTabVal(false);
    if (selectedTemp?.name === 'Image Generator') {
      setIsImgGenerator(true);
      setSelectedImage();
    }
  }, [selectedTemp]);

  useEffect(() => {
    if (currentLocation?.pathname?.includes('Projects') && !currentLocation?.state?.retainSelectedImage) {
      if (currentLocation?.state?.remove_background_url !== null) {
        setSelectedImage(currentLocation?.state);
        setSelectedImageToGen(currentLocation?.state);
      } else {
        setSelectedImage();
      }
    }
  }, []);

  useEffect(() => {
    if (selectedColorLocalStorage) {
      setSelectedColor(selectedColorLocalStorage);
    } else {
      setSelectedColor('#FFFFFF');
    }
  }, []);

  const ModalUpgradeCtaButton = () => {
    return (
      <button
        type='button'
        className='mt-3 inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold
text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-primary-50 sm:mt-0 sm:w-auto text-white'
        onClick={() => {
          setAlertModalOpen(false);
          navigate('/plans');
        }}
      >
        Upgrade
      </button>
    );
  };

  useEffect(() => {
    const uploadImgGenLibraryData = JSON.parse(localStorage.getItem('uploadImgGenLibrary'));
    if (!uploadImgGenLibraryData) {
      const uploadImgGenLibrary = {
        libraryImgUpload: true,
        redirectedUrl: window.location.pathname
      };
      localStorage.setItem('uploadImgGenLibrary', JSON.stringify(uploadImgGenLibrary));
    }
  }, []);

  useEffect(() => {
    if (selectedImage) {
      setClickedImage();
      const uploadImgGenLibrary = JSON.parse(localStorage.getItem('uploadImgGenLibrary'));
      if (uploadImgGenLibrary) {
        const tempData = { ...uploadImgGenLibrary, selectedImgData: selectedImage };
        localStorage.setItem('uploadImgGenLibrary', JSON.stringify(tempData));
      }
    }
  }, [selectedImage]);

  useEffect(() => {
    if (clickedImage && selectedImage) {
      setSelectedImage();
    }
  }, [clickedImage]);

  const handleSelectedImage = (data) => {
    setSelectedImage(data);
  };

  const generateIndividualImage = async (i, listData) => {
    setIsDisabledBtn(true);
    canvasTransformerRef?.current?.nodes([]);
    if (apiCallIndex === 0) {
      setGenerateLoading(true);
    }
    setImageGenerationLoading(true);
    const listValues = imageListData?.map((item) => {
      return {
        pid: selectedImageToGen?.pid,
        marginLeft: item?.current?.attrs?.x ? (item?.current?.attrs?.x + item?.x) : item?.x || 0,
        marginTop: item?.current?.attrs?.y ? (item?.current?.attrs?.y + item?.y) : item?.y || 0,
        scaleX: item?.current?.attrs?.scaleX || 1,
        scaleY: item?.current?.attrs?.scaleY || 1,
        angle: item?.current?.attrs?.rotation || 0
      };
    });
    const data = new FormData();
    data.append('prompt', prompt?.prompt);
    data.append('theme', prompt?.theme);
    data.append('pid', selectedImageToGen?.pid);
    data.append('coordinates_str', JSON.stringify(listValues));
    data.append('canvas_height', canvasHeight);
    data.append('canvas_width', canvasWidth);
    data.append('shadow_option', '');
    data.append('gen_height', 1024);
    data.append('gen_width', 1024);
    data.append('style', selectedImageStyle?.theme);
    data.append('img_generator', isImgGenerator);
    data.append('origin', selectedTemp?.name);
    if (projectID) data.append('projectID', projectID);
    if (documentID) data.append('documentID', documentID);

    const url = BASE_URL + 'image-gen/image_gen_single';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response.data && response.data.data) {
        listData[i] = { ...response?.data?.data, thumbnail: response?.data?.data?.url };
        if (i === 0) {
          setClickedImage(listData[0]);
        }
        setGeneratedImagesData([...listData]);
        if (apiCallIndex === 0) {
          setChangeClickedImage(true);
          setGenerateLoading(false);
        }
        if (apiCallIndex === 3) {
          setImageGenerationLoading(false);
        }
        if (i === 3) {
          setIsDisabledBtn(false);
        }
        return true;
      } else if (
        response.data.status_code === 400 &&
        response.data.detail.message === 'Your token has expired, Please login again'
      ) {
        handleTokenExpiryIssue();
      } else if (response.data.status_code === 400) {
        console.log(response.data.detail.message);
      }
    } catch (err) {
      setIsDisabledBtn(false);
      setGenerateLoading(false);
      setImageGenerationLoading(false);
      listData.splice(i, 4);
      setApiCallIndex();
      setGeneratedImagesData([...listData]);
      if (err?.response?.status === 402) {
        return setAlertModalOpen(true);
      }
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    }
    // handleRecentGeneratedImages()
    setGenerateLoading(false);
    setImageGenerationLoading(false);
  };

  const handleGenerateImages = () => {
    setImageByTextPid();
    const recentImages = generatedImagesData.concat();
    let tempListData = recentGenImgList;
    if (recentImages.length >= 28) {
      tempListData = recentImages.slice(0, (recentImages.length - 4));
    }
    for (let i = 0; i < 4; i++) {
      tempListData.unshift(null);
      setGeneratedImagesData(tempListData);
      setRecentGenImgList(tempListData);
    }
    setApiCallIndex(0);
  };

  //call the generate api one after another
  const makeSequentialApiCall = async () => {
    if (apiCallIndex <= 3) {
      const res = await generateIndividualImage(apiCallIndex, recentGenImgList);
      if (res) {
        setApiCallIndex(apiCallIndex + 1);
      }
    }
  };

  const makeSequentialApiCallForImageGenerator = async () => {
    if (apiCallIndex <= 3) {
      const res = await generateImagesByText(apiCallIndex, recentGenImgList);
      if (res) {
        setApiCallIndex(apiCallIndex + 1);
      }
    }
  };

  useEffect(() => {
    if (isImgGenerator) {
      makeSequentialApiCallForImageGenerator();
    } else {
      makeSequentialApiCall();
    }
  }, [apiCallIndex]);

  const generateImagesByText = async (i, listData) => {
    setIsDisabledBtn(true);
    if (apiCallIndex === 0) {
      setGenerateLoading(true);
    }
    setImageGenerationLoading(true);
    const pid = imageByTextPid || uuidv4();
    const data = new FormData();
    data.append('prompt', prompt?.prompt);
    data.append('style', selectedImageStyle?.theme === 'None' ? '' : selectedImageStyle?.theme);
    data.append('img_generator', isImgGenerator);
    data.append('pid', pid);
    data.append('origin', selectedTemp?.name);
    if (projectID) data.append('projectID', projectID);
    if (documentID) data.append('documentID', documentID);

    if (!imageByTextPid) setImageByTextPid(pid);

    const url = BASE_URL + 'image-gen/image_gen_single';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response.data && response.data.data) {
        listData[i] = { ...response?.data?.data, thumbnail: response?.data?.data?.url };
        if (i === 0) {
          setClickedImage(listData[0]);
        }
        setGeneratedImagesData([...listData]);
        if (apiCallIndex === 0) {
          setChangeClickedImage(true);
          setGenerateLoading(false);
        }
        if (apiCallIndex === 3) {
          setImageGenerationLoading(false);
          setIsDisabledBtn(false);
        }
        return true;
      } else if (
        response.data.status_code === 400 &&
        response.data.detail.message === 'Your token has expired, Please login again'
      ) {
        console.log(response.data.detail.message);
        localStorage?.clear(); // clear the local storage
        navigate('/login');
      } else if (response.data.status_code === 400) {
        console.log(response.data.detail.message);
      }
    } catch (err) {
      setIsDisabledBtn(false);
      setGenerateLoading(false);
      setImageGenerationLoading(false);
      listData.splice(i, 4);
      setApiCallIndex();
      setGeneratedImagesData([...listData]);
      if (err?.response?.status === 402) {
        return setAlertModalOpen(true);
      }
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    }
    // handleRecentGeneratedImages()
    setGenerateLoading(false);
    setImageGenerationLoading(false);
  };

  // const handleGenerateImages = () => {
  //   if (isImgGenerator) {
  //     generateImagesByText()
  //   } else generateImages()
  // }

  // api to show the recentGenerated images
  const handleRecentGeneratedImages = async () => {
    setRecentGenImgLoading(true);
    const data = new FormData();
    data.append('origin', selectedTemp?.name);
    data.append('primary_image', isListingTemplates && selectedListingTempTab === 'primary');
    if (documentID) data.append('documentID', documentID);
    const url = BASE_URL + 'image-gen/recent_images';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response?.data) {
        setGeneratedImagesData(response?.data?.data?.recent_images);
        // setClickedImage(response?.data?.data?.recent_images[0]);
        if (isDeleted) {
          //to check if the length of the generated image array to show the next image after deletion
          if (response?.data?.data?.recent_images?.length === 0) {
            setClickedImage();
            setDeletedImgIndex();
          } else if (response?.data?.data?.recent_images.length >= deletedImgIndex + 1) {
            setClickedImage(response?.data?.data?.recent_images[deletedImgIndex]);
            setDeletedImgIndex(deletedImgIndex);
          } else {
            setClickedImage(response?.data?.data?.recent_images[deletedImgIndex - 1]);
            setDeletedImgIndex(deletedImgIndex - 1);
          }
        }
        setRecentGenImgList(response?.data?.data?.recent_images);
        setIsDeleted(false);
        console.log(response?.data?.detail?.message);
      }
    } catch (err) {
      console.log('Something went wrong');
    }
    setRecentGenImgLoading(false);
  };

  // To clear the caption list on change of the image selection
  useEffect(() => {
    setCaptionListData();
    setSelectedCaption();
  }, [selectedImage, selectedGenImg]);

  useEffect(() => {
    handleRecentGeneratedImages();
  }, []);

  useEffect(() => {
    if (isDeleted) {
      handleRecentGeneratedImages();
    }
  }, [isDeleted]);

  useEffect(() => {
    handleRecentGeneratedImages();
    setClickedImage();
  }, [selectedListingTempTab]);

  const handleImagesPosition = (listData) => {
    // not changing the image position data un-till images are generated
    if (!imageGenerationLoading && listData) {
      setImageListData(listData);
    }
  };

  const updatedCaptionListData = (captionList) => {
    setCaptionListData(captionList);
  };

  const selectedTabData = (tabData) => {
    setSelectedTab(tabData);
  };

  const selectWalmartTab = (tabData) => {
    setSelectedListingTempTab(tabData);
  };

  useEffect(() => {
    if (generatedImagesData && changeClickedImage) {
      setSelectedGenImg(generatedImagesData[0]);
      // setSelectedImage()
    }
    setChangeClickedImage(false);
  }, [generatedImagesData]);

  const handleCloseImageResize = () => {
    handleRecentGeneratedImages();
    setShowResize(false);
  };

  const handleCloseImageEditor = () => {
    handleRecentGeneratedImages();
    setShowImgEditor(false);
  };

  const handleImageResizePopup = () => {
    setShowResize(true);
  };

  const handleImageEditorPopup = () => {
    setShowImgEditor(true);
  };

  const generatePrimaryImage = async (i, listData) => {
    setGenerateLoading(true);
    setImageGenerationLoading(true);
    const data = new FormData();
    data.append('pid', selectedImageToGen?.pid);
    data.append('origin', selectedTemp?.name);
    if (projectID) data.append('projectID', projectID);
    if (documentID) data.append('documentID', documentID);

    const url = BASE_URL + 'image-gen/gen_primary_image';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response.data && response.data.data) {
        listData[i] = { ...response?.data?.data, thumbnail: response?.data?.data?.url, primary_image: true };
        setGeneratedImagesData([...listData]);
        setChangeClickedImage(true);
        setGenerateLoading(false);
        setImageGenerationLoading(false);
        return true;
      } else if (
        response.data.status_code === 400 &&
        response.data.detail.message === 'Your token has expired, Please login again'
      ) {
        handleTokenExpiryIssue();
      } else if (response.data.status_code === 400) {
        console.log(response.data.detail.message);
      }
    } catch (err) {
      setGenerateLoading(false);
      setImageGenerationLoading(false);
      listData.splice(i, 1);
      setGeneratedImagesData([...listData]);
      if (err?.response?.status === 402) {
        return setAlertModalOpen(true);
      }
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    }
    setGenerateLoading(false);
    setImageGenerationLoading(false);
  };

  const handlePrimaryImageGeneration = () => {
    const recentImages = generatedImagesData.concat();
    let tempListData = recentGenImgList;
    if (recentImages.length >= 28) {
      tempListData = recentImages.slice(0, (recentImages.length - 4));
    }
    tempListData.unshift(null);
    setGeneratedImagesData(tempListData);
    setRecentGenImgList(tempListData);
    generatePrimaryImage(0, tempListData);
  };

  const generatePlainColorImage = async (i, listData, hexcode) => {
    setGenerateLoading(true);
    setImageGenerationLoading(true);
    const listValues = imageListData?.map((item) => {
      return {
        pid: selectedImageToGen?.pid,
        marginLeft: item?.current?.attrs?.x ? (item?.current?.attrs?.x + item?.x) : item?.x || 0,
        marginTop: item?.current?.attrs?.y ? (item?.current?.attrs?.y + item?.y) : item?.y || 0,
        scaleX: item?.current?.attrs?.scaleX || 1,
        scaleY: item?.current?.attrs?.scaleY || 1,
        angle: item?.current?.attrs?.rotation || 0
      };
    });
    const data = new FormData();
    data.append('pid', selectedImageToGen?.pid);
    data.append('origin', selectedTemp?.name);
    data.append('hex_code', hexcode);
    data.append('coordinates_str', JSON.stringify(listValues));
    data.append('canvas_height', canvasHeight);
    data.append('canvas_width', canvasWidth);
    if (projectID) data.append('projectID', projectID);
    if (documentID) data.append('documentID', documentID);

    const url = BASE_URL + 'image-gen/gen_plain_colour_image';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response.data && response.data.data) {
        listData[i] = { ...response?.data?.data, thumbnail: response?.data?.data?.url, colour_image: true };
        setClickedImage(listData[0]);
        setGeneratedImagesData([...listData]);
        setChangeClickedImage(true);
        setGenerateLoading(false);
        setImageGenerationLoading(false);
        return true;
      } else if (
        response.data.status_code === 400 &&
        response.data.detail.message === 'Your token has expired, Please login again'
      ) {
        handleTokenExpiryIssue();
      } else if (response.data.status_code === 400) {
        console.log(response.data.detail.message);
      }
    } catch (err) {
      setGenerateLoading(false);
      setImageGenerationLoading(false);
      listData.splice(i, 1);
      setGeneratedImagesData([...listData]);
      if (err?.response?.status === 402) {
        return setAlertModalOpen(true);
      }
      if (err?.response?.status === 440) {
        handleTokenExpiryIssue();
      }
      console.log('Something went wrong');
    }
    setGenerateLoading(false);
    setImageGenerationLoading(false);
  };

  const handlePlainColorImageGeneration = (hexcode) => {
    const recentImages = generatedImagesData.concat();
    let tempListData = recentGenImgList;
    if (recentImages.length >= 28) {
      tempListData = recentImages.slice(0, (recentImages.length - 4));
    }
    tempListData.unshift(null);
    setGeneratedImagesData(tempListData);
    setRecentGenImgList(tempListData);
    generatePlainColorImage(0, tempListData, hexcode);
  };

  const handleGenerateButtonClick = () => {
    if (prompt?.theme === 'color') {
      handlePlainColorImageGeneration(selectedColor);
    } else {
      handleGenerateImages();
    }
  };

  const creditPopupForResize = () => {
    handleCloseImageResize();
    setAlertModalOpen(true);
  };

  const creditPopupForEdit = () => {
    handleCloseImageEditor();
    setAlertModalOpen(true);
  };

  return (
    <>
      {showResize && (
        <ImageResize
          handleClose={handleCloseImageResize}
          selectedImage={clickedImage || selectedGenImg}
          creditPopupForResize={creditPopupForResize}
          projectID={projectID}
          documentID={documentID}
        />
      )}
      {showImgEditor && (
        <ImageEditor
          handleClose={handleCloseImageEditor}
          selectedImage={clickedImage || selectedGenImg}
          creditPopupForEdit={creditPopupForEdit}
          projectID={projectID}
          documentID={documentID}
        />
      )}
      <AlertModal modalOpen={alertModalOpen} setModalOpen={setAlertModalOpen}
        header={'Your photo credit limit is exhausted!'}
        body='Get 1000 photos and more advanced features starting at $9.99'
        additionalBtn={ModalUpgradeCtaButton}
      />
      <div className='flex font-jost'>
        <div className='w-[34.5%] h-[calc(100vh-99px)] relative m-[20px]'>
          <div className=''>
            <div className='text-[20px] font-[400] p-4 text-[#000]'>
              {isListingTemplates ? (
                selectedTemp?.subname ? selectedTemp?.name + ' - ' + selectedTemp?.subname : selectedTemp?.name
              ) : (
                selectedTemp?.subname ? selectedTemp?.name + ' ' + selectedTemp?.subname : selectedTemp?.name
              )}
            </div>
          </div>
          {!isListingTemplates && (
            <ImageAndTextSection
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              setSelectedImageToGen={setSelectedImageToGen}
              handleSelectedImage={handleSelectedImage}
              setSelectedTheme={setSelectedTheme}
              selectedTheme={selectedTheme}
              setPrompt={setPrompt}
              prompt={prompt}
              isBulkUpload={false}
              selectedImageStyle={selectedImageStyle}
              setSelectedImageStyle={setSelectedImageStyle}
              setImageForView={setImageForView}
              handleGenerateButtonClick={handleGenerateButtonClick}
              onlyImageTab={onlyImageTabVal}
              selectedTemp={selectedTemp}
              clickedImage={clickedImage}
              updatedCaptionListData={updatedCaptionListData}
              selectedTabData={selectedTabData}
              selectedGenImg={selectedGenImg}
              generateLoading={generateLoading}
              isImgGenerator={isImgGenerator}
              setIsImgGenerator={setIsImgGenerator}
              generateCaptionLoading={generateCaptionLoading}
              setGenerateCaptionLoading={setGenerateCaptionLoading}
              setGenerateLoading={setGenerateLoading}
              imageGenerationLoading={imageGenerationLoading}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              projectID={projectID}
              documentID={documentID}
            />
          )}
          {isListingTemplates && (
            <ListingTemplatesImages
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              setSelectedImageToGen={setSelectedImageToGen}
              handleSelectedImage={handleSelectedImage}
              setSelectedTheme={setSelectedTheme}
              selectedTheme={selectedTheme}
              setPrompt={setPrompt}
              prompt={prompt}
              isBulkUpload={false}
              selectedImageStyle={selectedImageStyle}
              setSelectedImageStyle={setSelectedImageStyle}
              setImageForView={setImageForView}
              handleGenerateButtonClick={handleGenerateButtonClick}
              selectWalmartTab={selectWalmartTab}
              generateLoading={generateLoading}
              isImgGenerator={isImgGenerator}
              setGenerateLoading={setGenerateLoading}
              imageGenerationLoading={imageGenerationLoading}
              handlePrimaryImageGeneration={handlePrimaryImageGeneration}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              projectID={projectID}
              documentID={documentID}
            />
          )}
        </div>
        <div className='w-[65%] h-[calc(100vh-58px)] border-l border-[#D9D9D9]'>
          <PreviewScreenAndGeneratedImages
            clickedImage={clickedImage}
            generateLoading={generateLoading}
            canvasWidth={canvasWidth}
            canvasStageRef={canvasStageRef}
            handleImagesPosition={handleImagesPosition}
            canvasTransformerRef={canvasTransformerRef}
            imageForView={imageForView}
            selectedImage={selectedImage}
            isMeshBg={true}
            generatedImagesData={generatedImagesData}
            setClickedImage={setClickedImage}
            captionListData={captionListData}
            selectedTab={selectedTab}
            recentGenImgList={recentGenImgList}
            setSelectedGenImg={setSelectedGenImg}
            recentGenImgLoading={recentGenImgLoading}
            setSelectedImage={setSelectedImage}
            selectedCaption={selectedCaption}
            setSelectedCaption={setSelectedCaption}
            generateCaptionLoading={generateCaptionLoading}
            setIsDeleted={setIsDeleted}
            handleCanvas={handleImageResizePopup}
            setDeletedImgIndex={setDeletedImgIndex}
            isListingTemplates={isListingTemplates}
            selectedListingTempTab={selectedListingTempTab}
            handleImageEditorPopup={handleImageEditorPopup}
            isDisabledBtn={isDisabledBtn}
          />
        </div>
      </div>
    </>
  );
};

export default DesktopComponent;