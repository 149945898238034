import React from 'react';
import { useField, useFormikContext } from 'formik';

const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const formData = useFormikContext();
  return (
    <div className='w-full h-full font-jost'>
      <label className='flex w-full text-left text-[20px] lg:text-[16px]
        xl:text-[18px] 2xl:text-[20px] font-[400] text-[#2C2C2C]'>
        {label}
      </label>
      <textarea
        className='customTextBoxColor w-full rounded-md border-[#D9D9D9] mt-[5px] 
        lg:text-[12px] xl:text-[14px] 2xl:text-[16px]'
        rows='3'
        {...field}
        {...props}
      />
      {formData?.submitCount > 0
        || (meta.error && meta.touched) ? (
        <div className='error text-red-500 text-[12px] mt-[-5px]'>{meta.error}</div>
      ) : null}
    </div>
  );
};

export default TextArea;