/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import closeIcon from 'assets/icons/closeIcon.svg';
import axios from 'axios';
import { BASE_URL } from 'utils/baseURL';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import { LocalStorage } from 'services/localStorage';
import { ClipLoader } from 'react-spinners';

const TemplatePopup = (props) => {
  const {
    // selectedProject,
    handleCloseTemplatePopup,
    projectName,
    projectID,
    isNewDocument
  } = props;

  const [socialTemp, setSocialTemp] = useState([]);
  const [id, setId] = useState('');
  const [templateLoading, setTemplateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getSocialTemplate = async () => {
    setTemplateLoading(true);
    try {
      const templateData = await axios.get(BASE_URL + 'image-gen/all_templates');
      setSocialTemp(templateData?.data?.data?.templates);
    } catch (error) {
      console.log(error);
    } finally {
      setTemplateLoading(false);
    }
  };

  useEffect(() => {
    getSocialTemplate();
  }, []);

  // tempId for google analytics for each template
  const handleTemplateId = (data) => {
    switch (data?.name) {
      case 'Image Generator':
        return 'Template_clickImageGen';
      case 'Photo Studio':
        return 'Template_clickPhotoStudio';
      case 'Instagram':
        return 'Template_clickInsta';
      case 'Facebook':
        return 'Template_clickFB';
      case 'Linkedin':
        return 'Template_clickLinkedin';
      default: break;
    }
  };

  const handleNavigation = (data, projectId, documentId) => {
    localStorage.removeItem('selectedListingTab');
    if (data) localStorage.setItem('selectedTemp', JSON.stringify(data));
    switch (data?.name) {
      case 'Image Generator':
        navigate(`/Projects/${projectId}/Document/${documentId}/ImageGenerator`);
        break;
      case 'Photo Studio':
        navigate(`/Projects/${projectId}/Document/${documentId}/PhotoStudio`);
        break;
      case 'Instagram':
        navigate(`/Projects/${projectId}/Document/${documentId}/InstagramPost`);
        break;
      case 'Facebook':
        navigate(`/Projects/${projectId}/Document/${documentId}/FacebookPost`);
        break;
      case 'Linkedin':
        navigate(`/Projects/${projectId}/Document/${documentId}/LinkedInPost`);
        break;
      case 'Google Search':
        navigate(`/Projects/${projectId}/Document/${documentId}/GoogleSearchAd`);
        break;
      case 'Walmart listings':
        if (data?.subname === 'Images') {
          navigate(`/Projects/${projectId}/Document/${documentId}/WalmartListingsImages`);
        } else navigate(`/Projects/${projectId}/Document/${documentId}/WalmartListingsDetails`);
        break;
      case 'Amazon listings':
        if (data?.subname === 'Images') {
          navigate(`/Projects/${projectId}/Document/${documentId}/AmazonListingsImages`);
        } else navigate(`/Projects/${projectId}/Document/${documentId}/AmazonListingsDetails`);
        break;
      default: break;
    }
  };

  const createNewProject = async (templateData) => {
    setLoading(true);
    const url = BASE_URL + 'image-gen/newProject';
    const data = new FormData();
    data?.append('project_name', projectName);
    data?.append('template_name', templateData?.t_name);
    try {
      const responseData = await axios.post(
      url,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: LocalStorage?.getItem()
        }
      }
      );
      const createdProjectData = responseData?.data?.data;
      const projectId = createdProjectData?.projects?.projectID;
      const documentId = createdProjectData?.documents?.documentID;
      localStorage.setItem('selectedProject', projectName);
      localStorage.setItem('selectedDocument', createdProjectData?.documents?.document_name || 'Untitled');
      handleNavigation(templateData, projectId, documentId);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const createNewDocument = async (templateData) => {
    setLoading(true);
    const url = BASE_URL + 'image-gen/newDocument';
    const data = new FormData();
    data?.append('projectID', projectID);
    data?.append('template_name', templateData?.t_name);
    try {
      const responseData = await axios.post(
      url,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: LocalStorage?.getItem()
        }
      }
      );
      const createdDocumentData = responseData?.data?.data;
      const projectId = createdDocumentData?.documents?.projectID || projectID;
      const documentId = createdDocumentData?.documents?.documentID;
      localStorage.setItem('selectedProject', projectName);
      localStorage.setItem('selectedDocument', createdDocumentData?.documents?.document_name || 'Untitled');
      handleNavigation(templateData, projectId, documentId);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = (responseData) => {
    if (!isNewDocument) {
      createNewProject(responseData);
    } else createNewDocument(responseData);
  };

  return (
    <div
      className="relative z-50 flex items-center justify-center font-jost"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="relative flex h-full items-end justify-center p-4 text-center">
          <div
            className="sticky transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all
            bottom-[calc(50%-305.5px)] ds1:bottom-[calc(50%-305.5px)] ds2:bottom-[calc(50%-360px)] ds3:bottom-[calc(50%-360px)] ds4:bottom-[calc(50%-432px)]"
          >
            <div className="bg-white font-jost text-[#000000]">
              <div className="w-[935px] h-[611px] ds1:h-[611px] ds2:h-[720px] ds3:h-[720px] ds4:h-[864px] p-[40px]">
                <div className='gap-[20px]'  id='modal-title'>
                  <div className='grid grid-cols-12 pb-[20px]'>
                    <div className='font-[600] text-[28px] whitespace-nowrap col-span-11'>
                      Project: {projectName}
                    </div>
                    <span
                      className='col-span-1 text-right flex items-start justify-end'
                      role="presentation"
                      onClick={() => handleCloseTemplatePopup()}
                    >
                      <img src={closeIcon} alt='' width='20px' height='20px' />
                    </span>
                  </div>
                  <div className='w-full font-[400] text-[24px] whitespace-nowrap pb-[20px]'>
                    What do you want to create?
                  </div>
                  {templateLoading ? (
                    <div className='flex justify-center items-center pt-[40px]'>
                      <div className='spinner-border ' role='status'>
                        <ClipLoader color='#757D89' />
                      </div>
                    </div>
                  ) : (
                    <div className='grid grid-cols-4 gap-[20px] overflow-y-auto scroll-smooth pb-[40px]
                      h-[453px] ds1:h-[453px] ds2:h-[562px] ds3:h-[562px] ds4:h-[706px]'>
                      {socialTemp.map((resp, index) => (
                        <div
                          key={index}
                          role='presentation'
                          onClick={() => { !loading && handleCreate(resp); }}
                        >
                          <div
                            key={index}
                            data-tooltip-id={index + 1}
                            className={`w-[199px] h-[198px] border border-[#94A3B8] 
                            rounded-[5px] flex flex-col items-center cursor-pointer text-primaryColor 
                            ${id === index && 'bg-[#f1f1f1] !text-[#5C80FF]'}`}
                            onMouseEnter={() => setId(index)}
                            onMouseLeave={() => setId('')}
                            id={handleTemplateId(resp)}
                          >
                            <div className='pt-[43px] pb-[21px]'>
                              <img
                                className='h-[60px] w-[60px]'
                                src={id === index ? resp?.image_color : resp?.image_bw}
                                alt='single'
                                width="60px"
                                height="60px"
                                id={handleTemplateId(resp)}
                              />
                            </div>
                            <div className='text-[25px] font-[400] leading-[30px]' id={handleTemplateId(resp)}>{resp?.name}</div>
                            <div className='text-[20px] font-[400] leading-[28.9px]' id={handleTemplateId(resp)}>{resp?.subname}</div>
                          </div>
                          <Tooltip
                            id={index + 1}
                            place='bottom-start'
                            variant='light'
                            noArrow='true'
                            style={{
                              width: '250px', marginTop: '20px',
                              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                            }}
                          >
                            {resp?.info}
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplatePopup;