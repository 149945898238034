/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
import { FC, createContext, ReactNode, useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';

const initialState = {
  pid: '',
  imageURL: '',
  generatedImages: [],
  optedImage: '',
  optedTheme: ''
};

export const MainContext = createContext({
  state: initialState,
  setState: () => {}
});

export const MainProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const editableImageRef = useRef([]);
  const editText = useRef([]);

  const clearProductContent = () => {
    setState(prev => ({
      ...prev,
      content: { ...prev.content, description: '', feature: '', title: '' },
      canvasData: { ...prev.canvasData, productContent: [{ title: '', feature: '', description: '' }] }
    }));
  };

  const clearBlog = () => {
    setState(prev => ({
      ...prev,
      content: { ...prev.content, blog: '' },
      canvasData: { ...prev.canvasData, blogPosts: '' }
    }));
  };

  const clearSocial = () => {
    setState(prev => ({
      ...prev,
      content: { ...prev.content, social: '' },
      canvasData: { ...prev.canvasData, socialPosts: '' }
    }));
  };

  const value = {
    state,
    setState,
    clearProductContent,
    clearBlog,
    clearSocial,
    editableImageRef,
    editText
  };

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};
