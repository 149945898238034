import React from 'react';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';

const ReactSelect = ({
  name,
  options,
  onChange,
  isDisabled,
  label,
  placeholder,
  noDropDownIndicator,
  isClearable,
  customStyles
}) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(name);
  const formData = useFormikContext();

  const defaultCustomStyles = {
    valueContainer: provided => ({
      ...provided,
      height: '36px'
    }),
    singleValue: provided => ({
      ...provided,
      top: '60%'
    }),
    menu: provided => ({
      ...provided,
      zIndex: 100,
      overflow: 'scroll'
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display:'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: noDropDownIndicator ? 'none' : ''
    }),
    control: provided => ({
      ...provided,
      boxShadow: 'none',
      borderColor: '#cccccc',
      '&:hover': {
          borderColor: '#cccccc'
      }
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      color: '#333333',
      '&:hover': {
        backgroundColor: '#f1f1f1'
      },
      '&:active': {
        backgroundColor: '#f1f1f1'
      },
      cursor: 'pointer', // added the cursor pointer to the options
      zIndex: 100,
      overflow: 'auto'
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '150px',
      overflow: 'auto',
      // added the scrollbar to the dropDown options 
      '::-webkit-scrollbar': {
        width: '5px',
        height: '5px'
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888'
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    })
  };

  const onPlatformSelect = (platform) => {
    onChange && onChange(platform);
    helpers.setValue(platform);
  };

  return (
    <div className='flex flex-col lg:text-[12px] xl:text-[14px] 2xl:text-[16px] whitespace-nowrap font-jost'>
      {label && (
        <label className='full text-[20px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-[400]'>
          {label}
        </label>
      )}
      <Select
        menuPlacement='auto'
        name={name}
        value={field.value}
        onChange={(value) => onPlatformSelect(value)}
        options={options}
        isDisabled={isDisabled}
        isSearchable={false}
        placeholder={placeholder}
        styles={customStyles ? {...defaultCustomStyles, ...customStyles} : defaultCustomStyles}
        isClearable={isClearable || false}
        className='mt-[5px] border-[#D9D9D9]'
      />
      {formData?.submitCount > 0 ||
        (meta.error && meta.touched) ? (
        <div className='error text-red-500 text-[12px]'>{meta.error}</div>
      ) : null}
    </div>
  );
};
export default ReactSelect;