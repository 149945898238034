import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import AppLoader from 'components/shared';
import { getUserStatistics } from 'queryhook/accounts/getUserStatistics';

const Pricing = () => {
  const { data, isLoading } = useQuery('userStatistics', getUserStatistics);

  useEffect(() => {
    window.analytics.page('Account Page', '/account', {
      userId: localStorage.getItem('user-id'),
      email: localStorage.getItem('user-email')
    });
  }, []);

  if (isLoading)
    return (
      <h2 className=''>
        {' '}
        <AppLoader isLoading={isLoading} size={70} />
      </h2>
    );

  return (
    <div className='h-full px-3'>
      <div className='pt-5'>
        <stripe-pricing-table pricing-table-id='prctbl_1N3I9ZE3QGdTkzHbuuPyiHAz'
          publishable-key=
          'pk_live_51MgBS4E3QGdTkzHbO129sUVP0WdgqdqJWvtPDpyEVstW6Tl1nUXcl8RCoGkiGUJ2aHaYIywwwoCh5oP8mBpYfng800AMg0yI08'
          client-reference-id={data?.data?.userID}
          customer-email={data?.data?.email}
        >
        </stripe-pricing-table>
      </div>
      {/* <div className='flex justify-between gap-5 my-7'>
				<PlanCard
					title='Starter Plan'
					price={data?.data?.credits_used}
					balance={data?.data?.total_credits}
					email={data?.data?.email}
					id={data?.data?.userID}
				/>
			</div> */}
    </div>
  );
};

export default Pricing;
