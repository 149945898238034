import React from 'react';
import Select from 'react-select';

const ReactSelectFilters = ({
  name,
  options,
  onChange,
  placeholder,
  noDropDownIndicator,
  isClearable,
  customStyles,
  handleDropdownInputChange,
  selectFieldValues,
  noOptionMessage,
  isMulti,
  menuListMaxHeight,
  onMenuOpen,
  disableEnterKey
}) => {
  // eslint-disable-next-line no-unused-vars

  const defaultCustomStyles = {
    valueContainer: provided => ({
      ...provided,
      minHeight: '44px',
      padding: '10px'
    }),
    multiValueGeneric: (provided) => ({
      ...provided,
      fontSize: '12px',
      fontFamily: 'Jost',
      fontWeight: '400',
      color: '#7D7D7D'
    }),
    singleValue: provided => ({
      ...provided,
      top: '60%',
      color: '#7D7D7D'
    }),
    menu: provided => ({
      ...provided,
      zIndex: 100,
      overflow: 'scroll'
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display:'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: noDropDownIndicator ? 'none' : ''
    }),
    control: provided => ({
      ...provided,
      boxShadow: 'none',
      borderColor: '#D9D9D9',
      '&:hover': {
          borderColor: '#D9D9D9'
      }
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      color: '#333333',
      '&:hover': {
        backgroundColor: '#f1f1f1'
      },
      '&:active': {
        backgroundColor: '#f1f1f1'
      },
      cursor: 'pointer', // added the cursor pointer to the options
      zIndex: 100,
      overflow: 'auto'
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: menuListMaxHeight || '150px',
      overflow: 'auto',
      // added the scrollbar to the dropDown options 
      '::-webkit-scrollbar': {
        width: '5px',
        height: '5px'
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888'
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }),
    multiValue: (provided) => ({
      ...provided,
      height: '24px',
      backgroundColor: '#F0F0F0',
      borderRadius: '100px',
      fontSize: '12px',
      fontFamily: 'Jost',
      fontWeight: '400',
      paddingLeft: '4px',
      paddingRight: '6px',
      color: '#7D7D7D !important',
      alignItems: 'center'
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#7D7D7D'
      },
      color: '#7D7D7D'
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: 'black',
      textAlign: 'left',
      fontWeight: '400',
      fontFamily: 'Jost',
      fontSize: '12px'
    })
  };

  const onPlatformSelect = (platform) => {
    onChange && onChange(platform);
    // helpers.setValue(platform);
  };

  // Handle Enter key event
  const handleKeyDown = (event) => {
    if (disableEnterKey && event?.key === 'Enter') {
      event?.preventDefault();
    }
  };
  
  return (
    <div className='flex flex-col lg:text-[12px] xl:text-[14px] 2xl:text-[16px] whitespace-nowrap font-jost'>
      <Select
        menuPlacement='auto'
        name={name}
        value={selectFieldValues}
        onChange={(value) => onPlatformSelect(value)}
        options={options}
        isSearchable={isMulti}
        placeholder={placeholder}
        styles={customStyles ? {...defaultCustomStyles, ...customStyles} : defaultCustomStyles}
        isClearable={isClearable || false}
        className='mt-[5px] border-[#D9D9D9]'
        isMulti={isMulti}
        onInputChange={(e) => handleDropdownInputChange(e)}
        noOptionsMessage={() => noOptionMessage || 'No options'}
        openMenuOnClick={!noOptionMessage}
        onKeyDown={handleKeyDown}
        onMenuOpen={onMenuOpen && onMenuOpen}
      />
    </div>
  );
};
export default ReactSelectFilters;