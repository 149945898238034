import React, { useState, useRef, useEffect } from 'react';
import { IoMdArrowBack } from 'react-icons/io';

const Caption = () => {
  const [text, setText] = useState('');
  // const [textX, setTextX] = useState(0);
  // const [textY, setTextY] = useState(0);
  const [fontSize, setFontSize] = useState(22);
  const [isTextBold, setIsTextBold] = useState(false);
  // const [isTextItalic, setIsTextItalic] = useState(false);
  const [isTextUnderline, setIsTextUnderline] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [canvas, setCanvas] = useState(null);
  // const [textElement, setTextElement] = useState(null);
  const canvasRef = useRef(null);
  // const imageRef = useRef(null);
  const textRef = useRef(null);

  console.log(canvas, setIsDragging);

  // test
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartX(e.clientX - posX);
    setStartY(e.clientY - posY);
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const { width, height } = canvasRef.current.getBoundingClientRect();
      const textPos = textRef.current.getBoundingClientRect();
      //console.log(left, e.clientX - startX);
      setPosX(Math.min(Math.max(e.clientX - startX, 0)), width - textPos.width);
      setPosY(Math.min(Math.max(e.clientY - startY, 0), height - textPos.height));
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const img = new Image();
    img.src = 'https://c4.wallpaperflare.com/wallpaper/849/720/847/two-white-5-petaled-flowers-wallpaper-preview.jpg';
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);
    };
    setCanvas(canvas);
  }, []);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  return (
    <div className='flex gap-[50px] justify-center pt-5'>
      <div className='h-[600px] bg-[#f5f5f5] rounded-lg'>
        <div className='bg-white mx-auto py-1 rounded-lg shadow-[0_4px_0px_rgba(0,0,0,0.3)] mt-[15px] w-[120px] 
        text-center text-[#684BFF] font-semibold text-[18px]'>Resize</div>
        <div className='mt-[25px] flex flex-col gap-1'>
          <div className='bg-[#684BFF] w-[220px] duration-200 pl-[18px] py-[10px] cursor-pointer
           hover:bg-[#b8b8b8] text-white rounded-lg'>Original</div>
          <div className='bg-white w-[220px] duration-200 pl-[18px] py-[10px] cursor-pointer
           hover:bg-[#b8b8b8] text-[#00000087] rounded-lg'>Facebook Post</div>
          <div className='bg-white w-[220px] duration-200 pl-[18px] py-[10px] cursor-pointer
           hover:bg-[#b8b8b8] text-[#00000087] rounded-lg'>Instagram Post</div>
          <div className='bg-white w-[220px] duration-200 pl-[18px] py-[10px] cursor-pointer
           hover:bg-[#b8b8b8] text-[#00000087] rounded-lg'>Instagram Story</div>
          <div className='bg-white w-[220px] duration-200 pl-[18px] py-[10px] cursor-pointer
           hover:bg-[#b8b8b8] text-[#00000087] rounded-lg'>1024 x 1024</div>
          <div className='bg-white w-[220px] duration-200 pl-[18px] py-[10px] cursor-pointer
           hover:bg-[#b8b8b8] text-[#00000087] rounded-lg'>Super Resolution</div>
          <div className='bg-white w-[220px] duration-200 pl-[18px] py-[10px] cursor-pointer
           hover:bg-[#b8b8b8] text-[#00000087] rounded-lg'>Custom Size</div>
        </div>
        <button className='generate__btn mt-[100px] disabled'>Resize</button>
      </div>

      <div>
        <div className='flex gap-1.5 items-center mb-4 cursor-pointer'><IoMdArrowBack /> Back</div>
        <div className='flex gap-[50px] justify-center'>
          <div className='bg-white rounded-lg p-4 w-[280px] h-[512px]'>
            <h4 className='text-center text-[18px] mb-4'>Edit Text Caption</h4>
            <textarea
              type="text"
              id="text-input"
              className='w-full rounded-lg mb-1.5'
              placeholder='Insert your text captions here...'
              value={text}
              onChange={handleTextChange}
            />
            <select className='w-full rounded-[6px] py-1 mb-3'>
              <option>Font 1</option>
              <option>Font 2</option>
              <option>Font 3</option>
            </select>
            <div className='flex justify-between'>
              <div className='flex'>
                <button className='border border-black px-1.5 py-1 rounded-l-md'
                  onClick={() => setFontSize(parseInt(fontSize) - 1)}>-</button>
                <input className="border border-black w-[45px] px-2 text-center" value={fontSize}
                  onChange={(e) => setFontSize(e.target.value)} />
                <button className='border border-black px-1.5 py-1 rounded-r-md'
                  onClick={() => setFontSize(parseInt(fontSize) + 1)}>+</button>
              </div>
              <button className={`${isTextBold ? 'bg-[#5555e8] text-white' : 'bg-[#f5f5f5]'} w-[35px] rounded-mg`}
                onClick={() => setIsTextBold(prev => !prev)}>B</button>
              <button className='bg-[#f5f5f5] w-[35px] rounded-mg'>/</button>
              <button className='bg-[#f5f5f5] w-[35px] rounded-mg'
                onClick={() => setIsTextUnderline(prev => !prev)}>U</button>
              <button className='bg-[#f5f5f5] w-[35px] rounded-mg'>Aa</button>
            </div>
            <button className='generate__btn mt-[100px]'>Save</button>
          </div>
          <div className='relative'>
            <canvas ref={canvasRef} className='w-[512px] h-[512px] object-cover' />
            <div
              style={{
                position: 'absolute',
                left: `${posX}px`,
                top: `${posY}px`,
                cursor: isDragging ? 'grabbing' : 'grab',
                fontSize: `${fontSize}px`,
                fontWeight: isTextBold ? 'bold' : 'normal',
                textDecoration: isTextUnderline ? 'underline' : 'none',
                border: '1px dashed #000'
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}

              ref={textRef}
            >
              {text}
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div>
      </div>
    </div>
  );
};

export default Caption;
