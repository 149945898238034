import { useEffect, useRef } from 'react';

const useClickOutsideHandler = (elementId, resetState, dependencies = []) => {
  const ref = useRef(null);

  const handleClick = (e) => {
    const targetElement = document.getElementById(elementId);
    
    if (ref.current && targetElement && !targetElement.contains(e.target)) {
      resetState();
    }
  };

  useEffect(() => {
    ref.current = document.getElementById(elementId);
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [elementId, ...dependencies]);

  return ref;
};

export default useClickOutsideHandler;
