import { LocalStorage } from 'services/localStorage';
import axios from 'axios';
// import { toast } from 'react-toastify';
import { BASE_URL } from 'utils/baseURL';

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(',');
    //console.log(arr)
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export async function uploadImgToRemoveBg(image, doMock, documentID, projectID) {
  function returnMockData() {
    return {
      pid: 'c7152442-4256-460c-b09a-aec99eb4a35c',
      input_image_url:
        'https://storage.googleapis.com/fun-gen-incoming/094459a5f4a84fb484ba5c5177e8e7b6.jpg',
      remove_background_url:
        'https://storage.googleapis.com/fun-gen-incoming/ad250c9a8e2c479a923129d2fd5f4266.png',
      userID: '4kycZitz7FTs9G0OI25SuGgXzW63',
      time: '05-03-2023 08-05-08',
      generated_images: [],
      _id: '58eeaa1a-3cfe-4d3f-a27e-2e7fba782e0a'
    };
  }

  if (doMock) {
    return { data: returnMockData() };
  } else {
    const URL =
      BASE_URL + 'image-gen/upload_image';
      // 'http://127.0.0.1:8000/image-gen/upload_image'

    const data = new FormData();
    data.append('image', image);
    // data.append('image_name', fileName || image.name);
    if (documentID) data.append('documentID', documentID);
    if (projectID) data.append('projectID', projectID);

    try {
      const response = await axios.post(
        // 'https://dbcxwnd7d5.us-east-1.awsapprunner.com/image-gen/predict-image',
        URL,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (
        response.data.status_code === 400 &&
        response.data.detail.message ===
          'Your token has expired, Please login again'
      ) {
        console.log(response.data.detail.message);
        return 'expired';
      } else if (response.data.status_code === 400) {
        console.log(response.data.detail.message);
        return null;
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
}

export async function uploadBulkImgToRemoveBg(images, collectionName) {
    const URL =
      BASE_URL + 'image-gen/upload_bulk_images';
      // 'http://127.0.0.1:8000/image-gen/upload_image'

    const data = new FormData();
    for (let i = 0; i < images.length; i++) {
      data.append('images', images[i]);
    }
    data.append('collection_name', collectionName);

    try {
      const response = await axios.post(
        URL,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (
        response.data.status_code === 400 &&
        response.data.detail.message ===
          'Your token has expired, Please login again'
      ) {
        console.log(response.data.detail.message);
        return 'expired';
      } else if (response.data.status_code === 400) {
        console.log(response.data.detail.message);
        return null;
      }else if (response.data.status_code === 402) {
        console.log(response.data.detail.message);
        return 'no credits';
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
}

export const getSocialMediaContent = async (setSocialContent) => {
  const socialPlatform = ['Instagram', 'Facebook', 'Pinterest', 'Twitter']; //TikTok
  const requests = socialPlatform.map((platform) =>
    axios.post(
      'https://content-generator-api-hvrhzwzgoa-uc.a.run.app/content/generate',
      {
        type: 'social',
        social_platform: platform,
        variations: 1,
        text: prompt
      },
      {
        headers: {
          'x-api-key': '70e1571f30ad402e94c3ff8c4d49ff07'
        }
      }
    )
  );
  try {
    const responses = await axios.all(requests);
    const data = [];
    responses.forEach((resp) => {
      data.push(resp.data);
    });
    setSocialContent(data);
  } catch (err) {
    console.log(err);
  }
};

export async function uploadBulkImages(images) {
  const URL =
    BASE_URL + 'image-gen/test_upload_bulk_images';
    // 'http://127.0.0.1:8000/image-gen/upload_image'

  const data = new FormData();
  for (let i = 0; i < images.length; i++) {
    data.append('images', images[i]);
  }
  // data.append('collection_name', collectionName);

  try {
    const response = await axios.post(
      URL,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: LocalStorage.getItem()
        }
      }
    );
    if (
      response.data.status_code === 400 &&
      response.data.detail.message ===
        'Your token has expired, Please login again'
    ) {
      console.log(response.data.detail.message);
      return 'expired';
    } else if (response.data.status_code === 400) {
      console.log(response.data.detail.message);
      return null;
    }else if (response.data.status_code === 402) {
      console.log(response.data.detail.message);
      return 'no credits';
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
