import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from 'utils/baseURL';

const Dashboard = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('');
  const [socialTemp, setSocialTemp] = useState([]);

  const getSocialTemplate = async () => {
    try {
      const templateData = await axios
        .get(BASE_URL + 'image-gen/all_templates_mobile', {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        });
      setSocialTemp(templateData?.data?.data?.templates);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSocialTemplate();
    setUserEmail(localStorage.getItem('user-email') ?? '');
  }, []);

  const handleNavigation = (data) => {
    if (data) localStorage.setItem('selectedTemp', JSON.stringify(data));
    localStorage.removeItem('selectedImage');
    localStorage.removeItem('selectedCaption');
    localStorage.removeItem('captionList');
    localStorage.removeItem('selectedSocialMediaTab');
    switch (data?.name) {
      case 'Image Generator':
        navigate('/mobile/ImageGenerator');
        window.localStorage.setItem('resizedPath', JSON.stringify('/mobile/ImageGenerator'));
        break;
      case 'Photo Studio':
        navigate('/mobile/PhotoStudio');
        window.localStorage.setItem('resizedPath', JSON.stringify('/mobile/PhotoStudio'));
        break;
      case 'Instagram':
        navigate('/mobile/InstagramPost/Image');
        window.localStorage.setItem('resizedPath', JSON.stringify('/mobile/InstagramPost/Image'));
        break;
      case 'Facebook':
        navigate('/mobile/FacebookPost/Image');
        window.localStorage.setItem('resizedPath', JSON.stringify('/mobile/FacebookPost/Image'));
        break;
      default: break;
    }
  };
  return (
    <div className='mt-4 flex flex-col justify-center items-center font-jost'>
      <div className='w-[100vw] flex justify-center'>
        <div className='text-[24px] font-[400] text-[#000] px-4 truncate w-100vw'>
          Hello {userEmail}!
        </div>
      </div>
      {socialTemp.length <= 0 ? (
        <div className='flex justify-center '>
          <div className='spinner-border ' role='status'>
            <ClipLoader color='#757D89' />
          </div>
        </div>
      ) : (
        <>
          <div className='text-[20px] font-[400] text-[#000] my-4'>
            What do you want to create?
          </div>
          <div className='grid sm:grid-cols-2 sm2:grid-cols-3 md:grid-cols-4 gap-[20px]'>
            {socialTemp.map((resp, index) => (
              <div key={index} role='presentation' onClick={() => handleNavigation(resp)}>
                <div
                  key={index}
                  className={`w-[160px] border border-[#D9D9D9] 
                    rounded-[5px] flex flex-col items-center cursor-pointer bg-[#F5F5F5]
                    px-[10px] py-[10px] text-[#000]`}
                >
                  <img className='mt-[10%] mb-[16px] h-[50px] w-[50px]'
                    src={resp?.image_color} alt='single' />
                  <div className='text-[16px] font-[400]'>{resp?.name}</div>
                  <div className='text-[12px] font-[300] text-center'>{resp?.info}</div>
                </div>
              </div>
            ))}
          </div>
        </>)}
    </div>
  );
};

export default Dashboard;