import React, { useEffect, useState } from 'react';
import { AppButton } from 'components/shared/AppButton';
import closeIcon from 'assets/icons/closeIcon.svg';
import { BASE_URL } from 'utils/baseURL';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import { ClipLoader } from 'react-spinners';
import blueTick from 'assets/img/Bluetick.svg';

const MoveToModal = ({
  handleCloseBtn,
  projectId,
  setProjectID,
  isFilesUploading,
  imageClickData,
  getProjectImageList
}) => {
  const [projectList, setProjectList] = useState([]);
  let currProjectID = imageClickData?.projectID;

  const handMoveToBtn = async () => {
    try {
      const data = new FormData();
      const imgPid = imageClickData?.pid;
      data.append('currentProjectID', currProjectID);
      data.append('newProjectID', projectId);
      data.append('pids', [imgPid]);
      const url = BASE_URL + 'image-gen/moveToProject';
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            token: LocalStorage.getItem()
          }
        });
      if (response && response.data) {
        getProjectImageList();
      }
    } catch (error) {
      console.log(error);
    }
    setProjectID();
    handleCloseBtn();
  };

  const getExistingProjectLists = async () => {
    const url = BASE_URL + 'image-gen/projects';
    try {
      const projectListData = await axios.get(
        url,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      setProjectList(projectListData?.data?.data?.projects);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getExistingProjectLists();
  }, []);

  const handleClose = () => {
    setProjectID();
    handleCloseBtn();
  };

  const handleImglocation = (projectID) => {
    setProjectID(projectID);
  };

  return (
    <div
      className='relative z-50'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
      onClick={(e) => e?.stopPropagation()}
    >
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>
      <div className='fixed inset-0 z-50 overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 w-[514px] h-[514px] p-[40px]'>
            <div className='bg-white flex flex-col gap-[20px]'>
              <div className='w-full'>
                <div className='text-left'>
                  <div className='grid grid-cols-12'>
                    <h3
                      className='font-jost font-[600] text-[24px] whitespace-nowrap col-span-11'
                      id='modal-title'
                    >
                      Move to...
                    </h3>
                    <span
                      className='text-right flex items-center justify-center w-[35px] h-[35px] 
                      hover:bg-[#F0F0F0] rounded-[5px] cursor-pointer'
                      role="presentation"
                      onClick={() => handleClose()}
                    >
                      <img src={closeIcon} alt='' width='20px' height='20px' />
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full text-[18px] text-[#7D7D7D]'>
                Selected assets are moved over to a new project folder
              </div>
              <div className='w-[434px] h-[248px] rounded-[10px] border border-solid border-[#D9D9D9] 
                  overflow-scroll p-[20px] flex flex-col gap-[10px]'>
                {projectList?.length > 0 ? (
                  projectList?.map((projectItem, index) => (
                    <div
                      key={index}
                      className={`p-[10px] text-[16px] rounded-[5px] relative
                      ${currProjectID.includes(projectItem?.projectID) ?
                          'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
                      ${!(projectId === projectItem?.projectID) && 'hover:bg-[#F0F0F0]'} 
                      ${projectId === projectItem?.projectID && 'bg-[#E5EBFF]'}`}
                      onClick={() => !(currProjectID.includes(projectItem?.projectID))
                        && handleImglocation(projectItem?.projectID)}
                    >
                      {projectItem?.project_name}
                      {projectId === projectItem?.projectID && (
                        <img
                          src={blueTick}
                          alt=''
                          className='absolute right-[10px] top-[14px] w-[16px] h-[16px]'
                        />
                      )}
                    </div>
                  ))) : (
                  <div className='flex justify-center'>
                    <ClipLoader color='#757D89' />
                  </div>
                )}
              </div>
              <div className='w-full flex justify-end items-center gap-[10px]'>
                <AppButton
                  onClick={() => handleClose()}
                  type='button'
                  variant='light'
                  extendClass='bg-black hover:bg-black rounded-[5px] border-[0px] text-[20px] h-[48px] px-5 2xl:px-7'
                  disabled={isFilesUploading}
                >
                  Cancel
                </AppButton>
                <AppButton
                  onClick={() => { handMoveToBtn(); }}
                  type='button'
                  variant='black'
                  extendClass={` rounded-[5px] text-[20px] h-[48px] px-5 2xl:px-7
                  ${(!projectId || isFilesUploading) ? 'bg-[#D9D9D9] hover:bg-[#D9D9D9] !opacity-100'
                      : 'bg-[#0038FF] hover:bg-[#0038FF]'} `}
                  disabled={!projectId || isFilesUploading}
                >
                  Move
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoveToModal;