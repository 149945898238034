import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RenderSmoothImage from 'components/ReactSmoothImageRendring';
import closeIcon from 'assets/icons/closeIcon.svg';
// import { toast } from 'react-toastify';
import downloadMob from 'assets/icons/downloadMob.svg';
import deleteOutlineMob from 'assets/mobileIcons/DeleteIcon.svg';
import { Dropdown } from 'flowbite-react';
import { LocalStorage } from 'services/localStorage';
import axios from 'axios';
import { AppButton } from 'components/shared/AppButton';
import resizeIcon from 'assets/mobileIcons/ResizeIcon.svg';
import RightArrow from 'assets/img/RightArrow.svg';
import cn from 'classnames';
import { BASE_URL } from 'utils/baseURL';

const ImageViewScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = window.location.pathname;
  const [showSelectButton, setShowSelectButton] = useState(false);

  const imageData = location?.state?.imageData;
  const selectImageToGen = location?.state?.selectImageToGen;
  const redirectPath = location?.state?.redirectPath;

  useEffect(() => {
    if (path.includes('InstagramPost') || path.includes('FacebookPost')) {
      setShowSelectButton(true);
    } else {
      setShowSelectButton(false);
    }
  }, [path]);

  const handleDownload = () => {
    const tempFileName = imageData?.url.split('/');
    const imageName = tempFileName[tempFileName.length - 1];
    const name = imageName || 'BreezeAppImage.png';
    fetch(imageData?.url,
      {
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' }
      })
      .then(response => response?.blob())
      .then(blob => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';

        if (name?.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = async () => {
    const imgGid = imageData.gid;
    const data = new FormData();
    data.append('gid', imgGid);

    const config = {
      method: 'delete',
      url: BASE_URL + 'image-gen/delete-image',
      headers: {
        token: LocalStorage.getItem(),
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };
    try {
      const response = await axios(config);
      if (response && response.data) {
        navigate(-1);
        console.log(response.data.message);
      }
    } catch (err) {
      console.log('Something went wrong');
    }
  };

  const handleSelectButton = () => {
    localStorage.setItem('selectedImage', JSON.stringify(imageData));
    localStorage.removeItem('captionList');
    localStorage.removeItem('selectedCaption');
    if (path.includes('InstagramPost')) {
      navigate('/mobile/InstagramPost/Preview');
    } else if (path.includes('FacebookPost')) {
      navigate('/mobile/FacebookPost/Preview');
    }
  };

  // Function to store the selected Image for generating bg in localStorage with navigation
  const handleSelectImageToGen = () => {
    localStorage.setItem('selectedImg', JSON.stringify(imageData));
    navigate(redirectPath);
  };

  const handleResize = () => {
    if (path.includes('PhotoStudio')) {
      navigate('/mobile/PhotoStudio/Resize', { state: imageData });
    } else if (path.includes('ImageGenerator')) {
      navigate('/mobile/ImageGenerator/Resize', { state: imageData });
    } else if (path.includes('InstagramPost')) {
      navigate('/mobile/InstagramPost/Image/Resize', { state: imageData });
    } else if (path.includes('FacebookPost')) {
      navigate('/mobile/FacebookPost/Image/Resize', { state: imageData });
    }
  };

  return (
    <>
      <div className='pt-[20px] h-auto'>
        <div className='flex justify-end pb-3'>
          <div role='presentation' onClick={() => navigate(-1)} className='p-[20px] mb-[10px]'>
            <img src={closeIcon} alt='' width='18px' height='18px' />
          </div>
        </div>
        <div className='aspect-square max-w-[385px] max-h-[385px] m-auto flex relative flex-col'>
          <div className={cn(selectImageToGen && 'bg-canvasMeshBg')}>
            <RenderSmoothImage
              src={(imageData?.gid || selectImageToGen) ? imageData?.url : imageData?.input_image_url}
              alt="loading..."
              objectFit="fill"
              className='bg-[#F1F1F1] max-w-full m-auto max-h-full'
              noBackGround
            />
          </div>
          {/* show download, resize and delete icon for non select image from library flow */}
          {!selectImageToGen && (
            <div className='flex gap-[20px] pt-[20px]'>
              {/*Added Download_Gen_Image_Mob as Id for all the related elements for goggle event trigger */}
              <div
                className='flex leading-[20px] hover:cursor-pointer bg-[#F0F0F0] p-[10px] rounded-[5px] ml-[20px]'
                role='presentation'
                onClick={handleDownload}
                id='Download_Gen_Image_Mob'
              >
                <img
                  src={downloadMob}
                  alt="downloadIcon"
                  width="20px"
                  height="20px"
                  id='Download_Gen_Image_Mob'
                />
                <span
                  className='flex items-center pl-[5px] text-center font-jost font-[400] text-[16px] text-[#191919]'
                  id='Download_Gen_Image_Mob'
                >
                  Download
                </span>
              </div>
              {/* show resize icon for only generated images and not for uploaded images */}
              {!path.includes('ImageLibrary') &&
                (imageData?.allow_resize || imageData?.remove_background_url) && (
                  <div
                    className='flex leading-[20px] hover:cursor-pointer'
                    role='presentation'
                    onClick={handleResize}
                  >
                    <img
                      src={resizeIcon}
                      alt="resizeIcon"
                      width="20px"
                      height="20px"
                    />
                    <span
                      className='flex items-center pl-[5px] text-center font-jost font-[400] text-[16px] text-[#191919]'
                    >
                      Resize
                    </span>
                  </div>
                )}
              {/* show delete icon for all images except uploaded images */}
              {imageData?.gid && (
                <div
                  className='relative flex p-[10px] leading-[20px]'
                  role='presentation'
                >
                  <Dropdown
                    className="deleteDropdownMob" // css added to position the hamburger dropdown
                    arrowIcon={false}
                    inline
                    label={<div className='rounded-full flex items-center justify-end
                      text-black cursor-pointer gap-[5px]'>
                      <img
                        id='Delete_Gen_Image_Btn'
                        src={deleteOutlineMob}
                        alt="deleteIcon"
                        width="20px"
                        height="20px"
                      />
                      <span className='text-[#191919] font-[400] text-[16px]'>Delete</span>
                    </div>}
                  >
                    <span className='text-[#EB4444] py-[6px] px-[12px] text-[14px] font-[400]'
                      onClick={handleDelete}>Confirm deletion</span>
                  </Dropdown>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* show below button for non select image from library flow */}
      {!selectImageToGen && showSelectButton && (
        <div className='bg-white p-[20px] flex items-center justify-center fixed bottom-0 w-full'>
          <AppButton
            onClick={() => handleSelectButton()}
            type="button"
            variant='black'
            extendClass='bg-black rounded-[5px] text-[16px] font-[400]
               h-[56px] !py-[2px] !px-[20px] flex justify-center items-center w-full'
          >
            <span className='text-[16px] font-[400] font-jost text-white pr-[20px]'>Select image for preview</span>
            <img src={RightArrow} alt='' className='w-[8px] h-[15px]' />
          </AppButton>
        </div>
      )}
      {/* show below button for select image from library flow */}
      {selectImageToGen && (
        <div className='bg-white p-[20px] flex items-center justify-center fixed bottom-0 w-full'>
          <AppButton
            onClick={() => handleSelectImageToGen()}
            type="button"
            variant='black'
            extendClass='bg-black rounded-[5px] text-[16px] font-[400]
               h-[56px] !py-[2px] !px-[20px] flex justify-center items-center w-full'
          >
            <span className='text-[16px] font-[400] font-jost text-white pr-[20px]'>Generate</span>
          </AppButton>
        </div>
      )}
    </>
  );
};

export default ImageViewScreen;