import React from 'react';
import { Button, Checkbox, Label } from 'flowbite-react';
import { HiArrowLeft } from 'react-icons/hi';
// import { BsTrash3 } from 'react-icons/bs';
// import ProductDescription from 'components/ProductDescription';
import { useNavigate, useParams } from 'react-router-dom';
import Product from 'components/Product';
import useSingleImageHistory from 'queryhook/useSingleImageHistory';
import useImagesHistory from 'queryhook/useImagesHistory';
import AppLoader from 'components/shared';
import moment from 'moment/moment';
import { useState } from 'react';

function Second() {
  const [isDescending, setIsDescending] = useState(true);
  const navigate = useNavigate();
  let { imageId } = useParams();
  const { singleImageHistory, deleteSingleImageMutation } = useSingleImageHistory(imageId);
  const { imageHistory, isImageHistoryFetching, deleteImageMutation } = useImagesHistory();

  if (isImageHistoryFetching)
    return (
      <h2 className=''>
        {' '}
        <AppLoader isLoading={isImageHistoryFetching} size={70} />
      </h2>
    );

  const transformedBottomArray = (() => {
    const arr = singleImageHistory?.data?.generated_images?.flatMap((imgData) => {
      return imgData?.generated_images?.map((generatedImage) => {
        return { generatedImage, imgData, time: imgData.time };
      });
    });

    const getPosition = index => {
      if (index % 3 === 0) {
        return 'left';
      }
      if (index % 3 === 1) {
        return 'middle';
      }
      if (index % 3 === 2) {
        return 'right';
      }
    };
    return arr?.map((el, idx) => ({ ...el, position: getPosition(idx) }));
  })();

  return (
    <div className='py-2'>
      {/* <header className='header'>
        <h1 className='header__name'>breeze.ai</h1>
        <h2 className='header__title'>My Products</h2>
      </header> */}
      <section className='content'>
        <aside className='top-0'>
          <Button color='secondaryColor' className=' prevLink'>
            <HiArrowLeft className='w-8 h-8 hover:text-primary' onClick={() => navigate('/panel/images')} />
          </Button>
          <aside className='sticky top-0 sidebar'>
            <ul className='sidebar__list'>
              {imageHistory?.data?.history?.map((imgData, index) => {
                return (
                  <li key={index} className='w-28 h-28 sidebar__item'>
                    <Product
                      id={imgData?.pid}
                      inputImageUrl={imgData?.input_image_url}
                      deleteImageMutation={deleteImageMutation}
                    />
                  </li>
                );
              })}
            </ul>
          </aside>
        </aside>
        <div className='main'>
          <div className='w-2/3 gap-10 main__top h-72'>
            <Product
              id={singleImageHistory?.data?.pid}
              inputImageUrl={singleImageHistory?.data?.input_image_url}
              deleteImageMutation={deleteSingleImageMutation}
              hasGenerateButton
              hasDeleteButton
            />
            <Product
              id={singleImageHistory?.data?.pid}
              inputImageUrl={singleImageHistory?.data?.remove_background_url}
              deleteImageMutation={deleteSingleImageMutation}
              hasDownloadButton
            />
          </div>
          <div className='main__middle'>
            <div className='checkbox'>
              <p>Select Options to show images:</p>
              <div className='checkbox__part'>
                <Checkbox id='resized' defaultChecked={true} />
                <Label htmlFor='resized'>Resized Images</Label>
              </div>
              <div className='checkbox__part'>
                <Checkbox id='edited' defaultChecked={true} />
                <Label htmlFor='edited'>Edited Images</Label>
              </div>
              <div className='checkbox__part'>
                <Checkbox id='favorits' defaultChecked={true} />
                <Label htmlFor='favorits'>Favorites only</Label>
              </div>
            </div>
            <select
              name='filters'
              id='filter'
              className='header__filter'
              onChange={event => setIsDescending(!!+event.target.value)}
            >
              <option value={1}>Newest First</option>
              <option value={0}>Oldest First</option>
            </select>
          </div>
          <div className='main__bottom'>
            {transformedBottomArray
              ?.sort((imgDataA, imgDataB) => {
                const order = isDescending ? 1 : -1;
                return (
                  order * (moment(imgDataB.time, 'DD-MM-YYYY hh:mm:ss') - moment(imgDataA.time, 'DD-MM-YYYY hh:mm:ss'))
                );
              })
              ?.map((val, idx) => {
                return (
                  <div key={val.generatedImage + idx}>
                    <Product
                      inputImageUrl={val.generatedImage}
                      productDescription={val.imgData}
                      hasDescription
                      hasDownloadButton
                      hasDeleteButton
                      productPosition={val.position}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Second;
