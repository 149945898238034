import React, { useEffect, useState, useRef } from 'react';
import downloadIconGray from 'assets/icons/downloadIconGray.svg';
import deleteOutline from 'assets/icons/deleteOutline.svg';
import loadingGif from 'assets/img/loaderAnimation.gif';
import CanvasScreen from 'components/shared/CanvasAdvanceScreen';
import ImageListingCanvas from 'components/DesktopComponents/ListingTemplatesImages/ImageListingCanvas';
// import { toast } from 'react-toastify';
import axios from 'axios';
import FormData from 'form-data';
import { LocalStorage } from 'services/localStorage';
import resizeIcon from 'assets/icons/resizeGrayIcon.svg';
import imageEditIcon from 'assets/icons/imageEditIcon.svg';
import cn from 'classnames';
import { BASE_URL } from 'utils/baseURL';

const PreviewScreen = ({
  clickedImage,
  generateLoading,
  canvasWidth,
  canvasStageRef,
  handleImagesPosition,
  canvasTransformerRef,
  selectedImage,
  isMeshBg,
  selectedTab,
  captionListData,
  selectedCaption,
  setIsDeleted,
  handleCanvas,
  isListingTemplates,
  selectedListingTempTab,
  handleImageEditorPopup,
  isDisabledBtn
}) => {
  const selectedTemp = JSON.parse(localStorage.getItem('selectedTemp'));
  const screenWidth = window.innerWidth;

  const [isOpen, setIsOpen] = useState(false);
  const confirmRef = useRef(null);
  const deleteRef = useRef(null);

  const handleDelete = async () => {
    const imgGid = clickedImage?.gid.split('.')[0];
    const data = new FormData();
    data.append('gids', [imgGid]);

    const config = {
      method: 'delete',
      url: BASE_URL + 'image-gen/delete-image',
      headers: {
        token: LocalStorage.getItem(),
        'Content-Type': 'multipart/form-data'
      },
      data: data
    };

    try {
      const response = await axios(config);
      if (response && response.data) {
        setIsDeleted(true);
        console.log(response.data.message);
      }
    } catch (err) {
      console.log('Something went wrong');
    }
  };

  const PreviewSection = (data) => {
    const { imgSrc, imageData } = data;
    const [previewImg, setPreviewImg] = useState(false);

    // useEffect(() => {
    //   setPreviewImg(false);
    // }, [clickedImage]);

    const toggleConfirmBox = () => {
      setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
      if (deleteRef.current && !deleteRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Attach event listener when the component mounts
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);

      // Cleanup the event listener when the component unmounts
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <>
        <div
          className='flex flex-col justify-center items-center'
          style={{
            width: screenWidth < 1200 ? '350px' : `${canvasWidth}px`,
            height: screenWidth < 1200 ? '350px' : `${canvasWidth}px`,
            maxWidth: screenWidth < 1200 ? '350px' : `${canvasWidth}px`,
            maxHeight: screenWidth < 1200 ? '350px' : `${canvasWidth}px`
          }}
        >
          {(selectedTemp?.name !== 'Instagram' && selectedCaption) && (
            <div
              className='lg:text-[12px] xl:text-[14px] 2xl:text-[16px] font-[400] text-[#2C2C2C] pb-[20px]'
            >
              {selectedCaption}
            </div>
          )}
          <div className={cn('bg-[#F1F1F1] w-full h-full flex items-center justify-center relative',
            ((isListingTemplates && selectedListingTempTab === 'primary') || imageData?.colour_image) &&
            'bg-white border-[1px] border-[#757D89]')}>
            <img
              className={cn('m-auto max-w-full max-h-full',
                isListingTemplates && selectedListingTempTab === 'primary' ? 'bg-white' : 'bg-[#F1F1F1]')}
              src={imgSrc}
              alt=''
              onLoad={() => { setPreviewImg(true); }}
            />

            {/*Added Download_Gen_Image_Btn as Id for all the related elements for goggle event trigger */}
            {previewImg && (
              <div className='absolute right-[-100px] xl-mid:right-[-110px] top-[0px] flex flex-col gap-[20px]'>
                <div
                  className='flex items-center justify-start text-black cursor-pointer gap-[5px] leading-[20px]'
                  onClick={handleDownload}
                  role='presentation'
                  id='Download_Gen_Image_Btn'
                >
                  <img
                    src={downloadIconGray}
                    alt="downloadIcon"
                    width="20px"
                    height="20px"
                    id='Download_Gen_Image_Btn'
                  />
                  <span
                    className='text-[#7D7D7D] font-[400] lg:text-[12px] xl:text-[14px] xl-mid:text-[16px]
                        flex items-center justify-center'
                    id='Download_Gen_Image_Btn'>
                    Download
                  </span>
                </div>
                {(selectedTab === 'images' || isListingTemplates) && (
                  <>
                    {(imageData?.allow_resize) && (
                      /*Added Resize_Icon as Id for all the related elements for goggle event trigger */
                      <div
                        className={`flex items-center justify-start text-black gap-[5px] leading-[20px]
                        ${isDisabledBtn ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer'}`}
                        onClick={!isDisabledBtn && handleCanvas}
                        role='presentation'
                        id='Resize_Icon'
                      >
                        <img
                          src={resizeIcon}
                          alt="resizeIcon"
                          width="20px"
                          height="20px"
                          id='Resize_Icon'
                          className='w-[20px] h-[20px]'
                        />
                        <span
                          className='text-[#7D7D7D] font-[400] lg:text-[12px] xl:text-[14px] xl-mid:text-[16px]
flex items-center justify-center'
                          id='Resize_Icon'
                        >
                          Resize
                        </span>
                      </div>
                    )}
                    <div
                      className={cn('flex items-center justify-start text-black gap-[5px] leading-[20px]',
                        `${isDisabledBtn ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer'}`,
                        (imageData?.allow_resize) ? 'top-20' : 'top-10')}
                      onClick={!isDisabledBtn && handleImageEditorPopup}
                      role='presentation'
                    >
                      <img
                        src={imageEditIcon}
                        alt="imageEditIcon"
                        width="19px"
                        height="19px"
                        className='w-[19px] h-[19px]'
                      />
                      <span
                        className='text-[#7D7D7D] font-[400] lg:text-[12px] xl:text-[14px] xl-mid:text-[16px]
                        flex items-center justify-center'
                      >
                        Edit Image
                      </span>
                    </div>
                    <div
                      className={cn('flex items-center justify-start text-black gap-[5px] leading-[20px]',
                        `${isDisabledBtn ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer'}`,
                        (imageData?.allow_resize) ? 'top-[120px]' : 'top-20')}
                      ref={deleteRef}
                      onClick={!isDisabledBtn && toggleConfirmBox}>
                      <img
                        id='Delete_Gen_Image_Btn'
                        src={deleteOutline}
                        alt="deleteIcon"
                        width="20px"
                        height="20px"
                      />
                      <span className='text-[#7D7D7D] font-[400] lg:text-[12px] xl:text-[14px] xl-mid:text-[16px]'>
                        Delete
                      </span>
                      {isOpen && (
                        <div className={`absolute ${imageData?.allow_resize && 'top-[150px]'} top-[110px] left-[0px] 
                          w-[135px] text-[14px] deleteDropdownLibrary px-[12px] py-[6px]`}
                          ref={confirmRef}
                          onClick={handleDelete}>
                          <span className='text-[#EB4444] text-[14px] font-[400] hover:cursor-pointer'>
                            Confirm deletion</span>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          {(selectedTemp?.name === 'Instagram' && selectedCaption) && (
            <div
              className='lg:text-[12px] xl:text-[14px] 2xl:text-[16px] font-[400] pt-[20px]'
            >
              {selectedCaption}
            </div>
          )}
        </div>
      </>
    );
  };

  const handleDownload = () => {
    const tempFileName = clickedImage?.url.split('/');
    const imageName = tempFileName[tempFileName.length - 1];
    const name = imageName || 'BreezeAppImage.png';
    fetch(clickedImage?.url,
      {
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' }
      })
      .then(response => response?.blob())
      .then(blob => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';

        if (name?.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {clickedImage && !generateLoading && (
        <div className='w-full h-full flex items-center justify-center'>
          <PreviewSection
            imgSrc={clickedImage?.url}
            imageData={clickedImage}
          />
        </div>
      )}
      {generateLoading && (
        <div className='flex items-center justify-center spinner-border h-full'>
          <div className='flex h-full flex-col items-center justify-center spinner-border
           bg-white bg-canvasMeshBg'
            style={{ height: `${canvasWidth}px`, width: `${canvasWidth}px` }}>
            <img src={loadingGif} alt='loading...' className='w-[70px]' />
          </div>
        </div>
      )}
      {/* <p className='lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost font-[600] 
      absolute p-2 bottom-0 text-primaryColor'>
        {(selectedTab === 'text' && captionListData && captionListData.length > 0) && 'Generated Outputs'}
        {(selectedTab === 'images') && 'Generated Images'}
      </p> */}
      {!clickedImage && !generateLoading && (
        <div className='flex flex-col items-center justify-center spinner-border h-[95%]'>
          {isListingTemplates && selectedListingTempTab === 'primary' ?
            (
              <ImageListingCanvas
                stageRef={canvasStageRef}
                handleImagesPosition={handleImagesPosition}
                canvasTransformerRef={canvasTransformerRef}
                width={canvasWidth}
                height={canvasWidth}
                imgSrc={selectedImage?.remove_background_url}
              />
            ) : (
              <CanvasScreen
                stageRef={canvasStageRef}
                handleImagesPosition={handleImagesPosition}
                canvasTransformerRef={canvasTransformerRef}
                width={canvasWidth}
                height={canvasWidth}
                imgSrc={selectedImage?.remove_background_url}
                isMeshBg={isMeshBg}
              />
            )
          }
        </div>
      )}
      <p className='lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost font-[600]  p-2 bottom-0 text-primaryColor'>
        {(selectedTab === 'text' && captionListData && captionListData?.output?.length > 0) && 'Generated Outputs'}
        {(selectedTab === 'images' || isListingTemplates) && 'Generated Images'}
      </p>
    </>
  );
};

export default PreviewScreen;