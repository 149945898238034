import React, { useEffect, useState } from 'react';
import { AppButton } from 'components/shared/AppButton';
import closeIcon from 'assets/icons/closeIcon.svg';
import { BASE_URL } from 'utils/baseURL';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import { ClipLoader } from 'react-spinners';
import blueTick from 'assets/img/Bluetick.svg';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

const AddToModal = ({
  handleCloseBtn,
  projectIdsList,
  setProjectIDsList,
  isFilesUploading,
  currProjectID,
  multiImageSelectedList,
  imageClickData,
  getProjectImageList,
  reCallImageList
}) => {
  const { projectID } = useParams();
  const [projectList, setProjectList] = useState([]);
  const [commonProjectIds, setCommonProjectIds] = useState([]);

  const handAddToBtn = async () => {
    try {
      const data = new FormData();
      data.append('projectIDs', projectIdsList);
      const imgPid = imageClickData?.pid;
      if (imageClickData?.selected) {
        const pidArray = [];
        // Iterate through the data array
        multiImageSelectedList?.forEach(item => {
          pidArray.push(item?.pid);
        });
        if (pidArray?.length > 0) {
          data.append('pids', pidArray);
        }
      } else data.append('pids', [imgPid]);
      const url = BASE_URL + 'image-gen/addToProject';
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            token: LocalStorage.getItem()
          }
        });
      if (response && response.data) {
        if (projectID) {
          getProjectImageList();
        } else {
          reCallImageList();
        }
      }
    } catch (error) {
      console.log(error);
    }
    // setProjectIDsList([]);
    handleClose();
  };

  const getExistingProjectLists = async () => {
    const url = BASE_URL + 'image-gen/projects';
    try {
      const projectListData = await axios.get(
        url,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      setProjectList(projectListData?.data?.data?.projects);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getExistingProjectLists();
    setCommonProjectIds([]);
  }, []);

  const handleClose = () => {
    setProjectIDsList([]);
    handleCloseBtn();
  };

  // Function to return common projectID array for disabling purpose
  const getCommonProjectIds = (images) => {
    let commonIDs = new Set(images[0]?.projectID);

    for (let i = 1; i < images.length; i++) {
      commonIDs = new Set([...commonIDs]?.filter(x => images[i]?.projectID?.includes(x)));
    }

    return Array.from(commonIDs);
  };

  useEffect(() => {
    const commonProjectData = getCommonProjectIds(multiImageSelectedList);
    setCommonProjectIds(commonProjectData);
  }, [multiImageSelectedList]);

  const handleImgLocation = (projectID) => {
    // Check if the projectID is already in the array
    if (projectIdsList.includes(projectID)) {
      // If yes, remove it
      setProjectIDsList((prevIDs) => prevIDs?.filter((id) => id !== projectID));
    } else {
      // If not, add it to the array
      setProjectIDsList((prevIDs) => [...prevIDs, projectID]);
    }
  };

  const handleImagesPresenceInProjects = (projectData) => {
    let isProjectVisible = false;
    if (multiImageSelectedList?.length > 0 && imageClickData?.selected) {
      // hiding the common projects among the selectedImages
      isProjectVisible = commonProjectIds?.includes(projectData?.projectID);
    } else {
      isProjectVisible = currProjectID?.includes(projectData?.projectID);
    }
    return isProjectVisible;
  };

  return (
    <div
      className='relative z-50'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
      onClick={(e) => e?.stopPropagation()}
    >
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>
      <div className='fixed inset-0 z-50 overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 w-[514px] h-[514px] p-[40px]'>
            <div className='bg-white flex flex-col gap-[20px]'>
              <div className='w-full'>
                <div className='text-left'>
                  <div className='grid grid-cols-12'>
                    <h3
                      className='font-jost font-[600] text-[24px] whitespace-nowrap col-span-11'
                      id='modal-title'
                    >
                      Add to...
                    </h3>
                    <span
                      className='text-right flex items-center justify-center w-[35px] h-[35px] 
                      hover:bg-[#F0F0F0] rounded-[5px] cursor-pointer'
                      role="presentation"
                      onClick={() => handleClose()}
                    >
                      <img src={closeIcon} alt='' width='20px' height='20px' />
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full text-[18px] text-[#7D7D7D]'>
                Selected assets are duplicated to another Project folder
                and will not be removed from its current location
              </div>
              <div className='w-[434px] h-[248px] rounded-[10px] border border-solid border-[#D9D9D9] 
                  overflow-scroll p-[20px] flex flex-col gap-[10px]'>
                {projectList?.length > 0 ? (
                  projectList?.map((projectItem, index) => (
                    <div
                      key={index}
                      className={cn('p-[10px] text-[16px] relative rounded-[5px]',
                        projectIdsList.includes(projectItem?.projectID) ? 'bg-[#E5EBFF]' : 'hover:bg-[#F0F0F0]',
                        handleImagesPresenceInProjects(projectItem)
                          ? 'opacity-50 cursor-not-allowed'
                          : 'cursor-pointer'
                      )}
                      onClick={() => !(currProjectID.includes(projectItem?.projectID))
                        && handleImgLocation(projectItem?.projectID)}
                    >
                      {projectItem?.project_name}
                      {projectIdsList.includes(projectItem?.projectID) && (
                        <img
                          src={blueTick}
                          alt=''
                          className='absolute right-[10px] top-[14px] w-[16px] h-[16px]'
                        />
                      )}
                    </div>
                  ))) : (
                  <div className='flex justify-center'>
                    <ClipLoader color='#757D89' />
                  </div>
                )}
              </div>
              <div className='w-full flex justify-end items-center gap-[10px]'>
                <AppButton
                  onClick={() => handleClose()}
                  type='button'
                  variant='light'
                  extendClass='bg-black hover:bg-black rounded-[5px] border-[0px] text-[20px] h-[48px] px-5 2xl:px-7'
                  disabled={isFilesUploading}
                >
                  Cancel
                </AppButton>
                <AppButton
                  onClick={() => { handAddToBtn(); }}
                  type='button'
                  variant='black'
                  extendClass={` rounded-[5px] text-[20px] h-[48px] px-5 2xl:px-7
                  ${(!(projectIdsList?.length > 0) || isFilesUploading) ? 'bg-[#D9D9D9] hover:bg-[#D9D9D9] !opacity-100'
                      : 'bg-[#0038FF] hover:bg-[#0038FF]'} `}
                  disabled={!(projectIdsList?.length > 0) || isFilesUploading}
                >
                  Add to {projectIdsList?.length > 0 && projectIdsList?.length}
                  {projectIdsList?.length > 1 ? ' projects' : ' project'}
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToModal;