import React, { useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { NotFound, Second } from '../pages';
import { CheckUserExpired, removeAllUserData } from 'utils/functions.util';
import Account from 'pages/Account';
import ImageHistory from 'pages/ImageHistory';
import { CheckUserValid } from 'utils/auth.helper';
import Caption from 'pages/Caption';
import ImageLibrary from 'components/DesktopComponents/ImageLibrary';
import DashboardUserLayout from 'layout/UserLayout/DashboardUserLayout';
import Create from 'components/DesktopComponents/CreateModule';
import DynamicComponent from 'components/DesktopComponents';
import Pricing from 'pages/Pricing';
import ImageResizing from 'components/DesktopComponents/ImageResizing';
import GoogleSearchAd from 'components/DesktopComponents/GoogleSearchAd';
import ListingTemplateDetails from 'components/DesktopComponents/ListingTemplatesDetails';
import Projects from 'components/DesktopComponents/Projects';
import ProjectsRoutes from 'components/DesktopComponents/Projects/projectRoutes';

function DesktopRoutes() {
  const protectedRoutes = useRoutes([
    {
      path: '',
      element: <DashboardUserLayout />,
      children: [
        {
          path: 'images/:pageNumber?',
          element: <ImageHistory />
        },
        {
          path: 'image/:imageId',
          element: <Second />
        },
        {
          path: 'account',
          element: <Account />
        },
        {
          path: 'caption',
          element: <Caption />
        },
        {
          path: 'Templates',
          element: <Create />
        },
        {
          path: 'ImageGenerator',
          element: <DynamicComponent />
        },
        {
          path: 'PhotoStudio',
          element: <DynamicComponent />
        },
        {
          path: 'InstagramPost',
          element: <DynamicComponent />
        },
        {
          path: 'FacebookPost',
          element: <DynamicComponent />
        },
        {
          path: 'LinkedInPost',
          element: <DynamicComponent />
        },
        {
          path: 'GoogleSearchAd',
          element: <GoogleSearchAd />
        },
        {
          path: 'WalmartListingsImages',
          element: <DynamicComponent />
        },
        {
          path: 'AmazonListingsImages',
          element: <DynamicComponent />
        },
        {
          path: 'WalmartListingsDetails',
          element: <ListingTemplateDetails />
        },
        {
          path: 'AmazonListingsDetails',
          element: <ListingTemplateDetails />
        },
        {
          path: 'imageLibrary',
          element: <ImageLibrary />
        },
        // {
        //   path: 'imageLibrary/:pid/:gid?',
        //   element: <ImageLibrarySingleImageDetails />
        // },
        {
          path: 'Projects',
          element: <Projects />
        },
        {
          path: 'Projects/*',
          element: <ProjectsRoutes />
        },
        {
          path: 'resize',
          element: <ImageResizing />
        },
        {
          path: 'plans',
          element: <Pricing />
        },
        {
          path: '*',
          element: <NotFound />
        }
      ]
    }
  ]);

  const location = useLocation();
  useEffect(() => {
    CheckUserExpired('Protected');
  }, [location]);

  // we are removing the search key from localStorage if we move to any other page
  const path = window.location.pathname;
  if (!path.includes('imageLibrary')) {
    localStorage.removeItem('searchKey');
  }

  // we are setting the libraryImgUpload key to false in localStorage if we move to any other page
  const existingData = JSON.parse(localStorage.getItem('uploadImgGenLibrary'));

  if (!path.includes('imageLibrary')) {
    if (existingData) {
      existingData.libraryImgUpload = false;
      localStorage.setItem('uploadImgGenLibrary', JSON.stringify(existingData));
    }
  }

  const isAuth = CheckUserValid();
  // eslint-disable-next-line eqeqeq
  if (isAuth && path !== '/') {
    return protectedRoutes;
  } else if (isAuth && path === '/') {
    if (window?.innerWidth < 992) {
      return <Navigate to={'/mobile/Templates'}></Navigate>;
    } else {
      return <Navigate to={'/imageLibrary'}></Navigate>;
    }
  } else {
    removeAllUserData();
    return <Navigate to={'/login'}></Navigate>;
  }
}

export default DesktopRoutes;
