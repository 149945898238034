// import { IGenerateFashionAttributes } from '../Types/GenerateFashionAttributes'
import {  useMutation, useQuery, useQueryClient } from 'react-query';
import API from './base';
// import { toast } from 'react-toastify';

export const getImageHistory = async (pageSize, startIdx) => {
  let requestUrl = `image-gen/uploadHistory?startIdx=${startIdx}&num_records=${pageSize}`;
  if (pageSize === undefined && startIdx === undefined) {
    requestUrl = 'image-gen/uploadHistory';
  }
  const { data } = await API.get(requestUrl);
  return data;
};

export const getBulkImageHistory = async (pageSize, startIdx) => {
  let requestUrl = `image-gen/bulk-history?startIdx=${startIdx}&num_records=${pageSize}`;
  if (pageSize === undefined && startIdx === undefined) {
    requestUrl = 'image-gen/bulk-history';
  }
  const { data } = await API.get(requestUrl);
  return data;
};

export const deleteImage = async ({ imageURL, PID }) => {
  const formData = new FormData();
  formData.append('pid', PID);
  formData.append('image_url', imageURL);
  const { data } = await API.delete('image-gen/delete-image', { data: formData });
  return data;
};

const useImagesHistory = (pageSize, startIdx) => {

  const queryClient = useQueryClient();
  const { data: imageHistory, isFetching: isImageHistoryFetching , refetch: refetchHistory} = useQuery(
    ['image-history' ],
    () => getImageHistory(pageSize, startIdx),
    { staleTime: 60 * 60 * 60 }
  );

  const deleteImageMutation = useMutation(deleteImage, {
    onSuccess: () => {
      queryClient.invalidateQueries(['image-history']);
      console.log('Image deleted');
    },
    onError: () => {
      console.log('Error deleting image');
    }
  });

  return { imageHistory, isImageHistoryFetching, deleteImageMutation, refetchHistory };
};

export default useImagesHistory;
