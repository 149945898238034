import React from 'react';
import cn from 'classnames';
import { ClipLoader } from 'react-spinners';

const GeneratedCaptionsSection = ({
  setSelectedCaption, selectedCaption, captionListData, generateCaptionLoading
}) => {
  return (
    <>
      {generateCaptionLoading ? (
        <div className='flex justify-start items-center w-full h-full'>
          <div className='spinner-border flex justify-center w-full' role='status'>
            <ClipLoader color='#757D89' />
          </div>
        </div>
      ) : (
        <div className='grid grid-cols-2 gap-4 p-4'>
          {captionListData?.output?.map((item, index) => (
            <div key={index}
              role='presentation'
              onClick={() => setSelectedCaption(item?.caption)}
              className={cn(
                'p-2 col-span-1 rounded-md cursor-pointer lg:text-[12px] xl:text-[14px] 2xl:text-[16px] font-[400]',
                selectedCaption === (item?.caption) && 'bg-[#F0F0F0] border-[1px] border-[#7D7D7D]')}
            >
              {item?.caption}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default GeneratedCaptionsSection;