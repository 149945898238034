import React, { useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { NotFound } from 'pages';
import { CheckUserExpired, removeAllUserData } from 'utils/functions.util';
import { CheckUserValid } from 'utils/auth.helper';
import DynamicComponent from 'components/DesktopComponents';
import GoogleSearchAd from 'components/DesktopComponents/GoogleSearchAd';
import ListingTemplateDetails from 'components/DesktopComponents/ListingTemplatesDetails';
// import ImageLibrarySingleImageDetails from 'components/DesktopComponents/ImageLibrarySingleImageDetails';
import ProjectDocuments from './ProjectDocuments';

function ProjectRoutes() {
  const protectedRoutes = useRoutes([
    {
      path: '*',
      children: [
        {
          path: ':projectID/Document/:documentID/ImageGenerator',
          element: <DynamicComponent />
        },
        {
          path: ':projectID/Document/:documentID/PhotoStudio',
          element: <DynamicComponent />
        },
        {
          path: ':projectID/Document/:documentID/InstagramPost',
          element: <DynamicComponent />
        },
        {
          path: ':projectID/Document/:documentID/FacebookPost',
          element: <DynamicComponent />
        },
        {
          path: ':projectID/Document/:documentID/LinkedInPost',
          element: <DynamicComponent />
        },
        {
          path: ':projectID/Document/:documentID/GoogleSearchAd',
          element: <GoogleSearchAd />
        },
        {
          path: ':projectID/Document/:documentID/WalmartListingsImages',
          element: <DynamicComponent />
        },
        {
          path: ':projectID/Document/:documentID/AmazonListingsImages',
          element: <DynamicComponent />
        },
        {
          path: ':projectID/Document/:documentID/WalmartListingsDetails',
          element: <ListingTemplateDetails />
        },
        {
          path: ':projectID/Document/:documentID/AmazonListingsDetails',
          element: <ListingTemplateDetails />
        },
        {
          path: ':projectID',
          element: <ProjectDocuments />
        },
        // {
        //   path: ':projectID/:pid/:gid?',
        //   element: <ImageLibrarySingleImageDetails />
        // },
        {
          path: '*',
          element: <NotFound />
        }
      ]
    }
  ]);

  const location = useLocation();
  useEffect(() => {
    CheckUserExpired('Protected');
  }, [location]);

  // we are removing the search key from localStorage if we move to any other page
  const path = window.location.pathname;

  const isAuth = CheckUserValid();
  // eslint-disable-next-line eqeqeq
  if (isAuth && path !== '/') {
    return protectedRoutes;
  } else if (isAuth && path === '/') {
    return <Navigate to={'/Projects'}></Navigate>;
  } else {
    removeAllUserData();
    return <Navigate to={'/login'}></Navigate>;
  }
}

export default ProjectRoutes;
