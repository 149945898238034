import React, { useEffect, useRef, useState } from 'react';

const ColorPicker = ({
  selectedTheme,
  setSelectedTheme,
  generateLoading,
  selectedColor,
  setSelectedColor,
  setPrompt,
  selectedImage
}) => {
  const [inputColor, setInputColor] = useState(selectedColor);
  const [bgColor, setBgColor] = useState('#000');
  const colorPickerRef = useRef(null);

  const handleOnClickColorPicker = () => {
    colorPickerRef.current.click();
  };

  const handleOnColorChange = (event) => {
    if (event.target.value) {
      setSelectedColor(event.target.value);
    }
  };

  const handleColorInputChange = (event) => {
    if (event.target.value) {
      const color = event.target.value;
      const validHexColor = /^#[0-9A-Za-z]{6}$/.test(color);
      setInputColor(color);
      if (validHexColor) {
        setSelectedColor(color);
      }
    }
  };

  // change the text to black or white depending on the color selected
  const pickTextColorBasedOnBgColor = (color) => {
    const bgColor = (color?.charAt(0) === '#') ? color?.substring(1, 7) : color;
    const r = parseInt(bgColor?.substring(0, 2), 16); // hexToR
    const g = parseInt(bgColor?.substring(2, 4), 16); // hexToG
    const b = parseInt(bgColor?.substring(4, 6), 16); // hexToB
    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors?.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    setBgColor((L > 0.179) ? '#000' : '#FFF');
  };

  useEffect(() => {
    pickTextColorBasedOnBgColor(selectedColor);
    setInputColor(selectedColor);
    localStorage.setItem('selectedColor', JSON.stringify(selectedColor));
  }, [selectedColor]);

  return (
    <div className={`col-span-4 aspect-square group relative w-full h-full 
      ${!selectedImage && 'pointer-events-none opacity-[0.5]'}`}>
      <div
        className='relative flex items-center justify-center shadow text-center 
          max-h-full max-w-full w-full h-full rounded-md border-[1px] border-solid
          border-[#000] hover:border-[2px] hover:border-solid
          hover:border-[#000]'
        style={{
          border: selectedTheme === 'color' ? '2px solid #000' : '',
          padding: '2px',
          backgroundColor: selectedColor
        }}
        onClick={() => {setSelectedTheme('color'); setPrompt({ theme: 'color', prompt: '' });}}
      >
        <div className='flex flex-col h-full w-full justify-between relative'>
          <div className='flex p-2 px-0 absolute top-0 w-full justify-evenly gap-1'>
            <div className={`w-[28px] h-[28px] rounded-full border-[1px] border-[${bgColor}] hover:border-[2px]`}
              onClick={handleOnClickColorPicker}>
              <input
                type='color'
                ref={colorPickerRef}
                name='colorPicker'
                id='colorPicker'
                className='opacity-0'
                onChange={(e) => handleOnColorChange(e)}
                value={selectedColor}
              />
            </div>
            <div className={`rounded-[5px] border-[1px] border-[${bgColor}] h-[31px] w-[91px] px-3 py-1`}>
              <input type='text' value={inputColor} className={`text-[${bgColor}] bg-transparent w-full p-0
                focus:shadow-none focus:border-none border-none`}
                onChange={(e) => handleColorInputChange(e)} />
            </div>
          </div>
          <div
            className={`absolute bottom-0 ${!generateLoading && 'cursor-pointer'}  leading-normal 
              rounded-md w-full font-jost lg:text-[14px] xl:text-[16px] xl-mid:text-[18px] font-[600]
              flex p-1 text-center text-[${bgColor}] justify-center`}
          >
            Colour
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;