import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

const BreadCrumb = () => {
  const [breadcrumbLayers, setBreadcrumbLayers] = useState([]);
  const currentLocation = useLocation();
  const parameters = currentLocation?.pathname?.split('/');

  const getBreadcrumbLayers = () => {
    let layers = [{
      name: 'Projects',
      link: '/Projects'
    }];
    if (parameters.length > 4 && currentLocation?.pathname?.includes('Projects')) {
      layers.push(
        { name: localStorage.getItem('selectedProject'), link: `/Projects/${parameters[2]}` },
        { name: localStorage.getItem('selectedDocument'), active: true }
      );
    } else if (parameters.length > 2 && currentLocation?.pathname?.includes('Projects')) {
      layers.push({
        name: localStorage.getItem('selectedProject'),
        link: `/Projects/${parameters[2]}`,
        active: true
      });
    } else if (currentLocation?.pathname?.includes('Projects')) {
      localStorage.removeItem('selectedDocument');
      localStorage.removeItem('selectedProject');
      layers = [{
        name: 'Projects',
        link: '/Projects',
        active: true
      }];
    } else {
      layers = [];
    }
    setBreadcrumbLayers(layers);
  };

  useEffect(() => {
    getBreadcrumbLayers();
  }, [currentLocation]);

  return (
    <div className='breadcrumbs font-jost text-[20px]'>
      {breadcrumbLayers?.map((layer, index) => {
        return (
          <div key={index} className='crumb'>
            <Link to={layer?.link} className={cn(layer?.active ? 'font-[600]' : 'font-[400]', 'text-[#000]')}>
              {layer?.name}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default BreadCrumb;