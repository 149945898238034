import React, { useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { NotFound } from '../pages';
import { CheckUserExpired, removeAllUserData } from 'utils/functions.util';
import Account from 'pages/Account';
import { CheckUserValid } from 'utils/auth.helper';
import Pricing from 'pages/Pricing';
import ImageGenerator from '../components/MobileComponents/ImageGenerator';
import Dashboard from '../components/MobileComponents/Dashboard';
import MobileUserLayout from '../layout/UserLayout/MobileUserLayout';
import PhotoStudio from 'components/MobileComponents/PhotoStudio';
import OutputList from 'components/MobileComponents/OutputGeneratedImg';
import ImageViewScreen from 'components/MobileComponents/ImageViewScreen';
import SocialMediaPostImage from 'components/MobileComponents/SocialMediaPost/ImageGeneration';
import SocialMediaPostText from 'components/MobileComponents/SocialMediaPost/TextGeneration';
import SocialMeidaPostTextOutput from 'components/MobileComponents/SocialMediaPost/TextOutput';
import SocialMediaPostPreview from 'components/MobileComponents/SocialMediaPost/PostPreview';
import Resize from 'components/MobileComponents/Resize';
import ImageLibrary from 'components/MobileComponents/MobileImageLibrary';

function MobileRoutes() {
  const protectedRoutes = useRoutes([
    {
      path: '',
      element: <MobileUserLayout />,
      children: [
        {
          path: 'account',
          element: <Account />
        },
        {
          path: 'Templates',
          element: <Dashboard />
        },
        {
          path: 'plans',
          element: <Pricing />
        },
        {
          path: 'ImageGenerator',
          element: <ImageGenerator />
        },
        {
          path: 'PhotoStudio',
          element: <PhotoStudio />
        },
        {
          path: 'ImageGenerator/Output',
          element: <OutputList />
        },
        {
          path: 'PhotoStudio/Output',
          element: <OutputList />
        },
        {
          path: 'ImageGenerator/imageView',
          element: <ImageViewScreen />
        },
        {
          path: 'PhotoStudio/imageView',
          element: <ImageViewScreen />
        },
        {
          path: 'InstagramPost/Image',
          element: <SocialMediaPostImage />
        },
        {
          path: 'InstagramPost/Image/Output',
          element: <OutputList />
        },
        {
          path: 'InstagramPost/Image/imageView',
          element: <ImageViewScreen />
        },
        {
          path: 'InstagramPost/Text',
          element: <SocialMediaPostText />
        },
        {
          path: 'InstagramPost/Text/Output',
          element: <SocialMeidaPostTextOutput />
        },
        {
          path: 'InstagramPost/Preview',
          element: <SocialMediaPostPreview />
        },
        {
          path: 'FacebookPost/Image',
          element: <SocialMediaPostImage />
        },
        {
          path: 'FacebookPost/Image/Output',
          element: <OutputList />
        },
        {
          path: 'FacebookPost/Image/imageView',
          element: <ImageViewScreen />
        },
        {
          path: 'FacebookPost/Text',
          element: <SocialMediaPostText />
        },
        {
          path: 'FacebookPost/Text/Output',
          element: <SocialMeidaPostTextOutput />
        },
        {
          path: 'FacebookPost/Preview',
          element: <SocialMediaPostPreview />
        },
        {
          path: 'ImageGenerator/Resize',
          element: <Resize />
        },
        {
          path: 'PhotoStudio/Resize',
          element: <Resize />
        },
        {
          path: 'InstagramPost/Image/Resize',
          element: <Resize />
        },
        {
          path: 'FacebookPost/Image/Resize',
          element: <Resize />
        },
        {
          path: 'ImageLibrary',
          element: <ImageLibrary />
        },
        {
          path: 'ImageLibrary/imageView',
          element: <ImageViewScreen />
        },
        {
          path: '*',
          element: <NotFound />
        }
      ]
    }
  ]);

  const location = useLocation();
  useEffect(() => {
    CheckUserExpired('Protected');
  }, [location]);

  const path = window.location.pathname;
  const isAuth = CheckUserValid();
  // eslint-disable-next-line eqeqeq
  if (isAuth && path !== '/') {
    return protectedRoutes;
  } else if (isAuth && path === '/') {
    if (window?.innerWidth < 992) {
      return <Navigate to={'/mobile/Templates'}></Navigate>;
    } else {
      return <Navigate to={'/imageLibrary'}></Navigate>;
    }
  } else {
    removeAllUserData();
    return <Navigate to={'/login'}></Navigate>;
  }
}

export default MobileRoutes;
