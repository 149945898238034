import React, { Fragment, useEffect, useRef, useState } from 'react';
// import { Button } from 'flowbite-react';
// import { HiArrowDown } from 'react-icons/hi';
// import { BsTrash3 } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
// import ProductDescription from './ProductDescription';
// import classNames from 'classnames';
// import { useImageSize } from 'react-image-size';
// import { MainContext } from 'Contexts/MainProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faCircleInfo, faDownload, faHeart as faSolidHeart, faTrashCan } from '@fortawesome/free-solid-svg-icons';

const Product = props => {
  // const { state, setState } = useContext(MainContext);
  const [isFavorite, setIsFavorite] = useState(false);

  const navigate = useNavigate();
  const {
    inputImageUrl,
    // removeBackgroundUrl,
    deleteImageMutation,
    id,
    // productDescription,
    // hasDescription = false,
    hasDeleteButton = false,
    // hasGenerateButton,
    hasDownloadButton,
    // productPosition,
    createdDate,
    downloadImageUrl,
    addFavorite,
    favoriteImages
  } = props;

  const handleDeleteImage = () => {
    deleteImageMutation.mutate({ imageURL: inputImageUrl, PID: id });

    navigate('/panel/images');
  };

  // const handleGenerate = () => {
  //   setState((prev) => ({ ...prev, pid: id, imageURL: removeBackgroundUrl }));
  //   navigate('/Templates');
  // };

  // const descriptionClasses = classNames('absolute z-50 hidden hover:block w-[27rem] ', {
  //   'right-[29rem]': productPosition === 'right',
  //   'left-[29rem]': productPosition === 'left',
  //   'top-[21rem] ': productPosition === 'middle'
  // });

  const getDateString = (date) => {
    const splitted = date.split('-');
    const day = splitted[0];
    const dummyDate = new Date();
    dummyDate.setMonth(parseInt(splitted[1]) - 1);

    const month = dummyDate.toLocaleString('en-US', { month: 'long' });
    const year = splitted[2];

    return `${day} ${month}, ${year}`;
  };

  const productRef = useRef(null);
  const descRef = useRef(null);

  useEffect(() => {
    let flag = 0;
    favoriteImages?.forEach(eachFav => {
      if ((downloadImageUrl && eachFav.image_url === downloadImageUrl) || eachFav.image_url === inputImageUrl) {
        setIsFavorite(true);
        flag = 1;
      }
    });
    if (!flag)
      setIsFavorite(false);
  }, [favoriteImages]);

  return (
    <Fragment>
      <div
        className={`relative w-full h-[calc(100%-30px)] p-3 pt-6 bg-white ${
          id ? 'cursor-pointer' : ''
        } product border-2 border-gray-50`}
        ref={productRef}
        onMouseLeave={() => {
          if (descRef.current) descRef.current.style.display = 'none';
        }}
      >
        <img 
          src={inputImageUrl}
          className='object-contain w-full h-[calc(100%-40px)]'
          alt=''
          // onClick={() => {
          //   if (id) navigate(`/panel/image/${id}`)
          // }}
          onMouseEnter={() => {
            if (descRef.current) descRef.current.style.display = 'block';
          }}
        />
        {/* <img
          className='absolute object-contain w-full h-[calc(100%-4rem)] top-3'
          src={inputImageUrl}
          alt=''
          onClick={() => {
            if (id) navigate(`/panel/image/${id}`)
          }}
          onMouseEnter={() => {
            if (descRef.current) descRef.current.style.display = 'block'
          }}
        /> */}
        {/* {hasDescription ? (
          <div className={descriptionClasses} ref={descRef}>
            <ProductDescription productDescription={productDescription} />
          </div>
        ) : null} */}
        {/* <div className='z-30 product__icons'>
          {hasDownloadButton && (
            <Button color='secondaryColor h-fit'>
              <a href={inputImageUrl} download className='w-8 h-8 p-0'>
                <HiArrowDown className='w-8 h-8 p-0' />
              </a>
            </Button>
          )}
          {hasDeleteButton && (
            <Button color='secondaryColor'>
              <BsTrash3 className='w-8 h-8 p-0' onClick={handleDeleteImage} />
            </Button>
          )}
        </div>
        {hasGenerateButton && (
            <Button color='secondaryColor' className='z-30 product__btn' onClick={handleGenerate}>
              Generate
            </Button>
        )} */}
        <div className='relative flex justify-between w-full'>
          {hasDownloadButton &&
            <div className='flex items-center rounded-full gap-2 bg-[#f0f0ff] text-[#5555e8]'>
              <a href={downloadImageUrl || inputImageUrl} download className='flex items-center gap-2 p-2.5 px-6'>
                <FontAwesomeIcon icon={faDownload} />
                Download
              </a>
            </div>
          }
          <div className='flex items-center gap-2'>
            <div className='cursor-pointer bg-[#f5f8fe] rounded-full p-2.5 px-3.5' 
            onClick={() => addFavorite(id, downloadImageUrl || inputImageUrl, isFavorite)}>
              {isFavorite ?
                <FontAwesomeIcon fontSize={18} icon={faSolidHeart}  color='#de473c' />
                :
                <FontAwesomeIcon fontSize={18} icon={faHeart} />
              }
            </div>
            <div className='cursor-pointer relative bg-[#f5f8fe] rounded-full p-2.5 px-3.5 image-history-info'>
              <FontAwesomeIcon fontSize={18} icon={faCircleInfo} />

              <div className='created-at hidden absolute bottom-[54px] text-center -right-3 w-[200px] 
              p-1 rounded-md bg-white shadow'>
                Created at : <span className='text-[#5555E8]'>{getDateString(createdDate.split(' ')[0])}</span>
              </div>
            </div>
            {hasDeleteButton &&
              <div className='cursor-pointer bg-[#f5f8fe] rounded-full p-2.5 px-3.5' onClick={handleDeleteImage}>
                <FontAwesomeIcon fontSize={18} icon={faTrashCan} color='red' />
              </div>
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Product;
