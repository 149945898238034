import React, { useState, Fragment, useEffect } from 'react';
// import { BsGoogle, BsFacebook, BsApple, BsWindows } from 'react-icons/bs';
import { AppButton } from 'components/shared/AppButton';
import { TextInput } from 'flowbite-react';
// import { LocalStorage } from 'services/localStorage';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { emailLoginRequest, registerRequest } from 'queryhook/auth/auth';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithGooglePopuop } from 'utils/Firebase';
import * as yup from 'yup';
import googleImg from '../assets/img/google.png';
import logo from '../assets/ColorLogo-noBackground.png';
import AlertModal from './AlertModal';
import Popup from './PopUp';
import { ClipLoader } from 'react-spinners';

const RegisterSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().min(6).max(50).required('Password is required')
});

const RegisterForm = () => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [screenAlert, setScreenAlert] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {

    if (window?.innerWidth < 992) {
      setScreenAlert(false);
    }
  }, []);

  const registerWithFirebaseMutation = useMutation(
    (email) => emailLoginRequest(email),
    {
      onSuccess: (data) => {
        const emailId = localStorage?.getItem('user-email');
        localStorage.removeItem('selectedColor');
        if (data) {
          window.analytics.identify(data?.userID, { email: emailId });
          if (data?.event === 'Sign up') {
            //console.log("[DEBUG] signup",data)
            window.analytics.track('Sign Up', {
              email: emailId
            });
            // toast.success('User successfully registered');

          } else if (data?.event === 'Sign in') {
            //console.log("[DEBUG] signin",data)
            window.analytics.track('Sign In', {
              email: emailId,
              userId: data?.userID
            });
          }
          if (window?.innerWidth < 992) {
            navigate('/mobile/Templates');
          } else {
            navigate('/Templates');
          }
        } else {
          toast.error(data.detail.message);
        }
        setLoading(false);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      }
    }
  );

  const onSignUpWithFirebase = async (event) => {
    event.preventDefault();
    try {
      const { user } = await signInWithGooglePopuop();
      setLoading(true);
      registerWithFirebaseMutation.mutate(user.email);
      // LocalStorage.setItem(user.accessToken);
      navigate('/');
      // await createUserDocumentFromAuth(user, { displayName });

    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        toast('this email is already registered before');
      } else {
        console.error('error has happend', error);
      }
    }
  };

  const registerMutation = useMutation((loginInfo) => registerRequest(loginInfo), {
    onSuccess: (data) => {
      window.analytics.identify(data.data.userID, { email: formData.email });
      window.analytics.track('Sign Up', {
        email: formData.email
      });
      setAlertModalOpen(true);
    },
    onError: (error) => {
      toast.error(error.response.data.detail.message);
    }
  });

  const submitRegister = async (event) => {
    event.preventDefault();
    // setLoading(true);
    try {
      await RegisterSchema.validate(formData);
    } catch (error) {
      toast.error('Invalid email or password');
      // setLoading(false);
      return;
    }
    registerMutation.mutate(formData);
    // setLoading(false);
  };

  return (
    <Fragment>
      {screenAlert && (
        <Popup
          msgContent='breeze.ai requires a desktop to fully experience its immersive features. 
          Please sign in from a desktop'
          setPopup={setScreenAlert}
        />
      )}
      {loading && (
        <div className='fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity w-screen h-screen z-50
          flex justify-center items-center'>
          <div className='flex justify-center items-center'>
            <div className='spinner-border' role='status'>
              <ClipLoader color='#757D89' />
            </div>
          </div>
        </div>
      )}
      <AlertModal modalOpen={alertModalOpen} setModalOpen={setAlertModalOpen}
        header={'Email verification required!'}
        body='Please verify your email to continue exploring Breeze.Ai. 
        We have sent an email verification link to your registered email address.'
        additionalFx={() => { navigate('/login'); }}
      />
      <div className='flex font-[Inter,sans-serif] flex-col justify-center sm:px-8 md:px-16 py-8 text-center
       bg-white backdrop-filter backdrop-blur-none'>
        <img src={logo} alt="logo" className='w-[160px] mx-auto' />
        <h2 className='text-[29px] mt-[26px] mb-[30px] text-black'>Signup to get
          <span className='bg-gradient-to-r from-[#8d48e7] via-[#b339e4] to-[#d22ce1] bg-clip-text text-transparent'>
            {' '} 20 free</span> photos</h2>
        <div className='flex justify-center items-center gap-2 py-1.5 w-full mx-auto bg-[#E8EDF8]
         hover:bg-[#f1f4fa] rounded-lg cursor-pointer' onClick={onSignUpWithFirebase}>
          <img src={googleImg} alt="google" className='w-[28px]' />
          <div className='text-[18px] font-semibold'>Continue with Google</div>
        </div>
        <div className='flex justify-between items-center mt-[10px] mb-[10px]'>
          <div className='border-t-[1px] border-[#5E6284] h-1 w-[calc(50%-50px)]'></div>
          <div className='text-xl'>OR</div>
          <div className='border-t-[1px] border-[#5E6284] h-1 w-[calc(50%-50px)]'></div>
        </div>
        <form className='flex flex-col gap-4' onSubmit={submitRegister}>
          {/* <div className=' text-left max-w-[450px] w-[100%] mx-auto'>
            <label className='font-semibold text-md'>Name</label>
            <TextInput
              id='name'
              type='text'
              placeholder='Enter your name'
              className='mt-[5px] reg-inputs w-full'
              required={true}
              onChange={e => setFormData({ ...formData, email: e.target.value })}
            />
          </div> */}
          <div className=' text-left max-w-[450px] w-[100%] mx-auto'>
            <label className='font-semibold text-md'>Email Address</label>
            <TextInput
              id='email'
              type='email'
              placeholder='Email your email address'
              className='mt-[5px] reg-inputs w-[100%]'
              required={true}
              onChange={e => setFormData({ ...formData, email: e.target.value })}
            />
          </div>
          <div className=' text-left max-w-[450px] w-[100%] mx-auto'>
            <label className='font-semibold text-md'>Password</label>
            <TextInput
              id='password'
              type='password'
              placeholder='Enter your password'
              className='mt-[5px] reg-inputs w-[100%]'
              required={true}
              onChange={e => setFormData({ ...formData, password: e.target.value })}
            />
          </div>
          <AppButton
            type='submit'
            className='flex px-10 py-2 mx-auto my-4 text-lg text-white bg-black rounded-md w-full max-w-[450px] 
            flex justify-center'
            isFull
            id='SignupPage_CTA'
          >
            Sign Up
          </AppButton>
        </form>
        <div id='signup__links' className='mt-2 font-semibold '>
          <div>
            Already have an account? &nbsp;
            <Link
              to='/login'
              className='font-semibold underline'
              href=''
            >
              Log in
            </Link>
          </div>
          <div className='text-sm mt-1'>
            By signing up, you agree to our terms of service and privacy policy
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterForm;
