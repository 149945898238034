
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithRedirect,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
// import { LocalStorage } from 'services/localStorage';
import { getFirestore, doc, getDoc, setDoc, collection, writeBatch, query, getDocs } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATA_BASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// console.log('firebaseConfig', firebaseConfig);
// Initialize Firebase
// if (!firebaseApp.apps.length) {
//   initializeApp({});
// }else {
//   app(); // if already initialized, use that one
// }
const firebaseApp = initializeApp(firebaseConfig);

// google sign in

const googleProvider = new GoogleAuthProvider();

// force the user to select their account in the provider
googleProvider.setCustomParameters({
  prompt: 'select_account'
});

export const auth = getAuth();

// commented below funtion since we are using custom token
// export const refeshToken =  () => {
//   const user = getAuth().currentUser;
//   if (user) {
//     localStorage.setItem('isRereshed', true);
//     user.getIdToken(true).then(function(newToken) {
//       localStorage.setItem('isRereshed', true);
//       LocalStorage.setItem(newToken);
//       return true;
//     }).catch(function() {
//       return false;
//     });
//   } else {
//     localStorage.setItem('isRereshed', false);
//   }
//   return false;
// };

export const signInWithGooglePopuop = () => signInWithPopup(auth, googleProvider);

export const signInWithGoogleRedirect = () => signInWithRedirect(auth, googleProvider);

// get database instance
export const db = getFirestore();

export const createUserDocumentFromAuth = async (userObj, additionalInformation) => {
  // returns an object: doc instance gets three arguments: database, collectionName, identifier
  const userDocRef = doc(db, 'Users', userObj.uid);

  /// returns data object: special of the document for us
  const userSnapShot = await getDoc(userDocRef);

  // we can also check the to check document is exists or not
  // userSnapShot.exists()

  // we should at first check the user data is exists
  if (!userSnapShot.exists()) {
    const { displayName, email } = userObj;
    const createdAt = new Date();
    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        ...additionalInformation
      });
    } catch (error) {
      console.error('error in the create a document', error.message);
    }
  }
  return userDocRef;
};

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);

export const addCollectionAndDocument = async (collectionKey, docObjToAdd) => {
  const collectionRef = collection(db, collectionKey);
  const batch = writeBatch(db);

  docObjToAdd.forEach(obj => {
    const docRef = doc(collectionRef, obj.title.toLowerCase());
    batch.set(docRef, obj);
  });

  try {
    await batch.commit();
  } catch (error) {
    console.error(error);
  }
};

export const getCategoriesAndDocuments = async () => {
  const collectionRef = collection(db, 'categories');

  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);

  const categoryMap = querySnapshot.docs.reduce((acc, docSnap) => {
    const { title, items } = docSnap.data();
    acc[title.toLowerCase()] = items;
    return acc;
  }, {});

  return categoryMap;
};

export default firebaseApp;
