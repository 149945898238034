// import { IGenerateFashionAttributes } from '../Types/GenerateFashionAttributes'
import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from './base';
// import { toast } from 'react-toastify';

export const getSingleImageHistory = async pid => {
  const { data } = await API.get(`image-gen/product-history?pid=${pid}`);
  return data;
};

export const deleteImage = async ({ imageURL, PID }) => {
  const formData = new FormData();
  formData.append('pid', PID);
  formData.append('image_url', imageURL);
  const { data } = await API.delete('image-gen/delete-image', { data: formData });
  return data;
};

const useSingleImageHistory = id => {
  const queryClient = useQueryClient();
  const { data: singleImageHistory, isFetching: isImageHistoryFetching } = useQuery(
    ['image-history', id],
    () => getSingleImageHistory(id),
    { staleTime: 60 * 60 * 60 }
  );

  const deleteSingleImageMutation = useMutation(deleteImage, {
    onSuccess: () => {
      queryClient.invalidateQueries(['image-history'], ['image-history', id]);
      console.log('Image deleted');
    },
    onError: () => {
      console.log('Error deleting image');
    }
  });

  return { singleImageHistory, isImageHistoryFetching, deleteSingleImageMutation };
};

export default useSingleImageHistory;
