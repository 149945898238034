import React from 'react';
import { Outlet } from 'react-router-dom';
import MobNavbar from 'components/MobileComponents/MobNavbar';
import cn from 'classnames';

const mainNav = {
  left: [
    {
      id: 1,
      display: 'خانه',
      path: '/'
    },
    {
      id: 2,
      display: 'محصولات',
      path: '/products'
    },
    {
      id: 3,
      display: 'مدیریت',
      path: '/orders'
    }
  ],
  right: [
    {
      id: 4,
      display: <i className='bx bx-search'></i>,
      path: '/search'
    },
    {
      id: 5,
      display: <i className='bx bx-user'></i>,
      path: '/login'
    },
    {
      id: 6,
      display: <i className='bx bx-shopping-bag'></i>,
      path: '/cart'
    }
  ]
};

const MobileUserLayout = () => {
  const path = window.location.pathname;
  const isSocialMediaHeader = (!path.includes('Resize') && (
    path.includes('InstagramPost') || path.includes('FacebookPost') || path.includes('ImageLibrary')))
  && !path.includes('imageView');

  return (
    <main className='bg-white'>
      {/*<CustomCursor/>*/}
      <MobNavbar links={mainNav} />
      <div className={cn('flex h-[calc(100vh-67px)] w-full',
          isSocialMediaHeader ? 'absolute top-[108px]' : 'absolute top-[67px]')}>
        <main className='mx-auto 3xl:container w-full h-auto bg-white overflow-auto'>
          <Outlet />
        </main>
      </div>
    </main>
  );
};

export default MobileUserLayout;
