import API from '../base';
import { handleTokenExpiryIssue } from 'utils/functions.util';

export const getUserStatistics = async () => {
  const { data } = await API.get('auth/user-info')
    .catch((err) => {
      if (err.response.status === 440) {
        handleTokenExpiryIssue();
      }
    });
  localStorage.setItem('showCreateModule', data?.data?.create_module);
  return data;
};
