/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import ImageCanvas from './ImageCanvasMob';
import axios from 'axios';
import RenderResizeImageOptionsCompMob from './RenderResizeImageOptionsCompMob';
// import { toast } from 'react-toastify';
import { LocalStorage } from 'services/localStorage';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from 'utils/baseURL';

const Resize = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = window.location.pathname;

  const canvasEleHeight = document?.getElementById('canvasElement');
  const canvasWidth = canvasEleHeight ? Math.ceil(canvasEleHeight?.offsetHeight * 0.9) : 500;
  const canvasHeight = canvasEleHeight ? Math.ceil(canvasEleHeight?.offsetHeight * 0.9) : 500;
  const [fetchImageSizeLoader, setFetchImageSizeLoader] = useState(false);
  const [selectedOptSize, setSelectedOptSize] = useState();
  const [imageResizeList, setImageResizeList] = useState();
  const [selectedResizedImage, setSelectedResizedImage] = useState();
  const [resizeImageGenLoading, setResizeImageGenLoading] = useState(false);
  const [resizeImageUpscaleLoading, setResizeImageUpscaleLoading] = useState(false);
  const [generatedImageList, setGeneratedImageList] = useState([]);
  const [changedCanvasWidth, setChangedCanvasWidth] = useState();
  const [changedCanvasHeight, setChangedCanvasHeight] = useState();

  const canvasTransformerRef = useRef(null);
  const canvasStageRef = useRef(null);
  const imageRef = useRef(null);

  const selectedImage = location?.state;

  const getImageResizeList = async () => {
    setFetchImageSizeLoader(true);
    try {
      const imageResizeListData =
        await axios.get(BASE_URL + 'image-gen/resize-options');
      const imageResizeData = imageResizeListData?.data?.data?.options;
      setImageResizeList(imageResizeData);
      setSelectedOptSize(imageResizeData[0]);
    } catch (err) {
      console.log(err);
    }
    setFetchImageSizeLoader(false);
  };

  const handleUpscaleImage = () => {
    // const tempId = uuidv4();
    // setGeneratedImageList(prev => [{ upscaled: true, id: tempId }, ...prev]);
    // setSelectedImageId(tempId);
  };

  const generateImageUpscale = () => {
    if (path.includes('PhotoStudio')) {
      navigate('/mobile/PhotoStudio/Output', { state: { selectedImage, type: 'upscale' } });
    } else if (path.includes('ImageGenerator')) {
      navigate('/mobile/ImageGenerator/Output', { state: { selectedImage, type: 'upscale' } });
    } else if (path.includes('InstagramPost')) {
      navigate('/mobile/InstagramPost/Image/Output', { state: { selectedImage, type: 'upscale' } });
    } else if (path.includes('FacebookPost')) {
      navigate('/mobile/FacebookPost/Image/Output', { state: { selectedImage, type: 'upscale' } });
    }
  };

  const generateResizeImage = async () => {
    const resizeObj = {
      selectedImage,
      type: 'resize',
      changedCanvasHeight,
      changedCanvasWidth,
      selectedOptSize
    };
    if (path.includes('PhotoStudio')) {
      navigate('/mobile/PhotoStudio/Output', { state: resizeObj });
    } else if (path.includes('ImageGenerator')) {
      navigate('/mobile/ImageGenerator/Output', { state: resizeObj });
    } else if (path.includes('InstagramPost')) {
      navigate('/mobile/InstagramPost/Image/Output', { state: resizeObj });
    } else if (path.includes('FacebookPost')) {
      navigate('/mobile/FacebookPost/Image/Output', { state: resizeObj });
    }
  };

  useEffect(() => {
    getImageResizeList();
  }, []);

  useEffect(() => {
    if (selectedOptSize) {
      const widthFactor = selectedOptSize.width / (canvasWidth - 50);
      const heightFactor = selectedOptSize.height / (canvasHeight - 50);
      if (widthFactor > heightFactor) {
        setChangedCanvasHeight(Math.ceil(selectedOptSize.height / widthFactor));
        setChangedCanvasWidth(Math.ceil(selectedOptSize.width / widthFactor));
      } else {
        setChangedCanvasHeight(Math.ceil(selectedOptSize.height / heightFactor));
        setChangedCanvasWidth(Math.ceil(selectedOptSize.width / heightFactor));
      }
    } else {
      setChangedCanvasHeight(canvasHeight - 50);
      setChangedCanvasWidth(canvasWidth - 50);
    }
  }, [selectedOptSize, canvasWidth, canvasHeight]);

  // To create the image with selectedResizedImage
  const selectedResizedImageCanvas = useMemo(() => {
    const img = document.createElement('img');
    img.src = selectedResizedImage?.upscaled_url || selectedResizedImage?.resized_url;

    function onload() {
      const widthFactor = (selectedResizedImage?.width) / (canvasWidth - 50);
      const heightFactor = (selectedResizedImage?.height) / (canvasHeight - 50);
      if (widthFactor > heightFactor) {
        img.height = (1 * selectedResizedImage?.height) / widthFactor;
        img.width = (1 * selectedResizedImage?.width) / widthFactor;
      } else {
        img.height = (1 * selectedResizedImage?.height) / heightFactor;
        img.width = (1 * selectedResizedImage?.width) / heightFactor;
      }
    }
    onload();
    img.onload = onload;
    const tempData = React.createElement(
      'img',
      {
        src: selectedResizedImage?.upscaled_url || selectedResizedImage?.resized_url,
        width: `${img.width}`,
        height: `${img.height}`
      },
      null
    );
    return tempData;
  }, [selectedResizedImage, canvasWidth, canvasHeight]);

  return (
    <div className='flex flex-col items-center justify-center w-full h-full'>
      <div className='px-[20px] py-[20px] h-[50%] w-full flex justify-center items-center' id='canvasElement'>
        {selectedResizedImage && (
          <div className='relative'>
            {selectedResizedImageCanvas}
          </div>
        )}
        {(!selectedResizedImage && !selectedOptSize?.info?.includes('Square')) && (
          <ImageCanvas
            stageRef={canvasStageRef}
            canvasTransformerRef={canvasTransformerRef}
            imageRef={imageRef}
            width={changedCanvasWidth || canvasWidth}
            height={changedCanvasHeight || canvasHeight}
            imgSrc={selectedImage?.url}
            isMeshBg
          />
        )}
        {!selectedResizedImage && selectedOptSize?.info?.includes('Square') && (
          <img
            width={canvasEleHeight?.offsetHeight * 0.6}
            height={canvasEleHeight?.offsetHeight * 0.6}
            src={selectedResizedImage?.upscaled_url || selectedResizedImage?.resized_url || selectedImage?.url}
          />
        )}
      </div>
      <div className='col-span-3 w-full h-[50%] border-r-2 p-[20px] relative'>
        <RenderResizeImageOptionsCompMob
          fetchImageSizeLoader={fetchImageSizeLoader}
          selectedOptSize={selectedOptSize}
          handleUpscaleImage={handleUpscaleImage}
          generateImageUpscale={generateImageUpscale}
          imageResizeList={imageResizeList}
          setSelectedOptSize={setSelectedOptSize}
          setSelectedResizedImage={setSelectedResizedImage}
          generateResizeImage={generateResizeImage}
          resizeImageGenLoading={resizeImageGenLoading}
          resizeImageUpscaleLoading={resizeImageUpscaleLoading}
        />
      </div>
    </div>
  );
};

export default Resize;