/* eslint-disable max-len */
import React from 'react';
import { AppButton } from 'components/shared/AppButton';

const DeleteConfirmPopup = (props) => {
  const {
    handleDeletePopup,
    handleSubmit,
    isLoading
  } = props;
  
  return (
    <div
      className="relative z-50 flex items-center justify-center font-jost"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="relative flex h-full items-end justify-center p-4 text-center">
          <div
            className="sticky bottom-[calc(50%-103px)] transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all"
          >
            <div className="bg-white p-[40px] w-[462px] h-[177px]">
              <div className="pb-[20px]">
                <div className="text-[20px] font-[400] text-center">
                  Are you sure you want to delete?
                </div>
              </div>
              <div className='w-full flex justify-center items-center gap-[20px]'>
                <AppButton
                  onClick={() => handleDeletePopup(false)}
                  type="button"
                  variant='white'
                  extendClass='bg-white hover:bg-white rounded text-[20px] h-[48px] w-[148px]'
                >
                  Cancel
                </AppButton>
                <AppButton
                  onClick={() => handleSubmit()}
                  type="button"
                  variant='black'
                  isLoading={isLoading}
                  extendClass='bg-black hover:bg-black rounded text-[20px] h-[48px] w-[148px]'
                >
                  Confirm
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmPopup;