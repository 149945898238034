/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import closeIcon from 'assets/icons/closeIconThin.svg';
import { ClipLoader } from 'react-spinners';
import cn from 'classnames';
import { getLinkExpireTime } from 'utils/commonFunctions';

const CollectContentPopup = (props) => {
  const [isCopied, setCopied] = useState(false);
  const {
    handleCollectContentPopUp,
    projectSharedKeyData,
    projectShareKeyLoading,
    projectName
  } = props;

  useEffect(() => {
    setTimeout(() => { setCopied(false); }, 5000);
  }, [isCopied]);

  const handleTextCopy = (clipText) => {
    setCopied(true);
    if (clipText) {
      navigator?.clipboard?.writeText(clipText);
    }
  };
  
  const handleSharedUrl = () => {
    const urlData = window?.location?.origin + /d/ + projectSharedKeyData?.secret_key;
    return urlData;
  };

  return (
    <div
      className="relative z-50 flex items-center justify-center font-jost"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="relative flex h-full items-center justify-center p-4 text-center">
          <div
            className="transform overflow-hidden rounded-lg
            bg-white text-left shadow-xl transition-all"
          >
            <div className="bg-white">
              <div className="w-full">
                <div
                  className="w-[514px] h-[261px] px-10 pt-5 pb-10 bg-white rounded-[10px] border border-[#D9D9D9]
                    flex-col justify-start items-end gap-5 inline-flex"
                >
                  <div className="self-stretch h-[55px] flex-col justify-center items-start flex">
                    <div className="self-stretch justify-between items-center inline-flex">
                      <div className="text-[#333333] text-2xl font-medium font-jost">Collect content</div>
                      <div className="w-5 h-5 relative cursor-pointer" onClick={() => handleCollectContentPopUp(false)}>
                        <img src={closeIcon} alt='' width='20px' height='20px' />
                      </div>
                    </div>
                    <div
                      className="text-[#7D7D7D] text-sm font-normal font-jost"
                    >
                      Copy link and share it with anyone to upload content
                    </div>
                  </div>
                  <div className="self-stretch h-[106px] flex-col justify-start items-end gap-2.5 flex">
                    <div className="self-stretch p-2.5 bg-white justify-start items-center inline-flex">
                      <div>
                        <span
                          className="text-[#333333] text-base font-semibold font-jost"
                        >
                          Project:
                        </span>
                        <span
                          className="text-[#333333] text-base font-normal font-jost"
                        >
                          {' ' + projectName}
                        </span>
                      </div>
                    </div>
                    <div
                      className="self-stretch p-2.5 bg-white rounded-[10px] border border-[#F0F0F0]
                        justify-between items-center inline-flex"
                    >
                      <div
                        className={cn('text-[#333333] text-base font-normal font-jost truncate ...',
                        projectShareKeyLoading && 'w-full')}
                      >
                        {projectShareKeyLoading ? (
                          <div className='flex justify-center items-center w-full'>
                            <ClipLoader
                              color='#757D89'
                              size={30}
                              className='flex items-center'
                            />
                          </div> 
                        ) : handleSharedUrl()}
                      </div>
                      <div
                        className={cn('px-5 py-[5px] bg-[#0038FF] rounded-[5px]',
                          'justify-center items-center gap-2.5 flex relative',
                          projectShareKeyLoading && 'opacity-50 cursor-not-allowed')}
                      >
                        <div
                          className="text-center text-white text-base font-normal font-jost cursor-pointer"
                          onClick={() => !projectShareKeyLoading && handleTextCopy(handleSharedUrl())}
                        >
                          Copy
                        </div>
                        {isCopied && (
                          <div className="absolute top-[-45px] right-[-45px] w-28 h-8 px-5 py-1.5
                          bg-[#333333] bg-opacity-75 rounded-[5px] z-100
                          justify-center items-center gap-2.5 inline-flex">
                            <div className="text-white text-sm font-medium font-['Jost']">
                              Link copied
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {projectSharedKeyData && (
                    <div className="text-[#000] text-[14px] font-normal font-jost">
                      Link will expire in   
                      <span className='text-[#0038FF] text-[14px] font-[500]'> {getLinkExpireTime(projectSharedKeyData?.expiration_time)}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  );
};

export default CollectContentPopup;