import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import FooterSection from '../FooterLayout';
import SocialMediaHeader from '../SocialMediaHeader';

const TextOutput = () => {
  const navigate = useNavigate();
  const path = window.location.pathname;
  const captionList = JSON.parse(localStorage.getItem('captionList'));
  const selectedCaption = JSON.parse(localStorage.getItem('selectedCaption'))?.caption;
 
  const handleCaptionSelection = (caption) => {
    localStorage.setItem('selectedCaption', JSON.stringify({ caption: caption }));
    if (path.includes('InstagramPost')) {
      navigate('/mobile/InstagramPost/Preview');
    } else if (path.includes('FacebookPost')) {
      navigate('/mobile/FacebookPost/Preview');
    }
  };

  return (
    <>
      <SocialMediaHeader socialMediaTab='text' />
      <div className='p-[20px] pt-[20px]'>
        <div className='py-[20px] text-[16px] font-jost font-[400] text-[#7D7D7D]'>
          Select a caption to preview
        </div>
        {captionList && (
          <div className='flex flex-col pb-[50px]'>
            {captionList.map((item, index) => {
              return (
                <div key={index} onClick={() => handleCaptionSelection(item?.caption)}
                  className={cn('py-[20px] text-[16px] font-[400] font-jost text-[#000]',
                  selectedCaption === item?.caption && 
                  'bg-[#F0F0F0] rounded-[5px] border-[1px] border-[#7D7D7D] px-[10px]')}>
                  {item?.caption}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <FooterSection />
    </>
  );
};

export default TextOutput;