import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import breezeLogo from 'assets/NewIcons/Mask group.svg';
import { AppButton } from 'components/shared/AppButton';
import cn from 'classnames';
import axios from 'axios';
import { BASE_URL } from 'utils/baseURL';
import { ClipLoader } from 'react-spinners';
import uploadIcon from 'assets/icons/uploadIcon.svg';
import tickMarkIcon from 'assets/icons/tickMarkIcon.svg';
import deleteImageIcon from 'assets/icons/deleteImageIcon.svg';
import { LocalStorage } from 'services/localStorage';
import DeleteContentPopUp from 'components/DesktopComponents/CollectContent/DeleteContentPopup';
import SelectImage from 'assets/img/selectImage.svg';
import { getLinkExpireTime } from 'utils/commonFunctions';
import LinkExpiredImage from 'assets/img/linkExpired.svg';

const CollectContent = () => {
  const { key } = useParams();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [projectDetails, setProjectDetails] = useState();
  const [linkError, setLinkError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isDeleteConfirmPopup, setDeleteConfirmPopup] = useState();
  const inputFileRef = useRef(null);
  const uploadFileRef = useRef(null);

  const getProjectDetails = async () => {
    setLoading(true);
    const url = BASE_URL + 'collect_content/getData' + `?key=${key}`;
    try {
      const projectDetailsData = await axios.get(
        url
      );
      if (projectDetails?.data?.status === 404) {
        setLinkError(true);
      } else setProjectDetails(projectDetailsData?.data?.data);
    } catch (err) {
      if (err?.response?.status === 404) {
        setLinkError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (key) {
      getProjectDetails();
    }
  }, [key]);

  const onFileChange = (e) => {
    e.preventDefault();
    const acceptedFiles = Object.entries(e.target.files).map((e) => {
      e[1].selected = false;
      e[1].loaded = false;
      e[1].src = URL.createObjectURL(e[1].file || e[1]);
      return e[1];
    });
    const uploadedImageList = [...selectedImage, ...acceptedFiles];
    // Code to remove images which do not have image types such as .jpg, .jpeg, .png, .webp, .ico, .tiff or .bmp
    const uniqueImageUploadedList = uploadedImageList?.filter((item) =>
      ['jpg','jpeg','png','webp','ico','tiff','bmp']
        .includes(item.name.split('.')[1])
    );
    setSelectedImage(uniqueImageUploadedList);
  };

  const onImageLoad = (index) => {
    setSelectedImage((prevImages) =>
      prevImages.map((img, idx) => {
        if (idx === index) {
          const updatedFile = new File([img], img?.name);
          updatedFile.selected = img.selected;
          updatedFile.loaded = true;
          updatedFile.src = img.src;
          return updatedFile;
        }
        return img;
      })
    );
  };

  const handleFileSelection = (fileIndex) => {
    const acceptedFiles = selectedImage?.map((item, index) => {
      if (fileIndex === index) {
        item.selected = !item.selected;
      }
      return item;
    });
    setSelectedImage(acceptedFiles);
  };

  const handleSelectAll = () => {
    const acceptedFiles = selectedImage?.map((item) => {
      item.selected = !selectAll;
      return item;
    });
    setSelectedImage(acceptedFiles);
  };

  const handleDelete = () => {
    const deletingItems = selectedImage?.filter((item) => !item.selected);
    setSelectedImage(deletingItems);
    setDeleteConfirmPopup(false);
    setSelectAll(false);
  };

  const handleUploadContent = async() => {
    setLoading(true);
    const data = new FormData();
    data.append('key', key);
    selectedImage?.map((item) =>
      data.append('images', item)
    );
    const url = BASE_URL + 'collect_content/upload';
    try {
      await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      navigate('success', { state: projectDetails });
    }
    catch (err) {
      setLoading(false);
    }
    finally {
      setLoading(false);
    }
  };

  const handleSubmitBtnText = () => {
    let text;
    if (selectedImage.length === 1) {
      text = `Submit ${selectedImage?.length} item`;
    } else if(selectedImage.length > 1) {
      text = `Submit ${selectedImage?.length} items`;
    } else text = 'Submit';
    return text;
  };

  return (
    <>
      {isDeleteConfirmPopup && (
        <DeleteContentPopUp
          setDeleteConfirmPopup={setDeleteConfirmPopup}
          handleDelete={handleDelete}
          selectedImage={selectedImage}
        />
      )}
      <div
        className="w-full h-14 px-5 py-2 bg-white border-b border-[#D9D9D9] justify-between items-center inline-flex"
      >
        <div className="grid grid-cols-12 w-full">
          <div className='col-span-6'>
            <img src={breezeLogo} alt='logo' className={'w-[86px] h-[16px] cursor-pointer'} />
          </div>
          <div
            className="col-span-6 text-right text-[#7D7D7D] text-base font-normal font-jost"
          >
            {projectDetails?.email}
          </div>
        </div>
      </div>
      <div className='w-full h-[calc(100vh-56px)] flex flex-col bg-white gap-[20px] py-[40px] px-[80px]'>
        {projectDetails?.project_name && (
          <div className="text-black text-3xl font-normal font-jost">
            {projectDetails?.project_name}
          </div>
        )}
        {selectedImage?.length > 0 && (
          <div
            className="w-full flex justify-end items-center"
          >
            <div className="w-[1760px] h-[89px] p-5 rounded-[10px] border border-[#D9D9D9]
              justify-between items-center inline-flex">
                <div className="justify-start items-center gap-5 flex">
                  <div
                    className="px-5 py-2.5 justify-start items-center gap-2.5 flex cursor-pointer"
                    onClick={() => { setSelectAll(!selectAll); handleSelectAll(); }}
                  >
                    <div className="w-[18px] h-[18px] relative">
                      <img src={tickMarkIcon} alt='' width='18px' height='18px' />
                    </div>
                    <div
                      className="text-[#7D7D7D] text-xl font-normal font-jost"
                    >
                      {!selectAll ? 'Select all' : 'Deselect all'}
                    </div>
                  </div>
                  <div
                    className={cn(`px-5 py-2.5 rounded-[5px] justify-start items-center gap-2.5 flex
                      ${selectedImage?.some(item => item?.selected) ? 'cursor-pointer hover:bg-[#F0F0F0]' :
                      'pointer-events-none'}`)}
                    onClick={() => setDeleteConfirmPopup(true)}
                  >
                    <div className="w-5 h-5 relative">
                      <img src={deleteImageIcon} alt='' width='20px' height='20px' />
                    </div>
                    <div className="text-[#7D7D7D] text-xl font-normal font-jost">Delete</div>
                  </div>
                </div>
                <div className="h-[29px] flex-col justify-between items-center inline-flex">
                  <div className="flex-col justify-start items-center gap-2.5 flex">
                    <div
                      className="justify-start items-center gap-2.5 inline-flex cursor-pointer"
                      onClick={() => uploadFileRef?.current?.click()}
                    >
                      <div className="w-[17.88px] h-[17.88px] relative">
                        <img src={uploadIcon} alt='' width='18px' height='18px' />
                      </div>
                      <div className="text-[#7D7D7D] text-xl font-normal font-jost">Upload</div>
                      <input
                        type='file'
                        onChange={onFileChange}
                        ref={uploadFileRef}
                        name='files'
                        id='file'
                        className='hidden'
                        accept=".jpg, .jpeg, .png, .webp, .ico, .tiff, .bmp"
                        multiple='multiple'
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="px-5 py-2.5 bg-[#0038FF] rounded-[5px] justify-end items-start gap-2.5 flex cursor-pointer"
                >
                  <div
                    className="text-center text-white text-xl font-normal font-jost"
                    onClick={() => selectedImage?.length > 0 && handleUploadContent()}
                  >
                    {handleSubmitBtnText()}
                  </div>
                </div>
            </div>
          </div>
        )}
      <div
        className='bg-white h-full w-full
          flex justify-center items-center'
      >
        {(!isLoading && linkError) ? (
          <div className='flex flex-col justify-center items-center font-jost'>
            <img src={LinkExpiredImage} alt='' className='w-[501px] h-[501px]' />
            <div className='text-[#0038FF] text-[36px] font-[600] pt-[40px]'>Sorry, the link has expired.</div>
            <div className='text-[#0038FF] text-[24px] font-[400] pt-[20px]'>
              Please contact the person who shared the link with you
            </div>
          </div>
        ) :
        !(!isLoading && linkError) && (
          <div
            className={cn('border border-[#D9D9D9] rounded-[10px] h-full w-full',
            selectedImage?.length <=0 && 'flex justify-center items-start')}
          > 
            {isLoading && (
              <div className='w-full h-full flex justify-center items-center'>
                <ClipLoader color='#757D89' />
              </div>
            )}
            {!isLoading && !linkError && (
              <>
                {selectedImage?.length <=0 ? (
                  <div className='flex flex-col items-center justify-center'>
                    <img src={SelectImage} alt='' className='w-[762px] h-[347px] mt-[20px]' />
                    <AppButton
                      type="button"
                      variant=''
                      onClick={() => inputFileRef?.current?.click()}
                      extendClass='!bg-[#0038FF] !text-white hover:bg-[#0038FF] rounded-[5px]
                      !text-base !font-normal !text-[20px] !font-jost
                      h-[49px] w-[157px] !py-[2px] px-[0px] border-[1px] border-solid !border-[#0038FF]'
                    >
                      Select images
                    </AppButton>
                    <input
                      type='file'
                      onChange={onFileChange}
                      ref={inputFileRef}
                      name='files'
                      id='file'
                      className='hidden'
                      accept=".jpg, .jpeg, .png, .webp, .ico, .tiff, .bmp"
                      multiple='multiple'
                    />
                    {projectDetails && (
                      <div className="text-[#000] text-[20px] font-normal font-jost pt-[20px]">
                        Link will expire in&nbsp;
                        <span className='text-[#0038FF] text-[20px] font-[400]'> 
                          {getLinkExpireTime(projectDetails?.expiration_time)}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={cn('h-[calc(100vh-308px)] overflow-auto')}>
                    <div className='grid grid-cols-6 p-[20px] gap-[10px] showScroll'>
                      {selectedImage?.map((item, index) => {
                        return (
                          <>
                            <div
                              key={item}
                              onClick={() => handleFileSelection(index)}
                              className={cn('flex-none group col-span-1 aspect-square max-w-full max-h-full',
                              'bg-[#F0F0F0] flex items-center justify-center rounded-[5px] relative',
                              'hover:border-[3px] hover:p-[2px] hover:border-[#0038FF]',
                              (item?.selected && item?.loaded) && 'border-[3px] p-[2px] border-[#0038FF]')}
                            >
                              {!item?.loaded && (
                                <div className='bg-[#F1F1F1] max-w-full max-h-full rounded-[2px]
                                  flex flex-col items-center justify-center m-auto'>
                                  <ClipLoader color='#7D7D7D' />
                                  <div className='text-[#7D7D7D] text-[20px] font-[600] pt-[10px] font-jost'>
                                    Loading
                                  </div>
                                </div>
                              )}
                              <img
                                src={item?.src} alt=''
                                className='max-w-full max-h-full'
                                style={{ display: item.loaded ? 'block' : 'none'}}
                                onLoad={() => onImageLoad(index)}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
     </div>
    </>
  );
};

export default CollectContent;