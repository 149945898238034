import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
// import { LocalStorage } from 'services/localStorage';

const AlertModal = ({ modalOpen, setModalOpen, header, body, additionalFx, additionalBtn, 
  AdditionalInput, Static }) => {
  // console.log('[INFO], Static:', Static);
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    // Initialize inputValues with values from AdditionalInput
    if (AdditionalInput && Object.keys(inputValues).length === 0) {
      // console.log('[INFO] in if loop in useEffect, inputValues',inputValues);
      const initialValues = Object.entries(AdditionalInput).reduce((acc, [fieldName, fieldValue]) => {
        if (!Array.isArray(fieldValue)) {
          acc[fieldName] = fieldValue;
        }
        return acc;
      }, {});
      // console.log('[INFO] in if loop in useEffect, initialValues:',initialValues);
      setInputValues(initialValues);
    }
  }, [AdditionalInput]);

  const handleInputChange = (e, fieldName) => {
    setInputValues((prevState) => ({
      ...prevState,
      [fieldName]: e.target.value
    }));
  };

  const renderAdditionalInputs = () => {
    if (!AdditionalInput) return null;

    return Object.entries(AdditionalInput).map(([fieldName, fieldValue]) => {
      if (Array.isArray(fieldValue)) {
        return (
          <div key={fieldName} className='mt-4'>
            <label htmlFor={fieldName} className='block text-sm font-medium text-gray-700'>
              {fieldName}
            </label>
            <select
              name={fieldName}
              id={fieldName}
              className='mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500
               focus:border-indigo-500 block w-full sm:text-sm'
              value={inputValues[fieldName] || ''}
              onChange={(e) => handleInputChange(e, fieldName)}
            >
              <option value=''>Please choose</option> {/* Default option */}
              {fieldValue.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      } else {
        return (
          <div key={fieldName} className='mt-4'>
            <label htmlFor={fieldName} className='block text-sm font-medium text-gray-700'>
              {fieldName}
            </label>
            <input
              type='text'
              name={fieldName}
              id={fieldName}
              className='mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500
               focus:border-indigo-500 block w-full sm:text-sm'
              value={inputValues[fieldName] || fieldValue}
              onChange={(e) => handleInputChange(e, fieldName)}
            />
          </div>
        );
      }
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    additionalFx && additionalFx();
  };

    return (
      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={() => {
          if (!Static) {
            setModalOpen(false);
            additionalFx && additionalFx();
          }
        }}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed z-100 inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 sm:mx-4'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl
                 transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                  <div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
                    <div className='sm:flex sm:items-start'>
                      {!AdditionalInput && (
                          <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full
                           bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                            <ExclamationTriangleIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
                          </div>
                        )}
                      <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
                        <Dialog.Title as='h3' className='text-base font-semibold leading-6 text-gray-900'>
                          {header}
                        </Dialog.Title>
                        <div className='mt-2'>
                          <p className='text-sm text-gray-500'>
                            {body}
                            {renderAdditionalInputs()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-3'>
                  {!AdditionalInput ? (
                    <React.Fragment>
                      {additionalBtn && additionalBtn()}
                      <button
                        type='button'
                        className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm 
                        font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                         hover:bg-gray-50 sm:mt-0 sm:w-auto'
                        onClick={handleCloseModal}
                      >
                        Close
                      </button>
                    </React.Fragment>
                  ) : (
                    additionalBtn && additionalBtn(inputValues)
                  )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

export default AlertModal;