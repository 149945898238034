/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import searchIcon from 'assets/icons/searchIcon.svg';
import plusIcon from 'assets/icons/whitePlusIcon.svg';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import format_list_bulleted from 'assets/icons/format_list_bulleted.svg';
import format_list from 'assets/icons/format_list.svg';
import grid_view_filled from 'assets/icons/grid_view_filled.svg';
import grid_view from 'assets/icons/grid_view.svg';
import GalleryView from './galleryView';
import ListView from './listView';
import { uploadImgToRemoveBg } from 'utils/fileHelpers';
import { toast } from 'react-toastify';
import Modal from 'components/Modal/index';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { BASE_URL } from 'utils/baseURL';
import TabButton from 'components/shared/TabButton';
import FilterComponent from './FilterComponent';
import cn from 'classnames';
import AddImages from 'components/DesktopComponents/Common/AddImages';
import UploadImgFolderModal from 'components/DesktopComponents/Common/UploadImgFolderModal';
import closeIcon from 'assets/icons/closeIcon.svg';
import ModalPopup from 'components/commonComponents/ModalPopup';
import googleDriveIcon from 'assets/img/googleDrive.svg';
import ImageClickPopUpCollections from '../ImageClickFunctions/ImageClickPopUpCollections';

const ImageLibrary = () => {
  const selectedTabLibrary = JSON.parse(localStorage.getItem('selectedTab'));

  const [selectedTab, setSelectedTab] = useState(selectedTabLibrary ? selectedTabLibrary : 'myUploads');
  const [imageList, setImageList] = useState([]);
  const [uploadedImages, setUploadedImage] = useState([]);
  const [selectedView, setSelectedView] = useState('galleryView');
  const [searchDataList, setSearchDataList] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [fileName, setFileName] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [isPopUp, setIsPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allImageCount, setAllImageCount] = useState();
  const [myUploadCount, setMyUploadCount] = useState();
  const doMock = false;
  // const inputFileRef = useRef(null);
  const [searchedText, setSearchedText] = useState(''); // to store the searched key
  const uploadImgGenLibrary = JSON.parse(localStorage.getItem('uploadImgGenLibrary'));
  const isAddFromLibrary = JSON.parse(localStorage.getItem('isAddFromLibrary'));
  const [isFilesUploading, setIsFilesUploading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isUploadFolder, setIsUploadFolder] = useState(false);
  const [imageUploadingOrigin, setImageUploadingOrigin] = useState();
  const [projectId, setProjectID] = useState();

  //states related to imageClick functions PopUp
  const [imageClickData, seImageClickData] = useState({});
  const [updatedImgName, setUpdatedImgName] = useState('');
  const [projectIdsList, setProjectIDsList] = useState([]);
  const [shareLinkKeyData, setShareLinkKeyData] = useState();
  const [shareLinkKeyLoading, setShareLinkLoading] = useState(false);
  const [isAllowDownload, setIsAllowDownload] = useState();

  const showCreateModule = JSON.parse(localStorage.getItem('showCreateModule'));

  const [isImgClickPopUps, setIsImgClickPopUps] = useState({
    shareLink: false,
    addTo: false,
    moveTo: false,
    renameImg: false,
    removeImg: false,
    deleteImg: false
  });

  const [googleDriveImportLoading, setGoogleDriveImportLoading] = useState(false);
  const [googleDriveImportError, setGoogleDriveImportError] = useState(false);

  const [multiImageSelectedList, setMultiImageSelectedList] = useState([]);

  useEffect(() => {
    setUpdatedImgName(imageClickData?.image_name);
  }, [imageClickData]);

  const navigate = useNavigate();
  const searchedData = JSON?.parse(localStorage?.getItem('searchKey'));

  const handleImageUpload = async (uploadListData) => {
    // setIsFilesUploading(true);
    const data = new FormData();
    if (selectedFile?.length > 0) {
      for (var i = 0; i < selectedFile?.length; i++) {
        data.append('images', selectedFile[i]);
      }
    }
    if (projectId) {
      data.append('projectID', projectId);
    }
    if (imageUploadingOrigin) {
      data.append('origin', imageUploadingOrigin);
    }
    const url = BASE_URL + 'image-gen/upload_multiple_images';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response && response?.data) {
        if (!projectId) {
          // const tempImageData = imageListData?.history;
          const tempUploadData = uploadListData?.history;
          for (let i = 0; i < response?.data?.data?.products?.length; i++) {
            // tempImageData[i] = { ...response?.data?.data?.products[i] };
            tempUploadData[i] = { ...response?.data?.data?.products[i] };
          }
          // setImageList({ total_records: tempImageData?.length, history: tempImageData });
          setUploadedImage({ total_records: tempUploadData?.length, history: tempUploadData });
          // setAllImageCount(tempImageData?.length);
          setMyUploadCount(tempUploadData?.length);
        } else {
          getAllImages();
          getMyUpload();
        }
      }
    } catch (err) {
      console.log('Something went wrong');
    }
    setImageUploadingOrigin();
    setIsFilesUploading(false);
  };

  const handleMultipleImagesUpload = () => {
    setIsFilesUploading(true);
    setIsUploadFolder(false);
    if (!projectId) {
      // let tempImageListData = { ...imageList };
      let tempUploadListData = { ...uploadedImages };
      for (let i = 0; i < selectedFile?.length; i++) {
        // tempImageListData?.history?.unshift(null);
        tempUploadListData?.history?.unshift(null);
        // setImageList(tempImageListData);
        setUploadedImage(tempUploadListData);
      }
      handleImageUpload(tempUploadListData);
    } else {
      handleImageUpload();
    }
  };

  const getAllImages = async () => {
    setLoading(true);
    try {
      const sampleImages = await axios.get(BASE_URL + 'image-gen/genHistory', {
        headers: {
          'Content-Type': 'multipart/form-data',
          newest_first: true,
          token: LocalStorage.getItem()
        }
      });
      const allImageList = sampleImages?.data?.data;
      setAllImageCount(allImageList?.history?.length);
      setImageList(allImageList);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getMyUpload = async () => {
    setUploadedImage();
    setLoading(true);
    try {
      let data;
      if (uploadImgGenLibrary?.projectID) {
        data = new FormData();
        data.append('projectID', uploadImgGenLibrary?.projectID);
      }
      const url = BASE_URL + 'image-gen/uploadHistory';
      const sampleImages = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            startIdx: 1,
            Num_records: 10,
            token: LocalStorage.getItem()
          }
        });
      const allImageList2 = sampleImages?.data?.data;
      setMyUploadCount(allImageList2?.history?.length);
      setUploadedImage(allImageList2);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  localStorage.setItem('selectedTab', JSON.stringify(selectedTab));

  useEffect(() => {
    //if searchedData is not then only call the allImages and myUploads api
    if (!searchedData) {
      getAllImages();
      getMyUpload();
    }
    if (uploadImgGenLibrary?.libraryImgUpload) {
      setSelectedTab('myUploads');
    } else if (selectedTabLibrary) {
      setSelectedTab(selectedTabLibrary);
    } else {
      setSelectedTab('myUploads');
    }
  }, []);

  useEffect(() => {
    if (isSearched) {
      if (searchDataList?.length > 0) {
        const tempSearchData = searchDataList?.filter(item => item?.image_origin === 'Uploaded');
        setMyUploadCount(tempSearchData?.length);
        setAllImageCount(searchDataList?.length - tempSearchData?.length);
      } else if ((!searchDataList || searchDataList?.length <= 0)) {
        setAllImageCount();
        setMyUploadCount();
      }
    }
    else {
      setAllImageCount(imageList?.history?.length);
      setMyUploadCount(uploadedImages?.history?.length);
    }
  }, [searchDataList, isSearched]);

  const handleSearchData = (data) => {
    const searchValue = data?.target?.value;
    if (searchValue) {
      setSearchedText(searchValue);
      // setIsSearched(true);
    } else {
      setSearchedText('');
      setIsSearched(false);
      setSearchDataList([]);
    }
  };

  // it will run when we manually reload the page from browser
  window.addEventListener('beforeunload', function () {
    if (window.location.pathname === '/imageLibrary') {
      localStorage.removeItem('searchKey');
    }
  });

  const handleFileName = (fileData) => {
    setFileName(fileData);
  };

  const uploadImage = () => {
    setLoading(true);
    uploadImgToRemoveBg(selectedFile, doMock, fileName)
      .then(async res => {
        if (res === 'expired') {
          return;
        }
        let tempUploadList = uploadedImages?.history;
        tempUploadList.unshift(res?.data);
        setUploadedImage({ ...uploadImage, history: tempUploadList });
        setMyUploadCount(tempUploadList?.length);
        let tempImageList = imageList?.history;
        tempImageList.unshift(res?.data);
        setImageList({ ...imageList, history: tempImageList });
        setAllImageCount(tempImageList?.length);
      })
      .catch(() => {
        toast.error('Oops, Something went wrong');
      })
      .finally(() => {
        setLoading(false);
        setFileName();
        setSelectedFile();
      });
  };

  useEffect(() => {
    if (selectedFile && fileName) uploadImage();
  }, [selectedFile, fileName]);

  const handleModal = () => {
    setIsPopUp(false);
  };

  const handleClose = () => {
    setFileName();
    setSelectedFile();
    setIsPopUp(false);
    setIsUploadFolder(false);
  };

  const handleImageClick = (image) => {
    // searchKey is stored in localStorage only when we click on any image
    localStorage?.setItem('searchKey', JSON?.stringify(searchedText));
    if (image?.pid) {
      if (image?.gid) {
        navigate(`/imageLibrary/${image?.pid}/${image?.gid}`);
      } else {
        navigate(`/imageLibrary/${image?.pid}`);
      }
    }
  };

  const reCallImageList = () => {
    getAllImages();
    getMyUpload();
  };

  const handleAllowDownload = async () => {
    try {
      const data = new FormData();
      if (imageClickData?.pid && !imageClickData?.gid) data.append('pid', imageClickData?.pid);
      if (imageClickData?.gid) data.append('gid', imageClickData?.gid);
      data.append('allow_download', isAllowDownload);
      const url = BASE_URL + 'share_link/allowDownload';
      await axios.post(
        url,
        data,
        {
          headers: {
            token: LocalStorage.getItem()
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if ((isAllowDownload === true) || (isAllowDownload === false)) {
      handleAllowDownload();
    }
  }, [isAllowDownload]);

  const TabView = () => (
    <div className='grid grid-cols-6 py-[20px]'>
      <div className='col-span-4 flex flex-row justify-start gap-[20px]' id='image-selector'>
        <TabButton
          active={selectedTab === 'myUploads'}
          onClick={() => {
            setSelectedTab('myUploads');
          }}
          type='button'
          className={cn('text-center text-black py-[11px] font-jost text-[18px]',
            selectedTab !== 'myUploads' && 'mb-[3px]')}
          activeClassName={{ color: '#000', borderBottom: '3px solid #000' }}
          fontWeight={selectedTab === 'myUploads' ? '600' : '400'}
        >
          <span className='py-[10px] px-[20px]'>
            Uploads {myUploadCount && `(${myUploadCount})`}
          </span>
        </TabButton>
        {showCreateModule && (
          <TabButton
            active={selectedTab === 'allImages'}
            onClick={() => {
              setSelectedTab('allImages');
            }}
            className={cn('text-center text-black py-[11px] font-jost text-[18px]',
              selectedTab !== 'allImages' && 'mb-[3px]')}
            type='button'
            style={{ text: 'nowrap' }}
            disabled={isAddFromLibrary}
            activeClassName={{ color: '#000', borderBottom: '3px solid #000' }}
            fontWeight={selectedTab === 'allImages' ? '600' : '400'}
          >
            <span className='py-[10px] px-[20px]'>
              AI Generated {allImageCount && `(${allImageCount})`}
            </span>
          </TabButton>
        )}
      </div>
      <div className='col-start-7 col-span-1 flex flex-row justify-end items-center gap-2.5' id='image-selector'>
        <div
          className=''
          role='presentation'
          onClick={() => setSelectedView('galleryView')}
        >
          <img
            src={selectedView === 'galleryView' ? `${grid_view_filled}` : `${grid_view}`}
            alt=''
            className='w-[24px] h-[24px]'
          />
        </div>
        <div
          className={`${isAddFromLibrary ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
          role='presentation'
          onClick={() => !isAddFromLibrary && setSelectedView('listView')}
        >
          <img
            src={selectedView === 'listView' ? `${format_list_bulleted}` : `${format_list}`}
            alt=''
            className='w-[24px] h-[24px]'
          />
        </div>
      </div>
    </div>
  );

  const searchFilterDivHeight = document?.getElementById('imageLibraryFilter')?.offsetHeight;

  return (
    <div className='h-screen w-full px-[40px] showScroll'>
      {googleDriveImportLoading && (
        <ModalPopup>
          {googleDriveImportError && (
            <div
              className='flex items-start justify-end'
              role="presentation"
              onClick={() => {
                setGoogleDriveImportLoading(false);
                setGoogleDriveImportError(false);
              }}
            >
              <img src={closeIcon} alt='' width='12px' height='12px' />
            </div>
          )}
          <div className='p-8'>
            <div className='flex justify-center items-center pb-4'>
              <img src={googleDriveIcon} alt="icon.." width='30px' height='32px' />
            </div>
            <div className={cn('text-[20px] font-jost font-semibold', !googleDriveImportError && 'pb-4')}>
              {googleDriveImportError ?
                'Something went wrong, please try again after some time' :
                'Importing Image from Google Drive...'
              }
            </div>
            {!googleDriveImportError && <ClipLoader color='#757D89' />}
          </div>
        </ModalPopup>
      )}
      {isPopUp && (
        <Modal
          handleModal={handleModal}
          handleFileName={handleFileName}
          handleCloseBtn={handleClose}
        />
      )}
      {isUploadFolder && (
        <UploadImgFolderModal
          handleCloseBtn={handleClose}
          setProjectID={setProjectID}
          projectId={projectId}
          isFilesUploading={isFilesUploading}
          handleMultipleImagesUpload={handleMultipleImagesUpload}
        />
      )}
      <ImageClickPopUpCollections
        isImgClickPopUps={isImgClickPopUps}
        setIsImgClickPopUps={setIsImgClickPopUps}
        shareLinkKeyData={shareLinkKeyData}
        shareLinkKeyLoading={shareLinkKeyLoading}
        isAllowDownload={isAllowDownload}
        setIsAllowDownload={setIsAllowDownload}
        setProjectIDsList={setProjectIDsList}
        projectIdsList={projectIdsList}
        imageClickData={imageClickData}
        multiImageSelectedList={multiImageSelectedList}
        updatedImgName={updatedImgName}
        setProjectID={setProjectID}
        setUpdatedImgName={setUpdatedImgName}
        reCallImageList={reCallImageList}
        projectId={projectId}
      />
      <div className='' id='imageLibraryFilter'>
        <div className='font-jost text-[#000] whitespace-nowrap
          text-[30px] font-[400] my-[20px]'>
          Image Library
        </div>
        <div className='grid grid-cols-6 mb-[20px]'>
          <div className='col-span-2 h-[48px] flex justify-start left-0 border-2 rounded-[4px]'>
            <Formik
              initialValues={searchedData ? { searchField: searchedData } : {}}
              enableReinitialize
            >
              <Form className='w-full h-full'>
                <div className='flex flex-row px-[10px] h-full gap-[10px]'>
                  <div className='flex items-center justify-center'>
                    <img
                      src={searchIcon}
                      alt='searchIcon'
                      className='w-[20px] h-[20px]'
                    />
                  </div>
                  <div className='flex items-center justify-start w-full'>
                    <Field
                      type='search'
                      className='w-full px-0 text-base font-normal font-jost customTextBoxColor leading-normal text-gray-800 bg-white border-0 appearance-none'
                      name='searchField'
                      placeholder='Search...'
                      onChange={handleSearchData}
                      autoComplete='off'
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
          <div
            className='col-span-1 col-start-6 flex justify-end items-center relative'
          >
            <div
              className={`w-[158px] h-12 px-[20px] py-[10px] bg-[#333] rounded border border-black justify-center items-center gap-[10px] 
              inline-flex ${(isAddFromLibrary || loading) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
              onClick={() => !isAddFromLibrary && setIsUpload(!isUpload)}
            >
              <img
                alt='plusIcon'
                className='w-[20px] h-[20px]'
                src={plusIcon}
              />
              <div
                className='text-white text-base font-medium font-jost'
              >
                Add Image
              </div>
            </div>
            {isUpload && !loading && (
              <AddImages
                setIsUpload={setIsUpload}
                setSelectedFile={setSelectedFile}
                setIsUploadFolder={setIsUploadFolder}
                setGoogleDriveImportLoading={setGoogleDriveImportLoading}
                setGoogleDriveImportError={setGoogleDriveImportError}
                setImageUploadingOrigin={setImageUploadingOrigin}
              />
            )}
          </div>
        </div>
        <FilterComponent
          searchDataList={searchDataList}
          searchedText={searchedText}
          setSearchDataList={setSearchDataList}
          setLoading={setLoading}
          setIsSearched={setIsSearched}
          searchedData={searchedData}
        />
        <hr className={cn('border-[1px] border-[#D9D9D9]')} />
        <TabView />
      </div>
      {loading && (
        <div className='flex flex-col items-center justify-center mt-6 spinner-border bg-white rounded'>
          <ClipLoader color='#757D89' />
          <div className='lg:text-[12px] xl:text-[16px] xl-mid:text-[18px] 4xl:text-[20px] font-semibold'>
            Loading...
          </div>
        </div>
      )}
      {!loading && selectedView === 'galleryView' && (
        <GalleryView
          selectedTab={selectedTab}
          imageList={imageList}
          uploadedImages={uploadedImages}
          searchDataList={searchDataList}
          isSearched={isSearched}
          handleImageClick={handleImageClick}
          loading={loading}
          searchFilterDivHeight={searchFilterDivHeight}
          seImageClickData={seImageClickData}
          setMultiImageSelectedList={setMultiImageSelectedList}
          libraryImgUpload
          setIsImgClickPopUps={setIsImgClickPopUps}
          setShareLinkKeyData={setShareLinkKeyData}
          setShareLinkLoading={setShareLinkLoading}
          setIsAllowDownload={setIsAllowDownload}
          isFilesUploading={isFilesUploading}
        />
      )}
      {!loading && selectedView === 'listView' && (
        <ListView
          selectedTab={selectedTab}
          imageList={imageList}
          uploadedImages={uploadedImages}
          searchDataList={searchDataList}
          isSearched={isSearched}
          handleImageClick={handleImageClick}
          searchFilterDivHeight={searchFilterDivHeight}
          loading={loading}
          seImageClickData={seImageClickData}
          setMultiImageSelectedList={setMultiImageSelectedList}
          setIsImgClickPopUps={setIsImgClickPopUps}
          setShareLinkKeyData={setShareLinkKeyData}
          setShareLinkLoading={setShareLinkLoading}
          setIsAllowDownload={setIsAllowDownload}
          isFilesUploading={isFilesUploading}
        />
      )}
    </div >
  );
};

export default ImageLibrary;