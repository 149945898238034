import React from 'react';

const ModalPopup = (props) => {
  const {
    children
  } = props;
  return (
    <div
      className='relative z-50'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div
        className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
      />
      <div className='fixed inset-0 z-50 overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
            transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-lg'>
            <div className='bg-white p-3'>
              <div className='w-full text-center'>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPopup;