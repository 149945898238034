/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
import { ACCESS_TOKEN, IS_LOGGED_IN, REFRESH_TOKEN } from './variables.config';
import { redirect } from 'react-router-dom';

export const getAppTitle = () => {
  return process.env.REACT_APP_WEBSITE_NAME;
};

export const getAppDescription = () => {
  return process.env.REACT_APP_WEBSITE_DESCRIPTION;
};

export const getUserloggedInData = () => {
  if (localStorage.hasOwnProperty('userData')) {
    return JSON.parse(localStorage.getItem('userData'));
  } else {
    return null;
  }
};

export const parseJwt = token => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export const CheckUserExpired = pageStatus => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (!token) return;
  const { exp } = parseJwt(token);
  if (exp * 1000 < Date.now()) {
    localStorage.removeItem('userData');
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(IS_LOGGED_IN);

    if (pageStatus !== 'public') {
      redirect({
        url: '/login?expired=true'
      });
    }
  }
};

export function removeAllUserData() {
  localStorage.removeItem('userData');
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(IS_LOGGED_IN);
}

export const loadBasketState = () => {
  try {
    const basketState = localStorage.getItem('Basket');
    if (basketState === null) {
      return undefined;
    }
    return JSON.parse(basketState);
  } catch (err) {
    return undefined;
  }
};

export const saveBasketState = state => {
  try {
    const basketState = JSON.stringify(state);
    localStorage.setItem('Basket', basketState);
  } catch {
    // error
  }
};

export const loadUserData = () => {
  try {
    const userData = JSON.parse(localStorage.getItem('userData'));

    const userInfo = {
      id: userData.id,
      role: userData.role,
      username: userData.username,
      password: userData.password,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      phone: userData.phone,
      address: userData.address,
      country: userData.country,
      state: userData.state,
      city: userData.city,
      zip: userData.zip,
      createdAt: userData.createdAt,
      exp: userData.exp,
      iat: userData.iat,
      loggedIn: userData.loggedIn,
      token: userData.token
    };

    if (userData === null) {
      return {
        id: null,
        role: '',
        username: '',
        password: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',

        address: '',
        country: '',
        state: '',
        city: '',
        zip: '',

        createdAt: null,
        exp: null,
        iat: null,
        loggedIn: false,
        token: ''
      };
    }
    return userInfo;
  } catch (err) {
    return undefined;
  }
};

export function loadOrderState() {
  try {
    const orderState = localStorage.getItem('Order');
    if (orderState === null) {
      return undefined;
    }
    return JSON.parse(orderState);
  } catch (err) {
    return undefined;
  }
}

export function saveOrderState(state) {
  try {
    const orderState = JSON.stringify(state);
    localStorage.setItem('Order', orderState);
  } catch {
    // error
  }
}

export function handleTokenExpiryIssue() {
  localStorage.removeItem('user-id');
  localStorage.removeItem('user-email');
  localStorage.removeItem('auth-token');
  redirect({
    url: '/login'
  });

}
