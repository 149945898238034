import React from 'react';
import loadingGif from 'assets/img/loaderAnimation.gif';
import 'react-lazy-load-image-component/src/effects/blur.css';
import RenderImage from './RenderImageList';
import { useNavigate } from 'react-router-dom';
import upscaleLoading from 'assets/img/upscale_Animation.gif';

const GeneratedImagesSection = ({
  generatedImagesData,
  generateLoading,
  isUpScaled,
  upScaledImgGenData
}) => {
  const path = window.location.pathname;
  const navigate = useNavigate();
  const handleSelectedGenImg = (data) => {
    if (path.includes('PhotoStudio')) {
      navigate('/mobile/PhotoStudio/imageView', { state: { imageData: data } });
    } else if (path.includes('ImageGenerator')) {
      navigate('/mobile/ImageGenerator/imageView', { state: { imageData: data } });
    } else if (path.includes('InstagramPost')) {
      navigate('/mobile/InstagramPost/Image/imageView', { state: { imageData: data } });
    } else if (path.includes('FacebookPost')) {
      navigate('/mobile/FacebookPost/Image/imageView', { state: { imageData: data } });
    }
  };

  return (
    <div className='p-[20px] h-full w-full'>
      <div className='grid sm:grid-cols-2 md:grid-cols-3 grid-cols-4 
      grid-flow-row gap-2 items-center h-full max-w-full'>
        {generatedImagesData?.map((image, index) => {
          return (
            <div key={index} className='col-span-1 group relative aspect-square'>
              <>
                {image === null ? (
                  <>{(isUpScaled) ? (
                    <div className='bg-[#F1F1F1] w-full m-auto h-full rounded-[2px] flex items-center justify-center'>
                      <img
                        src={upScaledImgGenData?.url}
                        alt='loading...'
                        className='w-full h-full'
                      />
                      <div
                        className='absolute rounded-md w-full h-full'
                        style={{
                          background: 'rgba(0, 0, 0, 0.5)',
                          top: '0px'
                        }}
                      >
                        <div
                          className='absolute items-center flex justify-center leading-normal 
                          rounded-md w-full font-jost text-[10px] inline p-1 text-center text-[#fff]'
                        >
                          <img src={upscaleLoading} className='rounded' />
                        </div>
                      </div>
                    </div>) : (
                    <div className='bg-[#F1F1F1] w-full m-auto h-full rounded-[2px] flex items-center justify-center'>
                      <img src={loadingGif} alt='loading...' className='w-[36px]' />
                    </div>
                  )}
                  </>

                ) : (
                  <>
                    <RenderImage
                      image={image}
                      index={index}
                      handleSelectedGenImg={handleSelectedGenImg}
                      generateLoading={generateLoading}
                    />
                  </>
                )}
              </>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GeneratedImagesSection;