import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import breezeLogo from 'assets/NewIcons/Mask group.svg';
import celebration_img1 from 'assets/img/celebration_img1.png';
import celebration_img2 from 'assets/img/celebration_img2.png';

const SuccessfullySubmittedScreen = () => {
  const locationData = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <div
        className="w-full h-14 px-5 py-2 bg-white border-b border-[#D9D9D9] justify-between items-center inline-flex"
      >
        <div className="grid grid-cols-12 w-full">
          <div className='col-span-6'>
            <img src={breezeLogo} alt='logo' className={'w-[86px] h-[16px] cursor-pointer'} />
          </div>
          <div
            className="col-span-6 text-right text-[#7D7D7D] text-base font-normal font-jost"
          >
            {locationData?.state?.email}
          </div>
        </div>
      </div>
      <div className="w-full h-[calc(100vh-56px)] flex-col justify-center items-center inline-flex bg-white">
        <div className='flex flex-row relative'>
          <img
            className="w-[108px] h-[107px] origin-left absolute left-[-140px] top-[-95px]"
            src={celebration_img1}
          />
          <div className="text-[#333333] text-4xl font-normal font-jost">
            Hooray! Your content has been submitted
            <div
              className="text-[#0038FF] text-2xl font-normal font-jost
                flex justify-center items-center pt-[20px] cursor-pointer"
              onClick={() => navigate(-1)}
            >
              Upload more
            </div>
          </div>
          <img
            className="w-[173.69px] h-[145.72px] origin-right absolute right-[-140px] top-0"
            src={celebration_img2}
          />
        </div>
      </div>
    </>
  );
};

export default SuccessfullySubmittedScreen;