import React from 'react';
// import DeleteIcon from 'assets/icons/deleteGoogleIcon.svg';
import CopyIcon from 'assets/icons/copyGoogleIcon.svg';

const CaptionAndHeadline = ({ captionListData }) => {

  const handleTextCopy = (clipText) => {
    if (clipText) {
      navigator?.clipboard?.writeText(clipText);
    }
  };

  return (
    <div className='pt-[80px] pl-[80px] pr-[20%] pb-[20px] flex flex-col gap-[80px] 
      h-[calc(100vh-58px)] overflow-y-scroll'>
      {captionListData?.output?.map((item, index) => (
        <div key={index}
          role='presentation'
          className=''
        >
          <div className='flex justify-between'>
            <div className='lg:text-[18px] xl:text-[20px] 2xl:text-[24px] text-[18px] font-[600] w-[calc(100%-30px)]'>
              <span>{item?.headline}</span>
            </div>
            <div className='w-[30px] flex justify-end items-start'>
              <img className='hover:cursor-pointer'
                src={CopyIcon}
                width={20}
                height={20}
                alt='CopyIcon'
                onClick={() => handleTextCopy(item?.headline)}
              />
              {/* <img src={DeleteIcon} alt='DeleteIcon' className='w-[20px] h-[20px]' /> */}
            </div>
          </div>
          <div className='flex justify-between'>
            <div className='lg:text-[16px] xl:text-[18px] 2xl:text-[20px] text-[16px] font-[400] w-[calc(100%-30px)]'>
              {item?.caption}
            </div>
            <div className='w-[30px] flex justify-end items-start'>
              <img className='hover:cursor-pointer'
                src={CopyIcon}
                width={20}
                height={20}
                alt='CopyIcon'
                onClick={() => handleTextCopy(item?.caption)}
              />
            </div>
          </div>
        </div>
      ))}</div>
  );
};

export default CaptionAndHeadline;
