import React, { useState, Fragment } from 'react';
import { AppButton } from 'components/shared/AppButton';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { emailLoginRequest, loginRequest } from 'queryhook/auth/auth';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithGooglePopuop } from 'utils/Firebase';
import * as yup from 'yup';
import googleImg from '../assets/img/google.png';
import logo from '../assets/ColorLogo-noBackground.png';
import AlertModal from './AlertModal';
import { ClipLoader } from 'react-spinners';
import { Formik, Form, ErrorMessage, Field } from 'formik';

const LoginSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required')
});

const LoginForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const resetFormFields = () => {
    setFormData({ email: '', password: '' });
  };

  const loginMutation = useMutation(loginInfo =>
    loginRequest(loginInfo), {
    onSuccess: data => {
      setLoading(false);
      localStorage.setItem('user-id', data?.userID);
      localStorage.removeItem('selectedColor');
      window.analytics.identify(data?.userID, { email: formData.email });
      window.analytics.track('Sign In', {
        email: formData.email,
        userId: data?.userID
      });
      if (window?.innerWidth < 992) {
        navigate('/mobile/Templates');
      } else {
        navigate('/imageLibrary');
      }
      // toast.success('You successfully logged in');
    },
    onError: error => {
      setLoading(false);
      if (error?.response?.status === 401) {
        setErrorMessage(error?.response?.data?.detail?.message);
        setAlertModalOpen(true);
        return;
      }
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.detail?.message);
        return;
      }
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === 'auth/too-many-requests') {
        // Handle too many login attempts error
        toast.error('Too many login attempts. Please try again later or reset your password.');
      } else if (errorCode === 'auth/wrong-password') {
        // Handle incorrect password error
        toast.error('Incorrect password. Please try again.');
      } else if (errorCode === 'auth/user-not-found') {
        // Handle user not found (incorrect email) error
        toast.error('The email address is not associated with any account. Please check your email or sign up.');
      } else {
        // Handle other errors
        toast.error(errorMessage || 'Something went wrong. Please try again');
      }
    }
  });

  const registerWithFirebaseMutation = useMutation(email => emailLoginRequest(email), {
    onSuccess: data => {
      if (data) {
        const emailId = localStorage?.getItem('user-email');
        localStorage.removeItem('selectedColor');
        window.analytics.identify(data?.userID, { email: emailId });
        if (data?.event === 'Sign up') {
          //console.log('[DEBUG] signup',data)
          window.analytics.track('Sign Up', {
            email: emailId
          });
          // toast.success('User successfully registered');

        } else if (data?.event === 'Sign in') {
          //console.log('[DEBUG] signin',data)
          window.analytics.track('Sign In', {
            email: emailId,
            userId: data?.userID
          });
        }
        if (window?.innerWidth < 992) {
          navigate('/mobile/Templates');
        } else {
          navigate('/imageLibrary');
        }
      } else {
        toast.error(data.detail.message);
      }
      setLoading(false);
    },
    onError: error => {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  });

  const handleFirebaseLogin = async event => {
    event.preventDefault();
    try {
      const data = await signInWithGooglePopuop();
      setLoading(true);
      registerWithFirebaseMutation.mutate(data.user.email);
      // LocalStorage.setItem(data.user.accessToken);
      // toast.success('You successfully logged in');

      // setCurrentUser(user)
      resetFormFields();
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        toast.error('email/password is incorrect!');
      } else if (error.code === 'auth/user-not-found') {
        toast.error('account is not exists!');
      } else {
        //console.log('error has happend', error)
      }
    }
  };

  const submitLogin = async (values) => {
    setLoading(true);
    setFormData({ email: values.email, password: values.password });
    localStorage.setItem('user-email', values.email);
    loginMutation.mutate(values);
  };

  return (
    <Fragment>
      {/* {screenAlert && (
        <Popup
          msgContent='breeze.ai requires a desktop to fully experience its immersive features. 
          Please sign in from a desktop'
          setPopup={setScreenAlert}
        />
      )} */}
      {loading && (
        <div className='fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity w-screen h-screen z-50
          flex justify-center items-center'>
          <div className='flex justify-center items-center'>
            <div className='spinner-border' role='status'>
              <ClipLoader color='#757D89' />
            </div>
          </div>
        </div>
      )}
      <AlertModal modalOpen={alertModalOpen} setModalOpen={setAlertModalOpen}
        header='Email verification required'
        body={errorMessage} />
      <div className='flex font-[Inter,sans-serif] flex-col justify-center sm:px-8 md:px-16 py-8 text-center
       bg-white backdrop-filter backdrop-blur-none'>
        <img src={logo} alt='logo' className='w-[160px] mx-auto' />
        <h2 className='text-[29px] mt-[26px] mb-[30px] text-black'>Welcome back</h2>
        <div className='flex justify-center items-center gap-2 py-1.5 w-full mx-auto bg-[#E8EDF8] 
        hover:bg-[#f1f4fa] rounded-lg cursor-pointer' onClick={handleFirebaseLogin}>
          <img src={googleImg} alt='google' className='w-[30px]' />
          <div className='text-[18px] font-semibold'>Continue with Google</div>
        </div>
        <div className='flex justify-between items-center mt-[10px] mb-[10px]'>
          <div className='border-t-[1px] border-[#5E6284] h-1 w-[calc(50%-50px)]'></div>
          <div className='text-xl'>OR</div>
          <div className='border-t-[1px] border-[#5E6284] h-1 w-[calc(50%-50px)]'></div>
        </div>
        {/* Formik component for handling the login form */}
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={LoginSchema} // Validation schema defined using Yup
          onSubmit={submitLogin}
        >
        <Form className='flex flex-col gap-4' noValidate>
          <div className=' text-left max-w-[450px] w-[100%] mx-auto'>
            <label htmlFor='email' className='font-semibold text-md'>Email Address</label>

            <Field
              type='email'
              id='email'
              name='email'
              placeholder='Enter your email address'
              className='mt-[5px] reg-inputs w-full text-sm border-gray-300 rounded-lg p-[10px] customTextBoxColor'
            />  
            {/* ErrorMessage component to display email validation errors */}
            <ErrorMessage
              name='email'
              component='div'
              className='text-red-600 text-xs mt-1'
            />
          </div>
          <div className=' text-left max-w-[450px] w-[100%] mx-auto'>
            <label htmlFor='password' className='font-semibold text-md'>Password</label>
            <Field
              type='password'
              id='password'
              name='password'
              placeholder='Enter your password'
              className='mt-[5px] reg-inputs w-full text-sm border-gray-300 rounded-lg p-[10px] customTextBoxColor'
            />
            {/* ErrorMessage component to display password validation errors */}
            <ErrorMessage
              name='password'
              component='div'
              className='text-red-600 text-xs mt-1'
            />
          </div>
          <AppButton
            type='submit'
            className='flex px-10 py-2 mx-auto my-4 text-lg text-white bg-black rounded-md 
            w-full max-w-[450px] justify-center'
            isFull
            id='LoginPage_CTA'
          >
            Login
          </AppButton>
        </Form>
        </Formik>
        <div id='signup__links' className='mt-2 font-semibold '>
          {/* commented the signup link in login page */}
          {/* Don&lsquo;t have an account?&nbsp;
          <Link
            to='/register'
            className='font-semibold underline'
            href=''
          >
            Sign Up
          </Link> */}
          <div className='text-sm mt-1'>
            <Link to='/recover-password' className='underline' href=''>
              Forgot Password?
            </Link>
          </div>
          <div className='text-sm mt-1'>
            By logging in, you agree to our terms of service and privacy policy
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginForm;
