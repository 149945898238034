import React, { useEffect, useMemo, useState, createRef, useRef } from 'react';
import { Stage, Layer, Transformer, Image as ImageKonva } from 'react-konva';
import backgroundMesh from 'assets/img/meshBgImageLarge.png';
import { ClipLoader } from 'react-spinners';
import IndividualImageForAdjustImage from './IndividualImagForAdustImage';

const AdjustImageCanvas = (props) => {
  const {
    backgroundImage,
    handleReset,
    reset,
    width,
    height,
    stageRef,
    originalImage,
    transformsData,
    canvasTransformerRef,
    handleImagesPosition,
    canvasHeight,
    canvasWidth,
    setIsImageMoved
  } = props;
  const [imgLoader, setImgLoader] = useState(true);
  const [images, setImages] = useState([]);
  const trRef = canvasTransformerRef;
  const imageRef = useRef(null);

  useEffect(() => {
    if (reset) {
      imageRef?.current?.position({ x: transformsData[0]?.marginLeft, y: transformsData[0].marginTop });
      imageRef?.current?.setRotation(transformsData[0].angle);
      imageRef?.current?.scaleX(transformsData[0].scaleX);
      imageRef?.current?.scaleY(transformsData[0].scaleY);
      handleReset(false);
      setIsImageMoved(false);
    }
  }, [reset]);

  const imageEle = useMemo(() => {
    const img = document.createElement('img');
    img.src = backgroundImage + '?v='+Math.random();
    img.onload = () => {
      const { naturalWidth, naturalHeight } = img;
      const imageWidth = naturalWidth || 500;
      const imageHeight = naturalHeight || 500;
      const widthFactor = imageWidth / width;
      const heightFactor = imageHeight / height;
      if (widthFactor > heightFactor) {
        img.height = (1 * imageHeight) / widthFactor;
        img.width = (1 * imageWidth) / widthFactor;
      } else {
        img.height = (1 * imageHeight) / heightFactor;
        img.width = (1 * imageWidth) / heightFactor;
      }
      setImgLoader(false);
    };
    img.crossOrigin = 'Anonymous';
    // setImgLoader(false);
    return img;
  }, [backgroundImage, width, height]);

  const getImagesFromTransformData = () => {
    transformsData?.map((image) => {
      var img1 = new Image();
      img1.src = image?.product_layer_url;
      img1.onload = function() {
        const leftRatio = image?.marginLeft / canvasWidth;
        const topRatio = image?.marginTop / canvasHeight;
        const imagesData = [
          {
            x: leftRatio ? leftRatio * width : image?.marginLeft,
            y: topRatio ? topRatio * height : image?.marginTop,
            scaleX: image?.scaleX || 1,
            scaleY: image?.scaleY || 1,
            angle: image?.angle || 0,
            imageUrl: image?.product_layer_url,
            current: createRef()
          }
        ];
        setImages(imagesData);
        trRef?.current?.nodes([]);
      };
    });
  };

  useEffect(() => {
    trRef?.current?.nodes([]);
    getImagesFromTransformData();
  }, [transformsData]);

  const handleList = () => {
    handleImagesPosition(images);
  };

  const generateImage = (imageSrc) => {
    const img = document.createElement('img');
    img.src = imageSrc;

    function onload() {
      const { naturalWidth, naturalHeight } = img;
      const widthFactor = naturalWidth / width;
      const heightFactor = naturalHeight / height;
      if (widthFactor > heightFactor) {
        img.height = ((0.4 * naturalHeight) / widthFactor);
        img.width = ((0.4 * naturalWidth) / widthFactor);
      } else {
        img.height = ((0.4 * naturalHeight) / heightFactor);
        img.width = ((0.4 * naturalWidth) / heightFactor);
      }
    }
    onload();
    img.onload = onload;
    return img;
  };

  return (
    <>
      {imgLoader ? (
        <div
          className='bg-canvasMeshBg flex items-center justify-center'
          style={{ width: width, height: height }}
        >
          <ClipLoader color='757D89' />
        </div>
      ) : (imageEle &&
        <Stage
          width={width}
          height={height}
          ref={stageRef}
          id='canvas'
          style={{
            border: (originalImage?.colour_image || originalImage?.primary_image) && '1px solid #757D89',
            margin: '2px',
            display: 'block',
            width: `${width}px`,
            height: `${height}px`,
            backgroundImage: `url(${backgroundMesh})`,
            backgroundSize: 'cover',
            boxSizing: 'content-box'
          }}
          onClick={() => {
            trRef?.current?.nodes([]);
          }}
          onTap={() => {
            trRef?.current?.nodes([]);
          }}
        >
          <Layer>
            <ImageKonva
              image={imageEle}
            />
          </Layer>
          <Layer>
            <Transformer
              enabledAnchors={[
                'top-left',
                'top-right',
                'bottom-left',
                'bottom-right'
              ]}
              keepRatio={true}
              aspect
              ref={trRef}
              style={{
                zIndex: '0'
              }}
              anchorStroke='black'
              anchorSize={12}
              borderStroke='black'
              className='rounded-5'
              onTransform={() => setIsImageMoved(true)}
            ></Transformer>
            {images?.map((image, index) => {
              return (
                <IndividualImageForAdjustImage
                  key={index}
                  image={image}
                  selectedImageUrl={image?.imageUrl}
                  imageItemRef={trRef}
                  handleList={handleList}
                  generateImage={generateImage}
                  setIsImageMoved={setIsImageMoved}
                  imageRef={imageRef}
                />
              );
            })}
          </Layer>
        </Stage>
      )}
    </>
  );
};

export default AdjustImageCanvas;
