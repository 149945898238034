import React from 'react';
import { AppButton } from 'components/shared/AppButton';
import { Formik, Form } from 'formik';
import ReactSelect from 'components/ReactSelectFilters';
import tickIcon from 'assets/img/tickIcon.svg';
import tickIconBlack from 'assets/img/tickiconBlack.svg';

const FilterPopup = ({
  filter,
  dropdownValues,
  handleDropdownInputChange,
  handleOnDropdownChange,
  selectedTags,
  handleClear,
  handleApplyFilter,
  selectFieldValues,
  selectedProjects,
  fileFormatList,
  selectedFilter,
  selectedFileFormat,
  selectedColor,
  setSelectedColor
}) => {

  const handleOptions = (optionList) => {
    const dropDownOptions = optionList?.map((item) => (
      {
        label: item,
        value: item
      }
    ));
    return dropDownOptions;
  };

  const handleFilterButtonDisable = (filterType) => {
    switch (filterType) {
      case 'Tags':
        return selectedTags?.length === 0;
      case 'Projects':
        return selectedProjects?.length === 0;
      case 'File format':
        return selectedFileFormat?.length === 0;
      case 'Color':
        return selectedColor?.length === 0;
      default: break;
    }
  };

  const colorList = ['#FE1016', '#FF903A', '#FFFF3B', '#3FB948', '#1AFEFE', '#0006F8', '#8D0CF8',
    '#FF86B7', '#D22D7D', '#FFE1AF', '#FFFDCD', '#AB5B24', '#FFFFFF', '#8E8E8E', '#000000'];

  const handleColor = (color) => {
    // Check if the color is already in the array
    const colorIndex = selectedColor.indexOf(color);

    if (colorIndex !== -1) {
      // If the color is found, remove it
      const updatedColors = [...selectedColor];
      updatedColors.splice(colorIndex, 1);
      setSelectedColor(updatedColors);
    } else {
      // If the color is not found, add it
      setSelectedColor([...selectedColor, color]);
    }

  };

  return (
    <div
      className={`${filter?.name === 'Color' ? 'w-[410px] min-h-[146px]' : 'w-[327px] min-h-[163px]'} 
      justify-center absolute top-[36px] z-10 bg-white rounded-[5px] shadow font-jost`}
      style={{ boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.10)' }}
    >
      <div className="p-[20px]">
        <div className='flex gap-[5px] items-center pb-[10px]'>
          <img src={filter?.normalIcon} alt='' className='w-[14px] h-[14px]' />
          <div className='font-[400] text-[12px] text-[#070707]'>
            {filter?.name}
          </div>
        </div>
        <div className="text-left text-[20px] font-[400]">
          {filter?.name !== 'Color' &&
            (<Formik>
              <Form>
                <ReactSelect
                  options={handleOptions(selectedFilter?.name !== 'File format' ? dropdownValues
                    : fileFormatList) || []}
                  placeholder={filter?.placeholder}
                  name='FilterDropdown'
                  noDropDownIndicator={selectedFilter?.name !== 'File format'}
                  handleDropdownInputChange={handleDropdownInputChange}
                  onChange={handleOnDropdownChange}
                  selectFieldValues={selectFieldValues}
                  noOptionMessage={filter?.noOptionMessage}
                  isMulti={true}
                  disableEnterKey
                />
              </Form>
            </Formik>)}
          {filter?.name === 'Color' &&
            (<div className='flex gap-[5px]'>
              {colorList?.map((result, index) => (
                <div key={index} className={`relative w-[20px] h-[20px] border-[1px]
                  ${result === '#EEECC1' && 'border-solid border-[#EEECC1]'} 
                  ${result === '#FFFFFF' && 'border-solid border-[#D9D9D9]'}`}
                  onClick={() => handleColor(result)}
                  style={{ background: `${result}` }}>
                  {selectedColor?.some(res => res === result) && (
                    <div className='absolute items-center flex justify-center leading-normal 
                    rounded-md w-full font-jost text-[10px] p-1 text-center text-[#fff]'
                    >
                      <img className='rounded'
                        src={(result === '#FFFF3B' || result === '#1AFEFE' || result === '#FFE1AF'
                          || result === '#FFFDCD' || result === '#FFFFFF') ? tickIconBlack : tickIcon} />
                    </div>
                  )}
                </div>
              ))}
            </div>
            )}
        </div>
        <div className='w-full flex justify-end items-center pt-[20px]'>
          <AppButton
            type="submit"
            extendClass='bg-white hover:bg-white !text-[12px] h-[32px] border-none'
            onClick={handleClear}
            disabled={handleFilterButtonDisable(filter?.name)}
          >
            Clear
          </AppButton>
          <AppButton
            type="submit"
            extendClass='disabled:!bg-[#D9D9D9] disabled:opacity-100 !text-[14px]
              !bg-[#0038FF] rounded-[5px] h-[32px] w-[72px]
              px-[20px] text-white flex justify-center items-center'
            disabled={handleFilterButtonDisable(filter?.name)}
            onClick={handleApplyFilter}
          >
            Apply
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default FilterPopup;