import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import ReactSelect from 'components/ReactSelectFormik';
import SelectImageStyles from './SelectImageStyles';
import cn from 'classnames';
import { BASE_URL } from 'utils/baseURL';
import TabButton from 'components/shared/TabButton';
import ColorPicker from './ColorPicker';

const SelectTheme = (props) => {
  const {
    selectedTheme,
    setSelectedTheme,
    setPrompt,
    isBulkUpload,
    selectedImageStyle,
    setSelectedImageStyle,
    // generateLoading,
    isImgGenerator,
    selectedColor,
    setSelectedColor,
    selectedImage,
    imageGenerationLoading
  } = props;
  const [selectedTab, setSelectedTab] = useState('images');
  const [themesLoading, setThemesLoading] = useState(false);
  const [themeCategories, setThemeCategories] = useState([]);
  // const [showCarousel, setShowCarousel] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [themes, setThemes] = useState([]);
  const [filteredThemes, setFilteredThemes] = useState();

  // const scrollContainerRef = useRef(null);

  const filterThemes = (category) => {
    setSelectedCategory(category);
    if (category === 'all') return setFilteredThemes(themes);

    const filteredThemesTemp = themes.filter(image => image.category === category);
    setFilteredThemes(filteredThemesTemp);
  };

  useEffect(() => {
    if (themes?.length)
      setFilteredThemes(themes);
  }, [themes]);

  const getThemesCategories = async () => {
    try {
      const categoriesData = await axios.get(
        BASE_URL + 'image-gen/theme-categories'
      );
      const categoriesListData = categoriesData?.data?.data?.categories;
      const categoriesList = categoriesListData.map((item) => (
        {
          label: item,
          value: item
        }
      ));
      setThemeCategories(categoriesList);
    } catch (error) {
      console.log(error);
    }
  };

  const getThemes = async () => {
    setThemesLoading(true);
    try {
      const themesData = await axios.get(BASE_URL + 'image-gen/themes-dummy');
      setThemes(themesData?.data?.data?.themes);
    } catch (error) {
      console.log(error);
    }
    setThemesLoading(false);
  };

  useEffect(() => {
    getThemes();
    getThemesCategories();
  }, []);

  useEffect(() => {
    if (selectedTab === 'images') {
      getThemes();
      getThemesCategories();
    }
  }, [selectedTab]);

  useEffect(() => {
    if (isImgGenerator) {
      setSelectedTab('form');
    } else {
      setSelectedTab('images');
    }
  }, [isImgGenerator]);

  const handleDropDownOptionFonts = () => {
    let fontSize = '20px';
    if (window?.innerWidth >= 1024) {
      fontSize = '16px';
    } else if (window?.innerWidth >= 1200) {
      fontSize = '18px';
    } else if (window?.innerWidth >= 1700) {
      fontSize = '20px';
    }
    return fontSize;
  };

  const customStyles = {
    valueContainer: provided => ({
      ...provided,
      height: '36px'
    }),
    singleValue: provided => ({
      ...provided,
      top: '60%',
      fontFamily: 'Jost',
      fontSize: handleDropDownOptionFonts(),
      color: '#333'
    }),
    menu: provided => ({
      ...provided,
      zIndex: 100
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    }),
    control: provided => ({
      ...provided,
      border: '1px solid #333 !important',
      height: '48px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#333 !important'
      }
    }),
    placeholder: provided => ({
      ...provided,
      fontFamily: 'Jost',
      fontSize: handleDropDownOptionFonts(),
      color: '#000'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      color: '#333333',
      '&:hover': {
        backgroundColor: !state.isSelected && '#f1f1f1'
      },
      '&:active': {
        backgroundColor: '#f1f1f1'
      },
      cursor: 'pointer', // added the cursor pointer to the options
      fontFamily: 'Jost',
      fontSize: handleDropDownOptionFonts()
    })
  };

  return (
    <div className='pb-[20px] pt-[20px] rounded bg-white w-full'>
      {!isImgGenerator &&
        <>
          <div className='rounded bg-white'>
            <div className='grid grid-cols-12 pb-[20px]' id='image-selector'>
              <div className='col-span-6 lg:col-span-6 xl-mid:col-span-5 2xl:col-span-4'>
                <TabButton
                  active={selectedTab === 'images'}
                  onClick={() => {
                    setSelectedTab('images');
                    setSelectedTheme();
                    setSelectedCategory('');
                  }}
                  className='lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost text-[#2C2C2C] text-left'
                  type='button'
                  style={{ padding: 0, text: 'nowrap' }}
                  activeClassName={{ color: '#333', borderBottom: '3px solid #000' }} 
                  fontWeight={selectedTab === 'images' ? '600' : '400'}
                >
                  Instant background
                </TabButton>
              </div>
              {!isBulkUpload && (
                <div className='col-span-6 lg:col-span-6 xl-mid:col-span-5 2xl:col-span-4'>
                  <TabButton
                    active={selectedTab !== 'images'}
                    onClick={() => {
                      setSelectedTab('form');
                      setSelectedTheme();
                      setPrompt({ theme: '', prompt: '' });
                      setSelectedCategory('');
                    }}
                    type='button'
                    className='lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost
                      p-0 text-[#2C2C2C] text-left'
                    activeClassName={{ color: '#333', borderBottom: '3px solid #000' }} 
                    fontWeight={selectedTab !== 'images' ? '600' : '400'}
                  >
                    Custom background
                  </TabButton>
                </div>
              )}
            </div>
          </div>
        </>
      }
      <div className='tab-content'>
        {selectedTab === 'images' && (
          <>
            <Formik initialValues={{}}>
              <Form>
                <ReactSelect
                  options={themeCategories || []}
                  name='themeCategories'
                  customId='value'
                  onChange={(e) => filterThemes(e.value)}
                  placeholder='Choose your product category'
                  customStyles={customStyles}
                // defaultValue={themeCategories[0]?.name}
                />
              </Form>
            </Formik>
            <div className='relative grid grid-cols-12 h-full max-w-full gap-3 pt-[20px]' id='themeContent'>
              <ColorPicker
                selectedTheme={selectedTheme}
                setSelectedTheme={setSelectedTheme}
                generateLoading={imageGenerationLoading}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                setPrompt={setPrompt}
                selectedImage={selectedImage}
              />
              {themesLoading ? (
                <div className='flex justify-center '>
                  <div className='spinner-border ' role='status'>
                    <ClipLoader color='#757D89' />
                  </div>
                </div>
              ) : (filteredThemes?.map((image, index) => (
                <div className={`col-span-4 aspect-square group relative w-full h-full
                  ${(selectedCategory === '' || !selectedImage) &&
                    'pointer-events-none opacity-[0.5]'}`}
                  id='themeImage' key={index} >
                  <div
                    className={`relative flex items-center justify-center shadow text-center 
                      max-h-full max-w-full w-full h-full ${!imageGenerationLoading && 'cursor-pointer'} rounded-md`}
                    key={index}
                    style={{
                      border:
                        selectedTheme?.image_url === image.image_url
                          ? '2px solid #757D89'
                          : '',
                      padding: '2px'
                    }}
                    onClick={() => {
                      if (!imageGenerationLoading) {
                        setSelectedTheme(image);
                        setPrompt({ theme: image?.theme, prompt: '' });
                      }
                    }}
                  >
                    <div
                      className={`relative aspect-square ${!imageGenerationLoading && 'cursor-pointer'}
                        rounded-md h-full`}
                      key={index}
                      style={{
                        border:
                          selectedTheme?.image_url === image.image_url
                            ? '2px solid white'
                            : ''
                      }}
                    >
                      <img
                        src={image?.image_url}
                        alt={image?.theme}
                        style={{ width: '100%', height: '100%' }}
                        className='smooth-image max-w-full max-h-full rounded-md object-fill'
                      />
                      <div
                        className={`absolute ${!imageGenerationLoading && 'cursor-pointer'} rounded-md w-full h-full`}
                        style={{
                          background: 'linear-gradient(0deg, #000 0%, rgba(217, 217, 217, 0.00) 50%)',
                          top: '0px'
                        }}
                      >
                        <div
                          className={`absolute bottom-0 ${!imageGenerationLoading && 'cursor-pointer'}  leading-normal 
                                rounded-md w-full font-jost lg:text-[14px] xl:text-[16px] xl-mid:text-[18px] font-[600]
                                flex p-1 text-center text-[#fff] justify-center`}
                          onClick={() =>
                            !imageGenerationLoading && setSelectedTheme(image)
                          }
                        >
                          {image.theme}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )))}
            </div>
          </>
        )}
        {selectedTab === 'form' && (
          <div className='flex flex-wrap gap-2'>
            <div className='w-full h-full'>
              <Formik>
                {() => (
                  <Form className='lg:text-[12px] xl:text-[14px] 2xl:text-[16px] font-jost flex flex-wrap'>
                    <label className={cn('font-[400] text-[#000] mb-2',
                      isImgGenerator
                      && 'lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-jost font-[600] pt-[10px] text-[#000]')}>
                      {isImgGenerator ? 'Describe the image you want to generate'
                        : 'Describe your background, tell Breeze what you want in your image.'}
                    </label>
                    <Field
                      type='text'
                      name='customBackGroundDetails'
                      className={cn('customTextBoxColor block w-full px-2 py-1 mb-1 lg:text-[12px] xl:text-[14px]',
                      '2xl:text-[16px] leading-normal text-gray-800 bg-white border border-[#94A3B8]',
                      'rounded-[4px] appearance-none pt-[10px]', imageGenerationLoading && 'cursor-not-allowed')}
                      component='textarea'
                      rows='4'
                      disabled={imageGenerationLoading}
                      placeholder='e.g. On a wooden table surrounded by a lake.'
                      style={{ resize: 'none' }}
                      onChange={e => e.target.value ? setPrompt({ prompt: e.target.value, theme: '' })
                        : setPrompt({ prompt: '', theme: '' })}
                    />
                  </Form>
                )}
              </Formik>
            </div>
            <SelectImageStyles
              selectedImageStyle={selectedImageStyle}
              setSelectedImageStyle={setSelectedImageStyle}
              generateLoading={imageGenerationLoading}
            />
          </div >
        )}
      </div >
    </div >
  );
};

export default SelectTheme;