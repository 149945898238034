import React from 'react';
import { useNavigate } from 'react-router-dom';
import Create from 'assets/NewIcons/Create Icon.svg';
import ProjectIcon from 'assets/icons/projectIcon.svg';
import Library from 'assets/NewIcons/Library Icon.svg';
import { getUserStatistics } from 'queryhook/accounts/getUserStatistics';
import { useQuery } from 'react-query';

const SidebarNew = () => {
  const navigate = useNavigate();
  const pageUrl = window?.location?.pathname;
  // const showCreateModule = JSON.parse(localStorage.getItem('showCreateModule') || true);
  const { data } = useQuery('userStatistics', getUserStatistics);
  const showCreateModule = data?.data?.create_module; 

  const handleSelected = (url) => {
    localStorage.removeItem('uploadImgGenLibrary');
    localStorage.removeItem('selectedTab');
    localStorage.setItem('isAddFromLibrary', JSON.stringify(false));
    navigate(url);
  };

  return (
    <div className='font-jost flex flex-col items-start w-[80px] pt-[49px] h-[calc(100vh-57px)] gap-8
     bg-[#f1f1f1] text-[16px] text-[#5F5F5F]'>
      <div className='flex flex-col gap-2'>
        <div className={`h-[80px] w-[80px] p-2 flex flex-col justify-center items-center cursor-pointer 
        ${pageUrl?.includes('/imageLibrary') && 'bg-white'}`}
          onClick={() => handleSelected('/imageLibrary')}>
          <img src={Library} alt='libraryIcon' />
          <div className='text-center text-[16px] font-[400] text-liteColor'>
            <span>Library</span>
          </div>
        </div>
        <div className={`h-[80px] w-[80px] p-2 flex flex-col justify-center items-center cursor-pointer 
        ${pageUrl?.includes('/Projects') && 'bg-white'}`}
          onClick={() => handleSelected('/Projects')}>
          <img src={ProjectIcon} alt='createIcon' width={29} height={24} />
          <div className='text-center text-[16px] font-[400] text-liteColor'>
            <span>Projects</span>
          </div>
        </div>
        {showCreateModule && (
          <div className={`h-[80px] w-[80px] p-2 flex flex-col justify-center items-center cursor-pointer 
          ${(!pageUrl?.includes('/imageLibrary') && !pageUrl?.includes('/Projects')) && 'bg-white'}`}
            onClick={() => handleSelected('/Templates')}>
            <img src={Create} alt='createIcon' />
            <div className='text-center text-[16px] font-[400] text-liteColor'>
              <span>Create</span>
            </div>
          </div>  
        )}
      </div>
    </div>
  );
};

export default SidebarNew;
