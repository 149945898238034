/* eslint-disable react/jsx-key */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import closeIcon from 'assets/icons/closeIcon.svg';
import ReactSelect from 'components/ReactSelectFormik';
import { Formik, Form } from 'formik';
import ImageEditCanvas from './ImageEditCanvas';
import { AppButton } from 'components/shared/AppButton';
import { BASE_URL } from 'utils/baseURL';
import { LocalStorage } from 'services/localStorage';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import GenerateImageList from './GenerateImageList';
import cn from 'classnames';
import TextField from 'components/commonFields/TextFieldFormik';
import AdjustImageCanvas from './AdjustImageCanvas';

const ImageEditor = (props) => {
  const {
    handleClose,
    selectedImage,
    creditPopupForEdit,
    projectID,
    documentID
  } = props;

  const [selectedOpt, setSelectedOpt] = useState();
  const [brushSize, setBrushSize] = useState(50);
  const [reset, setReset] = useState(false);
  const [imageEdited, setImageEdited] = useState(false);
  const [generatedImageList, setGeneratedImageList] = useState([]);
  const [changedCanvasWidth, setChangedCanvasWidth] = useState();
  const [changedCanvasHeight, setChangedCanvasHeight] = useState();
  const [imageEditLoading, setImageEditLoading] = useState(false);
  const [selectedEditImage, setSelectedEditImage] = useState();
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [showPointer, setShowPointer] = useState(false);
  const [showDefaultCursor, setShowDefaultCursor] = useState(true);
  const [dropDown, setDropDown] = useState();
  const [promptData, setPromptData] = useState();
  const [imageListData, setImageListData] = useState([]);
  const [imageLayersData, setImageLayersData] = useState();
  const [isImageMoved, setIsImageMoved] = useState(false);
  const canvasEleHeight = document?.getElementById('canvasElement');
  const canvasWidth = canvasEleHeight ? Math.ceil(canvasEleHeight?.offsetHeight * 0.75) : 500;
  const canvasHeight = canvasEleHeight ? Math.ceil(canvasEleHeight?.offsetHeight * 0.75) : 500;
  const stageRef = useRef();
  const canvasTransformerRef = useRef(null);

  const changePosition = (e) => {
    setShowPointer(true);
    setCursorPosition({ x: e?.clientX - 15, y: e?.clientY - 30 });
  };

  useEffect(() => {
    setSelectedEditImage(selectedImage);
    setGeneratedImageList([selectedImage]);
  }, [selectedImage]);

  const getImageLayers = async (gid) => {
    setShowDefaultCursor(true);
    setImageEditLoading(true);
    const data = new FormData();
    data.append('gid', gid);

    const url = BASE_URL + 'image-gen/getLayers';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response?.data?.data) {
        setImageLayersData(response?.data?.data);
      }
    }
    catch (err) {
      setImageEditLoading(false);
      setReset(true);
      if (err?.response?.status === 402) {
        creditPopupForEdit();
      }
    }
    finally {
      setImageEditLoading(false);
      setShowDefaultCursor(true);
    }
  };

  useEffect(() => {
    if (selectedOpt?.value === 'Adjust Product') {
      getImageLayers(selectedEditImage?.gid);
    }
  }, [selectedOpt, selectedEditImage]);

  useEffect(() => {
    const imageWidth = selectedImage?.dimensions[0];
    const imageHeight = selectedImage?.dimensions[1];
    if (selectedImage) {
      const widthFactor = imageWidth / (canvasWidth - 50);
      const heightFactor = imageHeight / (canvasHeight - 50);
      if (widthFactor > heightFactor) {
        setChangedCanvasHeight(Math.ceil(imageHeight / widthFactor));
        setChangedCanvasWidth(Math.ceil(imageWidth / widthFactor));
      } else {
        setChangedCanvasHeight(Math.ceil(imageHeight / heightFactor));
        setChangedCanvasWidth(Math.ceil(imageWidth / heightFactor));
      }
    } else {
      setChangedCanvasHeight(imageHeight / 3);
      setChangedCanvasWidth(imageWidth / 3);
    }
  }, [selectedImage, canvasWidth, canvasHeight]);

  const handleReset = (status) => {
    setReset(status);
  };

  const getDropDownTypes = async () => {
    const data = new FormData();
    data.append('gid', selectedImage?.gid);
    const url = BASE_URL + 'image-gen/gen-edit_options';
    try {
      const dropDownOption = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      const dropDownTypeData = dropDownOption?.data?.data?.options;
      if (dropDownTypeData && dropDownTypeData.length > 0) {
        const dropDownOptions = dropDownTypeData?.map((item) => (
          {
            label: item?.title,
            value: item?.title
          }
        ));
        setDropDown(dropDownOptions);
      }
    }
    catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDropDownTypes();
  }, []);

  const handleGenerateImage = async () => {
    setShowDefaultCursor(true);
    const imageEditorData = stageRef?.current?.toDataURL()?.split(';base64,')[1];
    setImageEditLoading(true);
    const data = new FormData();
    data.append('gid', selectedEditImage?.gid);
    data.append('image_url', selectedEditImage?.url || selectedEditImage?.edited_url);
    data.append('mask_image', imageEditorData);
    data.append('gen_edit_option', selectedOpt?.value);
    data.append('prompt', promptData);
    if (projectID) data.append('projectID', projectID);
    if (documentID) data.append('documentID', documentID);

    const url = BASE_URL + 'image-gen/gen-edit';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response?.data?.data) {
        setGeneratedImageList(prev => [
          {
            ...response?.data?.data
          }, ...prev]);
        setSelectedEditImage(response?.data?.data);
        setReset(true);
      }
    }
    catch (err) {
      setImageEditLoading(false);
      setReset(true);
      if (err?.response?.status === 402) {
        creditPopupForEdit();
      }
    }
    finally {
      setImageEditLoading(false);
      setShowDefaultCursor(true);
    }
  };

  const handleAdjustProductGenerateImage = async () => {
    setShowDefaultCursor(true);
    setImageEditLoading(true);
    canvasTransformerRef?.current?.nodes([]);
    const listValues = imageListData?.map((item) => {
      return {
        pid: selectedEditImage?.pid,
        marginLeft: item?.current?.attrs?.x ? (item?.current?.attrs?.x) : item?.x || 0,
        marginTop: item?.current?.attrs?.y ? (item?.current?.attrs?.y) : item?.y || 0,
        scaleX: item?.current?.attrs?.scaleX || 1,
        scaleY: item?.current?.attrs?.scaleY || 1,
        angle: item?.current?.attrs?.rotation || 0
      };
    });
    const data = new FormData();
    data.append('gid', selectedEditImage?.gid);
    data.append('gen_edit_option', selectedOpt?.value);
    data.append('canvas_width', changedCanvasWidth);
    data.append('canvas_height', changedCanvasHeight);
    data.append('coordinates_str', JSON.stringify(listValues));
    data.append('image_url', selectedEditImage?.url || selectedEditImage?.edited_url);
    if (projectID) data.append('projectID', projectID);
    if (documentID) data.append('documentID', documentID);

    const url = BASE_URL + 'image-gen/gen-edit';
    try {
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: LocalStorage.getItem()
          }
        }
      );
      if (response?.data?.data) {
        setGeneratedImageList(prev => [
          {
            ...response?.data?.data
          }, ...prev]);
        setSelectedEditImage(response?.data?.data);
        getImageLayers(response?.data?.data?.gid);
        setReset(true);
      }
    }
    catch (err) {
      setImageEditLoading(false);
      setReset(true);
      if (err?.response?.status === 402) {
        creditPopupForEdit();
      }
    }
    finally {
      setImageEditLoading(false);
      setShowDefaultCursor(true);
      setIsImageMoved(false);
    }
  };

  const handleImagesPosition = (listData) => {
    // not changing the image position data un-till images are generated
    if (listData) {
      setImageListData(listData);
    }
  };

  const handleSelectedImage = () => {
    return (
      <div className=''>
        {(selectedEditImage) && (
          <div className='h-[80%] relative'>
            {selectedOpt?.value !== 'Adjust Product' && (
              <ImageEditCanvas
                backgroundImage={selectedEditImage?.url || selectedEditImage?.edited_url}
                brushSize={brushSize}
                selectedOpt={selectedOpt}
                handleReset={handleReset}
                reset={reset}
                setImageEdited={setImageEdited}
                selectedImage={selectedEditImage}
                width={changedCanvasWidth || (selectedEditImage?.dimensions[0] / 3)}
                height={changedCanvasHeight || (selectedEditImage?.dimensions[1] / 3)}
                stageRef={stageRef}
                originalImage={selectedImage}
              />
            )}
            {selectedOpt?.value === 'Adjust Product' && (
              <AdjustImageCanvas
                backgroundImage={imageLayersData?.bg_layer_url}
                handleReset={handleReset}
                reset={reset}
                selectedImage={selectedEditImage}
                width={changedCanvasWidth || (imageLayersData?.dimensions[0] / 3)}
                height={changedCanvasHeight || (imageLayersData?.dimensions[1] / 3)}
                stageRef={stageRef}
                originalImage={selectedImage}
                transformsData={imageLayersData?.transforms}
                canvasTransformerRef={canvasTransformerRef}
                handleImagesPosition={handleImagesPosition}
                imageListData={imageListData}
                canvasHeight={imageLayersData?.canvas_height}
                canvasWidth={imageLayersData?.canvas_width}
                setIsImageMoved={setIsImageMoved}
              />
            )}
            {imageEditLoading && (
              <div
                className='absolute rounded-md w-full h-full'
                style={{
                  background: 'rgba(0, 0, 0, 0.3)',
                  top: '0px'
                }}
              >
                <div className='absolute items-center flex justify-center leading-normal w-full h-full'>
                  <ClipLoader color='#F0F0F0' />
                </div>
              </div>
            )}
          </div>
        )}
        <div className='h-[64px]'>
          {imageEdited && selectedOpt?.value === 'Generative Insert' && (
            <Formik initialValues={{}}>
              <Form
                className='pt-[15px] w-full gap-[18px] flex justify-center items-center h-full relative'
                onMouseOver={() => setShowDefaultCursor(false)}
                onMouseOut={() => setShowDefaultCursor(true)}
              >
                <div className='w-full'>
                  <TextField
                    name='prompt'
                    type='search'
                    disabled={!imageEdited}
                    onChange={(e) => setPromptData(e?.target?.value)}
                    placeholder='What would you like to generate?'
                    customClass='h-[48px] text-[20px] font-[400] text-[#000000]'
                  />
                </div>
                <AppButton
                  type='button'
                  variant='black'
                  disabled={!imageEdited || !promptData}
                  isLoading={imageEditLoading}
                  onClick={() => handleGenerateImage()}
                  extendClass='bg-black hover:bg-black rounded text-[14px] font-[400] h-[48px] w-[148px] !py-[2px] !px-2 absolute right-[-160px] top-[18px]'
                >
                  Generate
                </AppButton>
              </Form>
            </Formik>
          )}
        </div>
      </div>
    );
  };

  const handleBrushSize = (data) => {
    setBrushSize(data?.target?.value);
  };

  const handleInput = () => {
    return (
      <input
        type="range"
        min="1"
        max="100"
        disabled={imageEditLoading}
        onChange={handleBrushSize}
        className="bg-neutral-200 dark:bg-neutral-600
          range ring-white accent-black focus:accent-black w-full h-1 border-0
          rounded-lg cursor-pointer"
      />
    );
  };

  const handleSelectedOption = (data) => {
    if (imageEdited || isImageMoved) {
      handleReset(true);
    }
    setSelectedOpt(data);
  };

  return (
    <div
      className="relative z-50 flex items-center justify-center font-jost"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="relative flex w-full h-full items-end justify-center px-4 py-8 text-center">
          <div
            className="sticky w-full h-full bottom-2/4 transform overflow-hidden rounded-lg bg-white
            text-left shadow-xl transition-all"
          >
            <div className="bg-white w-full h-full">
              <div className='flex w-full h-full'>
                <div className='w-[482px] h-full border-r-2 p-2'>
                  <div className='lg:text-[26px] xl:text-[28px] 2xl:text-[30px] font-jost font-[400] border-b pl-2'>
                    Edit Image
                  </div>
                  <Formik initialValues={{ generativeErase: '' }}>
                    <Form className='px-[20px] pt-[24px]'>
                      <ReactSelect
                        options={dropDown || []}
                        placeholder='Select'
                        label='What do you want to do?'
                        name='generativeErase'
                        onChange={handleSelectedOption}
                      />
                    </Form>
                  </Formik>
                  <div className='p-[20px]'>
                    {selectedOpt && selectedOpt?.value !== 'Adjust Product' && (
                      <>
                        <div className='text-[20px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-[400]'>
                          {selectedOpt?.value === 'Generative Erase'
                            ? 'Erase any unwanted object (along with its shadows, reflection) with the brush'
                            : 'Select an area on the image where you want to Insert any object'
                          }
                        </div>
                        <label
                          className="block text-sm font-medium text-gray-900 dark:text-white py-[20px]
                          text-[18px] lg:text-[14px] xl:text-[16px] 2xl:text-[18px]"
                        >
                          Brush size
                        </label>
                        {handleInput()}
                        <div className='flex justify-end items-center py-[20px]'>
                          <AppButton
                            type='button'
                            variant='white'
                            onClick={() => selectedOpt && handleReset(true)}
                            disabled={!imageEdited || imageEditLoading}
                            extendClass={cn('lg:col-span-12 xl-mid:col-span-6 p-0 bg-black hover:bg-black rounded !text-[16px] font-[400] h-[48px] w-[180px] mr-[10px] !py-[2px] !px-2',
                              !(!imageEdited || imageEditLoading) && 'border-[1px] !border-[#000] !text-black',
                              selectedOpt?.value !== 'Generative Erase' && 'xl-mid:col-start-7')}
                          >
                            Clear & Restore
                          </AppButton>
                          {selectedOpt?.value === 'Generative Erase' && (
                            <AppButton
                              type='button'
                              variant='black'
                              disabled={!imageEdited}
                              isLoading={imageEditLoading}
                              onClick={() => handleGenerateImage()}
                              extendClass='lg:col-span-12 xl-mid:col-span-6 p-0 bg-black hover:bg-black rounded !text-[16px] font-[400] h-[48px] w-[180px] !py-[2px] !px-2'
                            >
                              Remove & Generate
                            </AppButton>
                          )}
                        </div>
                      </>
                    )}
                    {selectedOpt?.value === 'Adjust Product' && (
                      <>
                        <div className='text-[20px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] font-[400]'>
                          <p>You can click on product to</p>
                          <ul style={{ listStyleType: 'disc' }} className='px-[24px]'>
                            <li><strong>Move</strong> your product</li>
                            <li><strong>Change size</strong> of product</li>
                            <li><strong>Rotate</strong> your product</li>
                          </ul>
                        </div>
                        <div className='flex justify-end items-center py-[20px]'>
                          <AppButton
                            type='button'
                            variant='white'
                            onClick={() => selectedOpt && handleReset(true)}
                            disabled={!isImageMoved || imageEditLoading}
                            extendClass={cn('lg:col-span-12 xl-mid:col-span-6 p-0 bg-black hover:bg-black rounded !text-[16px] font-[400] h-[48px] w-[180px] mr-[10px] !py-[2px] !px-2',
                              !(!isImageMoved || imageEditLoading) && 'border-[1px] !text-black !border-[#000]')}
                          >
                            Restore
                          </AppButton>
                          <AppButton
                            type='button'
                            variant='black'
                            disabled={!isImageMoved}
                            isLoading={imageEditLoading}
                            onClick={() => handleAdjustProductGenerateImage()}
                            extendClass='lg:col-span-12 xl-mid:col-span-6 p-0 bg-black hover:bg-black rounded !text-[16px] font-[400] h-[48px] w-[180px] !py-[2px] !px-2'
                          >
                            Generate
                          </AppButton>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className='flex-[1] h-full'>
                  <div className='w-full h-[78%] p-4 relative'>
                    <div className='flex justify-end pb-3'>
                      <div role='presentation' onClick={() => handleClose()}>
                        <img src={closeIcon} alt='' width='18px' height='18px' />
                      </div>
                    </div>
                    <div
                      className={cn('flex justify-center items-center w-full h-[90%] overflow-auto', selectedOpt && selectedOpt?.value !== 'Adjust Product' && 'cursor-none')}
                      id='canvasElement'
                      onMouseMove={changePosition}
                      onMouseLeave={() => { setShowPointer(false); }}
                    >
                      {(showDefaultCursor && showPointer && selectedOpt && selectedOpt?.value !== 'Adjust Product') && <div className="customCursor"
                        style={{
                          left: cursorPosition?.x + 'px',
                          top: cursorPosition?.y + 'px',
                          width: brushSize + 'px',
                          height: brushSize + 'px'
                        }} />}
                      {handleSelectedImage()}
                    </div>
                    <p className='lg:text-[16px] xl:text-[18px] 2xl:text-[20px]
                      font-jost font-[600] p-2 absolute bottom-0 text-primaryColor'>
                      Generated Images
                    </p>
                  </div>
                  <div className='h-[22%] fixed border-t-2 p-4 mb-[20px]'>
                    <div className='max-h-full w-full overflow-scroll'>
                      <div className='grid grid-cols-7 2xl:grid-cols-8 
                        grid-flow-row gap-2 items-center'>
                        {generatedImageList?.map((image, index) => (
                          <GenerateImageList
                            image={image}
                            clickedImage={selectedEditImage}
                            setClickedImage={setSelectedEditImage}
                            handleReset={handleReset}
                            index={index}
                            generateLoading={imageEditLoading}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageEditor;