import React, { createRef, useState, useRef, useEffect } from 'react';
import { Layer, Stage,  Image as KonvaImage } from 'react-konva';
import cn from 'classnames';

const ImageListingCanvas = ({
  imgSrc = '',
  width,
  height,
  stageRef,
  canvasTransformerRef,
  handleImagesPosition
}) => {
  let imageUrl = imgSrc;
  const imageRef = useRef();
  const trRef = canvasTransformerRef;
  const containerRef = useRef(null);
  const [images, setImages] = useState([]);
  const [imagePos, setImagePos] = useState();

  const handleList = () => {
    handleImagesPosition(images);
  };

  useEffect(() => {
    var image = new Image();
    image.src = imgSrc;
    let imageWidth, imageHeight;
    image.onload = function() {
      imageWidth = image.width;
      imageHeight = image.height;
      const widthFactor = imageWidth / width;
      const heightFactor = imageHeight / height;
      if (widthFactor > heightFactor) {
        image.height = ((0.8 * imageHeight) / widthFactor);
        image.width = ((0.8 * imageWidth) / widthFactor);
      } else {
        image.height = ((0.8 * imageHeight) / heightFactor);
        image.width = ((0.8 * imageWidth) / heightFactor);
      }
      setImagePos({ width: image?.width, height: image?.height });
      setImages([{
        x: image?.width ? (width/2 - image?.width/2) : width / 3,
        y: image?.height ? (height/2 - image?.height/2) : height / 3,
        current: createRef()
      }]);
      trRef?.current?.nodes([]);
    };
  }, [imageUrl]);

  const generateImage = (imageSrc) => {
    const img = document.createElement('img');
    img.src = imageSrc;

    function onload() {
      const { naturalWidth, naturalHeight } = img;
      const widthFactor = naturalWidth / width;
      const heightFactor = naturalHeight / height;
      if (widthFactor > heightFactor) {
        img.height = ((0.8 * naturalHeight) / widthFactor);
        img.width = ((0.8 * naturalWidth) / widthFactor);
      } else {
        img.height = ((0.8 * naturalHeight) / heightFactor);
        img.width = ((0.8 * naturalWidth) / heightFactor);
      }
    }
    onload();
    img.onload = onload;
    return img;
  };

  return (
    <div
      ref={containerRef}
      className={cn('rounded-md bg-white mr-0 bg-canvasWithGuideline bg-cover')}
    >
      <Stage
        onClick={() => {
          trRef?.current?.nodes([]);
        }}
        onTap={() => {
          trRef?.current?.nodes([]);
        }}
        ref={stageRef}
        width={width}
        height={height}
      >
        <Layer>
          <KonvaImage
            ref={imageRef}
            alt='Image'
            className='rounded-5'
            style={{ zIndex: 1 }}
            image={generateImage(imgSrc)}
            draggable={false}
            onClick={() => handleList()}
            onTap={() => handleList()}
            x={imagePos?.width ? (width/2 - imagePos?.width/2) : width / 3}
            y={imagePos?.height ? (height/2 - imagePos?.height/2) : height / 3}
          />
        </Layer>
      </Stage>
    </div>
  );
};

export default ImageListingCanvas;
