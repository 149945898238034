import React from 'react';
import OpenIcon from 'assets/imgFunctionIcons/open.svg';
import shareIcon from 'assets/imgFunctionIcons/share.svg';
import download from 'assets/imgFunctionIcons/download.svg';
import addIcon from 'assets/imgFunctionIcons/addIcon.svg';
import moveIcon from 'assets/imgFunctionIcons/moveIcon.svg';
import rename from 'assets/imgFunctionIcons/rename.svg';
import removeIcon from 'assets/imgFunctionIcons/remove.svg';
import deleteIcon from 'assets/imgFunctionIcons/delete.svg';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import axios from 'axios';
import { LocalStorage } from 'services/localStorage';
import { BASE_URL } from 'utils/baseURL';
import { handleMultiImageDownload } from 'utils/commonFunctions';

const ImageClickFunctions = ({
  setImgFunctionPopUp,
  handleImageClick,
  imageData,
  // position true means it's the last item of the row
  position,
  dropdownPosition,
  viewType,
  multiSelectCount,
  isUploadImagesOnly,
  multiSelectedItems,
  setShareLinkKeyData,
  setShareLinkLoading,
  setIsAllowDownload,
  setIsImgClickPopUps,
  isImgClickPopUps,
  projectShareKey,
  projectShareAllowDownload
}) => {
  console.log('proj', projectShareAllowDownload);
  const { projectID } = useParams();

  const getShareLinkKeyData = async () => {
    setShareLinkKeyData();
    setShareLinkLoading(true);
    try {
      const data = new FormData();
      if (imageData?.pid && !imageData?.gid) data.append('pid', imageData?.pid);
      if (imageData?.gid) data.append('gid', imageData?.gid);
      const url = BASE_URL + 'share_link/getShareKey';
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            token: LocalStorage.getItem()
          }
        });
      if (response && response.data) {
        setShareLinkKeyData(response?.data?.data);
        setIsAllowDownload(response?.data?.data?.allow_download);
      }
    } catch (error) {
      console.log(error);
    }
    setShareLinkLoading(false);
  };

  // To handle multi image download
  const handleMultiDownload = async () => {
    if (imageData?.selected) {
      handleMultiImageDownload(multiSelectedItems);
    }
  };

  const handleDownload = () => {
    const tempFileName = imageData?.input_image_url
      ? imageData?.input_image_url.split('/') : imageData?.url.split('/');
    const imageName = tempFileName[tempFileName.length - 1];
    const name = imageData?.image_name || imageName || 'BreezeAppImage.png';

    fetch(imageData?.input_image_url ? imageData?.input_image_url : imageData?.url,
      {
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache' }
      })
      .then(response => response?.blob())
      .then(blob => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';

        if (name?.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch((err) => console.log(err));
  };

  const handleShareLink = () => {
    getShareLinkKeyData();
    setImgFunctionPopUp(false);
    setIsImgClickPopUps({
      ...isImgClickPopUps,
      shareLink: true
    });
  };

  const handlePopUps = (popUpName) => {
    setImgFunctionPopUp(false);
    setIsImgClickPopUps({
      ...isImgClickPopUps,
      [popUpName]: true
    });
  };

  const menuListData = [
    {
      icon: OpenIcon,
      label: 'Open',
      isDisabled: false,
      handleClick: () => handleImageClick(),
      isVisible: (imageData?.selected && multiSelectCount > 1 ? false : true)
    },
    {
      icon: shareIcon,
      label: 'Share a link',
      isDisabled: false,
      handleClick: () => handleShareLink(),
      isVisible: (projectShareKey && multiSelectCount <= 1) ? projectShareAllowDownload :
        (imageData?.selected && multiSelectCount > 1 ? false : true)
    },
    {
      icon: download,
      label: 'Download',
      isDisabled: false,
      handleClick: () => imageData?.selected && multiSelectCount > 1 ? handleMultiDownload() : handleDownload(),
      isVisible: true
    },
    {
      icon: addIcon,
      label: 'Add to project',
      isDisabled: false,
      handleClick: () => handlePopUps('addTo'),
      isVisible: !projectShareKey && (((imageData?.selected && isUploadImagesOnly) ? true : false) || 
        !imageData?.selected && !imageData?.gid)
    },
    {
      icon: moveIcon,
      label: 'Move to',
      isDisabled: false,
      handleClick: () => handlePopUps('moveTo'),
      isVisible: !projectShareKey && (projectID || (imageData?.selected && multiSelectCount > 1 && false))
    },
    {
      icon: rename,
      label: 'Rename',
      isDisabled: false,
      handleClick: () => handlePopUps('renameImg'),
      isVisible: !projectShareKey && ((imageData?.selected && multiSelectCount > 1 ? false : true))
    },
    {
      icon: removeIcon,
      label: 'Remove from this project',
      isDisabled: false,
      handleClick: () => handlePopUps('removeImg'),
      isVisible: !projectShareKey && (projectID || (imageData?.selected && multiSelectCount > 1 && false))
    },
    {
      icon: deleteIcon,
      label: 'Delete',
      isDisabled: false,
      handleClick: () => handlePopUps('deleteImg'),
      isVisible: !projectShareKey
    }
  ];

  const visibleItems = menuListData.filter(item => item?.isVisible);
  return (
    <div
      className={`w-[220px] h-auto justify-center absolute z-[5]
      ${position ? 'right-[0px]' : (viewType === 'list' ? 'left-[0px]' : 'left-[32px]')} 
      ${dropdownPosition === 'top' ? 'bottom-[35px]' : 'top-[35px]'} bg-white rounded-[5px] font-jost`}
      style={{ boxShadow: '0px 4px 4px 4px rgba(0, 0, 0, 0.10)', border: '1px solid #D9D9D9' }}
      onMouseLeave={() => setImgFunctionPopUp(false)}
    >
      <div className="p-[10px] flex flex-col text-[14px] text-[#333]">
        {(imageData?.selected && multiSelectCount > 0) && (
          <div
            className={cn('flex items-center justify-center gap-[10px] w-full h-[40px] p-[10px] rounded-[5px]')}
          >
            <span>{multiSelectCount} {multiSelectCount > 1 ? 'items' : 'item'} selected</span>
          </div>
        )}
        {visibleItems?.map((item, index) => {
          return (
            <div
              key={index}
              className={cn('flex items-center gap-[10px] w-full h-[40px] p-[10px] rounded-[5px] hover:bg-[#F0F0F0]',
                item?.isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer')}
              onClick={(e) => {
                e.stopPropagation();
                item?.handleClick();
              }}
            >
              <img src={item?.icon} alt='' className='w-[20px] h-[20px]' />
              <div className='leading-[20px]'>{item?.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageClickFunctions;