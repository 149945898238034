import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import CaretLeft from 'assets/img/LeftArrow.png';
import CaretRight from 'assets/img/RightArrow.png';
import { BASE_URL } from 'utils/baseURL';

const ImageStyleSelection = (props) => {
  const {
    selectedImageStyle,
    setSelectedImageStyle,
    generateLoading
  } = props;
  const [imageStyleLoading, setImageStyleLoading] = useState(false);
  const [showCarousel, setShowCarousel] = useState(false);

  const [imageStyles, setImageStyles] = useState([]);

  const imageStyleDiv = useRef(null);

  const getImageStyles = async () => {
    setImageStyleLoading(true);
    try {
      const imageStylesData = await axios.get(
        BASE_URL + 'image-gen/background_style'
      );
      setImageStyles(imageStylesData?.data?.data?.background_style);
    } catch (err) {
      console.log(err);
    }
    setImageStyleLoading(false);
  };

  useEffect(() => {
    getImageStyles();
  }, []);

  const calculateScroll = () => {
    let scrollLeftOrRight = 0;
    const styleImage = document.getElementById('styleImage');
    const styleImageWidth = styleImage?.clientWidth;
    const scrollableDivWidth = imageStyleDiv?.current?.clientWidth;
    let noOfImagesInView = scrollableDivWidth / styleImageWidth;
    noOfImagesInView = noOfImagesInView % 1 >= 0.99 ? Math.ceil(noOfImagesInView) : Math.floor(noOfImagesInView);
    scrollLeftOrRight = (noOfImagesInView * styleImageWidth) + (4 * noOfImagesInView); //Gap between images is 4px
    return scrollLeftOrRight;
  };

  const scrollToLeft = () => {
    const scrollLeft = calculateScroll();
    if (imageStyleDiv.current) {
      imageStyleDiv.current.scrollBy({
        top: 0,
        left: -scrollLeft,
        behavior: 'smooth'
      });
    }
  };

  const scrollToRight = () => {
    const scrollLeft = calculateScroll();
    if (imageStyleDiv.current) {
      imageStyleDiv.current.scrollBy({
        top: 0,
        left: scrollLeft,
        behavior: 'smooth'
      });
    }
  };

  const getImageStyleScrollableDivSize = () => {
    const imageStyleDiv = document.getElementById('imageStyleContent');
    const styleImage = document.getElementById('styleImage');
    const imageStylesData = imageStyles?.length * styleImage?.clientWidth;
    const gap = 4 * imageStyles?.length; // Gap between the images is 8px
    if ((imageStylesData + gap) > imageStyleDiv?.clientWidth) {
      setShowCarousel(true);
    } else {
      setShowCarousel(false);
    }
  };

  useEffect(() => {
    getImageStyleScrollableDivSize();
  }, [imageStyles]);

  // useEffect(() => {
  //   window.onresize = () => {
  //     getImageStyleScrollableDivSize();
  //   }
  // }, []);

  return (
    <div className='rounded bg-white'>
      <div className='text-[20px] font-jost font-[600] pt-[10px] mb-2 text-[#111]'>
        Select image style
      </div>
      <div className='tab-content'>
        <div className='relative grid grid-cols-18' id='imageStyleContent'>
          {!imageStyleLoading && imageStyles && showCarousel && (
            <div className='col-span-1 flex'>
              <button className='absolute -left-[4px] flex self-center' onClick={() => scrollToLeft()}>
                <img src={CaretLeft} height='17px' width='17px' alt='' />
              </button>
            </div>
          )}
          <div className={`${!showCarousel ? 'col-span-18' : 'col-span-16'}`}>
            <div className='flex relative'>
              <div className='flex flex-rows overflow-hidden gap-1' ref={imageStyleDiv}>
                {imageStyleLoading ? (
                  <div className='flex justify-center '>
                    <div className='spinner-border ' role='status'>
                      <ClipLoader color='#757D89' />
                    </div>
                  </div>
                ) : (imageStyles?.map((image, index) => (
                  <div key={index} className='flex-none' id='styleImage'>
                    <div
                      className={`relative ${!generateLoading && 'cursor-pointer'} rounded-md
                      w-[70px] lg:w-[95px] 2xl:w-[110px] 3xl:w-[110px]
                      h-[70px] lg:h-[95px] 2xl:h-[110px] 3xl:h-[110px]`}
                      key={index}
                      style={{
                        border:
                          selectedImageStyle?.image_url === image.image_url
                            ? '2px solid #757D89'
                            : '',
                        margin: '2px'
                      }}
                    >
                      <div
                        className={`relative ${!generateLoading && 'cursor-pointer'} rounded-md h-full`}
                        key={index}
                        style={{
                          border:
                            selectedImageStyle?.image_url === image.image_url
                              ? '2px solid white'
                              : ''
                        }}
                      >
                        <img
                          src={image.image_url}
                          alt=''
                          onClick={() => {
                            !generateLoading && setSelectedImageStyle(image);
                          }}
                          style={{ width: '100%', height: '100%' }}
                          className='w-full h-full rounded-md object-cover'
                        />
                      </div>
                    </div>
                    <div
                      className='text-center mt-2 text-[14px] font-jost 
                      text-primaryColory font-[400]'
                      onClick={() => {
                        !generateLoading && setSelectedImageStyle(image);
                      }}
                    >
                      {image.theme}
                    </div>
                  </div>
                )))}
              </div>
            </div>
          </div>
          {!imageStyleLoading && imageStyles && showCarousel && (
            <div className='col-span-1 flex'>
              <button className='absolute -right-[4px] flex self-center' onClick={() => scrollToRight()}>
                <img src={CaretRight} height='17px' width='17px' alt='' />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageStyleSelection;